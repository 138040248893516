[data-component='text'],
[data-component='title'] {
  color: var(--emu-common-colors-white);
  font: var(--emu-semantic-typography-narrow-body-regular);

  p,
  li {
    margin: var(--emu-common-spacing-none);
  }

  b {
    sup {
      font-weight: var(--emu-common-font-weight-light);
      @include mq('large') {
        font-size: 50%;
      }
    }
  }

  sup {
    font-size: 60%;
    font-weight: 300;
    position: relative;
    vertical-align: top;
    top: -2px;
    font-family: inherit;
  }

  // makes sure that the content is in one line and does not break
  // used for brand names mostly
  .emphasis {
    display: inline-block;
  }

  h2 {
    sup {
      font-weight: var(--emu-common-font-weight-light);
    }
  }
}

.break--mobile {
  br {
    display: block;

    @include mq('large') {
      display: none;
    }
  }
}

.break--desktop {
  br {
    display: none;

    @include mq('large') {
      display: block;
    }
  }
}

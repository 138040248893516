[data-component='modal'].emu-modal {
  &.modal--has-video {
    &,
    &.modal--fullscreen {
      .modal-content {
        padding: var(--emu-common-spacing-none);
      }
    }

    .modal-content {
      background-color: var(--emu-common-colors-transparent);

      .plyr__controls {
        bottom: 10px;
      }
    }

    .close {
      @include mq('1023px', 'max-width') {
        background-image: url('../../assets/images/modal-close-white.png');
      }
    }

    // fixes the aspect ratio of videos in different screen sizes
    .plyr .plyr__video-embed {
      aspect-ratio: auto !important; // important is needed to override the inline style being added via JS
    }

    .plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
      padding: var(--emu-common-spacing-none);
      height: 100vh;
      transform: none !important; // important is needed to override the inline style being added via JS

      @include aem-editor-view {
        height: 900px;
      }
    }

    .emu-embed,
    .plyr--video,
    .plyr__video-wrapper.plyr__video-embed {
      background-color: var(--emu-common-colors-transparent);
    }
  }

  &.modal--fullscreen {
    .modal-wrapper {
      padding: var(--emu-common-spacing-none);
    }

    .modal-content {
      max-width: 100%;
      min-height: 100%;
      padding-top: 40px;
      padding-right: 20px;
      padding-bottom: calc(
        113px + 88px
      ); // 113 height of ISI, 88 is space for the button
      padding-left: 20px;
      border-radius: var(--emu-common-border-radius-none);
      top: 0;

      @include mq('large') {
        padding-left: 72px;
        padding-right: 72px;
        padding-bottom: calc(
          113px + 17px
        ); // 113 height of ISI, 17 is additional space
      }
    }

    .close {
      position: fixed;
      z-index: var(--emu-common-other-z-index-modal);
    }
  }

  .modal-wrapper {
    opacity: 0;
    visibility: hidden;
    display: flex;
    align-items: safe center;
    justify-content: center;
    transition: all 0.4s ease-out;
    overflow: auto;
    padding-left: 20px;
    padding-right: 20px;

    &.is-open {
      opacity: 1;
      visibility: visible;
      transition: all 0.4s ease-in;

      .close {
        visibility: visible;
      }

      .modal-content {
        top: 0;
        opacity: 1;
        transition: all 0.4s ease-in;
      }
    }
  }

  .modal-content {
    margin-top: auto;
    margin-right: var(--emu-common-spacing-none);
    margin-bottom: auto;
    margin-left: var(--emu-common-spacing-none);
    width: 100%;
    opacity: 0;
    position: relative;
    top: 50px;
    transition: all 0.4s ease-out;
    border-radius: 12px;
  }

  .close {
    width: var(--emu-common-sizing-medium); // as per figma
    height: var(--emu-common-sizing-medium); // as per figma
    background-image: url('../../assets/images/modal-close.png');
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    top: 4px;
    right: 4px;
    visibility: hidden;
    opacity: 1;

    @include mq('large') {
      top: 14px;
      right: 14px;
    }

    span {
      display: none;
    }
  }
  // Botox Day Phase1 LOF3 modal styles
  &.modal-lo3-btx-day{
    .modal-content {
      top: var(--emu-common-spacing-none);
      margin-top: 14vh;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-none);
      padding-top: var(--emu-common-spacing-none);
      border-radius:var(--emu-common-spacing-none);
      max-width: var(--emu-semantic-sizing-breakpoints-small);
      @include mq('medium') {
        max-width: 622px;
        margin-top: 100px;
      }
      .mobile-view{
        @include mq('medium') {
        display: none;
        }
        }
      .btx-day-modal {
        &__content-container {
          .btx-day-modal__title-container {
            padding-left: var(--emu-component-layers-modal-body-padding-left-narrow);
            padding-right: var(--emu-component-layers-modal-body-padding-right-narrow);
            padding-top: var(--emu-component-layers-modal-body-padding-left-narrow);
            padding-bottom: var(--emu-component-layers-modal-body-padding-right-narrow);
            text-align: center;
            .title {
              color: var(--emu-semantic-colors-primary-violet-200);
              font-size: var(--emu-semantic-font-sizes-narrow-medium);
              line-height: 28px;
              padding-top: 5px;
              padding-bottom: 24px;
              @include mq('medium') {
                padding-top: 30px;
                padding-bottom: 30px;
              }              
            }
            .sub-title__note{
              color: var(--emu-semantic-colors-primary-violet-200);
              font-weight: var(--emu-common-font-weight-regular);
              font-size: 8px;
              line-height: 10px;
              padding-top: 25px;
              @include mq('medium') {                
                padding-top: 50px;
              }
            }
            a {
              color: var(--emu-semantic-colors-primary-violet-200);
            }
          }
        }
        &__button {
          border-color: var(--emu-semantic-colors-primary-violet-200);
          border-width:var(--emu-semantic-border-width-medium);
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 5px;
          padding-bottom: 5px;
          text-decoration: none;
          > span {
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            line-height: 29px;
            padding-left: 16px; 
            padding-right: 16px; 
          }
        }
      }
      .close {
        background-size: 12px;
      }
    }
  }
}

// author styles
[data-component='modal'] {
  .modal-wrapper,
  .modal-content {
    @include aem-editor-view {
      opacity: 1 !important;
      visibility: visible !important;
      pointer-events: initial !important;
    }
  } 
}

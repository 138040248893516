.about-details {
  .content-box {
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: var(--emu-common-spacing-none);

    @include mq('large') {
      padding-top: var(--emu-common-spacing-none);
      flex-direction: row;
    }

    &__inner {
      display: flex;
      height: 100%;
      flex-direction: column;

      @include mq('large') {
        justify-content: center;
        padding-top: var(--emu-common-spacing-none);
        height: 100%;
        max-width: 651px; //to match figma design
        left: -74px; //to match figma design
        position: relative;
      }

      @include aem-editor-view {
        left: 0;
      }
    }

    &__image {
      margin-left: -100px;
      margin-right: -20px;

      @include mq('large') {
        margin-left: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-none);
      }

      img {
        min-height: 451px; // approximate value of the height of the image
        object-fit: cover;

        @include mq('large') {
          min-height: var(--emu-common-sizing-none);
          width: 54vw;
          max-width: 778px;
        }
      }
    }

    // content
    > .container {
      flex: 1;
      order: 1;
      max-height: 500px;
      margin-top: auto;

      @include mq('large') {
        order: 2;
        max-height: none;
        margin-top: unset;
        align-self: center;
      }
    }

    // disclaimer
    > .text {
      flex: 0 0 auto;
      order: 2;
    }

    // image
    > .image {
      flex: 0 0 auto;
      order: 3;

      @include mq('large') {
        order: 1;
        align-self: flex-end;
      }
    }

    // aligns content to top in desktop
    // for screen-3 the content must be aligned to the top
    &--desktop-top-align-text {
      > .container {
        @include mq('large') {
          align-self: flex-start;
          margin-top: max(10vh, 80px);
        }

        // necessary to make sure that the content is not overlapping
        @media (min-height: 900px) and (min-width: 1300px) {
          margin-top: 191px;
        }
      }
    }

    &__title {
      margin-bottom: 21px; //to match figma design

      @include mq('large') {
        margin-bottom: 35px; //to match figma design
      }

      h2 {
        text-align: center;

        @include mq('large') {
          text-align: left;
        }
      }
    }

    &__text {
      padding-left: 12px; //to match figma design
      padding-right: 12px; //to match figma design

      @include mq('large') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      sup {
        top: -3px;
      }

      &--bold {
        p {
          color: var(--emu-semantic-colors-primary-violet-200);
        }
      }
    }

    &__text-small {
      padding-left: var(--emu-common-spacing-xxs);
      padding-right: var(--emu-common-spacing-xxs);

      @include mq('large') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      &[data-component='text'] p {
        text-align: center;
        font-size: 14px; //to match figma design
        line-height: normal;
        color: var(--emu-semantic-colors-secondary-grey-500);
        max-width: 331px; //to match figma

        @include mq('large') {
          max-width: 630px;
          text-align: left;
          font-size: 16px; //to match figma design
          letter-spacing: -0.2px; //to match figma design
        }

        br {
          @include mq('large') {
            display: none;
          }
        }

        sup {
          top: 1px;
        }
      }
    }

    .patient-disclaimer {
      position: static;

      @include mq('large') {
        position: fixed;
      }

      p {
        text-align: center;

        @include mq('large') {
          text-align: left;
        }
      }
    }
  }

  &--screen-1 {
    .content-box {
      &__inner {
        @include mq('large') {
          margin-bottom: 14px;
        }
      }

      &__text {
        margin-bottom: 21px;
        @include mq('large') {
          max-width: 485px;
        }
      }
    }
  }

  &--screen-2 {
    .content-box {
      @include mq('large') {
        justify-content: flex-start;
      }

      &__inner {
        justify-content: center;
        margin-bottom: 25px;
      }

      &__text {
        padding-left: 20px;
        padding-right: 20px;

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }

      &__inner {
        @include mq('large') {
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
    }
  }

  &__mobile-app-screen-1 {
    max-width: 605px;
  }

  &__mobile-app-screen-2 {
    max-width: 630px;
    .content-box {
      &__text-small {
        text-indent: -4px;
      }
    }
  }

  &__mobile-app-screen-3 {
    margin-bottom: -22px; //to match figma design
    .content-box__text:not(.about-details__mobile-app-screen-disclaimer) {
      max-width: 605px;
    }
  }

  &__mobile-app-screen-disclaimer {
    // multiple selectors needed to override
    &.content-box__text.text.cmp-text.aaaem-text {
      transition-delay: 0.8s;
    }

    @include mq('large') {
      margin-top: 51px;
      text-indent: -4px;
    }
  }

  &--screen-3 {
    .content-box {
      padding-top: 52px;

      @include mq('large') {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: 113px; // height of the ISI
      }

      &__inner {
        @include mq('large') {
          left: 0;
          width: auto;
        }

        @include mq('xx-large') {
          width: 651px; // value from figma
        }
      }

      &__text {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      &__text-small[data-component='text'] {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);

        p {
          letter-spacing: normal;

          @include mq('large') {
            font-size: 14px;
          }
        }
      }

      &__image {
        margin-left: 68px;
        margin-right: var(--emu-common-spacing-none);

        @include mq('large') {
          margin-left: var(--emu-common-spacing-none);
        }

        img {
          height: 50vh;
          min-height: 0;
          object-fit: contain;
          margin: auto;
          max-height: 70vh;

          @include aem-editor-view {
            height: auto;
            max-height: none;
          }

          @include mq('large') {
            height: auto;
            width: auto;
            max-width: 27vw;
          }
        }
      }

      &__button {
        margin-top: var(--emu-common-spacing-none);
      }
    }
  }

  // marker styles
  &--screen-1,
  &--screen-2 {
    .content-box {
      &__image {
        .emu-mapping-marker {
          transform: scale(0.72); // to resize the marker to 36px
          transform-origin: center;
          border: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-white);
          cursor: default;

          @include mq('x-large') {
            transform: scale(1);
          }

          span {
            display: none;
          }

          &.emu-mapping-marker-1,
          &.emu-mapping-marker-3 {
            border-top-left-radius: 18px;
            border-top-right-radius: 18px;
            border-bottom-right-radius: var(--emu-common-border-radius-none);
            border-bottom-left-radius: var(--emu-common-border-radius-none);
            height: 18px; // to match figma design
            width: 36px; // to match figma design
            border-bottom: var(--emu-common-border-width-none);

            @include mq('x-large') {
              border-top-left-radius: 25px;
              border-top-right-radius: 25px;
              width: 50px; // to match figma design
              height: 25px; // to match figma design
            }
          }

          &.emu-mapping-marker-1 {
            transform: rotate(-90deg) scale(0.9);

            @include mq('x-large') {
              transform: rotate(-90deg);
            }
          }

          &.emu-mapping-marker-3 {
            transform: rotate(90deg) scale(0.9);

            @include mq('x-large') {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  // content inside the markers
  &--screen-2 {
    .content-box {
      &__image {
        .emu-mapping-marker {
          &::before {
            content: attr(aria-label);
            display: flex;
            color: var(--emu-common-colors-white);
            text-align: center;
            font-family: var(--emu-semantic-font-families-body);
            font-size: 20px;
            font-weight: var(--emu-common-font-weight-light);
            line-height: normal;
            align-items: center;
            justify-content: center;
          }

          &.emu-mapping-marker-1 {
            &::before {
              transform: rotate(90deg) scale(0.72) translateX(-4px);

              @include mq('x-large') {
                transform: rotate(90deg);
              }
            }
          }

          &.emu-mapping-marker-3 {
            &::before {
              transform: rotate(-90deg) scale(0.72) translateX(4px);

              @include mq('x-large') {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
    }
  }
}

// default transition states
.about-details {
  &--screen-1 {
    .content-box__image {
      transform: translateX(-200px);
      transition: transform 0.8s linear;

      .emu-mapping-marker {
        opacity: 0;
        transition-duration: 0.4s;
        transition-delay: 0.4s;

        &.emu-mapping-marker-0 {
          transition-delay: 0.8s;
        }

        &.emu-mapping-marker-1 {
          transition-delay: 1s;
        }

        &.emu-mapping-marker-2 {
          transition-delay: 0.6s;
        }

        &.emu-mapping-marker-3 {
          transition-delay: 1.2s;
        }
      }
    }
  }

  &--screen-1,
  &--screen-2 {
    .content-box__inner {
      transition: all var(--default-page-transition-duration) linear;
    }
  }

  &--screen-3 {
    .content-box__image {
      transform: translateY(100px);
      transition: all 0.4s linear;
      opacity: 0;

      @include mq('large') {
        transform: translateX(-100px);
      }
    }
  }
}

.lo3-section-container--active {
  .about-details {
    &--screen-1 {
      .content-box__image {
        transform: translateX(0);

        .emu-mapping-marker {
          opacity: 1;
        }
      }
    }

    &--screen-3 {
      .content-box__image {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }
}

.lo3-section-container--prev-active {
  .about-details {
    &--screen-1 {
      .content-box__image {
        transform: translate(0, 0);
      }
    }

    &--screen-1,
    &--screen-2 {
      .content-box__inner {
        transform: translate(0, -200px);
        opacity: 0;
      }
    }

    &--screen-3 {
      .content-box__image {
        transform: translate(0, 400px);
        opacity: 0;
      }
    }
  }
}

.lo3-section-container--prev-active-down {
  .about-details {
    &--screen-1 {
      .content-box__image {
        transform: translate(0, 0);
      }
    }
  }
}

// author styles
.about-details .content-box--desktop-top-align-text > .container {
  @include aem-editor-view {
    margin-top: 80px !important;
  }
}

:root {
  --default-page-transition-duration: 800ms;
}

body.js-modal-opened {
  overflow: hidden;

  .aaaem-isi-container {
    z-index: var(--emu-common-other-z-index-base);
  }
}

// author mode styles
body:has(.cq-Editable-dom--container) {
  background: var(--emu-semantic-colors-primary-purple-500) !important;
  animation: none !important;
}

.has--loader {
  overflow: hidden;
  .lo3-section-container--active .lo3-section,
  .lo3-section,
  .header__sidenav-container,
  .floating-cta,
  .modal-wrapper,
  .experiencefragment {
    opacity: 0 !important;
    pointer-events: none;
    transition: all 0.2s linear;

    @include aem-editor-view {
      opacity: 1 !important;
      pointer-events: initial;
    }
  }

  .loader__wrapper {
    opacity: 1;
    pointer-events: initial;
  }
}

.has-isi-on-top-layer {
  .container-isi .aaaem-isi-container {
    z-index: var(--emu-common-other-z-index-modal);

    &__isi-content {
      z-index: var(--emu-common-other-z-index-modal);
    }
  }
}

// body class to turn to dark theme
.bg--dark {
  animation: transitionFromLight 0.8s ease forwards,
    gradientDark 10s ease 0.8s infinite;

  // Side Navigation bar Dark Mode
  .header {
    &__mobile-trigger {
      svg {
        path {
          fill: var(--emu-common-colors-white);
        }
      }
    }

    &__sidenav-container {
      ul {
        li {
          a {
            &:before {
              border-color: rgba(255, 255, 255, 0.5); //match figma design
            }

            &:after {
              background-color: rgba(255, 255, 255, 0.5); //match figma design
            }
          }

          &.active {
            a {
              color: var(--emu-common-colors-white);
            }

            &:after {
              background-color: var(--emu-common-colors-white);
            }

            &:before {
              background-color: var(--emu-common-colors-white);
            }
          }

          a {
            color: rgba(255, 255, 255, 0.5); //match figma design
          }
        }
      }
    }
  }

  // dark mode default tag colors
  [data-component='text'],
  [data-component='title'] {
    p,
    li {
      color: var(--emu-common-colors-white);
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--emu-semantic-colors-primary-purple-500);
    }
  }

  //dark patient disclaimer color
  .patient-disclaimer i {
    color: var(--emu-common-colors-white);
  }
}

// body class to turn to light theme
.bg--light {
  animation: transitionFromDark 0.8s ease forwards,
    gradientLight 10s ease 0.8s infinite;

  [data-component='text'],
  [data-component='title'] {
    p,
    li {
      color: var(--emu-semantic-colors-primary-violet-200);
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--emu-semantic-colors-primary-purple-600);
    }
  }

  .content-box .content-box__text-small[data-component='text'] {
    p {
      color: var(--emu-semantic-colors-secondary-grey-500);
    }
  }
}

.has-floating-cta-light {
  .scroll-button {
    svg {
      path {
        fill: var(--emu-common-colors-white);
      }
    }

    span {
      color: var(--emu-common-colors-white);
    }
  }
}

// body class to hide "see treatment stories" CTA
.has-treatment-cta--hidden {
  .floating-cta {
    flex-direction: row;
    gap: 23px; //to match figma
    align-items: center;
    justify-content: space-between;
    min-height: 48px;

    .button {
      flex: 1;

      @include mq('large') {
        flex: 0 0 244px; //to match figma design
      }

      &:first-child {
        flex: 0;
        align-self: center;

        @include mq('large') {
          flex: 0 0 244px; //to match figma design
        }
      }

      &:nth-child(2) {
        display: none;

        @include mq('large') {
          opacity: 0;
          pointer-events: none;
          display: flex;
          transform: translateY(400px);
        }
      }
    }
  }
}

// body class to hide "Start Journey" CTA
.has-journey-cta--hidden {
  .floating-cta {
    flex-direction: row;
    gap: 23px; //to match figma
    align-items: center;
    justify-content: space-between;
    min-height: 48px;
    z-index: var(--emu-common-other-z-index-base);

    .button {
      flex: 1;

      @include mq('large') {
        flex: 0 0 244px; //to match figma design
      }

      &:first-child {
        flex: 0;
        align-self: center;

        @include mq('large') {
          flex: 0 0 244px; //to match figma design
        }
      }

      &:nth-child(3) {
        display: none;

        @include mq('large') {
          opacity: 0;
          pointer-events: none;
          display: flex;
          transform: translateY(400px);
        }
      }
    }
  }
}

.has-floating-cta--hidden {
  .floating-cta {
    bottom: 0;
    transform: translateY(400px);
    pointer-events: none;
    opacity: 0;
  }
}

// body class to hide the side nav in desktop
.has-side-nav--hidden {
  .header__sidenav-container {
    @include mq('large') {
      opacity: 0;
      transform: translateX(84px) translateY(-50%) translateY(100px);
      pointer-events: none;
      transition-duration: 0.2s;
      transition: opacity 0.2s ease-in-out, transform 0.2s linear 0.4s;
    }

    @include mq('xx-large') {
      transform: translateY(-50%) translateY(100px)
        translateX(calc(50vw - 720px)) translateX(78px);
    }
  }
}

// body class to hide the main menu
.has-main-menu--hidden {
  .header {
    &__logo,
    &__eyebrow,
    &__content > .image img {
      transform: translateY(-500px);
    }

    &__content > .image .cmp-image__link {
      display: none; //for real device (iPhone)
      pointer-events: none;
    }
  }
}

// body class to hide all fixed global elements of the page
.is-fullscreen-mode {
  .header {
    display: none;
  }

  .floating-cta {
    display: none;
  }
}

.lo3-section {
  position: absolute;
  top: var(--emu-common-spacing-none);
  left: var(--emu-common-spacing-none);
  width: 100%;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  transition: all var(--default-page-transition-duration) linear;
  overflow: hidden;

  @include mq('xx-large') {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  // making sure that every element is visible in author mode
  @include aem-editor-view {
    position: static !important;
    height: auto !important;
    max-height: none; // to make sure that the content looks good in AEM
    opacity: 1 !important;

    *:not(.new):not(.cq-Editable-dom):not(.newpar) {
      opacity: 1 !important;
      transform: none !important;
      transition: none !important;
      animation: none !important;
    }
  }

  &--static {
    position: static;
    height: auto;
    opacity: 1;
  }
}

.lo3-section-container {
  height: 100vh;
  position: relative;

  @include aem-editor-view {
    height: auto;
    max-height: none; // to make sure that the content looks good in AEM
  }

  &--active,
  &--prev-active {
    .lo3-section {
      position: fixed;
      overflow: visible;
    }
  }
}

// duration classes from 100ms to 2000ms separated by 100ms apart
@for $i from 1 through 20 {
  .duration--#{$i * 100}ms {
    transition-duration: #{$i *
      100}
      ms !important; // needed to override the default values
    animation-duration: #{$i *
      100}
      ms !important; // needed to override the default values
  }
}

// delay classes from 100ms to 2000ms separated by 100ms apart
@for $i from 1 through 20 {
  .delay--#{$i * 100}ms {
    transition-delay: #{$i *
      100}
      ms !important; // needed to override the default values
    animation-delay: #{$i *
      100}
      ms !important; // needed to override the default values
  }
}

.content-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 187px; // height of the ISI in mobile. Content must be center aligned in the remaining space
  height: 100vh;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 545px;
  margin-left: auto;
  margin-right: auto;

  @include mq('large') {
    max-width: none;
    width: 758px; // to match the design for the most of the screens. From design
    margin-left: max(calc((100vw - 490px) / 2), 270px);
    padding-bottom: 113px; // height of the ISI in desktop. Content must be center aligned in the remaining space
    padding-left: var(--emu-common-spacing-none);
  }

  @include mq('x-large') {
    margin-left: max(calc((100vw - 1440px) / 2), 401px);
  }

  @include mq('xx-large') {
    margin-left: 401px;
    padding-right: var(--emu-common-spacing-none);
    // margin-right: 281px;
  }

  @include aem-editor-view {
    height: auto;
    max-height: none; // to make sure that the content looks good in AEM
  }

  &--full {
    padding-bottom: var(--emu-common-spacing-none);

    @include mq('large') {
      width: auto;
      margin-left: auto;
      margin-right: auto;
      padding-right: var(--emu-common-spacing-none);
    }
  }

  &__background {
    position: absolute;
    inset: 0;

    @include aem-editor-view {
      position: static;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include aem-editor-view {
        height: auto;
      }
    }
  }

  &__title {
    margin-bottom: 24px; //to match figma design
    text-align: center;

    @include mq('large') {
      margin-bottom: 34px; //to match figma design
      padding: var(--emu-common-spacing-none);
      text-align: left;
    }

    h2 {
      letter-spacing: -2px; //to match figma design

      sup {
        top: -4px;

        @include mq('large') {
          top: -8px;
        }
      }
    }
  }

  &__text {
    margin-bottom: 24px; //to match figma design

    &[data-component='text'] {
      text-align: center;

      @include mq('large') {
        text-align: left;
        font: var(--emu-semantic-typography-wide-body-regular);
      }
    }

    &[data-component='text'] sup {
      top: -3px;

      @include mq('large') {
        top: -4px;
      }
    }

    a,
    a > .emphasis {
      color: var(--emu-semantic-colors-primary-purple-100);
      font-weight: var(--emu-common-font-weight-bold);
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }

  &__text-small {
    &[data-component='text'] p {
      text-align: center;
      font-size: 14px; //to match figma design
      line-height: normal;
      margin-left: auto;
      margin-right: auto;

      @include mq('large') {
        text-align: left;
        font-size: 16px; //to match figma design
        letter-spacing: -0.2px; //to match figma design
        margin-left: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-none);
      }
    }
  }

  .button {
    text-align: center;

    @include mq('large') {
      text-align: left;
    }
  }

  &__button,
  &__text-small {
    margin-top: 24px;
  }

  &__modal {
    &-text {
      p {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        text-align: center;
        color: var(--emu-semantic-colors-primary-violet-200);

        @include mq('large') {
          text-align: left;
          line-height: 36px; //to match figma design
        }

        sup {
          top: -4px; //to match figma design
        }
      }
    }

    &-small-text[data-component='text'] p {
      margin-top: 29px; //to match figma design
      color: var(--emu-semantic-colors-secondary-grey-500);
      font-size: 14px; //to match figma design
      font-weight: var(--emu-common-font-weight-light);
      line-height: normal;
      margin-bottom: var(--emu-common-spacing-none);
      text-indent: -8px;

      @include mq('large') {
        font-size: 16px; //to match figma design
        margin-top: 18px; //to match figma design
      }

      sup {
        top: 1px;
      }

      .emphasis {
        text-indent: initial;
      }
    }

    &-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-bottom: 29px;

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-none);
      }

      @include mq('large') {
        flex-direction: row;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 10px;
      }

      h3.emu-title__text {
        color: var(--emu-semantic-colors-primary-violet-200);
        font-size: 150px;
        font-weight: var(--emu-common-font-weight-regular);
        line-height: normal;
        letter-spacing: -6px;

        sup {
          font-weight: var(--emu-common-font-weight-regular);
          top: 3px;

          @include mq('large') {
            top: 6px;
          }
        }
      }

      .content-box__modal-text p {
        @include mq('large') {
          line-height: 32px; //to match figma design
        }
      }
    }
  }

  .content-box__text:last-of-type {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

.lo3-section-container,
.lo3-section-container .lo3-animated-subsection {
  .content-box__title,
  .content-box__subtitle,
  .content-box__text,
  .content-box__text-small,
  .content-box__button {
    transform: translateY(40px);
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }

  .content-box__text {
    transition-delay: 0.2s;
  }

  .content-box__text-small {
    transition-delay: 0.4s;
  }

  .content-box__button {
    transition-delay: 0.6s;
  }

  // when button is present before the small text, change the transition delays
  .content-box:has(.button + .text) {
    .content-box__button {
      transition-delay: 0.4s;
    }

    .content-box__text-small {
      transition-delay: 0.6s;
    }
  }
}

.lo3-section-container--active,
.lo3-section-container .lo3-animated-subsection--active {
  .lo3-section {
    opacity: 1;
    z-index: var(--emu-common-other-z-index-layer);
    pointer-events: initial;
  }

  .content-box__title,
  .content-box__subtitle,
  .content-box__text,
  .content-box__text-small,
  .content-box__button {
    transform: translateY(0);
    opacity: 1;
  }
}

.lo3-section-container--prev-active {
  .lo3-section {
    opacity: 0;
    transition-timing-function: ease-out;
    transition-duration: calc(
      var(--default-page-transition-duration) - 100ms
    ); // 100ms less than the default transition duration to make sure that the transition is smooth

    &:not(.lo3-section--only-fade) {
      .content-box {
        transition-property: transform;
        transition-timing-function: ease-out;
        transition-duration: calc(
          var(--default-page-transition-duration) - 100ms
        ); // 100ms less than the default transition duration to make sure that the transition is smooth
        transform: translateY(-200px);
      }
    }
  }

  .content-box__title,
  .content-box__subtitle,
  .content-box__text,
  .content-box__text-small,
  .content-box__button {
    transform: translateY(0);
    opacity: 1;
  }
}

[data-component='text'].patient-disclaimer {
  position: fixed;
  bottom: 271px; //to match figma
  left: 0;
  z-index: var(--emu-common-other-z-index-layer);
  width: 100%;
  transition: all 0.4s linear;
  pointer-events: none;

  .has-treatment-cta--hidden & {
    bottom: 214px;
  }

  @include aem-editor-view {
    position: static;
  }

  @include mq('large') {
    bottom: 233px;

    .has-treatment-cta--hidden & {
      bottom: 233px;
    }
  }

  p {
    font-size: 14px; //to match figma
    line-height: normal;
    color: var(--emu-semantic-colors-primary-violet-200);
    text-align: center;
    width: 89%;
    margin-left: auto;
    margin-right: auto;

    @include mq('large') {
      width: 91%;
      max-width: 1298px; //to match figma design
      font-size: var(--emu-common-font-sizes-wide-medium);
    }
  }

  &--dark {
    p {
      color: var(--emu-semantic-colors-primary-violet-200);
    }
  }

  &--light {
    p {
      color: var(--emu-common-colors-white);
    }
  }

  &--light-mobile {
    p {
      color: var(--emu-common-colors-white);

      @include mq('large') {
        color: var(--emu-semantic-colors-primary-violet-200);
      }
    }
  }

  &--dark-mobile {
    p {
      color: var(--emu-semantic-colors-primary-violet-200);

      @include mq('large') {
        color: var(--emu-common-colors-white);
      }
    }
  }

  &--large {
    p {
      font-size: 16px;
      line-height: normal;
    }
  }

  &--left {
    p {
      text-align: left;
    }
  }

  &--right {
    p {
      text-align: right;
    }
  }
}

.lo3-animated-subsection {
  opacity: 0;
  pointer-events: none;
  height: 0;
  overflow: visible;
  transition: 0.4s linear;
  transform: translateY(100px);

  @include aem-editor-view {
    opacity: 1;
    transform: none;
    height: auto !important;
  }

  &.lo3-animated-subsection--prev-active {
    transform: translateY(0);
    opacity: 0;
  }

  &--active {
    transition: transform 0.4s linear, opacity 0.8s linear 0.2s,
      height 0.4s linear;
    transform: translateY(0);
    opacity: 1;
    pointer-events: initial;
  }
}

// styles for the highlighted text
.has-highlighted-text {
  u {
    text-decoration: none;
    border-radius: 4px;
    padding: var(--emu-common-spacing-xs);
    background-color: var(--emu-common-colors-transparent);
    color: var(--emu-common-colors-white);
    transition: all 0.4s linear;

    @include aem-editor-view {
      background-color: var(--emu-semantic-colors-primary-purple-100);
      color: var(--emu-common-colors-white);
    }
  }
}

// has highlighted text animation
.lo3-section-container--active {
  .lo3-section {
    &:not(.lo3-section--has-animated-subsection),
    &.lo3-section--has-animated-subsection .lo3-animated-subsection--active {
      .has-highlighted-text u {
        animation: highlightText 1.2s linear;
        animation-delay: 0.6s;

        &:only-of-type,
        &:last-of-type {
          animation-fill-mode: forwards;
        }

        @for $i from 2 through 10 {
          &:nth-of-type(#{$i}) {
            animation-delay: calc(
              #{($i - 1) * 1200}ms + 600ms
            ); // needed to override the default values
          }
        }
      }
    }
  }
}

@keyframes highlightText {
  20%,
  100% {
    color: var(--emu-common-colors-white);
    background-color: var(--emu-semantic-colors-primary-purple-100);
  }
}

// styles for the tooltip cta and the popover
.tooltip {
  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 27px; //to match figma design

    @include mq('large') {
      margin-top: 24px;
      justify-content: start;
      margin-left: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);
    }

    &--visible {
      .aaaem-button.aaaem-button__secondary-outline {
        transition-property: background-color;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        border-color: var(--emu-semantic-colors-primary-purple-100);
        background-color: rgba(149, 119, 191, 0.3);
        transition-delay: 0s;
      }

      .tooltip__popover.cmp-text {
        transform: translateX(-50%) translateY(-19px);
        opacity: 1;
        pointer-events: initial;
      }
    }

    @media screen and (pointer: fine) and (hover: hover) {
      &:hover {
        cursor: pointer;

        .aaaem-button {
          transition-property: background-color;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;
          border-color: var(--emu-semantic-colors-primary-purple-100);
          background-color: rgba(149, 119, 191, 0.3);
          transition-delay: 0s;
          pointer-events: none;
        }

        .tooltip__popover {
          transform: translateX(-50%) translateY(-19px);
          opacity: 1;
          pointer-events: initial;
          cursor: default;
        }
      }
    }

    @include aem-editor-view {
      display: block;
    }
  }

  &__popover.cmp-text {
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    opacity: 0;
    min-width: 295px; //to match figma design
    max-width: 295px; //to match figma design
    background: var(--emu-semantic-colors-primary-violet-100);
    border-radius: 12px; //to match figma design
    padding-top: 15px; //to match figma design
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-medium);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(20px);
    pointer-events: none;

    @include mq('large') {
      min-width: 397px; //to match figma design
      padding-top: 11px; //to match figma design
      padding-bottom: 11px; //to match figma design
    }

    @include aem-editor-view {
      opacity: 1;
      transform: none;
      position: static;
    }

    &::after {
      content: '';
      display: block;
      background-color: inherit;
      width: 20px; //to match figma design
      height: 20px; //to match figma design
      border: inherit;
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
      border-radius: 6px; //to match figma design
      position: absolute;
      transform: rotate(-45deg);
      left: calc(50% - 10px);
      bottom: -9px; //to match figma design
    }

    p {
      color: var(--emu-common-colors-white);
      text-align: center;
      font-size: var(--emu-common-font-sizes-wide-medium);
      font-weight: var(--emu-common-font-weight-regular);
      line-height: normal;

      sup {
        top: 1px;
      }
    }
  }
}

.fullscreen-video-container {
  justify-content: center;
  display: flex;
  width: 100%;
  align-items: center;
  height: 100vh;
  background-color: var(--emu-common-colors-transparent);

  @include aem-editor-view {
    height: 900px;
    display: block !important;
  }

  &.tablet-mobile-view {
    @include mq('large') {
      display: none;
    }

    @include aem-editor-view {
      display: block !important;
    }
  }

  &.desktop-view {
    display: none;

    @include mq('large') {
      display: flex;
    }

    @include aem-editor-view {
      display: block !important;
    }
  }

  .plyr {
    &--paused,
    &--stopped {
      &::after {
        content: '';
        position: fixed;
        inset: 0;
        background-color: #2f1945; // used only here
        opacity: 0.44;
      }
    }

    .plyr__controls {
      opacity: 1;
      pointer-events: initial;
      transform: translateY(0);
    }
  }
}

.page-fragment {
  min-height: 100vh;

  .aaaem-pf__loader {
    display: none;
  }

  @include aem-editor-view {
    min-height: 0;
  }
}

.has-hoops {
  .hoops {
    display: block;
  }
}

.hoops {
  opacity: 0;
  display: none;
  @include fade-animation(0, 1, 'fade-in', 'in', forwards, 10s);

  &__container {
    position: fixed;
    border: var(--emu-common-border-width-medium) solid
      var(--emu-common-colors-white);
    border-radius: 50%;
    overflow: hidden;
    stroke-width: 2px; //to match with figma output
    stroke: var(--emu-common-colors-white);
    opacity: 0.3;

    &::after {
      content: '';
      border: var(--emu-common-border-width-medium) solid
        var(--emu-common-colors-white);
      border-radius: 50%;
      position: fixed;
      overflow: hidden;
    }
    &--big-hoops {
      width: 375px; //to match with figma output
      height: 375px; //to match with figma output
      filter: blur(6px);
      animation: moveBigHoopsMobile 20s linear infinite;

      @include mq('large') {
        width: 560px; //to match with figma output
        height: 560px; //to match with figma output
        animation: moveBigHoopsDesktop 20s linear infinite;
        transform: none;
      }

      &::after {
        width: 340px; //to match with figma output
        height: 340px;
        filter: blur(6px);
        top: 4.5%; //to match with figma output
        left: 4.5%; //to match with figma output
        animation: moveBigHoopsMobile 20s linear infinite;

        @include mq('large') {
          width: 517px; //to match with figma output
          height: 517px; //to match with figma output
          top: 3.5%; //to match with figma output
          left: 3.5%; //to match with figma output
          animation: moveBigHoopsDesktop 20s linear infinite;
        }
      }
    }
    &--small-hoops {
      filter: blur(3px);
      width: 188px; //to match with figma output
      height: 188px; //to match with figma output
      top: 0%; //to match with figma output
      left: 9%;
      animation: moveSmallHoopsMobile 20s linear infinite;

      @include mq('large') {
        animation: moveSmallHoopsDesktop 20s linear infinite;
      }

      &::after {
        filter: blur(3px);
        width: 167px; //to match with figma output
        height: 167px; //to match with figma output
        top: 4.5%; //to match with figma output
        left: 4.5%; //to match with figma output
        animation: moveSmallHoopsMobile 20s linear infinite;

        @include mq('large') {
          animation: moveSmallHoopsDesktop 20s linear infinite;
        }
      }
    }
  }
}

// when teaser is used as fullscreen video
.teaser-as-fs-video {
  .teaser-video-controls,
  .content-container {
    display: none;
  }

  .cmp-teaser__video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// when iframe embed is used as fullscreen video
.embed .fullscreen-iframe-embed {
  .aaaem-embed__oembed {
    padding: var(--emu-common-spacing-none);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    overflow: hidden;

    @include aem-editor-view {
      position: static !important;
      padding: 0 !important;
    }
  }

  iframe {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 140%;
    height: 140%;

    @include aem-editor-view {
      position: static !important;
      width: 100% !important;
      height: 100% !important;
      transform: none !important;
      min-height: 600px;
    }
  }
}

// Media queries
@media screen and (min-width: 1024px) and (max-height: 750px) {
  .has-treatment-cta--hidden {
    .floating-cta {
      bottom: 133px;
    }
  }

  // to make the 'Did You Know' button clickable on start journey screen 4
  .has-main-menu--hidden {
    .header {
      &__logo,
      &__eyebrow,
      &__content > .image img {
        display: none;
      }

      .cmp-container-header {
        margin-top: var(--emu-common-spacing-none);
      }
    }
  }
}

.aaaem-button {
  &:not(a):hover,
  &:not(a):active {
    outline: 0;
  }

  &__primary-filled {
    justify-content: center;
    align-items: center;
  }

  &__secondary-outline.aaaem-button {
    justify-content: center;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 24px;
    padding-left: 12px;
    gap: 10px;
  }

  &__secondary-filled.aaaem-button {
    align-items: center;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);

    svg {
      width: var(--emu-common-sizing-small);

      @include mq('large') {
        margin-right: var(--emu-common-spacing-medium);
      }
    }

    span {
      flex: 1;
      text-align: center;
    }
  }
}

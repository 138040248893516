.first-look-specifications {
  .content-box {
    @include mq('large') {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: auto;
      gap: 30px;
    }

    @include mq('x-large') {
      gap: 50px;
    }

    &__lottie-container {
      @include mq('large') {
        max-width: 250px;
      }

      @include mq('x-large') {
        max-width: none;
      }
    }

    > .container:last-child {
      @include mq('large') {
        flex: 1;
      }
    }

    &__lottie {
      margin-bottom: 33px; //to match figma design
      height: 123.5px; // from figma
      margin-left: auto;
      margin-right: auto;
      margin-top: -6px; //to match figma design
      display: flex;
      justify-content: center;

      @include mq('large') {
        height: auto;
        margin-bottom: var(--emu-common-spacing-none);
        margin-left: -8px; //to match figma design
        margin-right: -6px; //to match figma desgin
        margin-top: var(--emu-common-spacing-none);
      }

      lottie-player {
        height: 100%;
      }
    }

    &__title-box {
      text-align: center;

      @include mq('large') {
        text-align: left;
      }
    }

    &__text {
      max-width: 642px; // from figma
    }

    &__warning {
      flex-direction: column;
      align-items: center;
      display: flex;
      margin-top: 23px;
      gap: 14px;
      max-width: 630px;

      @include mq('large') {
        flex-direction: row;
        align-items: flex-start;
      }

      > .text {
        @include mq('large') {
          flex: 1;
        }
      }

      img {
        @include mq('large') {
          margin-top: 10px; //to match figma design
          width: var(--emu-common-sizing-medium);
        }
      }
    }
  }

  &--screen-1 {
    .content-box {
      &__lottie {
        height: 178.5px; // from figma
        margin-top: -4px; //to match figma design

        @include mq('large') {
          height: auto;
          margin-left: -5px; //to match figma design
          margin-top: var(--emu-common-spacing-none);
        }
      }
    }
  }

  &--screen-2 {
    .content-box {
      @include mq('x-large') {
        gap: 54px;
      }

      @include mq('xx-large') {
        margin-left: 347px; // from figma
      }

      &__lottie {
        height: 125.5px; // to match figma design
        margin-bottom: 31px; //to match figma design

        @include mq('large') {
          height: auto;
          margin-bottom:var(--emu-common-spacing-none);
          margin-right: -9px; //to match figma design
        }
      }

      &__text {
        .emphasis {
          @include mq('large') {
            display: inline;
          }

          @include mq('xx-large') {
            display: inline-block;
          }
        }
      }

      &__button.aaaem-button {
        &__secondary-outline.aaaem-button {
          padding-left: 10px; //to match figma design
          padding-right: var(--emu-common-spacing-medium);
        }
      }
    }
  }

  &--screen-3 {
    .content-box {
      @include mq('large') {
        gap: 26px;
      }

      @include mq('x-large') {
        margin-left: 302px;
      }

      &__lottie {
        @include mq('large') {
          margin-top: -4px; //to match figma design
        }
      }

      &__text {
        max-width: 657px; // from figma
      }
    }
  }
}

.first-look-specifications {
  .content-box {
    &__lottie lottie-player {
      opacity: 0;
      transform: translateX(-40px);
      transform-origin: center;
      transition: all 0.5s ease-in-out 0.4s;
    }

    &__warning {
      opacity: 0;
      transform: translateY(60px);
      transition: all 0.8s ease-in-out;
    }
  }

  &--screen-1 {
    .content-box__lottie lottie-player {
      transform: translateX(0);
    }
  }
}

.lo3-section-container--active {
  .first-look-specifications {
    .content-box {
      &__lottie lottie-player,
      &__warning {
        opacity: 1;
        transform: translate(0);
      }
    }
  }
}

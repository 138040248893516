.header {
  &__nav-container {
    background: none;
    padding-left: 40px; //to match figma design
    padding-right: 40px; //to match figma design
  }

  &__nav-wrapper {
    width: 100%;
    background-color: var(--emu-semantic-colors-primary-violet-100);
    height: 100vh;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: var(--emu-common-spacing-none);
    left: var(--emu-common-spacing-none);
    overflow-y: auto;
    transform: translate(100%);
    max-height: 5000px; // to make sure that the content looks good in AEM

    .navigationlist {
      .emu-navigation__content-wrapper {
        position: static;

        nav {
          background-color: transparent;

          ul {
            @include mq('medium') {
              flex-direction: column;
            }

            > li {
              > a {
                border: var(--emu-common-border-width-none);
                padding-left: var(--emu-common-spacing-none);
                padding-right: var(--emu-common-spacing-none);
                padding-top: 18px; //to match figma design
                padding-bottom: 18px; //to match figma design
                color: var(--emu-common-colors-white);
                font-size: 18px; //to match figma design
                font-weight: var(--emu-common-font-weight-regular);
                line-height: 24px; //to match figma design
                position: relative;

                &:hover {
                  background-color: transparent;
                }

                &::after {
                  content: '';
                  width: 9px; //to match figma design
                  height: 16px; //to match figma design
                  background-image: url('../../assets/images/right-arrow.png');
                  background-repeat: no-repeat;
                  display: block;
                  position: absolute;
                  right: var(--emu-common-spacing-none);
                  top: var(--emu-common-spacing-none);
                  bottom: var(--emu-common-spacing-none);
                  margin: auto;
                }

                sup {
                  font-size: 50%;
                }
              }
            }
          }

          &.header__nav-primary {
            padding-top: 40px; //to match figma design
          }

          &.header__nav-secondary {
            &::before {
              content: '';
              border-top: var(--emu-common-border-width-thin) solid
                var(--emu-common-colors-white);
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 100%;
              margin-top: 24px; //to match figma design
              padding-bottom: 12px; //to match figma design
            }

            > ul {
              padding-top: 6px; //to match figma design

              > li {
                a {
                  padding-bottom: 6px; //to match figma design
                  padding-top: 6px; //to match figma design

                  &::after {
                    display: none;
                  }
                }
              }
            }
          }

          &.header__nav-submenu {
            display: block;
            max-height: 0;
            transition: all 0.4s ease-in-out;
            overflow: hidden;

            > ul {
              padding-top: 12px; //to match figma design
              padding-left: 21px; //to match figma design

              > li {
                padding-bottom: 17px; //to match figma design

                &:last-child {
                  padding-bottom: var(--emu-common-spacing-none);
                }

                a {
                  padding-bottom: var(--emu-common-spacing-none);
                  padding-top: var(--emu-common-spacing-none);
                  line-height: 14px; //to match figma design

                  sup {
                    font-size: 60%;
                    top: 1px;
                    position: relative;
                  }

                  .nav-item {
                    padding-left: var(--emu-common-spacing-none);
                    font-size: var(--emu-common-font-sizes-narrow-medium);
                    padding-top: var(--emu-common-spacing-xxs);
                    padding-bottom: var(--emu-common-spacing-none);
                  }

                  &::after {
                    display: none;
                  }
                }
              }
            }
          }
        }

        &.js-toggle-on {
          .header__nav-submenu {
            max-height: 1000px;
          }
        }
      }
    }

    &.js-toggle-on {
      transform: translate(0);

      @include mq('large') {
        transform: translate(100%);
      }
    }
  }

  &__nav-social-container {
    background: none;
    align-items: center;
    gap: 20px; //to match figma design
    padding-top: 54px; //to match figma design
    display: flex;
    position: relative;
    padding-bottom: 48px; //to match figma design

    &::before {
      content: '';
      width: 100%;
      border-top: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-white);
      margin-top: -60px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: var(--emu-common-spacing-medium);
      display: block;
      position: absolute;
      left: var(--emu-common-spacing-none);
      right: var(--emu-common-spacing-none);
    }

    img {
      max-width: var(--emu-common-sizing-small);
      height: var(--emu-common-sizing-small);
      object-fit: contain;
    }
  }

  &__nav-submenu-button {
    &.aaaem-button:not(a) {
      background-color: transparent;
      border: none;
      padding-top: 6px; //to match figma design
      padding-bottom: 6px; //to match figma design
      text-align: left;
      font-size: 18px; //to match figma design
      text-transform: capitalize;
      flex-direction: row;
      width: 100%;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      color: var(--emu-common-colors-white);

      .chevron {
        position: absolute;
        right: var(--emu-common-spacing-none);
        top: var(--emu-common-spacing-none);
        bottom: var(--emu-common-spacing-none);
        display: flex;
        margin: auto;
        width: 23px; //to match figma design
        align-items: center;

        svg {
          fill: var(--emu-common-colors-white);
          width: 100%;
          path {
            stroke: currentColor;
            stroke-width: 1;
          }
        }
      }
    }
  }

  &__nav-logo-container {
    background: none;
    display: flex;
    padding-left: 11px; //to match figma design
    padding-top: 15px; //to match figma design
    align-items: center;

    > .image:first-child {
      margin-right: 24px; //to match figma design
      position: relative;

      &::after {
        content: '';
        display: block;
        border-right: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-white);
        height: 37px; //to match figma design
        width: 4px; //to match figma design
        position: absolute;
        top: 5px; //to match figma design
        left: 90px; //to match figma design

        @include mq('large') {
          width: 132px; //to match figma design
          height: 56px; //to match figma design
          top: 5px; //to match figma design
        }
      }

      img {
        height: 48px; //to match figma design
      }
    }

    > .image:last-child {
      img {
        height: 39px; //to match figma design
      }
    }
  }

  &__nav-submenu-container-desktop {
    display: none;
    width: 100%;
    background: none;
    position: fixed;
    left: var(--emu-common-spacing-none);
    right: var(--emu-common-spacing-none);
    top: var(--emu-common-spacing-none);
    background-color: var(--emu-semantic-colors-primary-violet-100);

    &.js-toggle-on {
      @include mq('large') {
        display: block;
        max-width: var(
          --emu-semantic-sizing-breakpoints-xx-large
        ); //to match figma design
        margin: auto;
      }
    }

    .text {
      ul {
        display: flex;
        list-style: none;
        flex-wrap: wrap;
        row-gap: var(--emu-common-spacing-medium);
        align-items: center;
        margin-bottom: var(--emu-common-spacing-small);
        margin-top: var(--emu-common-spacing-small);

        li {
          flex: 0 0 25%;
          min-height: 34px; //to match figma design
          border-right: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-primary-purple-500);
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-child(4),
          &:last-child {
            border-right: none;
          }

          a {
            color: var(--emu-common-colors-white);
            text-align: center;
            font-size: 14px; //to match figma design
            font-weight: var(--emu-common-font-weight-regular);
            line-height: 14px; //to match figma design
            text-decoration: none;
            display: block;

            sup {
              top: -2px; //to match figma design
              font-size: 65%; //to match figma design
            }

            .emphasis {
              font-size: 10px; //to match figma design
            }
          }
        }
      }
    }

    .image {
      position: absolute;
      top: var(--emu-common-spacing-small);
      right: var(--emu-common-spacing-small);

      img {
        cursor: pointer;
        width: 12px; //to match figma design
      }
    }
  }
}

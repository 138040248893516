// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.hide {
  display: none !important; // important is needed to make sure that this cannot be overridden

  @include aem-editor-view {
    display: initial !important; // important is needed to make sure that this cannot be overridden
  }
}

/**
* Text Component Util Classes for text colors
*/
.text--color-white {
  color: var(--emu-semantic-colors-text-dark);
}

.text--color-black {
  color: var(--emu-semantic-colors-text-light);
}

.emu-spacing-mb__none {
  margin-bottom: var(--emu-common-spacing-none) !important;
}

.no-events {
  pointer-events: none;
}

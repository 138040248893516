.first-look {
  &-details {
    .content-box {
      &__title {
        position: relative;
        top: 81px; //to match figma design
        max-width: 330px; //to match figma design
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        z-index: var(--emu-common-other-z-index-overlay);

        @include mq('large') {
          top: 216px;
          left: 232px;
          max-width: 490px; //to match figma design
          text-align: left;
          display: flex;
          justify-content: end;
          margin: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          top: 265px; //to match figma design
          left: 297px; //to match figma design
        }

        h4 {
          color: var(--emu-semantic-colors-primary-violet-200);
          font-weight: var(--emu-semantic-font-weight-light);
          line-height: normal;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
            line-height: var(--emu-semantic-line-heights-wide-medium);
          }

          @include mq('x-large') {
            font-size: 36px; //to match figma design
            line-height: normal; //to match figma design
          }

          .emphasis {
            background: var(--emu-common-colors-white);
            border-radius: 5.4px; //to match figma design
            padding-left: 12px; //to match figma design
            padding-right: 12px; //to match figma design
            padding-top: var(--emu-common-spacing-xxs);
            padding-bottom: var(--emu-common-spacing-xxs);
            margin-bottom: var(--emu-common-spacing-xs);
            transform: translateY(40px);
            opacity: 0;

            &:last-child {
              padding-left: 10px; //to match figma design
              padding-right: 10px; //to match figma design
              margin-bottom: var(--emu-common-spacing-none);
            }

            @include mq('large') {
              border-radius: var(--emu-common-border-radius-small);
              padding-left: 12px; //to match figma design
              padding-right: 9px; //to match figma design
              padding-top: 3px; //to match figma design
              padding-bottom: 3px; //to match figma design

              &:nth-of-type(2) {
                padding-left: 11px; //to match figma design
                padding-right: 11px; //to match figma design
              }

              &:last-child {
                padding-left: 12px; //to match figma design
                padding-right: 9px; //to match figma design
              }
            }
          }
        }
      }

      &__ellipse {
        padding: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        background: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @include mq('large') {
          margin-bottom: 80px; //to match figma design
        }

        &--image {
          img {
            width: 305px; //to match figma design
            transform: translateY(40px);
            opacity: 0;
            object-fit: contain;
            max-height: 50vh;

            @include mq('medium') {
              width: min(400px, 32vh);
            }

            @include mq('large') {
              width: 455px;
              max-height: 80vh;
            }

            @include mq('x-large') {
              width: 569px; //to match figma design
            }
          }
        }
      }

      &__tooltip {
        background: none;
        margin: var(--emu-common-spacing-none);
        padding: var(--emu-common-spacing-none);
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include mq('large') {
          align-items: flex-start;
          min-width: 393px;
          bottom: 66px;
        }

        @include mq('x-large') {
          min-width: 500px; //to match figma design
          bottom: 100px; //to match figma design
        }

        &-dot {
          margin-top: 26px; //to match figma design

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
          }

          img {
            height: 20px; //to match figma design
            width: 20px; //to match figma design
            transform: scale(0);

            @include mq('large') {
              height: 32.2px; //to match figma design
              width: 32.2px; //to match figma design
            }
          }
        }

        &-content {
          background: var(--emu-semantic-colors-primary-purple-100);
          width: 221px; //to match figma design
          height: 40px; //to match figma design
          align-items: center;
          border-radius: var(--emu-common-border-radius-small);
          display: flex;
          justify-content: center;
          text-align: center;
          position: relative;
          left: var(--emu-common-spacing-none);
          top: -80px; //to match figma design
          font-size: 18px; //to match figma design
          line-height: 32px; //to match figma design
          font-weight: var(--emu-semantic-font-weight-regular);
          transform: translateX(-50%);
          opacity: 1;
          transform: scale(0);

          @include mq('large') {
            min-width: 221px;
            width: 100%;
            height: 50px;
            top: -109px;
            left: -94px;
            justify-content: center;
            border-radius: 9.2px; //to match figma design
            font-size: 24px;
            line-height: 42px;
          }

          @include mq('x-large') {
            min-width: 281px; //to match figma design
            height: 57px; //to match figma design
            top: -116px; //to match figma design
            left: -123px; //to match figma design
            font-size: 27.6px; //to match figma design
            line-height: 36.8px; //to match figma design
          }

          p {
            color: var(--emu-common-colors-white);
            font: inherit;
          }

          &:after {
            content: '';
            height: 20px; //to match figma design
            width: 20px; //to match figma design
            background-color: inherit;
            border: inherit;
            clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
            border-radius: 0 0 0 var(--emu-common-border-radius-xs);
            display: block;
            position: absolute;
            bottom: -9px; //to match figma design
            left: calc(50% - 10px);
            transform: rotate(-45deg);

            @include mq('large') {
              height: 40px; //to match figma design
              width: 40px; //to match figma design
              left: calc(50% - 20px);
            }
          }
        }
      }

      .patient-disclaimer {
        p {
          font-size: 14px; //to match figma design
          line-height: normal;

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-medium);
            text-align: left;
          }
        }
      }
    }

    &--screen-1,
    &--screen-2,
    &--screen-3 {
      .content-box {
        justify-content: flex-start;

        @include mq('medium') {
          justify-content: center;
        }

        @include mq('large') {
          align-items: center;
          max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
          width: auto;
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }
      }
    }

    &--screen-3 {
      .content-box {
        &__tooltip {
          @include mq('large') {
            align-items: flex-start;
            min-width: 532px;
            bottom: 74px;
          }

          @include mq('x-large') {
            min-width: 638px; //to match figma design
            bottom: 104px; //to match figma design
          }

          &-content {
            width: 221px; //to match figma design

            @include mq('large') {
              min-width: 274px;
              left: -119px;
              width: 100%;
            }

            @include mq('x-large') {
              min-width: 325px; //to match figma design
              left: -145px; //to match figma design
            }
          }
        }
      }
    }

    &--screen-4 {
      .content-box {
        width: auto;

        @include mq('large') {
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          gap: 25px; //to match figma design
        }

        @include mq('x-large') {
          gap: 108px; //to match figma design
        }

        &__title {
          position: static;
          width: max-content;
          margin-bottom: 30px; //to match figma design

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-none);
          }

          h4 {
            @include mq('large') {
              font-size: calc(1.2vw + var(--emu-common-font-sizes-wide-medium));
              line-height: normal;
            }

            @include mq('x-large') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
            }

            .emphasis {
              margin-bottom: var(--emu-common-spacing-medium);
              padding-top: 2.5px; //to match figma design
              padding-bottom: 2.5px; //to match figma design
              padding-left: 10px; //to match figma design
              padding-right: 10px; //to match figma design
              transform: translateY(40px);
              opacity: 0;

              &:nth-of-type(2) {
                padding-left: 10.5px; //to match figma design
                padding-right: 10.5px; //to match figma design
              }

              &:last-child {
                padding-left: var(--emu-common-spacing-small);
                padding-right: var(--emu-common-spacing-small);
              }

              @include mq('large') {
                padding-top: 3.5px; //to match figma design
                padding-bottom: 3.5px; //to match figma design
                padding-left: 12.3px; //to match figma design
                padding-right: 12.3px; //to match figma design
                margin-bottom: 28px; //to match figma design

                &:nth-of-type(2) {
                  padding-left: 18px; //to match figma design
                  padding-right: 18px; //to match figma design
                }

                &:last-child {
                  padding-top: 3px; //to match figma design
                  padding-bottom: 3px; //to match figma design
                  padding-left: 12px; //to match figma design
                  padding-right: 12px; //to match figma design
                }
              }
            }
          }
        }

        &__text {
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 24px; //to match figma design
          margin-right: 17px; //to match figma design
          margin-left: 17px; //to match figma design

          @include mq('large') {
            text-align: left;
            gap: 30px; //to match figma design
            margin-right: var(--emu-common-spacing-none);
            margin-left: var(--emu-common-spacing-none);
          }

          @include mq('x-large') {
            gap: 45px; //to match figma design
            margin-right: 43px; //to match figma design
          }

          p {
            color: var(--emu-semantic-colors-primary-violet-200);
            width: max-content;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            font: inherit;
            transform: translateY(40px);
            opacity: 0;

            @include mq('large') {
              max-width: 478px; //to match figma design
              margin: var(--emu-common-spacing-none);
            }

            sup {
              top: -3px;
            }
          }
        }
      }
    }
  }
}

.first-look {
  &-details {
    .content-box__ellipse--image img,
    .content-box__title .emphasis,
    .content-box__text p {
      opacity: 0;
      transition-property: all;
      transition-duration: 0.4s;
      transition-timing-function: ease-in;
    }

    .content-box__tooltip-dot img,
    .content-box__tooltip-content {
      transform: scale(0);
      transition-duration: 0.4s;
    }

    .content-box__tooltip-dot img {
      transition-delay: 0.5s;
    }

    .content-box__tooltip-content {
      transition-delay: 0.6s;
    }

    .content-box__title {
      .emphasis {
        &:nth-of-type(1) {
          transition-delay: 1s;
        }

        &:nth-of-type(2) {
          transition-delay: 1.2s;
        }

        &:last-child {
          transition-delay: 1.4s;
        }
      }
    }

    &--screen-4 .content-box__title .emphasis {
      &:nth-of-type(1) {
        transition-delay: 0s;
      }

      &:nth-of-type(2) {
        transition-delay: 0.2s;
      }

      &:nth-of-type(3) {
        transition-delay: 0.4s;
      }
    }

    .content-box__text p:nth-of-type(1) {
      transition-delay: 0.6s;
    }

    .content-box__text p:nth-of-type(2) {
      transition-delay: 0.8s;
    }
  }
}

.lo3-section-container--active {
  .first-look-details {
    .content-box__ellipse--image img,
    .content-box__title .emphasis,
    &--screen-4 .content-box__title .emphasis,
    .content-box__text p {
      transform: translateY(0);
      opacity: 1;
    }

    .content-box__tooltip-dot img,
    .content-box__tooltip-content {
      transform: scale(1);
    }
  }
}

.lo3-section-container--prev-active {
  .first-look {
    &-details {
      .content-box__ellipse--image img,
      .content-box__tooltip-dot img,
      .content-box__tooltip-content,
      .content-box__title .emphasis,
      &--screen-4 .content-box__title .emphasis,
      .content-box__text p {
        transform: translateY(-100px); //Move the image up during exit animation
        opacity: 0;
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
        transition-delay: 0s;
      }
    }
  }
}

// author mode details
.first-look {
  &-details {
    .content-box {
      &__title,
      &__tooltip,
      &__tooltip-content {
        @include aem-editor-view {
          position: static !important;
        }
      }
    }
  }
}

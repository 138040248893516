.video-testimonials {
  @include mq('large') {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin-left: auto;
    margin-right: auto;
  }

  .plyr {
    &--paused,
    &--stopped {
      &::after {
        display: none;
      }
    }

    &--video {
      .plyr__controls {
        position: absolute;
        bottom: 10px;
      }
    }

    &__control {
      &.plyr__control--overlaid {
        width: 48px;
        height: 48px;
        padding: var(--emu-common-spacing-none);

        @include mq('large') {
          width: 60px;
          height: 60px;
        }

        &::after {
          background-image: url('./assets/images/play-2.png');
        }
      }
    }
  }

  &__main-tabs {
    margin-top: var(--emu-common-spacing-medium);

    // hiding the tablist for the main tabs
    > .aaaem-tabs__tablist {
      display: none;
    }

    // showing the dropdown always for main tabs section
    &-wrapper {
      > .container {
        position: relative;
        z-index: 2;

        @include mq('large') {
          z-index: initial;
        }

        div.custom-tabs__select-wrapper {
          display: flex;

          @include mq('large') {
            width: 280px;
            display: inline-flex;
          }

          @include mq('x-large') {
            width: 379px;
          }

          > .options {
            flex: 1;
          }

          .emu-dropdown-menu__button {
            @include mq('large') {
              max-width: 100%;
            }
          }
        }
      }

      > .tabs {
        position: relative;
        z-index: 1;

        @include mq('large') {
          z-index: initial;
        }
      }
    }
  }

  &__inner-tabs {
    @include mq('large') {
      position: relative;
    }

    .aaaem-tabs__tablist {
      @include mq('large') {
        top: -64px;
        position: absolute;
        margin: 0;
        right: 0;
        width: calc(
          100% - 280px - 20px
        ); // 280 is width of main tab dropdown, 20px is the space between
      }

      @include mq('x-large') {
        width: calc(
          100% - 379px - 20px
        ); // 379 is width of main tab dropdown, 20px is the space between
      }
    }
  }

  &__content-container {
    > .button {
      text-align: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: calc(113px + 20px); // height of the ISI
      display: flex;
      justify-content: center;
      background-color: var(--emu-common-colors-white);
      z-index: var(--emu-common-other-z-index-layer);

      @include mq('large') {
        position: static;
        margin-bottom: 21px;
        transform: none;
        padding-bottom: var(--emu-common-spacing-none);
      }

      &::before {
        content: '';
        height: 20px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        background: linear-gradient(
          180deg,
          var(--emu-common-colors-transparent) 0%,
          var(--emu-common-colors-white) 100%
        );

        @include mq('large') {
          display: none;
        }
      }
    }
  }

  &__gallery-cta.emu-button {
    justify-content: center;
    width: 375px;
    max-width: 89%;

    @include mq('large') {
      max-width: 244px;
    }
  }

  &__title[data-component='title'] {
    .emu-title__text {
      line-height: normal;
      margin-bottom: 17px;
      margin-top: 34px;
      color: var(--emu-semantic-colors-primary-violet-200);

      @include mq('large') {
        margin-top: 14px;
        margin-bottom: 15px;
      }
    }
  }

  &__embed {
    margin-bottom: 17px;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;

    @include mq('large') {
      max-width: 800px;
      margin-bottom: 24px;
    }
  }

  &__disclaimer[data-component='text'] {
    p {
      color: var(--emu-common-colors-black);
      font-weight: var(--emu-common-font-weight-regular);
      font-size: 16px;
      line-height: normal;

      b {
        font-weight: var(--emu-common-font-weight-bold);
      }
    }
  }

  &__text[data-component='text'] {
    p {
      color: var(--emu-common-colors-black);
      font-size: 16px;
      line-height: normal;
      margin-bottom: 18px;
    }

    sup {
      top: 2px;
    }
  }
}

// Author styles
.video-testimonials {
  @include aem-editor-view {
    height: auto;

    .plyr {
      &--video {
        height: 450px; // height of the video in the modal
      }
    }
    
    &__main-tabs,
    &__inner-tabs,
    &__content-container {
      height: auto;
    }
    
    &__main-tabs-wrapper {
      div.custom-tabs__select-wrapper {
        height: auto;
        position: static;
      }
    }
    
    &__text[data-component='text'] p {
        margin-bottom: var(--emu-common-spacing-large);
    }
    
    &__gallery-cta.emu-button {
      position: static;
      display: block;
      transform: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.alle-banner {
  &.aaaem-container {
    background-color: var(--emu-common-colors-black);
    position: relative;
    z-index: var(--emu-common-other-z-index-layer);

    @include mq('large') {
      z-index: var(--emu-common-other-z-index-header);
    }
  }

  .banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 56px; //to match figma
    padding-bottom: 56px; //to match figma
    gap: 33px; //to match figma

    @include mq('large') {
      flex-direction: row;
      justify-content: center;
      padding-top: 93px; //to match figma
      padding-bottom: 93px; //to match figma
      gap: 68px; //to match figma
    }

    img {
      width: 101px; //to match figma
      height: auto;

      @include mq('large') {
        width: 210px; //to match figma
      }
    }

    p {
      max-width: 335px; // to match figma
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      line-height: var(--emu-semantic-line-heights-narrow-medium);
      color: var(--emu-common-colors-white);
      text-align: center;

      sup {
        top: -4px; //to match figma
      }

      .emphasis {
        sup {
          font-size: 45%;
          top: -5px;
        }
      }

      @include mq('large') {
        text-align: left;
        max-width: 360px;
      }

      br {
        display: block;

        @include mq('large') {
          display: none;
        }
      }
    }

    .button .cmp-button {
      text-decoration: none;
      padding-left: 14px; //to match figma
      padding-right: 14px; //to match figma

      @include mq('large') {
        margin-left: -29px; //to match figma
        margin-top: -14px; //to match figma
      }

      > span {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 25px;
        padding-left: 15px; //to match figma
        padding-right: 15px; //to match figma
      }
    }
  }
}

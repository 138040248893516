.start-journey {
  &__poster {
    .content-box {
      justify-content: flex-start;
      padding-top: 56px;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      max-width: none;
      position: relative;

      @include mq('large') {
        padding-top: min(10vh, 257px);
      }

      // media query needed to make sure that this applies only when there is enough space
      @media (min-width: 1024px) and (min-height: 850px) {
        padding-top: 257px;
      }

      // containers with image and text
      > .container:nth-child(2) {
        margin-top: auto;

        @include mq('large') {
          margin-top: var(--emu-common-spacing-none);
        }
      }

      &__title {
        text-align: left;
        margin-bottom: 19px;

        @include mq('large') {
          margin-bottom: 34px;
        }

        h2,
        h3,
        h4 {
          letter-spacing: -1px;

          @include mq('large') {
            letter-spacing: -2px;
          }
        }
      }

      &__subtitle {
        margin-bottom: 13px;

        @include mq('large') {
          margin-bottom: 24px;
        }

        h4 {
          line-height: normal;
          color: var(--emu-semantic-colors-primary-violet-200);
        }
      }

      &__text {
        text-align: left;
      }
    }

    .patient-disclaimer {
      position: static;
      margin-bottom: 7px;

      @include mq('large') {
        position: fixed;
        margin-bottom: var(--emu-common-spacing-none);

        p {
          text-align: left;
        }
      }
    }

    &-content-container {
      padding-left: 52px;
      padding-right: 52px;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      box-sizing: content-box;

      @include mq('large') {
        box-sizing: border-box;
        max-width: 50%;
        margin-left: var(--emu-common-spacing-none);
        padding-left: 71px;
        padding-right: var(--emu-common-spacing-none);
      }
    }

    &-text[data-component='text'] {
      ul {
        list-style: none;
        padding: var(--emu-common-spacing-none);
        margin: var(--emu-common-spacing-none);
      }

      li {
        margin-bottom: 6px;
        padding-left: var(--emu-common-spacing-large);
        position: relative;
        transform: translateY(20px);
        opacity: 0;
        transition: all 0.4s linear;

        @include mq('large') {
          margin-bottom: 3px;
          line-height: 32px;
          padding-left: 34px; //to match figma design
        }

        sup {
          top: -5px;
        }

        b sup {
          top: -3px;

          @include mq('large') {
            top: -4px;
          }
        }

        &::before,
        &::after {
          content: '';
          width: 3px;
          height: 10px;
          position: absolute;
          left: 0;
          top: 13px;
          background-color: var(--emu-semantic-colors-primary-purple-100);
          transform: rotate(-50deg) scaleY(0);
          border-radius: var(--emu-common-border-radius-xs);
          transition: all 0.2s linear;
          transform-origin: left top;

          @include mq('large') {
            top: 18px;
          }
        }

        &::after {
          height: 18px;
          transform: rotate(45deg) scaleY(0);
          top: 0;
          left: 7px;
          transform-origin: left bottom;

          @include mq('large') {
            top: 4px;
            left: 7px;
          }
        }
      }
    }

    &-img-container {
      @include mq('large') {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      > .image {
        // mobile image
        &:nth-child(2) {
          @include mq('large') {
            display: none;
          }
        }

        // desktop image
        &:nth-child(3) {
          display: none;

          @include mq('large') {
            display: block;
          }
        }
      }

      img {
        width: 100%;
        object-fit: contain;
        height: auto;
        max-height: 479px; // from figma
        object-position: bottom;
        margin: auto;

        @include mq('large') {
          width: auto;
          height: 80vh;
          max-height: none;
          max-width: 60vw;
        }

        @include mq('x-large') {
          max-width: 72vw;
        }

        // media query needed to make sure that this applies only when there is enough space
        @media (min-width: 1024px) and (min-height: 875px) {
          height: 875px; // from figma
          max-height: none;
        }
      }
    }

    &.start-journey {
      &--screen-2 {
        .start-journey {
          &__poster {
            &-img-container {
              img {
                // media query needed to make sure that this applies only when there is enough space
                @media (min-width: 1024px) and (min-height: 843px) {
                  height: 843px; // from figma
                }
              }
            }
          }
        }
      }

      &--screen-3 {
        .start-journey {
          &__poster {
            &-content-container {
              @include mq('large') {
                max-width: 576px;
              }
            }

            &-img-container {
              img {
                // media query needed to make sure that this applies only when there is enough space
                @media (min-width: 1024px) and (min-height: 842px) {
                  height: 842px; // from figma
                }
              }
            }
          }
        }
      }
    }
  }
}

// animations
.start-journey {
  &__poster {
    &-img {
      transform: translateX(80px);
      opacity: 0;
      transition: all 0.4s linear 0.6s;
    }
  }
}

.lo3-section-container {
  &--active {
    .start-journey__poster {
      .content-box {
        &__title {
          transition-delay: 0.4s;
        }

        &__subtitle {
          transition-delay: 0.8s;
        }

        &__text {
          transform: translateY(0);
          opacity: 1;
          transition-delay: 0s;
        }
      }

      &-text[data-component='text'] {
        ul {
          li {
            opacity: 1;
            transform: translateY(0);

            &::before {
              transform: rotate(-50deg) scaleY(1);
            }

            &::after {
              transform: rotate(45deg) scaleY(1);
            }

            // for 5 list items. Could be less, could be more. Adding a reasonable number. Original is just 3
            @for $i from 1 through 5 {
              &:nth-child(#{$i}) {
                transition-delay: #{($i * 200) + 1000}ms;

                &::before {
                  transition-delay: #{($i * 300) + 1000}ms;
                }

                &::after {
                  transition-delay: #{($i * 300) + 1200}ms;
                }
              }
            }
          }
        }
      }

      &-img {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &--prev-active {
    .start-journey__poster {
      &-content-container {
        transform: translateY(-200px);
        opacity: 0;
        transition: all 0.4s linear;
      }

      &-img {
        transition-delay: 0s;
        opacity: 0;
        transform: translateX(80px);
      }
    }
  }
}

// authoring styles
.start-journey__poster-img,
.start-journey__poster-img-container,
.start-journey__poster-img-container > .image {
  @include aem-editor-view {
    display: block !important;
    position: static !important;
  }
}

.start-journey__poster-img img {
  @include aem-editor-view {
    width: auto !important;
    max-height: none !important;
    margin-left: auto !important;
  }
}

.start-journey__poster {
  ul li {
    @include aem-editor-view {
      &::before {
        transform: rotate(-50deg) scaleY(1) !important;
      }

      &::after {
        transform: rotate(45deg) scaleY(1) !important;
      }
    }
  }
}

// Media queries
@media screen and (min-width: 1024px) and (max-height: 750px) {
  .start-journey {
    &__poster {
      .content-box {
        padding-top: 24px;
  
        .patient-disclaimer {
          top: 16px;
          bottom: auto;
          
          p {
            text-align: right
          }
        }
      }

      &-img-container {
        img {
          height: 90vh;
          margin-right: 125px;
          transform: scale(1.15);
        }
      }

      &.start-journey {
        &--screen-3 {
          .start-journey {
            &__poster {
              &-content-container {
                max-width: min(576px, 50%);
              }
            }
          }
        }
      } 
    }
  }
}

.loader {
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s linear;

    .content-box {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      transform: translateY(-52px);

      &__title {
        margin-bottom: 12px; //to match figma design
      }

      &__title,
      &__text {
        text-align: center;
        opacity: 1;
        transform: translateY(-28px);

        @media (min-width: 545px) {
          transform: translateY(-48px);
        }

        @include mq('large') {
          transform: translateY(-68px);
        }
      }
    }
  }

  &__main {
    .lottie {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include mq('large') {
        width: 720px; //to match figma design
      }
    }
  }
}

// loader inside the page fragment
.emu-pf__loader {
  display: none;
}

// Author Styles
.loader__wrapper {
  @include aem-editor-view {
    position: static;
    max-height: 800px;
  }
}

// Animation Variables
$standardAnimationDuration: 1s;
$standardAnimationDelay: 0.2s;
$standardEase: ease;

// ****************************************************************************
// Animation Mixins

// Example Usage: @include fade-animation(0, 100, cardAnimation, 'out', forwards, 10s)
@mixin fade-animation(
  $startOpacity: 0,
  $endOpacity: 100%,
  $name: 'fade',
  $direction: ease,
  $animationFillMode: forwards,
  $customDuration: $standardAnimationDuration
) {
  animation-name: $name;
  animation-duration: $customDuration;
  animation-delay: $standardAnimationDelay;
  animation-fill-mode: $animationFillMode;

  // Direction is to set whether you are fading something onto ("in") the page or off ("out") of the page
  @if $direction == 'in' {
    animation-timing-function: ease-out;
  } @else if $direction == 'out' {
    animation-timing-function: ease-in;
  } @else {
    animation-timing-function: $standardEase;
  }

  @keyframes #{$name} {
    from {
      opacity: $startOpacity;
    }
    to {
      opacity: $endOpacity;
    }
  }
}

// ****************************************************************************

// Example Usage: @include slide-animation(-25px, 0px, 0, 0, cardAnimation)
@mixin slide-animation(
  $startX: 0,
  $endX: 0,
  $startY: 0,
  $endY: 0,
  $name: slide
) {
  animation-name: $name;
  animation-duration: $standardAnimationDuration;
  animation-delay: $standardAnimationDelay;
  animation-timing-function: $standardEase;

  @keyframes #{$name} {
    from {
      transform: translateX($startX) translateY($startY);
    }
    to {
      transform: translateX($endX) translateY($endY);
    }
  }
}

// ****************************************************************************

// Example Usage: @include fade-and-slide-animation(0, 100, -15px, 0px, 0, 0, cardAnimation, 'in')
@mixin fade-and-slide-animation(
  $startOpacity: 0,
  $endOpacity: 100%,
  $startX: 0,
  $endX: 0,
  $startY: 0,
  $endY: 0,
  $name: fadeAndSlide,
  $direction: 'ease'
) {
  animation-name: $name;
  animation-duration: $standardAnimationDuration;
  animation-delay: $standardAnimationDelay;

  // Direction is to set whether you are fading something onto ("in") the page or off ("out") of the page
  @if $direction == 'in' {
    animation-timing-function: ease-out;
  } @else if $direction == 'out' {
    animation-timing-function: ease-in;
  } @else {
    animation-timing-function: $standardEase;
  }

  @keyframes #{$name} {
    from {
      opacity: $startOpacity;
      transform: translateX($startX) translateY($startY);
    }
    to {
      opacity: $endOpacity;
      transform: translateX($endX) translateY($endY);
    }
  }
}

// ****************************************************************************

// Example Usage: @include rotate-animation(20deg, 90deg, rotateImage)
@mixin rotate-animation($start, $end, $name: rotate) {
  animation-name: $name;
  animation-duration: 0.25s;
  animation-timing-function: $standardEase;

  @keyframes #{$name} {
    from {
      transform: rotate($start);
    }
    to {
      transform: rotate($end);
    }
  }
}

// ****************************************************************************

// Example Usage: @include stagger-animation((.25s, .55s, .80s), '&', 'animation')
@mixin stagger-animation($delays, $selector, $delay-property: 'transition') {
  @for $i from 1 through length($delays) {
    $delay: nth($delays, $i);
    #{$selector}:nth-child(#{$i}) {
      #{$delay-property}-delay: $delay;
    }
  }
}

// body background animation keyframes
@keyframes gradientDark {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 0% 40%;
  }
  100% {
    background-position: 0% 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }
  50% {
    background-position: 100% 90%;
  }
  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%,
  100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%,
  100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }
  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%,
  100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }
  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%,
  100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }
  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

footer.experiencefragment {
  position: relative;
  z-index: var(--emu-common-other-z-index-isi);
}

.footer-container {
  position: relative;
  z-index: var(--emu-common-other-z-index-header);

  .footer {
    .footer-bottom {
      padding-top: 40px;
      padding-right: 20px;
      padding-bottom: 34px;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
      margin-top: var(--emu-common-spacing-none);
      margin-right: auto;
      margin-bottom: var(--emu-common-spacing-none);
      margin-left: auto;
      background-color: var(--emu-semantic-colors-primary-purple-500);

      @include mq('large') {
        padding: 72px;
      }

      &--top-container {
        display: flex;
        flex-direction: column;
        gap: var(--emu-common-spacing-large);

        @include mq('large') {
          flex-direction: row;
          gap: 62px;
        }

        > .text {
          @include mq('large') {
            flex: 1;
          }
        }

        &__text {
          p {
            color: var(--emu-semantic-colors-primary-purple-600);
            margin-top: -6px; //to adjust the spacing according to figma

            a,
            a:active,
            a:hover,
            a:focus {
              color: var(
                --emu-semantic-colors-primary-purple-600
              ); //to match figma
              text-decoration: none;
            }

            @include mq('large') {
              font-size: 24px;
              line-height: 36px;
            }
          }

          br {
            display: none;

            @include mq('large') {
              display: block;
            }
          }
        }

        .image img {
          width: 157px; //to match figma
          height: auto;
        }
      }

      ul {
        list-style: none;
        padding: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-large);
        margin-right: var(--emu-common-spacing-none);
        margin-left: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        display: flex;
        flex-direction: column;
        gap: var(--emu-common-spacing-small);

        @include mq('large') {
          flex-direction: row;
          column-gap: 28px;
          row-gap: 10px;
          flex-wrap: wrap;
        }

        li {
          a,
          a:active,
          a:hover,
          a:focus {
            color: var(--emu-semantic-colors-primary-purple-600);
            text-decoration: none;
          }

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-narrow-xl);
            line-height: 32px; //to match figma
          }
        }
      }
    }
  }
}

.embed {
  .aaaem-embed__oembed {
    padding-bottom: 100vh;

    iframe {
      background-color: var(--emu-semantic-colors-primary-purple-500);
    }

    @include aem-editor-view {
      padding-bottom: 600px;
      max-height: 5000px; // to make sure that the content looks good in AEM
    }
  }

  .show-overlay {
    .plyr {
      &--paused,
      &--stopped {
        &::after {
          content: '';
          position: fixed;
          inset: 0;
          background-color: #2f1945; // used only here
          opacity: 0.44;
        }
      }
    }
  }

  .center-align-plyr {
    height: 100vh;
    background: var(--emu-common-colors-transparent);
    display: flex;
    justify-content: center;
    align-items: center;

    .plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
      @include mq('large', 'max-width') {
        transform: none !important;
        padding: var(--emu-common-spacing-none);
        height: calc(100% + 40px);
      }
    }

    .aaaem-embed__plyr-embeddable {
      width: 100%;
    }

    @include aem-editor-view {
      max-height: 900px;
    }
  }
}

.plyr {
  .plyr__video-embed {
    height: auto;
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  &__volume {
    width: auto;
    min-width: var(--emu-common-sizing-none);
    max-width: 80px;
  }

  &--video {
    max-height: 100vh;
    width: 100vw;

    @include aem-editor-view {
      height: 900px;
      width: auto;
    }
  }

  &--video {
    .plyr {
      &__controls {
        width: 330px;
        max-width: 90%;
        margin: auto;
        position: fixed;
        bottom: 196px;
        background: rgba(0, 0, 0, 0.5);
        padding: var(--emu-common-spacing-small);
        border-radius: 30px;
        z-index: var(--emu-common-other-z-index-layer);
        cursor: pointer;

        @include mq('large') {
          bottom: 118px;
        }
      }
    }
  }

  &__control {
    &.plyr__control--overlaid {
      width: 74px; // as per figma
      height: 74px; // as per figma

      @include mq('large') {
        width: 148px; // as per figma
        height: 148px; // as per figma
      }

      &,
      &:hover {
        background-color: var(--emu-common-colors-transparent);
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/play.png');
        background-size: cover;
        background-color: transparent;
      }

      svg,
      span {
        display: none;
      }
    }
  }
}

.aaaem-tabs {
  position: relative;

  // when there is only one single tab, remove the tab pill
  &.has-single-tab {
    > .aaaem-tabs__tablist {
      display: none;
    }
  }

  &__tablist {
    background-color: var(--emu-semantic-colors-primary-purple-500);
    border-radius: 27px;
    position: relative;
    margin-top: 29px;
    --tab-width: 200px;
    --active-tab-position: 0;

    @include mq('large') {
      margin-top: 34px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      border-radius: 27px;
      border: 3px solid var(--emu-semantic-colors-primary-purple-500);
      left: calc(var(--active-tab-position) * var(--tab-width));
      width: var(--tab-width);
      background-color: var(--emu-common-colors-white);
      z-index: var(--emu-common-other-z-index-base);
      transition: all 0.2s linear;
    }
  }

  &__tab {
    flex: 1;
    position: relative;
    z-index: var(--emu-common-other-z-index-layer);

    a {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: var(--emu-common-font-weight-regular);
      line-height: 24px;
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
      transition: all 0.2s linear;
      text-align: center;

      &[aria-selected='true'] {
        transition-delay: 0.1s;
      }
    }
  }

  &__tabpanels {
    position: relative;
  }

  &__tabpanel {
    transition: opacity 0.2s ease-in 0.2s;

    &[hidden] {
      top: -14px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      transition: opacity 0.2s ease-out;
      width: 100%;

      @include mq('large') {
        display: block;
        opacity: 0;
        pointer-events: none;
      }

      .aaaem-tabs__tablist {
        display: none;

        @include mq('large') {
          opacity: 0;
        }
      }
    }
  }
}

.custom-tabs {
  // when there is only one single tab, remove the tab pill and custom select dropdown
  &.has-single-tab {
    > .tabs {
      .aaaem-tabs {
        > .aaaem-tabs__tablist {
          display: none;
        }
      }
    }

    > .container {
      > .custom-tabs__select-wrapper {
        display: none;
      }
    }
  }

  > .tabs {
    .aaaem-tabs__tablist {
      // hiding tab pills
      @include mq('1023px', 'max-width') {
        display: none;
      }
    }
  }

  > .container {
    .custom-tabs__select-wrapper {
      // hiding select dropdown for desktop
      @include mq('large') {
        display: none;
      }
    }
  }
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

@font-face {
  font-family: BrownStd;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-Light.woff2") format("woff2");
}

@font-face {
  font-family: BrownStd;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-Light.woff") format("woff");
}

@font-face {
  font-family: BrownStd;
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: BrownStd;
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-LightItalic.woff") format("woff");
}

@font-face {
  font-family: BrownStd;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: BrownStd;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: BrownStd;
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-Italic.woff2") format("woff2");
}

@font-face {
  font-family: BrownStd;
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-Italic.woff") format("woff");
}

@font-face {
  font-family: BrownStd;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: BrownStd;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: BrownStd;
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: BrownStd;
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("resources/fonts/BrownLLWeb-BoldItalic.woff") format("woff");
}

body {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bold);
  color: inherit;
  margin: var(--emu-common-spacing-none);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-100: #ff7570;
  --emu-common-colors-red-200: #ff615c;
  --emu-common-colors-red-300: #ff4e47;
  --emu-common-colors-red-400: #ff3a33;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #ff120a;
  --emu-common-colors-red-700: #f50800;
  --emu-common-colors-red-800: #e00700;
  --emu-common-colors-red-900: #cc0700;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-font-families-sans: Arial;
  --emu-common-font-families-serif: Times New Roman;
  --emu-common-font-families-mono: Courier;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-white);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-surface-modal-backdrop: #2e1844cc;
  --emu-semantic-colors-actions-primary-light: var(--emu-common-colors-red-500);
  --emu-semantic-colors-actions-primary-dark: var(--emu-common-colors-red-900);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-violet-100: #2e1745;
  --emu-semantic-colors-primary-violet-200: #160c1f;
  --emu-semantic-colors-primary-violet-300: #241f21;
  --emu-semantic-colors-primary-purple-100: #9577bf;
  --emu-semantic-colors-primary-purple-200: #704799;
  --emu-semantic-colors-primary-purple-300: #ff57ff;
  --emu-semantic-colors-primary-purple-400: #f0f;
  --emu-semantic-colors-primary-purple-500: #d9d8e9;
  --emu-semantic-colors-primary-purple-600: #2e1844;
  --emu-semantic-colors-secondary-grey-100: #707078;
  --emu-semantic-colors-secondary-grey-200: #696969;
  --emu-semantic-colors-secondary-grey-300: #828287;
  --emu-semantic-colors-secondary-grey-400: #404042;
  --emu-semantic-colors-secondary-grey-500: #838187;
  --emu-semantic-colors-secondary-light-grey-100: #d9d9d9;
  --emu-semantic-colors-secondary-light-grey-200: #d9d9e8;
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-black);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-white);
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: BrownStd;
  --emu-semantic-font-families-heading: BrownStd;
  --emu-semantic-font-families-mono: BrownStd;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal: var(--emu-common-spacing-small);
  --emu-semantic-spacing-vertical: var(--emu-common-spacing-small);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-font-sizes-narrow-medium: 24px;
  --emu-semantic-font-sizes-narrow-large: 24px;
  --emu-semantic-font-sizes-narrow-xl: 42px;
  --emu-semantic-font-sizes-narrow-xxl: 30px;
  --emu-semantic-font-sizes-narrow-xxxl: 60px;
  --emu-semantic-font-sizes-wide-medium: 30px;
  --emu-semantic-font-sizes-wide-large: 36px;
  --emu-semantic-font-sizes-wide-xl: 48px;
  --emu-semantic-font-sizes-wide-xxl: 54px;
  --emu-semantic-font-sizes-wide-xxxl: 80px;
  --emu-semantic-line-heights-narrow-medium: 29px;
  --emu-semantic-line-heights-narrow-large: 29px;
  --emu-semantic-line-heights-narrow-xl: 62px;
  --emu-semantic-line-heights-narrow-xxl: 36px;
  --emu-semantic-line-heights-narrow-xxxl: 72px;
  --emu-semantic-line-heights-wide-medium: 36px;
  --emu-semantic-line-heights-wide-large: 43px;
  --emu-semantic-line-heights-wide-xl: 62px;
  --emu-semantic-line-heights-wide-xxl: 62px;
  --emu-semantic-line-heights-wide-xxxl: 96px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1280px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-sizing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-light) 18px / 24px var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-light) 24px / 36px var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-medium);
  --emu-component-lists-accordion-item-header-icon-size-height: 12px;
  --emu-component-lists-accordion-item-header-icon-size-width: 12px;
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: 500;
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 50px;
  --emu-component-ingredients-image-map-buttons-size-width: 50px;
  --emu-component-ingredients-image-map-buttons-color-background-light: #ffffff4d;
  --emu-component-ingredients-image-map-buttons-color-background-dark: #ffffff4d;
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0000;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-large);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-large);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-padding-narrow: 7px 20px 7px 19px;
  --emu-component-ingredients-select-input-padding-wide: 11px 22px 11px 25px;
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-medium);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-medium);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 18px / 22px var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 18px / 22px var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-color-link-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-padding-top-narrow: 13px;
  --emu-component-actions-button-padding-top-wide: 13px;
  --emu-component-actions-button-padding-right-narrow: 32px;
  --emu-component-actions-button-padding-right-wide: 19px;
  --emu-component-actions-button-padding-bottom-narrow: 13px;
  --emu-component-actions-button-padding-bottom-wide: 13px;
  --emu-component-actions-button-padding-left-narrow: 32px;
  --emu-component-actions-button-padding-left-wide: 19px;
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-none);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-medium);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-primary-outline: 0;
  --emu-component-actions-button-outline-width-secondary-filled: 0;
  --emu-component-actions-button-outline-width-secondary-outline: 0;
  --emu-component-actions-button-border-radius-primary-filled-top-left: 37px;
  --emu-component-actions-button-border-radius-primary-filled-top-right: 37px;
  --emu-component-actions-button-border-radius-primary-filled-bottom-right: 37px;
  --emu-component-actions-button-border-radius-primary-filled-bottom-left: 37px;
  --emu-component-actions-button-border-radius-primary-outline-top-left: 37px;
  --emu-component-actions-button-border-radius-primary-outline-top-right: 37px;
  --emu-component-actions-button-border-radius-primary-outline-bottom-right: 37px;
  --emu-component-actions-button-border-radius-primary-outline-bottom-left: 37px;
  --emu-component-actions-button-border-radius-secondary-filled-top-left: 30px;
  --emu-component-actions-button-border-radius-secondary-filled-top-right: 30px;
  --emu-component-actions-button-border-radius-secondary-filled-bottom-right: 30px;
  --emu-component-actions-button-border-radius-secondary-filled-bottom-left: 30px;
  --emu-component-actions-button-border-radius-secondary-outline-top-left: 30px;
  --emu-component-actions-button-border-radius-secondary-outline-top-right: 30px;
  --emu-component-actions-button-border-radius-secondary-outline-bottom-right: 30px;
  --emu-component-actions-button-border-radius-secondary-outline-bottom-left: 30px;
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-primary-purple-500);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-primary-purple-100);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-icon-size-height: 12px;
  --emu-component-actions-button-icon-size-width: 12px;
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-back-to-top-z-index: 600;
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-primary-purple-600);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-containers-isi-header-padding-top-narrow: 14px;
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-small);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: 10px;
  --emu-component-containers-isi-header-padding-left-narrow: 24px;
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-common-border-width-none);
  --emu-component-containers-isi-body-padding-top-narrow: 35px;
  --emu-component-containers-isi-body-padding-top-wide: 55px;
  --emu-component-containers-isi-body-padding-right-narrow: 20px;
  --emu-component-containers-isi-body-padding-right-wide: 71px;
  --emu-component-containers-isi-body-padding-bottom-narrow: 42px;
  --emu-component-containers-isi-body-padding-bottom-wide: 55px;
  --emu-component-containers-isi-body-padding-left-narrow: 20px;
  --emu-component-containers-isi-body-padding-left-wide: 71px;
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-common-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: 400;
  --emu-component-containers-container-max-width: 100%;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: 100;
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: 600;
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 100%;
  --emu-component-layers-modal-body-sizing-max-width: 636px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-large);
  --emu-component-layers-modal-body-padding-top-wide: 46px;
  --emu-component-layers-modal-body-padding-right-narrow: 20px;
  --emu-component-layers-modal-body-padding-right-wide: 48px;
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-large);
  --emu-component-layers-modal-body-padding-bottom-wide: 52px;
  --emu-component-layers-modal-body-padding-left-narrow: 20px;
  --emu-component-layers-modal-body-padding-left-wide: 56px;
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: 700;
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width) px;
  --emu-component-layers-cookie-consent-banner-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  font: var(--emu-semantic-typography-narrow-body-regular);
  text-align: left;
  scroll-behavior: smooth;
  margin: 0 auto;
  padding: 0;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: 0;
}

body {
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(135deg, var(--emu-semantic-colors-primary-violet-200) 0%, var(--emu-semantic-colors-primary-violet-200) 17.56%, var(--emu-semantic-colors-primary-purple-100) 41.52%, var(--emu-semantic-colors-primary-purple-500) 62.36%, var(--emu-semantic-colors-primary-purple-500) 74.86%, #f1ede9 87.36%, #f1ede9 100%);
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  background-repeat: no-repeat;
  background-size: 400% 400%;
  background-attachment: fixed;
  margin: 0 auto;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .mobile-view {
    display: none;
  }
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.hide {
  display: none !important;
}

.cq-Editable-dom--container .hide {
  display: inline !important;
  display: initial !important;
}

.text--color-white {
  color: var(--emu-semantic-colors-text-dark);
}

.text--color-black {
  color: var(--emu-semantic-colors-text-light);
}

.emu-spacing-mb__none {
  margin-bottom: var(--emu-common-spacing-none) !important;
}

.no-events {
  pointer-events: none;
}

:root {
  --default-page-transition-duration: .8s;
}

body.js-modal-opened {
  overflow: hidden;
}

body.js-modal-opened .aaaem-isi-container {
  z-index: var(--emu-common-other-z-index-base);
}

body:has(.cq-Editable-dom--container) {
  background: var(--emu-semantic-colors-primary-purple-500) !important;
  animation: none !important;
}

.has--loader {
  overflow: hidden;
}

.has--loader .lo3-section-container--active .lo3-section, .has--loader .lo3-section, .has--loader .header__sidenav-container, .has--loader .floating-cta, .has--loader .modal-wrapper, .has--loader .experiencefragment {
  pointer-events: none;
  transition: all .2s linear;
  opacity: 0 !important;
}

.cq-Editable-dom--container .has--loader .lo3-section-container--active .lo3-section, .cq-Editable-dom--container .has--loader .lo3-section, .cq-Editable-dom--container .has--loader .header__sidenav-container, .cq-Editable-dom--container .has--loader .floating-cta, .cq-Editable-dom--container .has--loader .modal-wrapper, .cq-Editable-dom--container .has--loader .experiencefragment {
  pointer-events: initial;
  opacity: 1 !important;
}

.has--loader .loader__wrapper {
  opacity: 1;
  pointer-events: initial;
}

.has-isi-on-top-layer .container-isi .aaaem-isi-container, .has-isi-on-top-layer .container-isi .aaaem-isi-container__isi-content {
  z-index: var(--emu-common-other-z-index-modal);
}

.bg--dark {
  animation: .8s forwards transitionFromLight, 10s .8s infinite gradientDark;
}

.bg--dark .header__mobile-trigger svg path {
  fill: var(--emu-common-colors-white);
}

.bg--dark .header__sidenav-container ul li a:before {
  border-color: #ffffff80;
}

.bg--dark .header__sidenav-container ul li a:after {
  background-color: #ffffff80;
}

.bg--dark .header__sidenav-container ul li.active a {
  color: var(--emu-common-colors-white);
}

.bg--dark .header__sidenav-container ul li.active:after, .bg--dark .header__sidenav-container ul li.active:before {
  background-color: var(--emu-common-colors-white);
}

.bg--dark .header__sidenav-container ul li a {
  color: #ffffff80;
}

.bg--dark [data-component="text"] p, .bg--dark [data-component="text"] li, .bg--dark [data-component="title"] p, .bg--dark [data-component="title"] li {
  color: var(--emu-common-colors-white);
}

.bg--dark [data-component="text"] h1, .bg--dark [data-component="text"] h2, .bg--dark [data-component="text"] h3, .bg--dark [data-component="text"] h4, .bg--dark [data-component="text"] h5, .bg--dark [data-component="title"] h1, .bg--dark [data-component="title"] h2, .bg--dark [data-component="title"] h3, .bg--dark [data-component="title"] h4, .bg--dark [data-component="title"] h5 {
  color: var(--emu-semantic-colors-primary-purple-500);
}

.bg--dark .patient-disclaimer i {
  color: var(--emu-common-colors-white);
}

.bg--light {
  animation: .8s forwards transitionFromDark, 10s .8s infinite gradientLight;
}

.bg--light [data-component="text"] p, .bg--light [data-component="text"] li, .bg--light [data-component="title"] p, .bg--light [data-component="title"] li {
  color: var(--emu-semantic-colors-primary-violet-200);
}

.bg--light [data-component="text"] h1, .bg--light [data-component="text"] h2, .bg--light [data-component="text"] h3, .bg--light [data-component="text"] h4, .bg--light [data-component="text"] h5, .bg--light [data-component="title"] h1, .bg--light [data-component="title"] h2, .bg--light [data-component="title"] h3, .bg--light [data-component="title"] h4, .bg--light [data-component="title"] h5 {
  color: var(--emu-semantic-colors-primary-purple-600);
}

.bg--light .content-box .content-box__text-small[data-component="text"] p {
  color: var(--emu-semantic-colors-secondary-grey-500);
}

.has-floating-cta-light .scroll-button svg path {
  fill: var(--emu-common-colors-white);
}

.has-floating-cta-light .scroll-button span {
  color: var(--emu-common-colors-white);
}

.has-treatment-cta--hidden .floating-cta {
  grid-gap: 23px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 23px;
  min-height: 48px;
}

.has-treatment-cta--hidden .floating-cta .button {
  flex: 1;
}

@media (min-width: 1024px) {
  .has-treatment-cta--hidden .floating-cta .button {
    flex: 0 0 244px;
  }
}

.has-treatment-cta--hidden .floating-cta .button:first-child {
  flex: 0;
  align-self: center;
}

@media (min-width: 1024px) {
  .has-treatment-cta--hidden .floating-cta .button:first-child {
    flex: 0 0 244px;
  }
}

.has-treatment-cta--hidden .floating-cta .button:nth-child(2) {
  display: none;
}

@media (min-width: 1024px) {
  .has-treatment-cta--hidden .floating-cta .button:nth-child(2) {
    opacity: 0;
    pointer-events: none;
    display: flex;
    transform: translateY(400px);
  }
}

.has-journey-cta--hidden .floating-cta {
  grid-gap: 23px;
  z-index: var(--emu-common-other-z-index-base);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 23px;
  min-height: 48px;
}

.has-journey-cta--hidden .floating-cta .button {
  flex: 1;
}

@media (min-width: 1024px) {
  .has-journey-cta--hidden .floating-cta .button {
    flex: 0 0 244px;
  }
}

.has-journey-cta--hidden .floating-cta .button:first-child {
  flex: 0;
  align-self: center;
}

@media (min-width: 1024px) {
  .has-journey-cta--hidden .floating-cta .button:first-child {
    flex: 0 0 244px;
  }
}

.has-journey-cta--hidden .floating-cta .button:nth-child(3) {
  display: none;
}

@media (min-width: 1024px) {
  .has-journey-cta--hidden .floating-cta .button:nth-child(3) {
    opacity: 0;
    pointer-events: none;
    display: flex;
    transform: translateY(400px);
  }
}

.has-floating-cta--hidden .floating-cta {
  pointer-events: none;
  opacity: 0;
  bottom: 0;
  transform: translateY(400px);
}

@media (min-width: 1024px) {
  .has-side-nav--hidden .header__sidenav-container {
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease-in-out, transform .2s linear .4s;
    transform: translateX(84px)translateY(-50%)translateY(100px);
  }
}

@media (min-width: 1440px) {
  .has-side-nav--hidden .header__sidenav-container {
    transform: translateY(-50%)translateY(100px)translateX(calc(50vw - 720px))translateX(78px);
  }
}

.has-main-menu--hidden .header__logo, .has-main-menu--hidden .header__eyebrow, .has-main-menu--hidden .header__content > .image img {
  transform: translateY(-500px);
}

.has-main-menu--hidden .header__content > .image .cmp-image__link {
  pointer-events: none;
  display: none;
}

.is-fullscreen-mode .header, .is-fullscreen-mode .floating-cta {
  display: none;
}

.lo3-section {
  top: var(--emu-common-spacing-none);
  left: var(--emu-common-spacing-none);
  opacity: 0;
  pointer-events: none;
  transition: all var(--default-page-transition-duration) linear;
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow: hidden;
}

@media (min-width: 1440px) {
  .lo3-section {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

.cq-Editable-dom--container .lo3-section {
  max-height: none;
  opacity: 1 !important;
  height: auto !important;
  position: static !important;
}

.cq-Editable-dom--container .lo3-section :not(.new):not(.cq-Editable-dom):not(.newpar) {
  opacity: 1 !important;
  transition: none !important;
  animation: none !important;
  transform: none !important;
}

.lo3-section--static {
  opacity: 1;
  height: auto;
  position: static;
}

.lo3-section-container {
  height: 100vh;
  position: relative;
}

.cq-Editable-dom--container .lo3-section-container {
  height: auto;
  max-height: none;
}

.lo3-section-container--active .lo3-section, .lo3-section-container--prev-active .lo3-section {
  position: fixed;
  overflow: visible;
}

.duration--100ms {
  transition-duration: 100 ms !important;
  animation-duration: 100 ms !important;
}

.duration--200ms {
  transition-duration: 200 ms !important;
  animation-duration: 200 ms !important;
}

.duration--300ms {
  transition-duration: 300 ms !important;
  animation-duration: 300 ms !important;
}

.duration--400ms {
  transition-duration: 400 ms !important;
  animation-duration: 400 ms !important;
}

.duration--500ms {
  transition-duration: 500 ms !important;
  animation-duration: 500 ms !important;
}

.duration--600ms {
  transition-duration: 600 ms !important;
  animation-duration: 600 ms !important;
}

.duration--700ms {
  transition-duration: 700 ms !important;
  animation-duration: 700 ms !important;
}

.duration--800ms {
  transition-duration: 800 ms !important;
  animation-duration: 800 ms !important;
}

.duration--900ms {
  transition-duration: 900 ms !important;
  animation-duration: 900 ms !important;
}

.duration--1000ms {
  transition-duration: 1000 ms !important;
  animation-duration: 1000 ms !important;
}

.duration--1100ms {
  transition-duration: 1100 ms !important;
  animation-duration: 1100 ms !important;
}

.duration--1200ms {
  transition-duration: 1200 ms !important;
  animation-duration: 1200 ms !important;
}

.duration--1300ms {
  transition-duration: 1300 ms !important;
  animation-duration: 1300 ms !important;
}

.duration--1400ms {
  transition-duration: 1400 ms !important;
  animation-duration: 1400 ms !important;
}

.duration--1500ms {
  transition-duration: 1500 ms !important;
  animation-duration: 1500 ms !important;
}

.duration--1600ms {
  transition-duration: 1600 ms !important;
  animation-duration: 1600 ms !important;
}

.duration--1700ms {
  transition-duration: 1700 ms !important;
  animation-duration: 1700 ms !important;
}

.duration--1800ms {
  transition-duration: 1800 ms !important;
  animation-duration: 1800 ms !important;
}

.duration--1900ms {
  transition-duration: 1900 ms !important;
  animation-duration: 1900 ms !important;
}

.duration--2000ms {
  transition-duration: 2000 ms !important;
  animation-duration: 2000 ms !important;
}

.delay--100ms {
  transition-delay: 100 ms !important;
  animation-delay: 100 ms !important;
}

.delay--200ms {
  transition-delay: 200 ms !important;
  animation-delay: 200 ms !important;
}

.delay--300ms {
  transition-delay: 300 ms !important;
  animation-delay: 300 ms !important;
}

.delay--400ms {
  transition-delay: 400 ms !important;
  animation-delay: 400 ms !important;
}

.delay--500ms {
  transition-delay: 500 ms !important;
  animation-delay: 500 ms !important;
}

.delay--600ms {
  transition-delay: 600 ms !important;
  animation-delay: 600 ms !important;
}

.delay--700ms {
  transition-delay: 700 ms !important;
  animation-delay: 700 ms !important;
}

.delay--800ms {
  transition-delay: 800 ms !important;
  animation-delay: 800 ms !important;
}

.delay--900ms {
  transition-delay: 900 ms !important;
  animation-delay: 900 ms !important;
}

.delay--1000ms {
  transition-delay: 1000 ms !important;
  animation-delay: 1000 ms !important;
}

.delay--1100ms {
  transition-delay: 1100 ms !important;
  animation-delay: 1100 ms !important;
}

.delay--1200ms {
  transition-delay: 1200 ms !important;
  animation-delay: 1200 ms !important;
}

.delay--1300ms {
  transition-delay: 1300 ms !important;
  animation-delay: 1300 ms !important;
}

.delay--1400ms {
  transition-delay: 1400 ms !important;
  animation-delay: 1400 ms !important;
}

.delay--1500ms {
  transition-delay: 1500 ms !important;
  animation-delay: 1500 ms !important;
}

.delay--1600ms {
  transition-delay: 1600 ms !important;
  animation-delay: 1600 ms !important;
}

.delay--1700ms {
  transition-delay: 1700 ms !important;
  animation-delay: 1700 ms !important;
}

.delay--1800ms {
  transition-delay: 1800 ms !important;
  animation-delay: 1800 ms !important;
}

.delay--1900ms {
  transition-delay: 1900 ms !important;
  animation-delay: 1900 ms !important;
}

.delay--2000ms {
  transition-delay: 2000 ms !important;
  animation-delay: 2000 ms !important;
}

.content-box {
  flex-direction: column;
  justify-content: center;
  max-width: 545px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 187px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .content-box {
    padding-bottom: 113px;
    padding-left: var(--emu-common-spacing-none);
    width: 758px;
    max-width: none;
    margin-left: max(50vw - 245px, 270px);
  }
}

@media (min-width: 1280px) {
  .content-box {
    margin-left: max(50vw - 720px, 401px);
  }
}

@media (min-width: 1440px) {
  .content-box {
    padding-right: var(--emu-common-spacing-none);
    margin-left: 401px;
  }
}

.cq-Editable-dom--container .content-box {
  height: auto;
  max-height: none;
}

.content-box--full {
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .content-box--full {
    padding-right: var(--emu-common-spacing-none);
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.content-box__background {
  position: absolute;
  inset: 0;
}

.cq-Editable-dom--container .content-box__background {
  position: static;
}

.content-box__background img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cq-Editable-dom--container .content-box__background img {
  height: auto;
}

.content-box__title {
  text-align: center;
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  .content-box__title {
    padding: var(--emu-common-spacing-none);
    text-align: left;
    margin-bottom: 34px;
  }
}

.content-box__title h2 {
  letter-spacing: -2px;
}

.content-box__title h2 sup {
  top: -4px;
}

@media (min-width: 1024px) {
  .content-box__title h2 sup {
    top: -8px;
  }
}

.content-box__text {
  margin-bottom: 24px;
}

.content-box__text[data-component="text"] {
  text-align: center;
}

@media (min-width: 1024px) {
  .content-box__text[data-component="text"] {
    text-align: left;
    font: var(--emu-semantic-typography-wide-body-regular);
  }
}

.content-box__text[data-component="text"] sup {
  top: -3px;
}

@media (min-width: 1024px) {
  .content-box__text[data-component="text"] sup {
    top: -4px;
  }
}

.content-box__text a, .content-box__text a > .emphasis {
  color: var(--emu-semantic-colors-primary-purple-100);
  font-weight: var(--emu-common-font-weight-bold);
  text-underline-offset: 2px;
  text-decoration: underline;
}

.content-box__text-small[data-component="text"] p {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .content-box__text-small[data-component="text"] p {
    text-align: left;
    letter-spacing: -.2px;
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    font-size: 16px;
  }
}

.content-box .button {
  text-align: center;
}

@media (min-width: 1024px) {
  .content-box .button {
    text-align: left;
  }
}

.content-box__button, .content-box__text-small {
  margin-top: 24px;
}

.content-box__modal-text p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  text-align: center;
  color: var(--emu-semantic-colors-primary-violet-200);
}

@media (min-width: 1024px) {
  .content-box__modal-text p {
    text-align: left;
    line-height: 36px;
  }
}

.content-box__modal-text p sup {
  top: -4px;
}

.content-box__modal-small-text[data-component="text"] p {
  color: var(--emu-semantic-colors-secondary-grey-500);
  font-size: 14px;
  font-weight: var(--emu-common-font-weight-light);
  margin-top: 29px;
  margin-bottom: var(--emu-common-spacing-none);
  text-indent: -8px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .content-box__modal-small-text[data-component="text"] p {
    margin-top: 18px;
    font-size: 16px;
  }
}

.content-box__modal-small-text[data-component="text"] p sup {
  top: 1px;
}

.content-box__modal-small-text[data-component="text"] p .emphasis {
  text-indent: 0;
  text-indent: initial;
}

.content-box__modal-title-wrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;
  display: flex;
}

@media (min-width: 1024px) {
  .content-box__modal-title-wrapper {
    margin-bottom: var(--emu-common-spacing-none);
    grid-gap: 20px;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 10px;
  }
}

.content-box__modal-title-wrapper h3.emu-title__text {
  color: var(--emu-semantic-colors-primary-violet-200);
  font-size: 150px;
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: -6px;
  line-height: normal;
}

.content-box__modal-title-wrapper h3.emu-title__text sup {
  font-weight: var(--emu-common-font-weight-regular);
  top: 3px;
}

@media (min-width: 1024px) {
  .content-box__modal-title-wrapper h3.emu-title__text sup {
    top: 6px;
  }

  .content-box__modal-title-wrapper .content-box__modal-text p {
    line-height: 32px;
  }
}

.content-box .content-box__text:last-of-type {
  margin-bottom: var(--emu-common-spacing-none);
}

.lo3-section-container .content-box__title, .lo3-section-container .content-box__subtitle, .lo3-section-container .content-box__text, .lo3-section-container .content-box__text-small, .lo3-section-container .content-box__button, .lo3-section-container .lo3-animated-subsection .content-box__title, .lo3-section-container .lo3-animated-subsection .content-box__subtitle, .lo3-section-container .lo3-animated-subsection .content-box__text, .lo3-section-container .lo3-animated-subsection .content-box__text-small, .lo3-section-container .lo3-animated-subsection .content-box__button {
  opacity: 0;
  transition: all .4s ease-in-out;
  transform: translateY(40px);
}

.lo3-section-container .content-box__text, .lo3-section-container .lo3-animated-subsection .content-box__text {
  transition-delay: .2s;
}

.lo3-section-container .content-box__text-small, .lo3-section-container .lo3-animated-subsection .content-box__text-small {
  transition-delay: .4s;
}

.lo3-section-container .content-box__button, .lo3-section-container .lo3-animated-subsection .content-box__button {
  transition-delay: .6s;
}

.lo3-section-container .content-box:has(.button + .text) .content-box__button {
  transition-delay: .4s;
}

.lo3-section-container .lo3-animated-subsection .content-box:has(.button + .text) .content-box__button {
  transition-delay: .4s;
}

.lo3-section-container .content-box:has(.button + .text) .content-box__text-small {
  transition-delay: .6s;
}

.lo3-section-container .lo3-animated-subsection .content-box:has(.button + .text) .content-box__text-small {
  transition-delay: .6s;
}

.lo3-section-container--active .lo3-section, .lo3-section-container .lo3-animated-subsection--active .lo3-section {
  opacity: 1;
  z-index: var(--emu-common-other-z-index-layer);
  pointer-events: initial;
}

.lo3-section-container--active .content-box__title, .lo3-section-container--active .content-box__subtitle, .lo3-section-container--active .content-box__text, .lo3-section-container--active .content-box__text-small, .lo3-section-container--active .content-box__button, .lo3-section-container .lo3-animated-subsection--active .content-box__title, .lo3-section-container .lo3-animated-subsection--active .content-box__subtitle, .lo3-section-container .lo3-animated-subsection--active .content-box__text, .lo3-section-container .lo3-animated-subsection--active .content-box__text-small, .lo3-section-container .lo3-animated-subsection--active .content-box__button {
  opacity: 1;
  transform: translateY(0);
}

.lo3-section-container--prev-active .lo3-section {
  opacity: 0;
  transition-timing-function: ease-out;
  transition-duration: calc(var(--default-page-transition-duration)  - .1s);
}

.lo3-section-container--prev-active .lo3-section:not(.lo3-section--only-fade) .content-box {
  transition-property: transform;
  transition-timing-function: ease-out;
  transition-duration: calc(var(--default-page-transition-duration)  - .1s);
  transform: translateY(-200px);
}

.lo3-section-container--prev-active .content-box__title, .lo3-section-container--prev-active .content-box__subtitle, .lo3-section-container--prev-active .content-box__text, .lo3-section-container--prev-active .content-box__text-small, .lo3-section-container--prev-active .content-box__button {
  opacity: 1;
  transform: translateY(0);
}

[data-component="text"].patient-disclaimer {
  z-index: var(--emu-common-other-z-index-layer);
  pointer-events: none;
  width: 100%;
  transition: all .4s linear;
  position: fixed;
  bottom: 271px;
  left: 0;
}

.has-treatment-cta--hidden [data-component="text"].patient-disclaimer {
  bottom: 214px;
}

.cq-Editable-dom--container [data-component="text"].patient-disclaimer {
  position: static;
}

@media (min-width: 1024px) {
  [data-component="text"].patient-disclaimer, .has-treatment-cta--hidden [data-component="text"].patient-disclaimer {
    bottom: 233px;
  }
}

[data-component="text"].patient-disclaimer p {
  color: var(--emu-semantic-colors-primary-violet-200);
  text-align: center;
  width: 89%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  line-height: normal;
}

@media (min-width: 1024px) {
  [data-component="text"].patient-disclaimer p {
    font-size: var(--emu-common-font-sizes-wide-medium);
    width: 91%;
    max-width: 1298px;
  }
}

[data-component="text"].patient-disclaimer--dark p {
  color: var(--emu-semantic-colors-primary-violet-200);
}

[data-component="text"].patient-disclaimer--light p, [data-component="text"].patient-disclaimer--light-mobile p {
  color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  [data-component="text"].patient-disclaimer--light-mobile p {
    color: var(--emu-semantic-colors-primary-violet-200);
  }
}

[data-component="text"].patient-disclaimer--dark-mobile p {
  color: var(--emu-semantic-colors-primary-violet-200);
}

@media (min-width: 1024px) {
  [data-component="text"].patient-disclaimer--dark-mobile p {
    color: var(--emu-common-colors-white);
  }
}

[data-component="text"].patient-disclaimer--large p {
  font-size: 16px;
  line-height: normal;
}

[data-component="text"].patient-disclaimer--left p {
  text-align: left;
}

[data-component="text"].patient-disclaimer--right p {
  text-align: right;
}

.lo3-animated-subsection {
  opacity: 0;
  pointer-events: none;
  height: 0;
  transition: all .4s linear;
  overflow: visible;
  transform: translateY(100px);
}

.cq-Editable-dom--container .lo3-animated-subsection {
  opacity: 1;
  transform: none;
  height: auto !important;
}

.lo3-animated-subsection.lo3-animated-subsection--prev-active {
  opacity: 0;
  transform: translateY(0);
}

.lo3-animated-subsection--active {
  opacity: 1;
  pointer-events: initial;
  transition: transform .4s linear, opacity .8s linear .2s, height .4s linear;
  transform: translateY(0);
}

.has-highlighted-text u {
  padding: var(--emu-common-spacing-xs);
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-common-colors-white);
  border-radius: 4px;
  text-decoration: none;
  transition: all .4s linear;
}

.cq-Editable-dom--container .has-highlighted-text u {
  background-color: var(--emu-semantic-colors-primary-purple-100);
  color: var(--emu-common-colors-white);
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u, .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u {
  animation: 1.2s linear .6s highlightText;
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:only-of-type, .lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:last-of-type, .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:only-of-type, .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:last-of-type {
  animation-fill-mode: forwards;
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:nth-of-type(2), .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:nth-of-type(2) {
  animation-delay: 1.8s;
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:nth-of-type(3), .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:nth-of-type(3) {
  animation-delay: 3s;
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:nth-of-type(4), .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:nth-of-type(4) {
  animation-delay: 4.2s;
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:nth-of-type(5), .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:nth-of-type(5) {
  animation-delay: 5.4s;
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:nth-of-type(6), .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:nth-of-type(6) {
  animation-delay: 6.6s;
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:nth-of-type(7), .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:nth-of-type(7) {
  animation-delay: 7.8s;
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:nth-of-type(8), .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:nth-of-type(8) {
  animation-delay: 9s;
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:nth-of-type(9), .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:nth-of-type(9) {
  animation-delay: 10.2s;
}

.lo3-section-container--active .lo3-section:not(.lo3-section--has-animated-subsection) .has-highlighted-text u:nth-of-type(10), .lo3-section-container--active .lo3-section.lo3-section--has-animated-subsection .lo3-animated-subsection--active .has-highlighted-text u:nth-of-type(10) {
  animation-delay: 11.4s;
}

@keyframes highlightText {
  20%, 100% {
    color: var(--emu-common-colors-white);
    background-color: var(--emu-semantic-colors-primary-purple-100);
  }
}

.tooltip__container {
  justify-content: center;
  width: max-content;
  margin: 20px auto 27px;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .tooltip__container {
    margin-top: 24px;
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    justify-content: start;
  }
}

.tooltip__container--visible .aaaem-button.aaaem-button__secondary-outline {
  border-color: var(--emu-semantic-colors-primary-purple-100);
  background-color: #9577bf4d;
  transition: background-color .2s ease-in-out;
}

.tooltip__container--visible .tooltip__popover.cmp-text {
  opacity: 1;
  pointer-events: initial;
  transform: translateX(-50%)translateY(-19px);
}

@media screen and (pointer: fine) and (hover: hover) {
  .tooltip__container:hover {
    cursor: pointer;
  }

  .tooltip__container:hover .aaaem-button {
    border-color: var(--emu-semantic-colors-primary-purple-100);
    pointer-events: none;
    background-color: #9577bf4d;
    transition: background-color .2s ease-in-out;
  }

  .tooltip__container:hover .tooltip__popover {
    opacity: 1;
    pointer-events: initial;
    cursor: default;
    transform: translateX(-50%)translateY(-19px);
  }
}

.cq-Editable-dom--container .tooltip__container {
  display: block;
}

.tooltip__popover.cmp-text {
  opacity: 0;
  background: var(--emu-semantic-colors-primary-violet-100);
  padding-top: 15px;
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  pointer-events: none;
  border-radius: 12px;
  min-width: 295px;
  max-width: 295px;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%)translateY(20px);
}

@media (min-width: 1024px) {
  .tooltip__popover.cmp-text {
    min-width: 397px;
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

.cq-Editable-dom--container .tooltip__popover.cmp-text {
  opacity: 1;
  position: static;
  transform: none;
}

.tooltip__popover.cmp-text:after {
  content: "";
  background-color: inherit;
  border: inherit;
  -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border-radius: 6px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  bottom: -9px;
  left: calc(50% - 10px);
  transform: rotate(-45deg);
}

.tooltip__popover.cmp-text p {
  color: var(--emu-common-colors-white);
  text-align: center;
  font-size: var(--emu-common-font-sizes-wide-medium);
  font-weight: var(--emu-common-font-weight-regular);
  line-height: normal;
}

.tooltip__popover.cmp-text p sup {
  top: 1px;
}

.fullscreen-video-container {
  background-color: var(--emu-common-colors-transparent);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
}

.cq-Editable-dom--container .fullscreen-video-container {
  height: 900px;
  display: block !important;
}

@media (min-width: 1024px) {
  .fullscreen-video-container.tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .fullscreen-video-container.tablet-mobile-view {
  display: block !important;
}

.fullscreen-video-container.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .fullscreen-video-container.desktop-view {
    display: flex;
  }
}

.cq-Editable-dom--container .fullscreen-video-container.desktop-view {
  display: block !important;
}

.fullscreen-video-container .plyr--paused:after, .fullscreen-video-container .plyr--stopped:after {
  content: "";
  opacity: .44;
  background-color: #2f1945;
  position: fixed;
  inset: 0;
}

.fullscreen-video-container .plyr .plyr__controls {
  opacity: 1;
  pointer-events: initial;
  transform: translateY(0);
}

.page-fragment {
  min-height: 100vh;
}

.page-fragment .aaaem-pf__loader {
  display: none;
}

.cq-Editable-dom--container .page-fragment {
  min-height: 0;
}

.has-hoops .hoops {
  display: block;
}

.hoops {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 10s;
  animation-timing-function: ease-out;
  animation-delay: .2s;
  animation-fill-mode: forwards;
  display: none;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.hoops__container {
  border: var(--emu-common-border-width-medium) solid var(--emu-common-colors-white);
  stroke-width: 2px;
  stroke: var(--emu-common-colors-white);
  opacity: .3;
  border-radius: 50%;
  position: fixed;
  overflow: hidden;
}

.hoops__container:after {
  content: "";
  border: var(--emu-common-border-width-medium) solid var(--emu-common-colors-white);
  border-radius: 50%;
  position: fixed;
  overflow: hidden;
}

.hoops__container--big-hoops {
  filter: blur(6px);
  width: 375px;
  height: 375px;
  animation: 20s linear infinite moveBigHoopsMobile;
}

@media (min-width: 1024px) {
  .hoops__container--big-hoops {
    width: 560px;
    height: 560px;
    animation: 20s linear infinite moveBigHoopsDesktop;
    transform: none;
  }
}

.hoops__container--big-hoops:after {
  filter: blur(6px);
  width: 340px;
  height: 340px;
  animation: 20s linear infinite moveBigHoopsMobile;
  top: 4.5%;
  left: 4.5%;
}

@media (min-width: 1024px) {
  .hoops__container--big-hoops:after {
    width: 517px;
    height: 517px;
    animation: 20s linear infinite moveBigHoopsDesktop;
    top: 3.5%;
    left: 3.5%;
  }
}

.hoops__container--small-hoops {
  filter: blur(3px);
  width: 188px;
  height: 188px;
  animation: 20s linear infinite moveSmallHoopsMobile;
  top: 0%;
  left: 9%;
}

@media (min-width: 1024px) {
  .hoops__container--small-hoops {
    animation: 20s linear infinite moveSmallHoopsDesktop;
  }
}

.hoops__container--small-hoops:after {
  filter: blur(3px);
  width: 167px;
  height: 167px;
  animation: 20s linear infinite moveSmallHoopsMobile;
  top: 4.5%;
  left: 4.5%;
}

@media (min-width: 1024px) {
  .hoops__container--small-hoops:after {
    animation: 20s linear infinite moveSmallHoopsDesktop;
  }
}

.teaser-as-fs-video .teaser-video-controls, .teaser-as-fs-video .content-container {
  display: none;
}

.teaser-as-fs-video .cmp-teaser__video {
  position: fixed;
  inset: 0;
}

.embed .fullscreen-iframe-embed .aaaem-embed__oembed {
  padding: var(--emu-common-spacing-none);
  position: fixed;
  inset: 0;
  overflow: hidden;
  transform: none;
}

.cq-Editable-dom--container .embed .fullscreen-iframe-embed .aaaem-embed__oembed {
  padding: 0 !important;
  position: static !important;
}

.embed .fullscreen-iframe-embed iframe {
  width: 140%;
  height: 140%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cq-Editable-dom--container .embed .fullscreen-iframe-embed iframe {
  min-height: 600px;
  width: 100% !important;
  height: 100% !important;
  position: static !important;
  transform: none !important;
}

@media screen and (min-width: 1024px) and (max-height: 750px) {
  .has-treatment-cta--hidden .floating-cta {
    bottom: 133px;
  }

  .has-main-menu--hidden .header__logo, .has-main-menu--hidden .header__eyebrow, .has-main-menu--hidden .header__content > .image img {
    display: none;
  }

  .has-main-menu--hidden .header .cmp-container-header {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #error .header .cmp-container-header {
    margin-top: 42px;
  }
}

@media (min-width: 1024px) {
  #error .error-container__title .emu-title__text {
    font-size: 160px;
    line-height: 192px;
  }
}

#home-banner .patient-disclaimer p, #home-banner-inner .patient-disclaimer p {
  text-align: right;
}

@media (min-width: 1024px) {
  #home-banner .patient-disclaimer p, #home-banner-inner .patient-disclaimer p {
    text-align: left;
  }
}

#home-banner .home-banner__video-container--mobile iframe, #home-banner-inner .home-banner__video-container--mobile iframe {
  width: 100%;
  transform: translateY(-60%)translateX(-50%);
}

.cq-Editable-dom--container .home-banner__video-container {
  display: block !important;
}

.about-intro--screen-1 .content-box__title, .about-intro--screen-1 .content-box__text, .about-intro--screen-1 .content-box__text-small {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .about-intro--screen-1 .content-box__title, .about-intro--screen-1 .content-box__text, .about-intro--screen-1 .content-box__text-small {
    max-width: none;
  }
}

.about-intro--screen-1 .content-box__text-small {
  max-width: 78%;
}

@media (min-width: 1024px) {
  .about-intro--screen-1 .content-box__text-small {
    max-width: none;
  }
}

.about-intro--screen-2 .content-box__text {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .about-intro--screen-3 .content-box {
    padding-right: var(--emu-common-spacing-none);
  }

  .about-intro--screen-3 .content-box__title {
    margin-bottom: 38px;
  }

  .about-intro--screen-3 .content-box__title .emphasis {
    display: inline;
  }
}

@media (min-width: 1280px) {
  .about-intro--screen-3 .content-box__title .emphasis {
    display: inline-block;
  }
}

.about-intro--screen-3 .content-box__title sup {
  letter-spacing: normal;
  letter-spacing: initial;
  font-size: 50%;
  font-weight: var(--emu-common-font-weight-light);
  margin-left: 2px;
}

@media (min-width: 1024px) {
  .about-intro--screen-3 .content-box__text {
    max-width: 706px;
  }

  .about-intro--screen-3 .content-box__text.has-highlighted-text u {
    display: inline-block;
  }

  .about-intro--screen-3 .content-box__text--spl {
    max-width: 720px;
  }
}

.about-intro--screen-3 .content-box__text-small {
  max-width: 76%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .about-intro--screen-3 .content-box__text-small {
    max-width: 100%;
  }
}

.about-intro--screen-2 .content-box__text {
  transition-delay: 0s;
}

.about-intro--screen-2 .content-box__text p {
  opacity: 0;
  transition: all .8s ease-in-out;
  transform: translateY(160px);
}

.about-intro--screen-2 .content-box__text p:first-child {
  transition-delay: .12s;
}

.about-intro--screen-2 .content-box__text p:nth-child(2) {
  transition-delay: .24s;
}

.about-intro--screen-2 .content-box__text p:nth-child(3) {
  transition-delay: .36s;
}

.about-intro--screen-2 .content-box__text p:nth-child(4) {
  transition-delay: .48s;
}

.about-intro--screen-2 .content-box__text p:nth-child(5) {
  transition-delay: .6s;
}

.about-intro--screen-2 .content-box__text p:nth-child(6) {
  transition-delay: .72s;
}

.about-intro--screen-2 .content-box__text p:nth-child(7) {
  transition-delay: .84s;
}

.about-intro--screen-2 .content-box__text p:nth-child(8) {
  transition-delay: .96s;
}

.about-intro--screen-2 .content-box__text p:nth-child(9) {
  transition-delay: 1.08s;
}

.about-intro--screen-2 .content-box__text p:nth-child(10) {
  transition-delay: 1.2s;
}

.lo3-section-container--active .about-intro--screen-2 .content-box__text p {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 1024px) {
  .first-look-specifications .content-box {
    grid-gap: 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    width: auto;
  }
}

@media (min-width: 1280px) {
  .first-look-specifications .content-box {
    grid-gap: 50px;
    gap: 50px;
  }
}

@media (min-width: 1024px) {
  .first-look-specifications .content-box__lottie-container {
    max-width: 250px;
  }
}

@media (min-width: 1280px) {
  .first-look-specifications .content-box__lottie-container {
    max-width: none;
  }
}

@media (min-width: 1024px) {
  .first-look-specifications .content-box > .container:last-child {
    flex: 1;
  }
}

.first-look-specifications .content-box__lottie {
  justify-content: center;
  height: 123.5px;
  margin: -6px auto 33px;
  display: flex;
}

@media (min-width: 1024px) {
  .first-look-specifications .content-box__lottie {
    margin-bottom: var(--emu-common-spacing-none);
    margin-left: -8px;
    margin-right: -6px;
    margin-top: var(--emu-common-spacing-none);
    height: auto;
  }
}

.first-look-specifications .content-box__lottie lottie-player {
  height: 100%;
}

.first-look-specifications .content-box__title-box {
  text-align: center;
}

@media (min-width: 1024px) {
  .first-look-specifications .content-box__title-box {
    text-align: left;
  }
}

.first-look-specifications .content-box__text {
  max-width: 642px;
}

.first-look-specifications .content-box__warning {
  grid-gap: 14px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  max-width: 630px;
  margin-top: 23px;
  display: flex;
}

@media (min-width: 1024px) {
  .first-look-specifications .content-box__warning {
    flex-direction: row;
    align-items: flex-start;
  }

  .first-look-specifications .content-box__warning > .text {
    flex: 1;
  }

  .first-look-specifications .content-box__warning img {
    width: var(--emu-common-sizing-medium);
    margin-top: 10px;
  }
}

.first-look-specifications--screen-1 .content-box__lottie {
  height: 178.5px;
  margin-top: -4px;
}

@media (min-width: 1024px) {
  .first-look-specifications--screen-1 .content-box__lottie {
    margin-left: -5px;
    margin-top: var(--emu-common-spacing-none);
    height: auto;
  }
}

@media (min-width: 1280px) {
  .first-look-specifications--screen-2 .content-box {
    grid-gap: 54px;
    gap: 54px;
  }
}

@media (min-width: 1440px) {
  .first-look-specifications--screen-2 .content-box {
    margin-left: 347px;
  }
}

.first-look-specifications--screen-2 .content-box__lottie {
  height: 125.5px;
  margin-bottom: 31px;
}

@media (min-width: 1024px) {
  .first-look-specifications--screen-2 .content-box__lottie {
    margin-bottom: var(--emu-common-spacing-none);
    height: auto;
    margin-right: -9px;
  }

  .first-look-specifications--screen-2 .content-box__text .emphasis {
    display: inline;
  }
}

@media (min-width: 1440px) {
  .first-look-specifications--screen-2 .content-box__text .emphasis {
    display: inline-block;
  }
}

.first-look-specifications--screen-2 .content-box__button.aaaem-button__secondary-outline.aaaem-button {
  padding-left: 10px;
  padding-right: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .first-look-specifications--screen-3 .content-box {
    grid-gap: 26px;
    gap: 26px;
  }
}

@media (min-width: 1280px) {
  .first-look-specifications--screen-3 .content-box {
    margin-left: 302px;
  }
}

@media (min-width: 1024px) {
  .first-look-specifications--screen-3 .content-box__lottie {
    margin-top: -4px;
  }
}

.first-look-specifications--screen-3 .content-box__text {
  max-width: 657px;
}

.first-look-specifications .content-box__lottie lottie-player {
  opacity: 0;
  transform-origin: center;
  transition: all .5s ease-in-out .4s;
  transform: translateX(-40px);
}

.first-look-specifications .content-box__warning {
  opacity: 0;
  transition: all .8s ease-in-out;
  transform: translateY(60px);
}

.first-look-specifications--screen-1 .content-box__lottie lottie-player {
  transform: translateX(0);
}

.lo3-section-container--active .first-look-specifications .content-box__lottie lottie-player, .lo3-section-container--active .first-look-specifications .content-box__warning {
  opacity: 1;
  transform: translate(0);
}

#error .header__sidenav-container {
  display: none;
}

#error .content-box {
  max-width: var(--emu-component-containers-container-max-width);
  margin: var(--emu-common-spacing-none);
  width: 100%;
}

#error .header .cmp-container-header {
  background-color: var(--emu-common-colors-white);
  padding-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  #error .header .cmp-container-header {
    margin-top: 42px;
  }
}

#error .error-container .aem-Grid {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#error .error-container__title .emu-title__text {
  font-size: var(--emu-semantic-font-sizes-wide-xxxl);
  line-height: var(--emu-semantic-line-heights-wide-xxxl);
  margin-bottom: var(--emu-common-spacing-large);
  margin-top: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #error .error-container__title .emu-title__text {
    font-size: 160px;
    line-height: 192px;
  }
}

#error .error-container__text p {
  margin-top: var(--emu-common-spacing-medium);
  margin-bottom: var(--emu-common-spacing-medium);
}

#error .error-container .button {
  justify-content: center;
  align-items: center;
  display: flex;
}

#error .error-container .button .cmp-button {
  padding-left: var(--emu-common-spacing-large);
  padding-right: var(--emu-common-spacing-large);
  margin-top: var(--emu-common-spacing-medium);
  width: auto;
}

#error .error-container__footer-text {
  margin-top: var(--emu-common-spacing-medium);
  margin-bottom: var(--emu-common-spacing-medium);
}

#error .error-container__footer-text a {
  color: var(--emu-semantic-colors-primary-purple-100);
  font-weight: var(--emu-common-font-weight-regular);
}

.loader__wrapper {
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  transition: all .4s linear;
  position: fixed;
  top: 0;
  left: 0;
}

.loader__wrapper .content-box {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  transform: translateY(-52px);
}

.loader__wrapper .content-box__title {
  margin-bottom: 12px;
}

.loader__wrapper .content-box__title, .loader__wrapper .content-box__text {
  text-align: center;
  opacity: 1;
  transform: translateY(-28px);
}

@media (min-width: 545px) {
  .loader__wrapper .content-box__title, .loader__wrapper .content-box__text {
    transform: translateY(-48px);
  }
}

@media (min-width: 1024px) {
  .loader__wrapper .content-box__title, .loader__wrapper .content-box__text {
    transform: translateY(-68px);
  }
}

.loader__main .lottie {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .loader__main .lottie {
    width: 720px;
  }
}

.emu-pf__loader {
  display: none;
}

.cq-Editable-dom--container .loader__wrapper {
  max-height: 800px;
  position: static;
}

.about-details .content-box {
  padding-top: 20px;
  padding-bottom: var(--emu-common-spacing-none);
  justify-content: space-between;
}

@media (min-width: 1024px) {
  .about-details .content-box {
    padding-top: var(--emu-common-spacing-none);
    flex-direction: row;
  }
}

.about-details .content-box__inner {
  flex-direction: column;
  height: 100%;
  display: flex;
}

@media (min-width: 1024px) {
  .about-details .content-box__inner {
    padding-top: var(--emu-common-spacing-none);
    justify-content: center;
    max-width: 651px;
    height: 100%;
    position: relative;
    left: -74px;
  }
}

.cq-Editable-dom--container .about-details .content-box__inner {
  left: 0;
}

.about-details .content-box__image {
  margin-left: -100px;
  margin-right: -20px;
}

@media (min-width: 1024px) {
  .about-details .content-box__image {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

.about-details .content-box__image img {
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 451px;
}

@media (min-width: 1024px) {
  .about-details .content-box__image img {
    min-height: var(--emu-common-sizing-none);
    width: 54vw;
    max-width: 778px;
  }
}

.about-details .content-box > .container {
  flex: 1;
  order: 1;
  max-height: 500px;
  margin-top: auto;
}

@media (min-width: 1024px) {
  .about-details .content-box > .container {
    margin-top: unset;
    order: 2;
    align-self: center;
    max-height: none;
  }
}

.about-details .content-box > .text {
  flex: none;
  order: 2;
}

.about-details .content-box > .image {
  flex: none;
  order: 3;
}

@media (min-width: 1024px) {
  .about-details .content-box > .image {
    order: 1;
    align-self: flex-end;
  }

  .about-details .content-box--desktop-top-align-text > .container {
    align-self: flex-start;
    margin-top: max(10vh, 80px);
  }
}

@media (min-height: 900px) and (min-width: 1300px) {
  .about-details .content-box--desktop-top-align-text > .container {
    margin-top: 191px;
  }
}

.about-details .content-box__title {
  margin-bottom: 21px;
}

@media (min-width: 1024px) {
  .about-details .content-box__title {
    margin-bottom: 35px;
  }
}

.about-details .content-box__title h2 {
  text-align: center;
}

@media (min-width: 1024px) {
  .about-details .content-box__title h2 {
    text-align: left;
  }
}

.about-details .content-box__text {
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 1024px) {
  .about-details .content-box__text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.about-details .content-box__text sup {
  top: -3px;
}

.about-details .content-box__text--bold p {
  color: var(--emu-semantic-colors-primary-violet-200);
}

.about-details .content-box__text-small {
  padding-left: var(--emu-common-spacing-xxs);
  padding-right: var(--emu-common-spacing-xxs);
}

@media (min-width: 1024px) {
  .about-details .content-box__text-small {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.about-details .content-box__text-small[data-component="text"] p {
  text-align: center;
  color: var(--emu-semantic-colors-secondary-grey-500);
  max-width: 331px;
  font-size: 14px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .about-details .content-box__text-small[data-component="text"] p {
    text-align: left;
    letter-spacing: -.2px;
    max-width: 630px;
    font-size: 16px;
  }

  .about-details .content-box__text-small[data-component="text"] p br {
    display: none;
  }
}

.about-details .content-box__text-small[data-component="text"] p sup {
  top: 1px;
}

.about-details .content-box .patient-disclaimer {
  position: static;
}

@media (min-width: 1024px) {
  .about-details .content-box .patient-disclaimer {
    position: fixed;
  }
}

.about-details .content-box .patient-disclaimer p {
  text-align: center;
}

@media (min-width: 1024px) {
  .about-details .content-box .patient-disclaimer p {
    text-align: left;
  }

  .about-details--screen-1 .content-box__inner {
    margin-bottom: 14px;
  }
}

.about-details--screen-1 .content-box__text {
  margin-bottom: 21px;
}

@media (min-width: 1024px) {
  .about-details--screen-1 .content-box__text {
    max-width: 485px;
  }

  .about-details--screen-2 .content-box {
    justify-content: flex-start;
  }
}

.about-details--screen-2 .content-box__inner {
  justify-content: center;
  margin-bottom: 25px;
}

.about-details--screen-2 .content-box__text {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  .about-details--screen-2 .content-box__text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  .about-details--screen-2 .content-box__inner {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.about-details__mobile-app-screen-1 {
  max-width: 605px;
}

.about-details__mobile-app-screen-2 {
  max-width: 630px;
}

.about-details__mobile-app-screen-2 .content-box__text-small {
  text-indent: -4px;
}

.about-details__mobile-app-screen-3 {
  margin-bottom: -22px;
}

.about-details__mobile-app-screen-3 .content-box__text:not(.about-details__mobile-app-screen-disclaimer) {
  max-width: 605px;
}

.about-details__mobile-app-screen-disclaimer.content-box__text.text.cmp-text.aaaem-text {
  transition-delay: .8s;
}

@media (min-width: 1024px) {
  .about-details__mobile-app-screen-disclaimer {
    text-indent: -4px;
    margin-top: 51px;
  }
}

.about-details--screen-3 .content-box {
  padding-top: 52px;
}

@media (min-width: 1024px) {
  .about-details--screen-3 .content-box {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: 113px;
  }

  .about-details--screen-3 .content-box__inner {
    width: auto;
    left: 0;
  }
}

@media (min-width: 1440px) {
  .about-details--screen-3 .content-box__inner {
    width: 651px;
  }
}

.about-details--screen-3 .content-box__text, .about-details--screen-3 .content-box__text-small[data-component="text"] {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

.about-details--screen-3 .content-box__text-small[data-component="text"] p {
  letter-spacing: normal;
}

@media (min-width: 1024px) {
  .about-details--screen-3 .content-box__text-small[data-component="text"] p {
    font-size: 14px;
  }
}

.about-details--screen-3 .content-box__image {
  margin-left: 68px;
  margin-right: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .about-details--screen-3 .content-box__image {
    margin-left: var(--emu-common-spacing-none);
  }
}

.about-details--screen-3 .content-box__image img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 50vh;
  min-height: 0;
  max-height: 70vh;
  margin: auto;
}

.cq-Editable-dom--container .about-details--screen-3 .content-box__image img {
  height: auto;
  max-height: none;
}

@media (min-width: 1024px) {
  .about-details--screen-3 .content-box__image img {
    width: auto;
    max-width: 27vw;
    height: auto;
  }
}

.about-details--screen-3 .content-box__button {
  margin-top: var(--emu-common-spacing-none);
}

.about-details--screen-1 .content-box__image .emu-mapping-marker, .about-details--screen-2 .content-box__image .emu-mapping-marker {
  transform-origin: center;
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-white);
  cursor: default;
  transform: scale(.72);
}

@media (min-width: 1280px) {
  .about-details--screen-1 .content-box__image .emu-mapping-marker, .about-details--screen-2 .content-box__image .emu-mapping-marker {
    transform: scale(1);
  }
}

.about-details--screen-1 .content-box__image .emu-mapping-marker span, .about-details--screen-2 .content-box__image .emu-mapping-marker span {
  display: none;
}

.about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-1, .about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-3, .about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-1, .about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-3 {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: var(--emu-common-border-radius-none);
  border-bottom-left-radius: var(--emu-common-border-radius-none);
  border-bottom: var(--emu-common-border-width-none);
  width: 36px;
  height: 18px;
}

@media (min-width: 1280px) {
  .about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-1, .about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-3, .about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-1, .about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-3 {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    width: 50px;
    height: 25px;
  }
}

.about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-1, .about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-1 {
  transform: rotate(-90deg)scale(.9);
}

@media (min-width: 1280px) {
  .about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-1, .about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-1 {
    transform: rotate(-90deg);
  }
}

.about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-3, .about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-3 {
  transform: rotate(90deg)scale(.9);
}

@media (min-width: 1280px) {
  .about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-3, .about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-3 {
    transform: rotate(90deg);
  }
}

.about-details--screen-2 .content-box__image .emu-mapping-marker:before {
  content: attr(aria-label);
  color: var(--emu-common-colors-white);
  text-align: center;
  font-family: var(--emu-semantic-font-families-body);
  font-size: 20px;
  font-weight: var(--emu-common-font-weight-light);
  justify-content: center;
  align-items: center;
  line-height: normal;
  display: flex;
}

.about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-1:before {
  transform: rotate(90deg)scale(.72)translateX(-4px);
}

@media (min-width: 1280px) {
  .about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-1:before {
    transform: rotate(90deg);
  }
}

.about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-3:before {
  transform: rotate(-90deg)scale(.72)translateX(4px);
}

@media (min-width: 1280px) {
  .about-details--screen-2 .content-box__image .emu-mapping-marker.emu-mapping-marker-3:before {
    transform: rotate(-90deg);
  }
}

.about-details--screen-1 .content-box__image {
  transition: transform .8s linear;
  transform: translateX(-200px);
}

.about-details--screen-1 .content-box__image .emu-mapping-marker {
  opacity: 0;
  transition-duration: .4s;
  transition-delay: .4s;
}

.about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-0 {
  transition-delay: .8s;
}

.about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-1 {
  transition-delay: 1s;
}

.about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-2 {
  transition-delay: .6s;
}

.about-details--screen-1 .content-box__image .emu-mapping-marker.emu-mapping-marker-3 {
  transition-delay: 1.2s;
}

.about-details--screen-1 .content-box__inner, .about-details--screen-2 .content-box__inner {
  transition: all var(--default-page-transition-duration) linear;
}

.about-details--screen-3 .content-box__image {
  opacity: 0;
  transition: all .4s linear;
  transform: translateY(100px);
}

@media (min-width: 1024px) {
  .about-details--screen-3 .content-box__image {
    transform: translateX(-100px);
  }
}

.lo3-section-container--active .about-details--screen-1 .content-box__image {
  transform: translateX(0);
}

.lo3-section-container--active .about-details--screen-1 .content-box__image .emu-mapping-marker {
  opacity: 1;
}

.lo3-section-container--active .about-details--screen-3 .content-box__image {
  opacity: 1;
  transform: translate(0);
}

.lo3-section-container--prev-active .about-details--screen-1 .content-box__image {
  transform: translate(0);
}

.lo3-section-container--prev-active .about-details--screen-1 .content-box__inner, .lo3-section-container--prev-active .about-details--screen-2 .content-box__inner {
  opacity: 0;
  transform: translate(0, -200px);
}

.lo3-section-container--prev-active .about-details--screen-3 .content-box__image {
  opacity: 0;
  transform: translate(0, 400px);
}

.lo3-section-container--prev-active-down .about-details--screen-1 .content-box__image {
  transform: translate(0);
}

.cq-Editable-dom--container .about-details .content-box--desktop-top-align-text > .container {
  margin-top: 80px !important;
}

.first-look-intro .content-box {
  padding: var(--emu-common-spacing-none);
  max-width: none;
  display: block;
}

.cq-Editable-dom--container .first-look-intro .content-box__background {
  display: block !important;
}

.first-look-intro__content {
  background-color: #e0e0e6;
  padding-top: 14px;
  padding-bottom: 14px;
  position: relative;
  transform: translateY(102px);
}

@media (min-width: 1024px) {
  .first-look-intro__content {
    padding-top: 69px;
    padding-bottom: 62px;
    transform: translateY(65px);
  }
}

@media (min-height: 700px) and (min-width: 1024px) {
  .first-look-intro__content {
    transform: translateY(198px);
  }
}

.first-look-intro__content-inner {
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  .first-look-intro__content-inner {
    justify-content: flex-end;
    margin-right: 72px;
  }
}

.first-look-intro__content h3 {
  letter-spacing: -.245px;
  text-align: left;
  font-size: 12.734px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .first-look-intro__content h3 {
    letter-spacing: -.5px;
    font-size: 26px;
  }
}

.first-look-intro__content h3:nth-child(3) {
  text-align: right;
}

.first-look-intro__content h2 {
  letter-spacing: -.49px;
  font-size: 48.977px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .first-look-intro__content h2 {
    letter-spacing: -1px;
    font-size: 80px;
  }
}

@media (min-width: 1440px) {
  .first-look-intro__content h2 {
    font-size: 100px;
  }
}

.first-look-intro__img {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (orientation: portrait) and (min-width: 768px) and (max-width: 1440px) {
  .first-look-intro__img {
    width: 100%;
  }
}

.cq-Editable-dom--container .first-look-intro__img {
  position: static;
}

.first-look-intro__img img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 584px;
}

@media (min-width: 1024px) {
  .first-look-intro__img img {
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: bottom;
    object-position: bottom;
    max-width: 44vw;
    margin-left: 140px;
  }
}

@media (min-width: 1280px) {
  .first-look-intro__img img {
    max-width: none;
    height: calc(100vh - 5px);
    max-height: 900px;
    margin-left: 173px;
  }
}

@media (orientation: portrait) and (min-width: 768px) and (max-width: 1440px) {
  .first-look-intro__img img {
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    margin: var(--emu-common-spacing-none);
    width: 100%;
    max-width: none;
    height: auto;
    max-height: calc(100vh - 330px);
  }
}

.cq-Editable-dom--container .first-look-intro__img img {
  margin: var(--emu-common-spacing-none);
  height: auto;
}

@media (min-width: 1024px) {
  .first-look-intro .patient-disclaimer p {
    text-align: right;
  }
}

.first-look-intro__content h3 {
  transform: translateX(100%);
}

.first-look-intro__content h3:nth-child(3) {
  transform: translateX(-100%);
}

.first-look-intro__content h2 {
  transform: translateY(30px);
}

.first-look-intro__content h2, .first-look-intro__content h3 {
  opacity: 0;
  transition: all .4s ease-in-out .4s;
}

.cq-Editable-dom--container .first-look-intro__content h2, .cq-Editable-dom--container .first-look-intro__content h3 {
  opacity: 1;
  transform: none;
}

.first-look-intro__img {
  transition: all .4s linear;
  transform: translate(-40px);
}

.lo3-section-container--active .first-look-intro__content h2, .lo3-section-container--active .first-look-intro__content h3, .lo3-section-container--active .first-look-intro__img {
  opacity: 1;
  transform: translate(0);
}

.lo3-section-container--prev-active .first-look-intro__content {
  transition: all .8s linear;
  transform: translateY(-40px);
}

.lo3-section-container--prev-active .first-look-intro__content h2, .lo3-section-container--prev-active .first-look-intro__content h3 {
  transform: translate(0);
}

.lo3-section-container--prev-active .first-look-intro__img {
  transition: all .6s linear .4s;
  transform: translate(0, -40px);
}

.first-look-details .content-box__title {
  text-align: center;
  z-index: var(--emu-common-other-z-index-overlay);
  max-width: 330px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 81px;
}

@media (min-width: 1024px) {
  .first-look-details .content-box__title {
    text-align: left;
    margin: var(--emu-common-spacing-none);
    justify-content: end;
    max-width: 490px;
    display: flex;
    top: 216px;
    left: 232px;
  }
}

@media (min-width: 1280px) {
  .first-look-details .content-box__title {
    top: 265px;
    left: 297px;
  }
}

.first-look-details .content-box__title h4 {
  color: var(--emu-semantic-colors-primary-violet-200);
  font-weight: var(--emu-semantic-font-weight-light);
  line-height: normal;
}

@media (min-width: 1024px) {
  .first-look-details .content-box__title h4 {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-medium);
  }
}

@media (min-width: 1280px) {
  .first-look-details .content-box__title h4 {
    font-size: 36px;
    line-height: normal;
  }
}

.first-look-details .content-box__title h4 .emphasis {
  background: var(--emu-common-colors-white);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: var(--emu-common-spacing-xxs);
  padding-bottom: var(--emu-common-spacing-xxs);
  margin-bottom: var(--emu-common-spacing-xs);
  opacity: 0;
  border-radius: 5.4px;
  transform: translateY(40px);
}

.first-look-details .content-box__title h4 .emphasis:last-child {
  margin-bottom: var(--emu-common-spacing-none);
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1024px) {
  .first-look-details .content-box__title h4 .emphasis {
    border-radius: var(--emu-common-border-radius-small);
    padding: 3px 9px 3px 12px;
  }

  .first-look-details .content-box__title h4 .emphasis:nth-of-type(2) {
    padding-left: 11px;
    padding-right: 11px;
  }

  .first-look-details .content-box__title h4 .emphasis:last-child {
    padding-left: 12px;
    padding-right: 9px;
  }
}

.first-look-details .content-box__ellipse {
  padding: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  background: none;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (min-width: 1024px) {
  .first-look-details .content-box__ellipse {
    margin-bottom: 80px;
  }
}

.first-look-details .content-box__ellipse--image img {
  opacity: 0;
  -o-object-fit: contain;
  object-fit: contain;
  width: 305px;
  max-height: 50vh;
  transform: translateY(40px);
}

@media (min-width: 768px) {
  .first-look-details .content-box__ellipse--image img {
    width: min(400px, 32vh);
  }
}

@media (min-width: 1024px) {
  .first-look-details .content-box__ellipse--image img {
    width: 455px;
    max-height: 80vh;
  }
}

@media (min-width: 1280px) {
  .first-look-details .content-box__ellipse--image img {
    width: 569px;
  }
}

.first-look-details .content-box__tooltip {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  background: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .first-look-details .content-box__tooltip {
    align-items: flex-start;
    min-width: 393px;
    bottom: 66px;
  }
}

@media (min-width: 1280px) {
  .first-look-details .content-box__tooltip {
    min-width: 500px;
    bottom: 100px;
  }
}

.first-look-details .content-box__tooltip-dot {
  margin-top: 26px;
}

@media (min-width: 1024px) {
  .first-look-details .content-box__tooltip-dot {
    margin-top: var(--emu-common-spacing-none);
  }
}

.first-look-details .content-box__tooltip-dot img {
  width: 20px;
  height: 20px;
  transform: scale(0);
}

@media (min-width: 1024px) {
  .first-look-details .content-box__tooltip-dot img {
    width: 32.2px;
    height: 32.2px;
  }
}

.first-look-details .content-box__tooltip-content {
  background: var(--emu-semantic-colors-primary-purple-100);
  border-radius: var(--emu-common-border-radius-small);
  text-align: center;
  left: var(--emu-common-spacing-none);
  font-size: 18px;
  line-height: 32px;
  font-weight: var(--emu-semantic-font-weight-regular);
  opacity: 1;
  justify-content: center;
  align-items: center;
  width: 221px;
  height: 40px;
  display: flex;
  position: relative;
  top: -80px;
  transform: scale(0);
}

@media (min-width: 1024px) {
  .first-look-details .content-box__tooltip-content {
    border-radius: 9.2px;
    justify-content: center;
    width: 100%;
    min-width: 221px;
    height: 50px;
    font-size: 24px;
    line-height: 42px;
    top: -109px;
    left: -94px;
  }
}

@media (min-width: 1280px) {
  .first-look-details .content-box__tooltip-content {
    min-width: 281px;
    height: 57px;
    font-size: 27.6px;
    line-height: 36.8px;
    top: -116px;
    left: -123px;
  }
}

.first-look-details .content-box__tooltip-content p {
  color: var(--emu-common-colors-white);
  font: inherit;
}

.first-look-details .content-box__tooltip-content:after {
  content: "";
  background-color: inherit;
  border: inherit;
  -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border-radius: 0 0 0 var(--emu-common-border-radius-xs);
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  bottom: -9px;
  left: calc(50% - 10px);
  transform: rotate(-45deg);
}

@media (min-width: 1024px) {
  .first-look-details .content-box__tooltip-content:after {
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
  }
}

.first-look-details .content-box .patient-disclaimer p {
  font-size: 14px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .first-look-details .content-box .patient-disclaimer p {
    font-size: var(--emu-common-font-sizes-wide-medium);
    text-align: left;
  }
}

.first-look-details--screen-1 .content-box, .first-look-details--screen-2 .content-box, .first-look-details--screen-3 .content-box {
  justify-content: flex-start;
}

@media (min-width: 768px) {
  .first-look-details--screen-1 .content-box, .first-look-details--screen-2 .content-box, .first-look-details--screen-3 .content-box {
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  .first-look-details--screen-1 .content-box, .first-look-details--screen-2 .content-box, .first-look-details--screen-3 .content-box {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    align-items: center;
    width: auto;
  }

  .first-look-details--screen-3 .content-box__tooltip {
    align-items: flex-start;
    min-width: 532px;
    bottom: 74px;
  }
}

@media (min-width: 1280px) {
  .first-look-details--screen-3 .content-box__tooltip {
    min-width: 638px;
    bottom: 104px;
  }
}

.first-look-details--screen-3 .content-box__tooltip-content {
  width: 221px;
}

@media (min-width: 1024px) {
  .first-look-details--screen-3 .content-box__tooltip-content {
    width: 100%;
    min-width: 274px;
    left: -119px;
  }
}

@media (min-width: 1280px) {
  .first-look-details--screen-3 .content-box__tooltip-content {
    min-width: 325px;
    left: -145px;
  }
}

.first-look-details--screen-4 .content-box {
  width: auto;
}

@media (min-width: 1024px) {
  .first-look-details--screen-4 .content-box {
    grid-gap: 25px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
  }
}

@media (min-width: 1280px) {
  .first-look-details--screen-4 .content-box {
    grid-gap: 108px;
    gap: 108px;
  }
}

.first-look-details--screen-4 .content-box__title {
  width: max-content;
  margin-bottom: 30px;
  position: static;
}

@media (min-width: 1024px) {
  .first-look-details--screen-4 .content-box__title {
    margin-bottom: var(--emu-common-spacing-none);
  }

  .first-look-details--screen-4 .content-box__title h4 {
    font-size: calc(1.2vw + var(--emu-common-font-sizes-wide-medium));
    line-height: normal;
  }
}

@media (min-width: 1280px) {
  .first-look-details--screen-4 .content-box__title h4 {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

.first-look-details--screen-4 .content-box__title h4 .emphasis {
  margin-bottom: var(--emu-common-spacing-medium);
  opacity: 0;
  padding: 2.5px 10px;
  transform: translateY(40px);
}

.first-look-details--screen-4 .content-box__title h4 .emphasis:nth-of-type(2) {
  padding-left: 10.5px;
  padding-right: 10.5px;
}

.first-look-details--screen-4 .content-box__title h4 .emphasis:last-child {
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  .first-look-details--screen-4 .content-box__title h4 .emphasis {
    margin-bottom: 28px;
    padding: 3.5px 12.3px;
  }

  .first-look-details--screen-4 .content-box__title h4 .emphasis:nth-of-type(2) {
    padding-left: 18px;
    padding-right: 18px;
  }

  .first-look-details--screen-4 .content-box__title h4 .emphasis:last-child {
    padding: 3px 12px;
  }
}

.first-look-details--screen-4 .content-box__text {
  text-align: center;
  grid-gap: 24px;
  flex-direction: column;
  gap: 24px;
  margin-left: 17px;
  margin-right: 17px;
  display: flex;
}

@media (min-width: 1024px) {
  .first-look-details--screen-4 .content-box__text {
    text-align: left;
    grid-gap: 30px;
    margin-right: var(--emu-common-spacing-none);
    margin-left: var(--emu-common-spacing-none);
    gap: 30px;
  }
}

@media (min-width: 1280px) {
  .first-look-details--screen-4 .content-box__text {
    grid-gap: 45px;
    gap: 45px;
    margin-right: 43px;
  }
}

.first-look-details--screen-4 .content-box__text p {
  color: var(--emu-semantic-colors-primary-violet-200);
  font: inherit;
  opacity: 0;
  width: max-content;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(40px);
}

@media (min-width: 1024px) {
  .first-look-details--screen-4 .content-box__text p {
    margin: var(--emu-common-spacing-none);
    max-width: 478px;
  }
}

.first-look-details--screen-4 .content-box__text p sup {
  top: -3px;
}

.first-look-details .content-box__ellipse--image img, .first-look-details .content-box__title .emphasis, .first-look-details .content-box__text p {
  opacity: 0;
  transition-property: all;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.first-look-details .content-box__tooltip-dot img, .first-look-details .content-box__tooltip-content {
  transition-duration: .4s;
  transform: scale(0);
}

.first-look-details .content-box__tooltip-dot img {
  transition-delay: .5s;
}

.first-look-details .content-box__tooltip-content {
  transition-delay: .6s;
}

.first-look-details .content-box__title .emphasis:first-of-type {
  transition-delay: 1s;
}

.first-look-details .content-box__title .emphasis:nth-of-type(2) {
  transition-delay: 1.2s;
}

.first-look-details .content-box__title .emphasis:last-child {
  transition-delay: 1.4s;
}

.first-look-details--screen-4 .content-box__title .emphasis:first-of-type {
  transition-delay: 0s;
}

.first-look-details--screen-4 .content-box__title .emphasis:nth-of-type(2) {
  transition-delay: .2s;
}

.first-look-details--screen-4 .content-box__title .emphasis:nth-of-type(3) {
  transition-delay: .4s;
}

.first-look-details .content-box__text p:first-of-type {
  transition-delay: .6s;
}

.first-look-details .content-box__text p:nth-of-type(2) {
  transition-delay: .8s;
}

.lo3-section-container--active .first-look-details .content-box__ellipse--image img, .lo3-section-container--active .first-look-details .content-box__title .emphasis, .lo3-section-container--active .first-look-details--screen-4 .content-box__title .emphasis, .lo3-section-container--active .first-look-details .content-box__text p {
  opacity: 1;
  transform: translateY(0);
}

.lo3-section-container--active .first-look-details .content-box__tooltip-dot img, .lo3-section-container--active .first-look-details .content-box__tooltip-content {
  transform: scale(1);
}

.lo3-section-container--prev-active .first-look-details .content-box__ellipse--image img, .lo3-section-container--prev-active .first-look-details .content-box__tooltip-dot img, .lo3-section-container--prev-active .first-look-details .content-box__tooltip-content, .lo3-section-container--prev-active .first-look-details .content-box__title .emphasis, .lo3-section-container--prev-active .first-look-details--screen-4 .content-box__title .emphasis, .lo3-section-container--prev-active .first-look-details .content-box__text p {
  opacity: 0;
  transition: all .5s ease-out;
  transform: translateY(-100px);
}

.cq-Editable-dom--container .first-look-details .content-box__title, .cq-Editable-dom--container .first-look-details .content-box__tooltip, .cq-Editable-dom--container .first-look-details .content-box__tooltip-content {
  position: static !important;
}

@media (min-width: 1280px) {
  .first-look-description .content-box {
    width: 812px;
  }
}

.first-look-description .content-box__title {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .first-look-description .content-box__title {
    max-width: none;
  }
}

@media (min-width: 1440px) {
  .first-look-description .content-box__inner {
    padding-top: 100px;
  }
}

@media (min-width: 1024px) {
  .first-look-description--screen-1 .content-box__text {
    text-indent: -4px;
  }
}

.cq-Editable-dom--container .real-results--screen-1 .tablet-mobile-view {
  display: block !important;
}

@media (min-width: 1280px) {
  .real-results--screen-2 .content-box {
    width: 810px;
  }
}

.real-results--screen-2 .content-box__title .emphasis sup {
  font-size: 95%;
  top: 0;
}

.real-results--screen-2 .content-box__text-small {
  max-width: 785px;
}

.real-results--screen-2 .content-box__text-small p, .real-results--screen-2 .content-box__text-small li {
  color: var(--emu-semantic-colors-secondary-grey-500);
  text-indent: -8px;
  letter-spacing: normal;
  text-align: left;
}

.real-results--screen-2 .content-box__text-small .emphasis {
  text-indent: 0;
  text-indent: initial;
}

.real-results--screen-2 .content-box__text-small .emphasis sup {
  top: 1px;
}

.real-results--screen-3 .real-results-modal-trigger {
  display: none;
}

.real-results--screen-3 .content-box {
  width: auto;
}

@media (min-width: 1024px) {
  .real-results--screen-3 .content-box {
    padding-right: var(--emu-common-spacing-none);
    margin-right: 4.5%;
  }
}

.real-results--screen-3 .aaaem-carousel {
  opacity: 0;
  margin: var(--emu-common-spacing-none);
  transform: translateY(60px);
}

.real-results--screen-3 .aaaem-carousel .tns-outer:before, .real-results--screen-3 .aaaem-carousel .tns-outer:after {
  content: "";
  width: var(--emu-common-sizing-none);
  z-index: var(--emu-common-other-z-index-layer);
  opacity: 0;
  pointer-events: none;
  background-image: linear-gradient(90deg, #160c1f99, 75%, #fff0 100%);
  height: 240px;
  transition: all .4s linear;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 1024px) {
  .real-results--screen-3 .aaaem-carousel .tns-outer:before, .real-results--screen-3 .aaaem-carousel .tns-outer:after {
    height: 100%;
  }
}

.real-results--screen-3 .aaaem-carousel .tns-outer:after {
  background-image: linear-gradient(270deg, #160c1f99, 75%, #fff0 100%);
  left: auto;
  right: 0;
}

@media (min-width: 1024px) {
  .real-results--screen-3 .aaaem-carousel .tns-outer:after {
    background-image: linear-gradient(270deg, #160c1f99, 75%, #fff0 100%);
  }
}

.real-results--screen-3 .aaaem-carousel .tns-outer.prevActive:before, .real-results--screen-3 .aaaem-carousel .tns-outer.nextActive:after {
  opacity: 1;
  width: 29px;
}

@media (min-width: 1024px) {
  .real-results--screen-3 .aaaem-carousel .tns-outer.prevActive:before, .real-results--screen-3 .aaaem-carousel .tns-outer.nextActive:after {
    width: 100px;
  }

  .real-results--screen-3 .aaaem-carousel .tns-outer .tns-item {
    max-width: calc(45vh - 20px);
  }
}

.real-results--screen-3 .aaaem-carousel__content {
  margin-bottom: var(--emu-common-spacing-xs);
}

.real-results--screen-3 .aaaem-carousel__item-container .testimonial-slide {
  cursor: pointer;
  position: relative;
}

.real-results--screen-3 .aaaem-carousel__item-container .testimonial-slide:before {
  content: "";
  left: var(--emu-common-spacing-none);
  right: var(--emu-common-spacing-none);
  bottom: var(--emu-common-spacing-none);
  z-index: var(--emu-common-other-z-index-layer);
  background: linear-gradient(0deg, #2e184466 0%, #2e184400 100%);
  border-radius: 10px;
  width: 100%;
  height: 40%;
  position: absolute;
}

.real-results--screen-3 .aaaem-carousel__item-container .testimonial-slide:after {
  content: "";
  z-index: var(--emu-common-other-z-index-layer);
  width: var(--emu-common-sizing-small);
  background-image: url("resources/images/hand-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 21px;
  position: absolute;
  bottom: 20px;
  left: 18px;
}

@media (min-width: 1024px) {
  .real-results--screen-3 .aaaem-carousel__item-container .testimonial-slide:after {
    height: var(--emu-common-sizing-medium);
    width: 28px;
    bottom: 30px;
    left: 30px;
  }
}

.real-results--screen-3 .aaaem-carousel__item-container .testimonial-slide__img img {
  z-index: var(--emu-common-other-z-index-base);
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  width: 216px;
  height: 240px;
  position: relative;
}

@media (min-width: 1024px) {
  .real-results--screen-3 .aaaem-carousel__item-container .testimonial-slide__img img {
    width: 360px;
    height: auto;
  }
}

.real-results--screen-3 .aaaem-carousel__item-container .testimonial-slide__text {
  z-index: var(--emu-common-other-z-index-layer);
  width: max-content;
  position: absolute;
  bottom: 5px;
  right: 10px;
}

@media (min-width: 1024px) {
  .real-results--screen-3 .aaaem-carousel__item-container .testimonial-slide__text {
    bottom: var(--emu-common-spacing-small);
    right: 18px;
  }
}

.real-results--screen-3 .aaaem-carousel__item-container .testimonial-slide__text p {
  margin: var(--emu-common-spacing-none);
  text-align: right;
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-common-font-sizes-wide-medium);
}

.real-results--screen-3 .aaaem-carousel__actions {
  position: absolute;
  bottom: -60px;
  right: 0;
}

@media (min-width: 1024px) {
  .real-results--screen-3 .aaaem-carousel__actions {
    bottom: -84px;
  }
}

.real-results--screen-3 .aaaem-carousel__action {
  background-repeat: no-repeat;
  background-color: var(--emu-common-colors-white);
  background-position: center;
  background-size: 21px 18px;
  border: none;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  position: relative;
}

@media (min-width: 1024px) {
  .real-results--screen-3 .aaaem-carousel__action {
    background-size: auto;
    width: 48px;
    height: 48px;
  }
}

.real-results--screen-3 .aaaem-carousel__action:disabled {
  opacity: .5;
}

.real-results--screen-3 .aaaem-carousel__action svg {
  display: none;
}

.real-results--screen-3 .aaaem-carousel__action-prev {
  margin-right: var(--emu-common-spacing-medium);
  background-image: url("resources/images/arrow-prev.png");
}

@media (min-width: 1024px) {
  .real-results--screen-3 .aaaem-carousel__action-prev {
    margin-right: var(--emu-common-spacing-large);
  }
}

.real-results--screen-3 .aaaem-carousel__action-next {
  background-image: url("resources/images/arrow-next.png");
}

@media (min-width: 1024px) {
  .real-results--screen-3 .patient-disclaimer p {
    text-align: left;
  }
}

.real-results__modal:has(.plyr--fullscreen-fallback) .modal-wrapper {
  z-index: 700;
}

:is(.real-results__modal:has(.plyr--fullscreen-fallback) .custom-tabs__select-wrapper, .real-results__modal:has(.plyr--fullscreen-fallback) .aaaem-tabs__tablist) {
  visibility: hidden;
}

.real-results__modal:has(.plyr--fullscreen-fallback) .modal-content > .container {
  z-index: 700;
  position: relative;
}

.real-results__modal:has(.plyr--fullscreen-fallback) .plyr--fullscreen-fallback {
  justify-content: center;
  align-items: center;
  display: flex;
}

.real-results__modal .embed .aaaem-embed__oembed {
  padding-bottom: 56.25%;
}

.real-results__modal .emu-pf {
  overflow: visible;
  overflow: initial;
}

.real-results__modal .modal-content {
  top: 0;
}

.real-results__modal .modal-content .close {
  background-color: var(--emu-common-colors-white);
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .real-results__modal[data-component="modal"].emu-modal .modal-wrapper, .real-results__modal[data-component="modal"].emu-modal .modal-content {
    opacity: 1;
    display: none;
  }

  .real-results__modal[data-component="modal"].emu-modal .modal-wrapper.is-open {
    display: block;
  }
}

.real-results__modal[data-component="modal"].emu-modal .modal-wrapper.is-open .modal-content {
  display: block;
}

.real-results--screen-3 .aaaem-carousel {
  opacity: 0;
  transition-property: all;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.lo3-section-container--active .real-results--screen-3 .aaaem-carousel {
  opacity: 1;
  transform: translateY(0);
}

.lo3-section-container--prev-active .real-results--screen-3 .aaaem-carousel {
  opacity: 0;
  transform: translateY(-100px);
}

.real-stories--screen-0 .content-box__title sup {
  letter-spacing: -1px;
  margin-left: 2px;
  font-size: 50%;
  top: -5px;
}

@media (min-width: 1024px) {
  .real-stories--screen-0 .content-box__title sup {
    top: -9px;
  }

  .real-stories--screen-1 [data-component="text"].patient-disclaimer p {
    text-align: left;
  }
}

.real-stories--screen-1 [data-component="text"].patient-disclaimer--second-screen {
  display: none;
}

@media (min-width: 1024px) {
  .real-stories--screen-1 [data-component="text"].patient-disclaimer--second-screen {
    background-color: #2e184480;
    width: auto;
    min-width: 296px;
    padding: 6px 24px 7px 18px;
    display: inline-block;
    inset: 42px 0 auto auto;
  }

  .real-stories--screen-1 [data-component="text"].patient-disclaimer--second-screen p {
    width: 100%;
  }

  .real-stories--screen-1 [data-component="text"].patient-disclaimer--first-screen {
    display: none;
  }
}

.real-stories--screen-1 .content-box {
  opacity: 0;
}

.real-stories--screen-1.content-visible .content-box {
  opacity: 1;
}

.real-stories--screen-1.content-visible .content-box .patient-disclaimer--first-screen {
  display: none;
}

@media (min-width: 1024px) {
  .real-stories--screen-1.content-visible .content-box .patient-disclaimer--first-screen {
    display: block;
  }
}

.real-stories--screen-1.content-visible .real-stories__bg-img {
  opacity: 0;
  pointer-events: none;
  mix-blend-mode: revert;
}

.real-stories--screen-1.content-visible .real-stories__bg-img--desktop {
  opacity: 1;
}

.real-stories--screen-1.content-visible .real-stories__bg-img--initial {
  opacity: 0;
  transition: right .4s linear, transform .8s linear .4s, opacity .4s linear .4s;
  transform: translateX(100%);
}

@media (min-width: 1024px) {
  .real-stories--screen-1.content-visible .real-stories__bg-img--initial, .real-stories--screen-1.content-visible .real-stories__bg-img--final {
    right: -60vw;
  }
}

@media (min-width: 1140px) {
  .real-stories--screen-1.content-visible .real-stories__bg-img--initial, .real-stories--screen-1.content-visible .real-stories__bg-img--final {
    right: -54.5vw;
  }
}

@media (min-width: 1440px) {
  .real-stories--screen-1.content-visible .real-stories__bg-img--initial, .real-stories--screen-1.content-visible .real-stories__bg-img--final {
    right: -800px;
  }
}

@media (min-width: 1024px) {
  .real-stories--screen-1.content-visible .real-stories__bg-img--flowers {
    right: -20vw;
  }
}

@media (min-width: 1140px) {
  .real-stories--screen-1.content-visible .real-stories__bg-img--flowers {
    right: -17vw;
  }
}

@media (min-width: 1440px) {
  .real-stories--screen-1.content-visible .real-stories__bg-img--flowers {
    right: -260px;
  }
}

.real-stories--screen-1 .content-box {
  position: static;
}

@media (min-width: 1024px) {
  .real-stories--screen-1 .content-box {
    width: 590px;
  }
}

.real-stories--screen-1 .content-box__inner {
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
}

.real-stories--screen-1 .content-box__inner .lo3-animated-subsection {
  height: auto !important;
}

.real-stories--screen-1 .content-box__button {
  max-width: 100%;
  margin-top: 26px;
  padding-left: 70px;
  padding-right: 70px;
}

@media (min-width: 1024px) {
  .real-stories--screen-1 .content-box__button {
    padding-left: 72px;
    padding-right: 72px;
  }
}

.real-stories--screen-1 .content-box__text-small {
  margin-top: 33px;
}

@media (min-width: 1024px) {
  .real-stories--screen-1 .content-box__text-small {
    max-width: 420px;
    margin-top: 45px;
  }
}

@media (min-width: 1280px) {
  .real-stories--screen-1 .content-box__text-small {
    max-width: 481px;
  }
}

.real-stories--screen-1 .content-box__text-small p {
  letter-spacing: normal;
  text-indent: -8px;
  text-align: left;
}

.real-stories--screen-1 .content-box__text-small p .emphasis {
  text-indent: 0;
  text-indent: initial;
}

.real-stories--screen-1 .content-box__text-small p .emphasis sup {
  top: 1px;
}

.real-stories--screen-1 .real-stories__bg-img {
  mix-blend-mode: color;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 187px;
  transition: right .4s linear, opacity .4s linear;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(0);
}

@media (min-width: 1024px) {
  .real-stories--screen-1 .real-stories__bg-img {
    padding-bottom: 113px;
  }
}

.real-stories--screen-1 .real-stories__bg-img--mobile {
  display: flex;
}

@media (min-width: 1024px) {
  .real-stories--screen-1 .real-stories__bg-img--mobile {
    display: none;
  }
}

.real-stories--screen-1 .real-stories__bg-img--desktop {
  display: none;
}

@media (min-width: 1024px) {
  .real-stories--screen-1 .real-stories__bg-img--desktop {
    display: flex;
  }
}

.real-stories--screen-1 .real-stories__bg-img img {
  width: auto;
  height: auto;
  margin: auto;
}

@media (min-width: 1024px) {
  .real-stories--screen-1 .real-stories__bg-img img {
    width: 100%;
  }
}

.cq-Editable-dom--container .real-stories__bg-img {
  display: block !important;
  position: static !important;
}

.cq-Editable-dom--container .real-stories--screen-1 .content-box {
  opacity: 1 !important;
}

#real-stories .lo3-section {
  overflow: hidden;
}

.start-journey__poster .content-box {
  padding-top: 56px;
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  justify-content: flex-start;
  max-width: none;
  position: relative;
}

@media (min-width: 1024px) {
  .start-journey__poster .content-box {
    padding-top: min(10vh, 257px);
  }
}

@media (min-width: 1024px) and (min-height: 850px) {
  .start-journey__poster .content-box {
    padding-top: 257px;
  }
}

.start-journey__poster .content-box > .container:nth-child(2) {
  margin-top: auto;
}

@media (min-width: 1024px) {
  .start-journey__poster .content-box > .container:nth-child(2) {
    margin-top: var(--emu-common-spacing-none);
  }
}

.start-journey__poster .content-box__title {
  text-align: left;
  margin-bottom: 19px;
}

@media (min-width: 1024px) {
  .start-journey__poster .content-box__title {
    margin-bottom: 34px;
  }
}

.start-journey__poster .content-box__title h2, .start-journey__poster .content-box__title h3, .start-journey__poster .content-box__title h4 {
  letter-spacing: -1px;
}

@media (min-width: 1024px) {
  .start-journey__poster .content-box__title h2, .start-journey__poster .content-box__title h3, .start-journey__poster .content-box__title h4 {
    letter-spacing: -2px;
  }
}

.start-journey__poster .content-box__subtitle {
  margin-bottom: 13px;
}

@media (min-width: 1024px) {
  .start-journey__poster .content-box__subtitle {
    margin-bottom: 24px;
  }
}

.start-journey__poster .content-box__subtitle h4 {
  color: var(--emu-semantic-colors-primary-violet-200);
  line-height: normal;
}

.start-journey__poster .content-box__text {
  text-align: left;
}

.start-journey__poster .patient-disclaimer {
  margin-bottom: 7px;
  position: static;
}

@media (min-width: 1024px) {
  .start-journey__poster .patient-disclaimer {
    margin-bottom: var(--emu-common-spacing-none);
    position: fixed;
  }

  .start-journey__poster .patient-disclaimer p {
    text-align: left;
  }
}

.start-journey__poster-content-container {
  box-sizing: content-box;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 52px;
  padding-right: 52px;
}

@media (min-width: 1024px) {
  .start-journey__poster-content-container {
    box-sizing: border-box;
    margin-left: var(--emu-common-spacing-none);
    padding-left: 71px;
    padding-right: var(--emu-common-spacing-none);
    max-width: 50%;
  }
}

.start-journey__poster-text[data-component="text"] ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
  list-style: none;
}

.start-journey__poster-text[data-component="text"] li {
  padding-left: var(--emu-common-spacing-large);
  opacity: 0;
  margin-bottom: 6px;
  transition: all .4s linear;
  position: relative;
  transform: translateY(20px);
}

@media (min-width: 1024px) {
  .start-journey__poster-text[data-component="text"] li {
    margin-bottom: 3px;
    padding-left: 34px;
    line-height: 32px;
  }
}

.start-journey__poster-text[data-component="text"] li sup {
  top: -5px;
}

.start-journey__poster-text[data-component="text"] li b sup {
  top: -3px;
}

@media (min-width: 1024px) {
  .start-journey__poster-text[data-component="text"] li b sup {
    top: -4px;
  }
}

.start-journey__poster-text[data-component="text"] li:before, .start-journey__poster-text[data-component="text"] li:after {
  content: "";
  background-color: var(--emu-semantic-colors-primary-purple-100);
  border-radius: var(--emu-common-border-radius-xs);
  transform-origin: 0 0;
  width: 3px;
  height: 10px;
  transition: all .2s linear;
  position: absolute;
  top: 13px;
  left: 0;
  transform: rotate(-50deg)scaleY(0);
}

@media (min-width: 1024px) {
  .start-journey__poster-text[data-component="text"] li:before, .start-journey__poster-text[data-component="text"] li:after {
    top: 18px;
  }
}

.start-journey__poster-text[data-component="text"] li:after {
  transform-origin: 0 100%;
  height: 18px;
  top: 0;
  left: 7px;
  transform: rotate(45deg)scaleY(0);
}

@media (min-width: 1024px) {
  .start-journey__poster-text[data-component="text"] li:after {
    top: 4px;
    left: 7px;
  }

  .start-journey__poster-img-container {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .start-journey__poster-img-container > .image:nth-child(2) {
    display: none;
  }
}

.start-journey__poster-img-container > .image:nth-child(3) {
  display: none;
}

@media (min-width: 1024px) {
  .start-journey__poster-img-container > .image:nth-child(3) {
    display: block;
  }
}

.start-journey__poster-img-container img {
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: bottom;
  object-position: bottom;
  width: 100%;
  height: auto;
  max-height: 479px;
  margin: auto;
}

@media (min-width: 1024px) {
  .start-journey__poster-img-container img {
    width: auto;
    max-width: 60vw;
    height: 80vh;
    max-height: none;
  }
}

@media (min-width: 1280px) {
  .start-journey__poster-img-container img {
    max-width: 72vw;
  }
}

@media (min-width: 1024px) and (min-height: 875px) {
  .start-journey__poster-img-container img {
    height: 875px;
    max-height: none;
  }
}

@media (min-width: 1024px) and (min-height: 843px) {
  .start-journey__poster.start-journey--screen-2 .start-journey__poster-img-container img {
    height: 843px;
  }
}

@media (min-width: 1024px) {
  .start-journey__poster.start-journey--screen-3 .start-journey__poster-content-container {
    max-width: 576px;
  }
}

@media (min-width: 1024px) and (min-height: 842px) {
  .start-journey__poster.start-journey--screen-3 .start-journey__poster-img-container img {
    height: 842px;
  }
}

.start-journey__poster-img {
  opacity: 0;
  transition: all .4s linear .6s;
  transform: translateX(80px);
}

.lo3-section-container--active .start-journey__poster .content-box__title {
  transition-delay: .4s;
}

.lo3-section-container--active .start-journey__poster .content-box__subtitle {
  transition-delay: .8s;
}

.lo3-section-container--active .start-journey__poster .content-box__text {
  opacity: 1;
  transition-delay: 0s;
  transform: translateY(0);
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li {
  opacity: 1;
  transform: translateY(0);
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:before {
  transform: rotate(-50deg)scaleY(1);
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:after {
  transform: rotate(45deg)scaleY(1);
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:first-child {
  transition-delay: 1.2s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:first-child:before {
  transition-delay: 1.3s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:first-child:after {
  transition-delay: 1.5s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(2) {
  transition-delay: 1.4s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(2):before {
  transition-delay: 1.6s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(2):after {
  transition-delay: 1.8s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(3) {
  transition-delay: 1.6s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(3):before {
  transition-delay: 1.9s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(3):after {
  transition-delay: 2.1s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(4) {
  transition-delay: 1.8s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(4):before {
  transition-delay: 2.2s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(4):after {
  transition-delay: 2.4s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(5) {
  transition-delay: 2s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(5):before {
  transition-delay: 2.5s;
}

.lo3-section-container--active .start-journey__poster-text[data-component="text"] ul li:nth-child(5):after {
  transition-delay: 2.7s;
}

.lo3-section-container--active .start-journey__poster-img {
  opacity: 1;
  transform: translateX(0);
}

.lo3-section-container--prev-active .start-journey__poster-content-container {
  opacity: 0;
  transition: all .4s linear;
  transform: translateY(-200px);
}

.lo3-section-container--prev-active .start-journey__poster-img {
  opacity: 0;
  transition-delay: 0s;
  transform: translateX(80px);
}

.cq-Editable-dom--container .start-journey__poster-img, .cq-Editable-dom--container .start-journey__poster-img-container, .cq-Editable-dom--container .start-journey__poster-img-container > .image {
  display: block !important;
  position: static !important;
}

.cq-Editable-dom--container .start-journey__poster-img img {
  width: auto !important;
  max-height: none !important;
  margin-left: auto !important;
}

.cq-Editable-dom--container .start-journey__poster ul li:before {
  transform: rotate(-50deg)scaleY(1) !important;
}

.cq-Editable-dom--container .start-journey__poster ul li:after {
  transform: rotate(45deg)scaleY(1) !important;
}

@media screen and (min-width: 1024px) and (max-height: 750px) {
  .start-journey__poster .content-box {
    padding-top: 24px;
  }

  .start-journey__poster .content-box .patient-disclaimer {
    top: 16px;
    bottom: auto;
  }

  .start-journey__poster .content-box .patient-disclaimer p {
    text-align: right;
  }

  .start-journey__poster-img-container img {
    height: 90vh;
    margin-right: 125px;
    transform: scale(1.15);
  }

  .start-journey__poster.start-journey--screen-3 .start-journey__poster-content-container {
    max-width: min(576px, 50%);
  }
}

.start-journey__planner .content-box {
  justify-content: flex-start;
  padding-top: 63px;
}

@media (min-width: 1024px) {
  .start-journey__planner .content-box {
    padding-top: 132px;
  }

  .start-journey__planner .content-box__title {
    margin-bottom: 50px;
  }
}

.start-journey__planner .content-box__text-small {
  text-align: center;
  max-width: 317px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .start-journey__planner .content-box__text-small {
    max-width: none;
  }
}

.start-journey__planner .content-box__text-small p {
  letter-spacing: normal;
}

@media (min-width: 1024px) {
  .start-journey__planner .content-box__text-small p {
    margin-bottom: 18px;
  }
}

.start-journey__planner .content-box__button.cmp-button {
  margin-top: 20px;
  padding-right: 18px;
}

.start-journey__planner .content-box__button.cmp-button svg {
  margin-right: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .start-journey__planner .content-box__button.cmp-button {
    margin-top: 22px;
  }
}

.start-journey__planner-text[data-component="text"] {
  text-align: center;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .start-journey__planner-text[data-component="text"] {
    text-align: left;
    margin-bottom: 14px;
  }

  .start-journey__planner-text[data-component="text"] p {
    line-height: 32px;
  }
}

.start-journey__planner-text[data-component="text"] sup {
  top: -4px;
}

.start-journey__planner-visit-container {
  grid-gap: 11px;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  margin-bottom: 29px;
  display: flex;
}

@media (min-width: 1024px) {
  .start-journey__planner-visit-container {
    align-items: flex-start;
  }
}

.start-journey__planner-visit-container > .container {
  width: 100%;
}

.start-journey__planner-visit-container .cmp-form-text {
  grid-gap: 9px;
  flex-direction: column;
  gap: 9px;
  display: flex;
}

.start-journey__planner-visit-container label {
  color: var(--emu-semantic-colors-primary-violet-200);
  text-align: center;
  display: block;
}

@media (min-width: 1024px) {
  .start-journey__planner-visit-container label {
    font-size: 24px;
    line-height: 32px;
  }
}

.start-journey__planner-visit-container input {
  padding: var(--emu-common-spacing-none);
  pointer-events: none;
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-purple-100);
  padding: var(--emu-common-spacing-none);
  font-weight: var(--emu-common-font-weight-regular);
  opacity: .5;
  text-align: center;
  border: none;
  width: 104px;
  height: 24px;
  margin-left: auto;
  margin-right: auto;
  transition: all .4s linear;
  display: block;
}

@media (min-width: 1024px) {
  .start-journey__planner-visit-container input {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    height: auto;
    font-size: 36px;
    line-height: normal;
  }
}

.start-journey__planner-visit-container input, .start-journey__planner-visit-container input:active, .start-journey__planner-visit-container input:hover, .start-journey__planner-visit-container input:focus {
  box-shadow: none;
  outline: none;
}

.start-journey__planner-visit-container input::placeholder {
  color: var(--emu-semantic-colors-primary-purple-100);
}

.start-journey__planner-visit-container input.datepicker-input--has-value {
  color: var(--emu-semantic-colors-primary-purple-600);
  background-color: #9577bf33;
}

@media (min-width: 1024px) {
  .start-journey__planner-visit-container input.datepicker-input--has-value {
    text-align: center;
    letter-spacing: -1px;
    width: 210px;
    height: auto;
  }
}

.start-journey__planner-visit-container input.has-value {
  color: var(--emu-semantic-colors-primary-purple-100);
  opacity: 1;
}

.start-journey__planner-visit-container--visit1 {
  grid-gap: var(--emu-common-spacing-none);
  gap: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .start-journey__planner-visit-container--visit1 {
    margin-bottom: 35px;
  }
}

.start-journey__planner-visit-container--visit1:after {
  display: none;
}

.start-journey__planner-visit-container--visit1 input {
  opacity: 1;
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-purple-600);
  pointer-events: initial;
  width: 110px;
}

@media (min-width: 1024px) {
  .start-journey__planner-visit-container--visit1 input {
    text-align: left;
    padding-bottom: var(--emu-common-spacing-xxs);
    border-bottom-color: var(--emu-semantic-colors-primary-purple-100);
    width: auto;
    max-width: 230px;
    height: auto;
  }

  .start-journey__planner-visit-container--visit2 {
    margin-bottom: var(--emu-common-spacing-large);
  }
}

@media screen and (max-height: 850px) {
  .start-journey__planner-visit-container--visit2 .emu-atc__dropdown-content {
    top: -260px;
  }
}

@media (min-width: 1024px) {
  .start-journey__planner-visit-container--visit3 {
    margin-bottom: 29px;
  }
}

@media screen and (max-height: 850px) {
  .start-journey__planner-visit-container--visit3 .emu-atc__dropdown-content {
    top: -260px;
  }
}

@media (min-width: 1024px) {
  .start-journey__planner-visit-container--with-atc {
    grid-gap: var(--emu-common-spacing-none);
    align-items: center;
    gap: var(--emu-common-spacing-none);
    flex-direction: row;
  }
}

.start-journey__planner-visit-container--with-atc > .container {
  flex: 1;
}

@media (min-width: 1024px) {
  .start-journey__planner-visit-container--with-atc .cmp-form-text {
    grid-gap: 20px;
    flex-direction: row;
    align-items: baseline;
    gap: 20px;
  }

  .start-journey__planner-visit-container--with-atc .cmp-form-text input {
    letter-spacing: 2px;
  }

  .start-journey__planner-visit-container--with-atc input {
    text-align: left;
    width: 250px;
  }

  .start-journey__planner-modal[data-component="modal"] .modal-wrapper .modal-content {
    max-width: 777px;
  }
}

.start-journey__planner-modal[data-component="modal"] .content-box__modal-text {
  margin-top: -22px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1024px) {
  .start-journey__planner-modal[data-component="modal"] .content-box__modal-text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-top: 10px;
  }

  .start-journey__planner-modal[data-component="modal"] .content-box__modal-title-wrapper {
    grid-gap: 24px;
    margin-top: var(--emu-common-spacing-none);
    align-items: flex-start;
    gap: 24px;
  }
}

.start-journey__planner-modal[data-component="modal"] .content-box__modal-title-wrapper .emu-title__text {
  margin-top: -26px;
}

@media (min-width: 1024px) {
  .start-journey__planner-modal[data-component="modal"] .content-box__modal-small-text p {
    margin-top: 14px;
  }
}

.start-journey__planner .content-box {
  opacity: 0;
  transition: all .4s linear;
  transform: translateY(100px);
}

.start-journey__planner .content-box__title, .start-journey__planner .content-box__text, .start-journey__planner .content-box__text-small, .start-journey__planner .content-box__button {
  opacity: 1;
  transition: none;
  transform: translateY(0);
}

.lo3-section-container--active .start-journey__planner .content-box {
  opacity: 1;
  transform: translateY(0);
}

.lo3-section-container--prev-active .start-journey__planner .content-box {
  opacity: 0;
  transform: translateY(100px);
}

@media screen and (min-width: 1024px) and (max-height: 750px) {
  .start-journey__planner .content-box {
    width: auto;
    padding-top: 59px;
  }

  .start-journey__planner .content-box__title {
    margin-bottom: 21px;
  }

  .start-journey__planner .content-box__button.cmp-button {
    margin-top: var(--emu-common-spacing-none);
    position: fixed;
    top: 36px;
    right: 71px;
  }

  .start-journey__planner-visit-container--visit1 {
    margin-bottom: 27px;
  }

  .start-journey__planner-visit-container--visit2 {
    margin-bottom: var(--emu-common-spacing-medium);
  }

  .start-journey__planner-visit-container--visit3 {
    margin-bottom: 27px;
  }
}

@media (min-width: 1024px) {
  .video-testimonials {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin-left: auto;
    margin-right: auto;
  }
}

.video-testimonials .plyr--paused:after, .video-testimonials .plyr--stopped:after {
  display: none;
}

.video-testimonials .plyr--video .plyr__controls {
  position: absolute;
  bottom: 10px;
}

.video-testimonials .plyr__control.plyr__control--overlaid {
  padding: var(--emu-common-spacing-none);
  width: 48px;
  height: 48px;
}

@media (min-width: 1024px) {
  .video-testimonials .plyr__control.plyr__control--overlaid {
    width: 60px;
    height: 60px;
  }
}

.video-testimonials .plyr__control.plyr__control--overlaid:after {
  background-image: url("resources/images/play-2.png");
}

.video-testimonials__main-tabs {
  margin-top: var(--emu-common-spacing-medium);
}

.video-testimonials__main-tabs > .aaaem-tabs__tablist {
  display: none;
}

.video-testimonials__main-tabs-wrapper > .container {
  z-index: 2;
  position: relative;
}

@media (min-width: 1024px) {
  .video-testimonials__main-tabs-wrapper > .container {
    z-index: auto;
    z-index: initial;
  }
}

.video-testimonials__main-tabs-wrapper > .container div.custom-tabs__select-wrapper {
  display: flex;
}

@media (min-width: 1024px) {
  .video-testimonials__main-tabs-wrapper > .container div.custom-tabs__select-wrapper {
    width: 280px;
    display: inline-flex;
  }
}

@media (min-width: 1280px) {
  .video-testimonials__main-tabs-wrapper > .container div.custom-tabs__select-wrapper {
    width: 379px;
  }
}

.video-testimonials__main-tabs-wrapper > .container div.custom-tabs__select-wrapper > .options {
  flex: 1;
}

@media (min-width: 1024px) {
  .video-testimonials__main-tabs-wrapper > .container div.custom-tabs__select-wrapper .emu-dropdown-menu__button {
    max-width: 100%;
  }
}

.video-testimonials__main-tabs-wrapper > .tabs {
  z-index: 1;
  position: relative;
}

@media (min-width: 1024px) {
  .video-testimonials__main-tabs-wrapper > .tabs {
    z-index: auto;
    z-index: initial;
  }

  .video-testimonials__inner-tabs {
    position: relative;
  }

  .video-testimonials__inner-tabs .aaaem-tabs__tablist {
    width: calc(100% - 300px);
    margin: 0;
    position: absolute;
    top: -64px;
    right: 0;
  }
}

@media (min-width: 1280px) {
  .video-testimonials__inner-tabs .aaaem-tabs__tablist {
    width: calc(100% - 399px);
  }
}

.video-testimonials__content-container > .button {
  text-align: center;
  background-color: var(--emu-common-colors-white);
  z-index: var(--emu-common-other-z-index-layer);
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 133px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .video-testimonials__content-container > .button {
    padding-bottom: var(--emu-common-spacing-none);
    margin-bottom: 21px;
    position: static;
    transform: none;
  }
}

.video-testimonials__content-container > .button:before {
  content: "";
  background: linear-gradient(180deg, var(--emu-common-colors-transparent) 0%, var(--emu-common-colors-white) 100%);
  width: 100%;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

@media (min-width: 1024px) {
  .video-testimonials__content-container > .button:before {
    display: none;
  }
}

.video-testimonials__gallery-cta.emu-button {
  justify-content: center;
  width: 375px;
  max-width: 89%;
}

@media (min-width: 1024px) {
  .video-testimonials__gallery-cta.emu-button {
    max-width: 244px;
  }
}

.video-testimonials__title[data-component="title"] .emu-title__text {
  color: var(--emu-semantic-colors-primary-violet-200);
  margin-top: 34px;
  margin-bottom: 17px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .video-testimonials__title[data-component="title"] .emu-title__text {
    margin-top: 14px;
    margin-bottom: 15px;
  }
}

.video-testimonials__embed {
  max-width: 450px;
  margin-bottom: 17px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .video-testimonials__embed {
    max-width: 800px;
    margin-bottom: 24px;
  }
}

.video-testimonials__disclaimer[data-component="text"] p {
  color: var(--emu-common-colors-black);
  font-weight: var(--emu-common-font-weight-regular);
  font-size: 16px;
  line-height: normal;
}

.video-testimonials__disclaimer[data-component="text"] p b {
  font-weight: var(--emu-common-font-weight-bold);
}

.video-testimonials__text[data-component="text"] p {
  color: var(--emu-common-colors-black);
  margin-bottom: 18px;
  font-size: 16px;
  line-height: normal;
}

.video-testimonials__text[data-component="text"] sup {
  top: 2px;
}

.cq-Editable-dom--container .video-testimonials {
  height: auto;
}

.cq-Editable-dom--container .video-testimonials .plyr--video {
  height: 450px;
}

.cq-Editable-dom--container .video-testimonials__main-tabs, .cq-Editable-dom--container .video-testimonials__inner-tabs, .cq-Editable-dom--container .video-testimonials__content-container {
  height: auto;
}

.cq-Editable-dom--container .video-testimonials__main-tabs-wrapper div.custom-tabs__select-wrapper {
  height: auto;
  position: static;
}

.cq-Editable-dom--container .video-testimonials__text[data-component="text"] p {
  margin-bottom: var(--emu-common-spacing-large);
}

.cq-Editable-dom--container .video-testimonials__gallery-cta.emu-button {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: static;
  transform: none;
}

.gallery__modal[data-component="modal"].modal--fullscreen .modal-wrapper .modal-content {
  padding-top: var(--emu-common-spacing-none);
  padding-left: 9px;
  padding-right: 9px;
}

@media (min-width: 1024px) {
  .gallery__modal[data-component="modal"].modal--fullscreen .modal-wrapper .modal-content {
    padding-top: 10px;
  }
}

.gallery__inner-container {
  max-width: 857px;
  margin-left: auto;
  margin-right: auto;
}

.gallery__inner-container > .container:last-child .gallery__section > .container:last-child .gallery__subsection {
  margin-bottom: 14px;
}

@media (min-width: 1024px) {
  .gallery__inner-container > .container:last-child .gallery__section > .container:last-child .gallery__subsection {
    margin-bottom: 25px;
  }
}

.gallery__section {
  margin-top: 20px;
  margin-bottom: -9px;
}

@media (min-width: 1024px) {
  .gallery__section {
    margin-bottom: -5px;
  }
}

.gallery__section-title[data-component="title"] .cmp-title__text {
  color: var(--emu-semantic-colors-primary-purple-600);
  letter-spacing: -1px;
  font-size: 40px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .gallery__section-title[data-component="title"] .cmp-title__text {
    font-size: 48px;
  }
}

.gallery__subsection {
  margin-bottom: 29px;
}

@media (min-width: 1024px) {
  .gallery__subsection {
    margin-bottom: 35px;
  }
}

.gallery__subsection-pretitle[data-component="title"] .cmp-title__text {
  color: var(--emu-common-colors-black);
  font-size: 14px;
  font-weight: var(--emu-common-font-weight-bold);
  margin-top: 15px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .gallery__subsection-pretitle[data-component="title"] .cmp-title__text {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 16px;
  }

  .gallery__subsection-title[data-component="title"] {
    position: relative;
  }

  .gallery__subsection-title[data-component="title"]:after {
    content: "";
    height: var(--emu-common-border-width-thin);
    background-color: var(--emu-semantic-colors-primary-purple-500);
    z-index: var(--emu-common-other-z-index-base);
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.gallery__subsection-title[data-component="title"] .cmp-title__text {
  color: var(--emu-semantic-colors-primary-violet-200);
  letter-spacing: -1px;
  font-size: 28px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .gallery__subsection-title[data-component="title"] .cmp-title__text {
    z-index: var(--emu-common-other-z-index-layer);
    background-color: var(--emu-common-colors-white);
    padding-left: 30px;
    padding-right: 30px;
    display: inline-block;
    position: relative;
  }
}

.gallery__treatment-wrapper {
  margin-top: 10px;
}

@media (min-width: 1024px) {
  .gallery__treatment-wrapper {
    margin-top: 14px;
    margin-bottom: 18px;
  }
}

.gallery__treatment-img img {
  width: 100%;
}

.gallery__treatment-title[data-component="title"] .cmp-title__text {
  color: var(--emu-common-colors-black);
  font-size: 16px;
  font-weight: var(--emu-common-font-weight-regular);
  margin-top: 20px;
  margin-bottom: 5px;
  line-height: normal;
}

@media (min-width: 1024px) {
  .gallery__treatment-title[data-component="title"] .cmp-title__text {
    margin-top: 35px;
    font-size: 24px;
  }
}

.gallery__treatment-section-wrapper {
  grid-gap: 9px;
  gap: 9px;
  display: flex;
}

@media (min-width: 1024px) {
  .gallery__treatment-section-wrapper {
    grid-gap: 25px;
    gap: 25px;
  }
}

.gallery__treatment-section-wrapper > .container {
  width: 50%;
}

.gallery__treatment-section-title[data-component="title"] .cmp-title__text {
  color: var(--emu-common-colors-black);
  font-size: 15px;
  line-height: normal;
  font-weight: var(--emu-common-font-weight-light);
  margin-bottom: var(--emu-common-spacing-xs);
}

@media (min-width: 1024px) {
  .gallery__treatment-section-title[data-component="title"] .cmp-title__text {
    margin-bottom: 10px;
    font-size: 20px;
  }
}

.gallery__close-cta {
  width: 100%;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .gallery__close-cta {
    width: auto;
  }
}

.gallery__close-cta.aaaem-button {
  padding-top: 12px;
  padding-bottom: 12px;
}

@media (min-width: 1024px) {
  .gallery__close-cta.aaaem-button {
    padding-left: 22px;
    padding-right: 25px;
  }
}

.gallery__close-cta-container {
  text-align: center;
  background-color: var(--emu-common-colors-white);
  z-index: var(--emu-common-other-z-index-layer);
  width: 100%;
  padding-top: 20px;
  padding-bottom: 133px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .gallery__close-cta-container {
    text-align: right;
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    padding: var(--emu-common-spacing-none);
    background-color: var(--emu-common-colors-transparent);
    margin-left: auto;
    margin-right: auto;
    bottom: 150px;
  }
}

.gallery__close-cta-container > .button {
  width: 89%;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .gallery__close-cta-container > .button {
    width: 91%;
    max-width: 1298px;
    margin-left: auto;
    margin-right: auto;
  }
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

@media (min-width: 1024px) {
  .emu-atc {
    text-align: left;
  }
}

.emu-atc .button .emu-button.emu-button-v2 {
  background-color: var(--emu-semantic-colors-primary-purple-100);
  text-align: center;
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-purple-100);
  border-radius: var(--emu-common-border-radius-large);
  color: var(--emu-common-colors-grey-100);
  font: var(--emu-component-actions-button-text-typography-wide);
  padding: 9.5px var(--emu-common-spacing-large);
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  width: 244px;
  max-width: 350px;
  position: relative;
}

.emu-atc .button .emu-button.emu-button-v2 > svg {
  margin-left: -10px;
  margin-right: auto;
}

.emu-atc .button .emu-button.emu-button-v2 > span {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 10px;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .emu-atc .button .emu-button.emu-button-v2 > span {
    padding-right: var(--emu-common-spacing-none);
  }
}

.emu-atc .button .emu-button.emu-button-v2 > span:after {
  content: "";
  left: var(--emu-common-spacing-medium);
  z-index: var(--emu-common-other-z-index-layer);
  background-image: url("resources/images/right-arrow.png");
  background-position: 100%;
  background-repeat: no-repeat;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

@media (min-width: 1024px) {
  .emu-atc .button .emu-button.emu-button-v2 > span:after {
    left: var(--emu-common-spacing-none);
    background-size: 7px;
    margin-left: 18px;
  }
}

.emu-atc .button .emu-button.emu-button-v2:hover, .emu-atc .button .emu-button.emu-button-v2:focus, .emu-atc .button .emu-button.emu-button-v2:active {
  background-color: var(--emu-semantic-colors-primary-purple-100);
  box-shadow: none;
  color: var(--emu-common-colors-grey-100);
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-purple-100);
}

.emu-atc__dropdown-content {
  left: calc(50% - 82.5px);
  top: var(--emu-common-spacing-xl);
  border-radius: var(--emu-common-border-radius-small);
  color: #dedede;
  z-index: var(--emu-common-other-z-index-cookie-banner);
  background-color: #2e2e2e;
  width: 165px;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 4px 20px -2px #0009, 0 2px 4px -1px #0006;
}

.emu-atc__dropdown-content li:first-child img, .emu-atc__dropdown-content li:nth-child(2) img, .emu-atc__dropdown-content li:nth-child(4) img {
  filter: invert();
}

.emu-atc__dropdown-content a {
  color: var(--emu-common-colors-grey-100);
  font: var(--emu-component-actions-button-text-typography-wide);
  padding: 14px 22px;
  display: flex;
}

.emu-atc__dropdown-content a:hover {
  color: #f1f1f1;
  background-color: #373737;
}

.emu-atc__dropdown-content a img {
  width: 18px;
  height: 18px;
}

.emu-atc__dropdown-content a span {
  margin-left: 13px;
}

.emu-atc.disabled .button .emu-button.emu-button-v2 {
  pointer-events: none;
  transition: all .4s linear;
  opacity: .5 !important;
}

.emu-atc.disabled .emu-atc__main--dropdown ul {
  display: none !important;
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

.alle-banner.aaaem-container {
  background-color: var(--emu-common-colors-black);
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
}

@media (min-width: 1024px) {
  .alle-banner.aaaem-container {
    z-index: var(--emu-common-other-z-index-header);
  }
}

.alle-banner .banner-container {
  grid-gap: 33px;
  flex-direction: column;
  align-items: center;
  gap: 33px;
  padding-top: 56px;
  padding-bottom: 56px;
  display: flex;
}

@media (min-width: 1024px) {
  .alle-banner .banner-container {
    grid-gap: 68px;
    flex-direction: row;
    justify-content: center;
    gap: 68px;
    padding-top: 93px;
    padding-bottom: 93px;
  }
}

.alle-banner .banner-container img {
  width: 101px;
  height: auto;
}

@media (min-width: 1024px) {
  .alle-banner .banner-container img {
    width: 210px;
  }
}

.alle-banner .banner-container p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  color: var(--emu-common-colors-white);
  text-align: center;
  max-width: 335px;
}

.alle-banner .banner-container p sup {
  top: -4px;
}

.alle-banner .banner-container p .emphasis sup {
  font-size: 45%;
  top: -5px;
}

@media (min-width: 1024px) {
  .alle-banner .banner-container p {
    text-align: left;
    max-width: 360px;
  }
}

.alle-banner .banner-container p br {
  display: block;
}

@media (min-width: 1024px) {
  .alle-banner .banner-container p br {
    display: none;
  }
}

.alle-banner .banner-container .button .cmp-button {
  padding-left: 14px;
  padding-right: 14px;
  text-decoration: none;
}

@media (min-width: 1024px) {
  .alle-banner .banner-container .button .cmp-button {
    margin-top: -14px;
    margin-left: -29px;
  }
}

.alle-banner .banner-container .button .cmp-button > span {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  padding-left: 15px;
  padding-right: 15px;
  line-height: 25px;
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

.aaaem-button:not(a):hover, .aaaem-button:not(a):active {
  outline: 0;
}

.aaaem-button__primary-filled {
  justify-content: center;
  align-items: center;
}

.aaaem-button__secondary-outline.aaaem-button {
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 7px 24px 7px 12px;
}

.aaaem-button__secondary-filled.aaaem-button {
  align-items: center;
  box-shadow: 0 0 15px #0000000d;
}

.aaaem-button__secondary-filled.aaaem-button svg {
  width: var(--emu-common-sizing-small);
}

@media (min-width: 1024px) {
  .aaaem-button__secondary-filled.aaaem-button svg {
    margin-right: var(--emu-common-spacing-medium);
  }
}

.aaaem-button__secondary-filled.aaaem-button span {
  text-align: center;
  flex: 1;
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

.container-isi .aaaem-isi-container {
  bottom: var(--emu-common-spacing-none);
  max-height: 113px;
  transition: all .3s ease-in;
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    left: var(--emu-common-spacing-none);
    right: var(--emu-common-spacing-none);
    margin: auto;
  }
}

.container-isi .aaaem-isi-container__isi-content {
  z-index: var(--emu-common-other-z-index-isi);
  max-width: 100%;
  margin: auto;
  position: relative;
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container__isi-content {
    z-index: var(--emu-common-other-z-index-header);
  }
}

@media (min-width: 768px) {
  .container-isi .aaaem-isi-container__isi-content {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  }

  .container-isi .aaaem-isi-container__isi-content > div:first-child {
    padding-bottom: 58px;
  }
}

.container-isi .aaaem-isi-container__isi-content .aaaem-text p {
  font-weight: var(--emu-semantic-font-weight-light);
  color: var(--emu-common-colors-black);
  word-break: break-word;
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container__isi-content .aaaem-text p {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: 32px;
  }
}

.container-isi .aaaem-isi-container__isi-content .aaaem-text p sup {
  vertical-align: top;
  top: -3px;
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container__isi-content .aaaem-text p sup {
    top: -4px;
  }
}

.container-isi .aaaem-isi-container__isi-content .aaaem-text p a {
  color: var(--emu-common-colors-black);
}

.container-isi .aaaem-isi-container__isi-content .aaaem-text p a[href^="tel:"] {
  text-decoration: none;
}

.container-isi .aaaem-isi-container__isi-content .aaaem-text ul {
  margin-top: var(--emu-common-spacing-xxs);
  margin-bottom: var(--emu-common-spacing-none);
  margin-left: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  padding-inline-start: 28px;
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container__isi-content .aaaem-text ul {
    padding-inline-start: 37px;
  }
}

.container-isi .aaaem-isi-container__isi-content .aaaem-text ul li, .container-isi .aaaem-isi-container__isi-content .aaaem-text ul p, .container-isi .aaaem-isi-container__isi-content .aaaem-text ul b {
  color: var(--emu-common-colors-black);
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container__isi-content .aaaem-text ul li, .container-isi .aaaem-isi-container__isi-content .aaaem-text ul p, .container-isi .aaaem-isi-container__isi-content .aaaem-text ul b {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: 32px;
  }
}

.container-isi .aaaem-isi-container .aaaem-isi-banner {
  margin-top: var(--emu-common-spacing-none);
  margin-right: auto;
  margin-bottom: var(--emu-common-spacing-none);
  border-top-left-radius: var(--emu-common-border-radius-small);
  border-top-right-radius: var(--emu-common-border-radius-small);
  background-color: var(--emu-semantic-colors-primary-violet-100);
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 89%;
  margin-left: auto;
  display: flex;
  box-shadow: 0 0 15px #0000000d;
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container .aaaem-isi-banner {
    width: 91%;
    max-width: 1298px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-button {
  padding: var(--emu-common-spacing-none) var(--emu-common-spacing-none) 10px;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-button .aaaem-button__icon:before, .container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-button .aaaem-button__icon:after {
  display: none;
}

.container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-isi-container__banner-content {
  max-width: 300px;
  margin-top: -10px;
}

@media (min-width: 425px) {
  .container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-isi-container__banner-content {
    max-width: 100%;
  }
}

.container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-isi-container__banner-content p, .container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-isi-container__banner-content ul {
  color: var(--emu-component-ingredients-title-color-dark);
  margin: var(--emu-common-spacing-none);
  font-size: 16px;
  line-height: normal;
  font-weight: var(--emu-common-font-weight-regular);
  width: 100%;
}

.container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-isi-container__banner-content p sup, .container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-isi-container__banner-content ul sup {
  vertical-align: top;
  font-size: 60%;
  position: relative;
  top: 1px;
}

.container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-isi-container__banner-content ul {
  padding-top: var(--emu-common-spacing-xxs);
  padding-inline-start: 20px;
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-isi-container__banner-content ul {
    padding-inline-start: 30px;
  }
}

.container-isi .aaaem-isi-container .aaaem-isi-banner .aaaem-isi-container__banner-content ul li {
  font-size: inherit;
  line-height: 16px;
}

.container-isi .aaaem-isi-container__button-wrapper {
  align-self: end;
}

.isi-dummy-clicker__container {
  opacity: 1;
  pointer-events: initial;
  width: 100%;
  transition: all .1s linear .2s;
  position: fixed;
  bottom: 93px;
  left: 0;
}

.isi-dummy-clicker__container > .button {
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
  justify-content: flex-end;
  width: 89%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 1024px) {
  .isi-dummy-clicker__container > .button {
    width: 91%;
    max-width: 1298px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.isi-dummy-clicker__container > .button .isi-dummy-clicker__cta.emu-button-v2.emu-button {
  padding: var(--emu-common-spacing-none);
  width: auto;
  height: auto;
  transform: translateY(6px);
}

.isi-dummy-clicker__container > .button .isi-dummy-clicker__cta.emu-button-v2.emu-button, .isi-dummy-clicker__container > .button .isi-dummy-clicker__cta.emu-button-v2.emu-button:active, .isi-dummy-clicker__container > .button .isi-dummy-clicker__cta.emu-button-v2.emu-button:hover {
  background-color: var(--emu-common-colors-transparent);
  outline: var(--emu-common-outline-width-unset);
  border: var(--emu-common-border-width-none);
}

@media (min-width: 768px) {
  .isi-dummy-clicker__container > .button .isi-dummy-clicker__cta.emu-button-v2.emu-button {
    transform: translateY(7px)translateX(-8px);
  }
}

@media (min-width: 1024px) {
  .isi-dummy-clicker__container > .button .isi-dummy-clicker__cta.emu-button-v2.emu-button {
    transform: translateY(16px)translateX(12px);
  }
}

.isi-dummy-clicker__container > .button .isi-dummy-clicker__cta.emu-button-v2.emu-button:after {
  content: "+";
  color: var(--emu-component-ingredients-title-color-dark);
  font-size: 36px;
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 1024px) {
  .isi-dummy-clicker__container > .button .isi-dummy-clicker__cta.emu-button-v2.emu-button:after {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

.js-content-shown .isi-dummy-clicker__container {
  opacity: 0;
  pointer-events: none;
  transition: none;
}

[data-component="container-isi-v2"].js-content-shown .isi-container {
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease-out;
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

.datepicker {
  width: min-content;
}

.datepicker:not(.active) {
  display: none;
}

.datepicker-dropdown {
  z-index: 20;
  padding-top: 4px;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}

.datepicker-picker {
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
}

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 2px 3px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
}

.datepicker-main {
  flex: auto;
  padding: 2px;
}

.datepicker-footer {
  background-color: #f5f5f5;
  box-shadow: inset 0 1px 1px #0a0a0a1a;
}

.datepicker-title {
  text-align: center;
  background-color: #f5f5f5;
  padding: .375rem .75rem;
  font-weight: 700;
  box-shadow: inset 0 -1px 1px #0a0a0a1a;
}

.datepicker-controls {
  display: flex;
}

.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}

.datepicker-controls .button {
  box-shadow: none;
  cursor: pointer;
  vertical-align: top;
  text-align: center;
  white-space: nowrap;
  color: #363636;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 2.25em;
  margin: 0;
  padding: calc(.375em - 1px) .75em;
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.datepicker-controls .button:focus, .datepicker-controls .button:active {
  outline: none;
}

.datepicker-controls .button:hover {
  color: #363636;
  border-color: #b8b8b8;
}

.datepicker-controls .button:focus {
  color: #363636;
  border-color: #3273dc;
}

.datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 .125em #3273dc40;
}

.datepicker-controls .button:active {
  color: #363636;
  border-color: #474747;
}

.datepicker-controls .button[disabled] {
  cursor: not-allowed;
}

.datepicker-header .datepicker-controls .button {
  border-color: #0000;
  font-weight: bold;
}

.datepicker-header .datepicker-controls .button:hover {
  background-color: #f9f9f9;
}

.datepicker-header .datepicker-controls .button:active {
  background-color: #f2f2f2;
}

.datepicker-footer .datepicker-controls .button {
  border-radius: 2px;
  flex: auto;
  margin: calc(.375rem - 1px) .375rem;
  font-size: .75rem;
}

.datepicker-controls .view-switch {
  flex: auto;
}

.datepicker-controls .prev-button, .datepicker-controls .next-button {
  flex: 0 0 14.2857%;
  padding-left: .375rem;
  padding-right: .375rem;
}

.datepicker-controls .prev-button.disabled, .datepicker-controls .next-button.disabled {
  visibility: hidden;
}

.datepicker-view, .datepicker-grid {
  display: flex;
}

.datepicker-view {
  align-items: stretch;
  width: 15.75rem;
}

.datepicker-grid {
  flex-wrap: wrap;
  flex: auto;
}

.datepicker .days {
  flex-direction: column;
  flex: auto;
  display: flex;
}

.datepicker .days-of-week {
  display: flex;
}

.datepicker .week-numbers {
  flex-direction: column;
  flex: 0 0 9.67742%;
  display: flex;
}

.datepicker .weeks {
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  display: flex;
}

.datepicker span {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.datepicker .dow {
  height: 1.5rem;
  font-size: .875rem;
  font-weight: 700;
}

.datepicker .week {
  color: #b8b8b8;
  flex: auto;
  font-size: .75rem;
}

.datepicker-cell, .datepicker .days .dow {
  flex-basis: 14.2857%;
}

.datepicker-cell {
  height: 2.25rem;
}

.datepicker-cell:not(.day) {
  flex-basis: 25%;
  height: 4.5rem;
}

.datepicker-cell:not(.disabled):hover {
  cursor: pointer;
  background-color: #f9f9f9;
}

.datepicker-cell.focused:not(.selected) {
  background-color: #e9e9e9;
}

.datepicker-cell.selected, .datepicker-cell.selected:hover {
  color: #fff;
  background-color: #3273dc;
  font-weight: 600;
}

.datepicker-cell.disabled {
  color: #dcdcdc;
}

.datepicker-cell.prev:not(.disabled), .datepicker-cell.next:not(.disabled) {
  color: #7a7a7a;
}

.datepicker-cell.prev.selected, .datepicker-cell.next.selected {
  color: #e6e6e6;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  background-color: #f5f5f5;
  border-radius: 0;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #efefef;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e9e9e9;
}

.datepicker-cell.today:not(.selected) {
  background-color: #00d1b2;
}

.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}

.datepicker-cell.today.focused:not(.selected) {
  background-color: #00ccad;
}

.datepicker-cell.range-end:not(.selected), .datepicker-cell.range-start:not(.selected) {
  color: #fff;
  background-color: #b8b8b8;
}

.datepicker-cell.range-end.focused:not(.selected), .datepicker-cell.range-start.focused:not(.selected) {
  background-color: #b3b3b3;
}

.datepicker-cell.range-start:not(.range-end) {
  border-radius: 4px 0 0 4px;
}

.datepicker-cell.range-end:not(.range-start) {
  border-radius: 0 4px 4px 0;
}

.datepicker-cell.range {
  background-color: #dcdcdc;
  border-radius: 0;
}

.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #d7d7d7;
}

.datepicker-cell.range.disabled {
  color: #c6c6c6;
}

.datepicker-cell.range.focused {
  background-color: #d1d1d1;
}

.datepicker-input.in-edit {
  border-color: #276bda;
}

.datepicker-input.in-edit:focus, .datepicker-input.in-edit:active {
  box-shadow: 0 0 .25em .25em #276bda33;
}

.datepicker-container {
  width: 100%;
  position: relative;
}

.datepicker-dropdown {
  padding: var(--emu-common-spacing-none);
  z-index: var(--emu-common-other-z-index-header);
  width: 370px;
  max-width: 100%;
  margin-top: -3px;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
}

@media (max-width: 635px) {
  .datepicker-dropdown {
    width: 100%;
    left: 0 !important;
  }
}

@media (min-width: 1024px) {
  .datepicker-dropdown {
    margin-top: -8px;
  }
}

.datepicker-dropdown .datepicker-picker {
  padding-top: 11px;
  padding-left: var(--emu-common-spacing-none);
  padding-bottom: 19px;
  padding-right: var(--emu-common-spacing-none);
  grid-gap: 13px;
  border-radius: var(--emu-common-border-radius-xs);
  background-color: var(--emu-common-colors-white);
  gap: 13px;
  box-shadow: 0 15px 30px #0003;
}

.datepicker-header {
  padding-top: var(--emu-common-spacing-none);
  padding-right: 24px;
  padding-bottom: var(--emu-common-spacing-none);
  padding-left: 24px;
}

.datepicker-header .datepicker-controls {
  padding: var(--emu-common-spacing-none);
  position: relative;
}

.datepicker-header .datepicker-controls .prev-btn, .datepicker-header .datepicker-controls .next-btn {
  opacity: 0;
}

.datepicker-header .datepicker-controls .button {
  color: var(--emu-common-colors-black);
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: -1px;
  padding: var(--emu-common-spacing-none);
  height: auto;
  line-height: 30px;
}

.datepicker-header .datepicker-controls:after, .datepicker-header .datepicker-controls:before {
  content: "";
  cursor: pointer;
  pointer-events: none;
  background-image: url("resources/images/arrow-prev.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 11px;
  width: 12px;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.datepicker-header .datepicker-controls:after {
  background-image: url("resources/images/arrow-next.png");
  background-position: 100%;
  left: auto;
  right: 0;
}

.datepicker-main {
  padding: var(--emu-common-spacing-none);
}

.datepicker-view {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.datepicker-view .days .datepicker-cell {
  color: var(--emu-common-colors-black);
  line-height: 32px;
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: -1px;
  font-feature-settings: "sups" on;
  height: 32px;
  font-size: 32px;
  position: relative;
}

.datepicker-view .days .datepicker-cell:after {
  content: "";
  background-color: var(--emu-semantic-colors-primary-purple-100);
  opacity: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 6px);
  left: 50%;
  transform: translate(-50%, -50%)scale(.5);
}

.datepicker-view .days .datepicker-cell:hover, .datepicker-view .days .datepicker-cell.focused {
  background-color: var(--emu-common-colors-transparent);
}

@media (hover: hover) and (pointer: fine) {
  .datepicker-view .days .datepicker-cell:hover:after {
    opacity: .4;
    transition: all .2s linear;
    transform: translate(-50%, -50%)scale(.75);
  }
}

.datepicker-view .days .datepicker-cell span {
  z-index: var(--emu-common-other-z-index-layer);
  cursor: pointer;
  position: relative;
}

.datepicker-view .days .datepicker-cell.disabled span {
  opacity: .4;
  cursor: not-allowed;
}

.datepicker-view .days .datepicker-cell.next {
  opacity: .6;
}

.datepicker-view .days .datepicker-cell.selected {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-common-colors-white);
}

.datepicker-view .days .datepicker-cell.selected:after {
  opacity: 1;
  transition: all .2s linear;
  transform: translate(-50%, -50%)scale(1);
}

.datepicker-view .days .days-of-week .dow {
  font-size: 19px;
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: -1px;
  height: 32px;
  margin-bottom: 11px;
}

.datepicker-grid {
  grid-row-gap: 18px;
  row-gap: 18px;
}

.datepicker-footer {
  display: none;
}

@media screen and (min-width: 1024px) and (max-height: 580px) {
  .datepicker-dropdown {
    width: 310px;
  }

  .datepicker-dropdown .datepicker-picker {
    grid-gap: var(--emu-common-spacing-xs);
    gap: var(--emu-common-spacing-xs);
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
  }

  .datepicker-grid {
    grid-row-gap: var(--emu-common-spacing-xs);
    row-gap: var(--emu-common-spacing-xs);
  }

  .datepicker-view .days .datepicker-cell {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
  }

  .datepicker-view .days .datepicker-cell:after {
    width: 40px;
    height: 40px;
  }

  .datepicker-view .days .days-of-week .dow {
    margin-bottom: var(--emu-common-spacing-small);
    height: 28px;
  }
}

@media screen and (min-width: 1024px) and (min-height: 581px) and (max-height: 630px) {
  .datepicker-dropdown {
    width: 350px;
  }

  .datepicker-dropdown .datepicker-picker {
    grid-gap: var(--emu-common-spacing-small);
    gap: var(--emu-common-spacing-small);
  }

  .datepicker-grid {
    grid-row-gap: 12px;
    row-gap: 12px;
  }
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

.embed .aaaem-embed__oembed {
  padding-bottom: 100vh;
}

.embed .aaaem-embed__oembed iframe {
  background-color: var(--emu-semantic-colors-primary-purple-500);
}

.cq-Editable-dom--container .embed .aaaem-embed__oembed {
  max-height: 5000px;
  padding-bottom: 600px;
}

.embed .show-overlay .plyr--paused:after, .embed .show-overlay .plyr--stopped:after {
  content: "";
  opacity: .44;
  background-color: #2f1945;
  position: fixed;
  inset: 0;
}

.embed .center-align-plyr {
  background: var(--emu-common-colors-transparent);
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

@media (max-width: 1024px) {
  .embed .center-align-plyr .plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
    padding: var(--emu-common-spacing-none);
    height: calc(100% + 40px);
    transform: none !important;
  }
}

.embed .center-align-plyr .aaaem-embed__plyr-embeddable {
  width: 100%;
}

.cq-Editable-dom--container .embed .center-align-plyr {
  max-height: 900px;
}

.plyr .plyr__video-embed {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
}

.plyr__volume {
  min-width: var(--emu-common-sizing-none);
  width: auto;
  max-width: 80px;
}

.plyr--video {
  width: 100vw;
  max-height: 100vh;
}

.cq-Editable-dom--container .plyr--video {
  width: auto;
  height: 900px;
}

.plyr--video .plyr__controls {
  padding: var(--emu-common-spacing-small);
  z-index: var(--emu-common-other-z-index-layer);
  cursor: pointer;
  background: #00000080;
  border-radius: 30px;
  width: 330px;
  max-width: 90%;
  margin: auto;
  position: fixed;
  bottom: 196px;
}

@media (min-width: 1024px) {
  .plyr--video .plyr__controls {
    bottom: 118px;
  }
}

.plyr__control.plyr__control--overlaid {
  width: 74px;
  height: 74px;
}

@media (min-width: 1024px) {
  .plyr__control.plyr__control--overlaid {
    width: 148px;
    height: 148px;
  }
}

.plyr__control.plyr__control--overlaid, .plyr__control.plyr__control--overlaid:hover {
  background-color: var(--emu-common-colors-transparent);
}

.plyr__control.plyr__control--overlaid:after {
  content: "";
  background-color: #0000;
  background-image: url("resources/images/play.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr__control.plyr__control--overlaid svg, .plyr__control.plyr__control--overlaid span {
  display: none;
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

.floating-cta {
  grid-gap: 20px;
  bottom: 132px;
  left: var(--emu-common-spacing-none);
  right: var(--emu-common-spacing-none);
  z-index: var(--emu-common-other-z-index-isi);
  flex-direction: column;
  gap: 20px;
  width: 89%;
  margin: auto;
  transition: all .4s;
  display: flex;
  position: fixed;
}

.cq-Editable-dom--container .floating-cta {
  position: static;
}

.cq-Editable-dom--container .floating-cta > .button {
  margin-bottom: 20px;
  opacity: 1 !important;
  display: flex !important;
  transform: none !important;
}

@media (min-width: 1024px) {
  .floating-cta {
    grid-gap: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 91%;
    max-width: 1298px;
    bottom: 140px;
  }
}

@media (min-width: 1280px) {
  .floating-cta {
    bottom: 151px;
  }
}

.floating-cta .button {
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 335px;
  display: flex;
}

@media (min-width: 1024px) {
  .floating-cta .button {
    align-self: initial;
    flex: 0 0 244px;
    transition: all .4s;
  }
}

.floating-cta .button:first-child {
  justify-content: flex-start;
  align-self: flex-start;
  width: auto;
}

@media (min-width: 1024px) {
  .floating-cta .button:first-child {
    align-self: initial;
  }
}

.floating-cta .button a {
  width: 100%;
}

.floating-cta .button .scroll-button {
  background-color: var(--emu-common-colors-transparent);
  padding: var(--emu-common-spacing-none);
  grid-gap: 5px;
  color: var(--emu-semantic-colors-primary-purple-600);
  align-items: center;
  gap: 5px;
}

.floating-cta .button .start-journey-cta.aaaem-button__primary-filled {
  padding-left: 19px;
  padding-right: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .floating-cta .button .start-journey-cta.aaaem-button__primary-filled {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.floating-cta .button .start-journey-cta.aaaem-button__primary-filled > svg {
  margin-right: auto;
}

.floating-cta .button .start-journey-cta.aaaem-button__primary-filled > span {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 10px;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .floating-cta .button .start-journey-cta.aaaem-button__primary-filled > span {
    padding-right: var(--emu-common-spacing-none);
  }
}

.floating-cta .button .start-journey-cta.aaaem-button__primary-filled > span:after {
  content: "";
  left: var(--emu-common-spacing-none);
  z-index: var(--emu-common-other-z-index-layer);
  background-image: url("resources/images/right-arrow.png");
  background-position: 100%;
  background-repeat: no-repeat;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

@media (min-width: 1024px) {
  .floating-cta .button .start-journey-cta.aaaem-button__primary-filled > span:after {
    margin-left: var(--emu-common-spacing-none);
    background-size: 7px;
    top: 1px;
  }
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

footer.experiencefragment {
  z-index: var(--emu-common-other-z-index-isi);
  position: relative;
}

.footer-container {
  z-index: var(--emu-common-other-z-index-header);
  position: relative;
}

.footer-container .footer .footer-bottom {
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  margin-top: var(--emu-common-spacing-none);
  margin-right: auto;
  margin-bottom: var(--emu-common-spacing-none);
  background-color: var(--emu-semantic-colors-primary-purple-500);
  flex-direction: column;
  margin-left: auto;
  padding: 40px 20px 34px;
  display: flex;
}

@media (min-width: 1024px) {
  .footer-container .footer .footer-bottom {
    padding: 72px;
  }
}

.footer-container .footer .footer-bottom--top-container {
  grid-gap: var(--emu-common-spacing-large);
  gap: var(--emu-common-spacing-large);
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  .footer-container .footer .footer-bottom--top-container {
    grid-gap: 62px;
    flex-direction: row;
    gap: 62px;
  }

  .footer-container .footer .footer-bottom--top-container > .text {
    flex: 1;
  }
}

.footer-container .footer .footer-bottom--top-container__text p {
  color: var(--emu-semantic-colors-primary-purple-600);
  margin-top: -6px;
}

.footer-container .footer .footer-bottom--top-container__text p a, .footer-container .footer .footer-bottom--top-container__text p a:active, .footer-container .footer .footer-bottom--top-container__text p a:hover, .footer-container .footer .footer-bottom--top-container__text p a:focus {
  color: var(--emu-semantic-colors-primary-purple-600);
  text-decoration: none;
}

@media (min-width: 1024px) {
  .footer-container .footer .footer-bottom--top-container__text p {
    font-size: 24px;
    line-height: 36px;
  }
}

.footer-container .footer .footer-bottom--top-container__text br {
  display: none;
}

@media (min-width: 1024px) {
  .footer-container .footer .footer-bottom--top-container__text br {
    display: block;
  }
}

.footer-container .footer .footer-bottom--top-container .image img {
  width: 157px;
  height: auto;
}

.footer-container .footer .footer-bottom ul {
  padding: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-large);
  margin-right: var(--emu-common-spacing-none);
  margin-left: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  grid-gap: var(--emu-common-spacing-small);
  gap: var(--emu-common-spacing-small);
  flex-direction: column;
  list-style: none;
  display: flex;
}

@media (min-width: 1024px) {
  .footer-container .footer .footer-bottom ul {
    grid-column-gap: 28px;
    -moz-column-gap: 28px;
    grid-row-gap: 10px;
    flex-flow: wrap;
    gap: 10px 28px;
  }
}

.footer-container .footer .footer-bottom ul li a, .footer-container .footer .footer-bottom ul li a:active, .footer-container .footer .footer-bottom ul li a:hover, .footer-container .footer .footer-bottom ul li a:focus {
  color: var(--emu-semantic-colors-primary-purple-600);
  text-decoration: none;
}

@media (min-width: 1024px) {
  .footer-container .footer .footer-bottom ul li {
    font-size: var(--emu-common-font-sizes-narrow-xl);
    line-height: 32px;
  }
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"] .emu-dropdown-menu__button {
  font-weight: var(--emu-common-font-weight-regular);
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  z-index: var(--emu-common-other-z-index-isi);
  border-style: solid;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  line-height: normal;
  display: flex;
  width: 100% !important;
}

@media (min-width: 1024px) {
  div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"] .emu-dropdown-menu__button {
    max-width: 100%;
  }
}

div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"] .emu-dropdown-menu__button svg {
  width: 12px;
  height: 10px;
  transition: all .2s linear;
}

@media (min-width: 1024px) {
  div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"] .emu-dropdown-menu__button span {
    text-align: center;
    flex: 1;
  }
}

div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"] .emu-dropdown-menu__group {
  border-radius: var(--emu-common-border-radius-small);
  background-color: var(--emu-semantic-colors-primary-purple-500);
  z-index: var(--emu-common-other-z-index-overlay);
  pointer-events: none;
  max-width: 375px;
  height: auto;
  transition: all .2s linear;
  transform: translateY(-40px);
  box-shadow: 0 0 12px 4px #0000001a;
  width: 100% !important;
}

@media (min-width: 1024px) {
  div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"] .emu-dropdown-menu__group {
    max-width: 379px;
  }
}

div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"] .emu-dropdown-menu__item {
  font-weight: var(--emu-common-font-weight-regular);
  color: var(--emu-semantic-colors-primary-purple-600);
  background-color: var(--emu-common-colors-transparent);
}

@media (min-width: 1024px) {
  div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"] .emu-dropdown-menu__item {
    text-align: center;
  }
}

div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"] .emu-dropdown-menu__item[aria-selected="true"], div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"] .emu-dropdown-menu__item:hover {
  background-color: var(--emu-semantic-colors-primary-purple-100);
  color: var(--emu-common-colors-white);
}

div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"]:not(.js-hide-dropdown) .emu-dropdown-menu__button {
  border-radius: var(--emu-component-ingredients-select-input-border-radius-narrow);
  background-color: var(--emu-semantic-colors-primary-purple-100);
  border-color: var(--emu-semantic-colors-primary-purple-100);
  color: var(--emu-common-colors-white);
}

div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"]:not(.js-hide-dropdown) .emu-dropdown-menu__button svg path {
  fill: var(--emu-common-colors-white);
}

div.emu-form-dropdown[data-component="dropdown-menu"][data-emu-type="dropdown"]:not(.js-hide-dropdown) .emu-dropdown-menu__group {
  pointer-events: initial;
  opacity: 1;
  transform: translateY(10px);
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

.header {
  z-index: var(--emu-common-other-z-index-header);
  top: var(--emu-common-spacing-none);
  left: var(--emu-common-spacing-none);
  width: 100%;
  transition: transform .5s;
  position: fixed;
}

.header .cmp-container-header {
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  padding-left: 11px;
  padding-right: 20px;
  display: flex;
}

@media (min-width: 1024px) {
  .header .cmp-container-header {
    margin-top: 57px;
    padding-left: 57px;
  }
}

.header__global-navigation {
  display: none;
}

.header__logo {
  padding-right: var(--emu-common-spacing-medium);
  margin-right: var(--emu-common-spacing-small);
  width: auto;
  transition: all .4s linear;
  position: relative;
}

.header__logo a {
  z-index: var(--emu-common-other-z-index-header);
  position: relative;
}

@media (min-width: 1024px) {
  .header__logo a {
    z-index: var(--emu-common-other-z-index-base);
  }

  .header__logo {
    margin-right: 15px;
  }
}

.header__logo:after {
  content: "";
  border-right: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-violet-100);
  width: 93px;
  height: 37px;
  display: block;
  position: absolute;
  top: 3px;
}

@media (min-width: 1024px) {
  .header__logo:after {
    width: 4px;
    height: 56px;
    top: 5px;
    left: 132px;
  }
}

.header__logo img {
  height: 48px;
}

@media (min-width: 1024px) {
  .header__logo img {
    height: 70px;
  }
}

.header__content {
  flex: 1;
  display: flex;
}

.header__content > .image img {
  height: 39px;
  transition: all .4s linear;
}

@media (min-width: 1024px) {
  .header__content > .image img {
    height: 59px;
  }
}

.header__content > .button {
  z-index: var(--emu-common-other-z-index-layer);
  margin-left: auto;
  margin-right: var(--emu-common-spacing-xs);
  width: 24px;
  position: relative;
}

@media (min-width: 1024px) {
  .header__content > .button {
    display: none;
  }
}

.header__content > .button .header__mobile-trigger.aaaem-button {
  background-color: var(--emu-common-colors-transparent);
  padding: var(--emu-semantic-spacing-none);
  border: none;
  outline: none;
}

.header__content > .button .header__mobile-trigger.aaaem-button.js-toggle-on .chevron {
  top: var(--emu-common-spacing-none);
  left: var(--emu-common-spacing-none);
  background-color: #0000;
  background-image: url("resources/images/close.png");
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

.header__content > .button .header__mobile-trigger.aaaem-button.js-toggle-on .chevron svg, .header__content > .button .header__mobile-trigger.aaaem-button.js-toggle-on svg, .header__sidenav-container {
  display: none;
}

@media (min-width: 1024px) {
  .header__sidenav-container {
    left: var(--emu-common-spacing-none);
    opacity: 1;
    pointer-events: initial;
    padding-bottom: 180px;
    transition: all .4s ease-in-out;
    display: block;
    position: fixed;
    top: 50%;
    left: -50px;
    transform: translateX(84px)translateY(-50%);
  }
}

@media (min-width: 1080px) {
  .header__sidenav-container {
    left: -34px;
  }
}

@media (min-height: 1440px) {
  .header__sidenav-container {
    top: calc(50% - 310px);
  }
}

@media (min-width: 1280px) {
  .header__sidenav-container {
    left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1440px) {
  .header__sidenav-container {
    transform: translateX(calc(50vw - 720px))translateX(78px)translateY(-50%);
  }
}

.header__sidenav-container ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
  flex-direction: column;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .header__sidenav-container ul {
    max-width: 310px;
  }
}

.header__sidenav-container ul li:after, .header__sidenav-container ul a:after {
  content: "";
  top: 12px;
  left: var(--emu-common-spacing-none);
  pointer-events: none;
  background: #2e184480;
  width: 4px;
  height: calc(100% - 12px);
  display: flex;
  position: absolute;
}

.header__sidenav-container ul li:before, .header__sidenav-container ul a:before {
  content: "";
  border: 2.5px solid #2e184480;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  display: flex;
  position: absolute;
  top: 0;
  left: -4px;
}

.header__sidenav-container ul li:after {
  height: var(--emu-common-sizing-none);
  background-color: var(--emu-common-colors-transparent);
  transition: height .2s;
}

.header__sidenav-container ul li:before {
  height: var(--emu-common-sizing-none);
  background-color: var(--emu-common-colors-transparent);
  border: none;
  transition: all .2s .2s;
}

.header__sidenav-container ul li.no-transition:before, .header__sidenav-container ul li.no-transition:after {
  transition: none;
}

.header__sidenav-container ul li.active a {
  color: var(--emu-semantic-colors-primary-violet-100);
}

.header__sidenav-container ul li.active:before {
  background-color: var(--emu-semantic-colors-primary-violet-100);
  border-color: var(--emu-semantic-colors-primary-violet-100);
  height: 12px;
}

.header__sidenav-container ul li.prev-active:after {
  background-color: var(--emu-semantic-colors-primary-violet-100);
  opacity: 1;
  height: calc(100% - 12px);
}

.header__sidenav-container ul li {
  align-items: end;
  padding-bottom: 47.5px;
  padding-left: 26px;
  list-style-type: none;
  display: flex;
  position: relative;
}

.header__sidenav-container ul li:last-child {
  padding-bottom: var(--emu-common-spacing-none);
}

.header__sidenav-container ul li a {
  color: #2e184480;
  font-size: 14px;
  font-weight: var(--emu-common-font-weight-regular);
  line-height: 16px;
  text-decoration: none;
}

.header__sidenav-container ul li:last-child:after, .header__sidenav-container ul li:last-child a:after {
  display: none;
}

header .header__eyebrow {
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  right: var(--emu-common-spacing-none);
  left: var(--emu-common-spacing-none);
  top: var(--emu-common-spacing-none);
  background-color: var(--emu-semantic-colors-primary-violet-100);
  width: 100%;
  margin: auto;
  transition: all .4s linear;
  display: none;
  position: absolute;
}

@media (min-width: 1024px) {
  header .header__eyebrow {
    display: block;
  }
}

header .header__eyebrow .header__eyebrow-inner {
  background-color: inherit;
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid {
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

@media (min-width: 1280px) {
  header .header__eyebrow .header__eyebrow-inner > .aem-Grid {
    grid-gap: 40px;
    gap: 40px;
  }
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .button {
  width: auto;
  display: flex;
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .button .header__nav-submenu-button-desktop {
  font-size: 14px;
  font-weight: var(--emu-common-font-weight-regular);
  padding: var(--emu-common-spacing-none);
  background-color: #0000;
  border: none;
  line-height: normal;
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .button .header__nav-submenu-button-desktop:hover, header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .button .header__nav-submenu-button-desktop:active {
  color: var(--emu-common-colors-white);
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .button .header__nav-submenu-button-desktop svg {
  fill: var(--emu-common-colors-white);
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .text {
  width: auto;
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .text ul {
  grid-gap: 10px;
  justify-content: center;
  gap: 10px;
  margin-top: 9px;
  margin-bottom: 9px;
  list-style: none;
  display: flex;
}

@media (min-width: 1280px) {
  header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .text ul {
    grid-gap: 40px;
    gap: 40px;
  }
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .text ul li a {
  color: var(--emu-common-colors-white);
  font-size: 14px;
  font-weight: var(--emu-common-font-weight-regular);
  line-height: normal;
  text-decoration: none;
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .container {
  width: auto;
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .container .header__eyebrow-social {
  grid-gap: var(--emu-common-spacing-small);
  gap: var(--emu-common-spacing-small);
  background: none;
  margin-left: 20px;
  display: flex;
}

@media (min-width: 1280px) {
  header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .container .header__eyebrow-social {
    grid-gap: 12px;
    gap: 12px;
    margin-left: 40px;
  }
}

header .header__eyebrow .header__eyebrow-inner > .aem-Grid > .container img {
  width: 100%;
  height: 12px;
}

.has-mobile-menu-open {
  overflow: hidden;
}

@media (min-width: 1024px) {
  .has-mobile-menu-open {
    overflow: auto;
  }
}

.has-mobile-menu-open .header__logo a {
  position: static;
}

@media (min-width: 1024px) {
  .has-mobile-menu-open .header__logo a {
    position: relative;
  }
}

@media screen and (min-width: 1024px) and (max-height: 750px) {
  .header__sidenav-container {
    padding-bottom: 114px;
  }
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

.header__nav-container {
  background: none;
  padding-left: 40px;
  padding-right: 40px;
}

.header__nav-wrapper {
  background-color: var(--emu-semantic-colors-primary-violet-100);
  top: var(--emu-common-spacing-none);
  left: var(--emu-common-spacing-none);
  width: 100%;
  height: 100vh;
  max-height: 5000px;
  transition: all .2s ease-in-out;
  position: absolute;
  overflow-y: auto;
  transform: translate(100%);
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper {
  position: static;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav {
  background-color: #0000;
}

@media (min-width: 768px) {
  .header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav ul {
    flex-direction: column;
  }
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav ul > li > a {
  border: var(--emu-common-border-width-none);
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-white);
  font-size: 18px;
  font-weight: var(--emu-common-font-weight-regular);
  padding-top: 18px;
  padding-bottom: 18px;
  line-height: 24px;
  position: relative;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav ul > li > a:hover {
  background-color: #0000;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav ul > li > a:after {
  content: "";
  right: var(--emu-common-spacing-none);
  top: var(--emu-common-spacing-none);
  bottom: var(--emu-common-spacing-none);
  background-image: url("resources/images/right-arrow.png");
  background-repeat: no-repeat;
  width: 9px;
  height: 16px;
  margin: auto;
  display: block;
  position: absolute;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav ul > li > a sup {
  font-size: 50%;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-primary {
  padding-top: 40px;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-secondary:before {
  content: "";
  border-top: var(--emu-common-border-width-thin) solid var(--emu-common-colors-white);
  width: 100%;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 12px;
  display: block;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-secondary > ul {
  padding-top: 6px;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-secondary > ul > li a {
  padding-top: 6px;
  padding-bottom: 6px;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-secondary > ul > li a:after {
  display: none;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-submenu {
  max-height: 0;
  transition: all .4s ease-in-out;
  display: block;
  overflow: hidden;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-submenu > ul {
  padding-top: 12px;
  padding-left: 21px;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-submenu > ul > li {
  padding-bottom: 17px;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-submenu > ul > li:last-child {
  padding-bottom: var(--emu-common-spacing-none);
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-submenu > ul > li a {
  padding-bottom: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
  line-height: 14px;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-submenu > ul > li a sup {
  font-size: 60%;
  position: relative;
  top: 1px;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-submenu > ul > li a .nav-item {
  padding-left: var(--emu-common-spacing-none);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  padding-top: var(--emu-common-spacing-xxs);
  padding-bottom: var(--emu-common-spacing-none);
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper nav.header__nav-submenu > ul > li a:after {
  display: none;
}

.header__nav-wrapper .navigationlist .emu-navigation__content-wrapper.js-toggle-on .header__nav-submenu {
  max-height: 1000px;
}

.header__nav-wrapper.js-toggle-on {
  transform: translate(0);
}

@media (min-width: 1024px) {
  .header__nav-wrapper.js-toggle-on {
    transform: translate(100%);
  }
}

.header__nav-social-container {
  grid-gap: 20px;
  background: none;
  align-items: center;
  gap: 20px;
  padding-top: 54px;
  padding-bottom: 48px;
  display: flex;
  position: relative;
}

.header__nav-social-container:before {
  content: "";
  border-top: var(--emu-common-border-width-thin) solid var(--emu-common-colors-white);
  padding-bottom: var(--emu-common-spacing-medium);
  left: var(--emu-common-spacing-none);
  right: var(--emu-common-spacing-none);
  width: 100%;
  margin-top: -60px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: absolute;
}

.header__nav-social-container img {
  max-width: var(--emu-common-sizing-small);
  height: var(--emu-common-sizing-small);
  -o-object-fit: contain;
  object-fit: contain;
}

.header__nav-submenu-button.aaaem-button:not(a) {
  text-align: left;
  text-transform: capitalize;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-white);
  background-color: #0000;
  border: none;
  flex-direction: row;
  width: 100%;
  font-size: 18px;
}

.header__nav-submenu-button.aaaem-button:not(a) .chevron {
  right: var(--emu-common-spacing-none);
  top: var(--emu-common-spacing-none);
  bottom: var(--emu-common-spacing-none);
  align-items: center;
  width: 23px;
  margin: auto;
  display: flex;
  position: absolute;
}

.header__nav-submenu-button.aaaem-button:not(a) .chevron svg {
  fill: var(--emu-common-colors-white);
  width: 100%;
}

.header__nav-submenu-button.aaaem-button:not(a) .chevron svg path {
  stroke: currentColor;
  stroke-width: 1px;
}

.header__nav-logo-container {
  background: none;
  align-items: center;
  padding-top: 15px;
  padding-left: 11px;
  display: flex;
}

.header__nav-logo-container > .image:first-child {
  margin-right: 24px;
  position: relative;
}

.header__nav-logo-container > .image:first-child:after {
  content: "";
  border-right: var(--emu-common-border-width-thin) solid var(--emu-common-colors-white);
  width: 4px;
  height: 37px;
  display: block;
  position: absolute;
  top: 5px;
  left: 90px;
}

@media (min-width: 1024px) {
  .header__nav-logo-container > .image:first-child:after {
    width: 132px;
    height: 56px;
    top: 5px;
  }
}

.header__nav-logo-container > .image:first-child img {
  height: 48px;
}

.header__nav-logo-container > .image:last-child img {
  height: 39px;
}

.header__nav-submenu-container-desktop {
  left: var(--emu-common-spacing-none);
  right: var(--emu-common-spacing-none);
  top: var(--emu-common-spacing-none);
  background: none;
  background-color: var(--emu-semantic-colors-primary-violet-100);
  width: 100%;
  display: none;
  position: fixed;
}

@media (min-width: 1024px) {
  .header__nav-submenu-container-desktop.js-toggle-on {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin: auto;
    display: block;
  }
}

.header__nav-submenu-container-desktop .text ul {
  grid-row-gap: var(--emu-common-spacing-medium);
  row-gap: var(--emu-common-spacing-medium);
  margin-bottom: var(--emu-common-spacing-small);
  margin-top: var(--emu-common-spacing-small);
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  display: flex;
}

.header__nav-submenu-container-desktop .text ul li {
  border-right: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-purple-500);
  flex: 0 0 25%;
  justify-content: center;
  align-items: center;
  min-height: 34px;
  display: flex;
}

.header__nav-submenu-container-desktop .text ul li:nth-child(4), .header__nav-submenu-container-desktop .text ul li:last-child {
  border-right: none;
}

.header__nav-submenu-container-desktop .text ul li a {
  color: var(--emu-common-colors-white);
  text-align: center;
  font-size: 14px;
  font-weight: var(--emu-common-font-weight-regular);
  line-height: 14px;
  text-decoration: none;
  display: block;
}

.header__nav-submenu-container-desktop .text ul li a sup {
  font-size: 65%;
  top: -2px;
}

.header__nav-submenu-container-desktop .text ul li a .emphasis {
  font-size: 10px;
}

.header__nav-submenu-container-desktop .image {
  top: var(--emu-common-spacing-small);
  right: var(--emu-common-spacing-small);
  position: absolute;
}

.header__nav-submenu-container-desktop .image img {
  cursor: pointer;
  width: 12px;
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

[data-component="modal"].emu-modal.modal--has-video .modal-content, [data-component="modal"].emu-modal.modal--has-video.modal--fullscreen .modal-content {
  padding: var(--emu-common-spacing-none);
}

[data-component="modal"].emu-modal.modal--has-video .modal-content {
  background-color: var(--emu-common-colors-transparent);
}

[data-component="modal"].emu-modal.modal--has-video .modal-content .plyr__controls {
  bottom: 10px;
}

@media (max-width: 1023px) {
  [data-component="modal"].emu-modal.modal--has-video .close {
    background-image: url("resources/images/modal-close-white.png");
  }
}

[data-component="modal"].emu-modal.modal--has-video .plyr .plyr__video-embed {
  aspect-ratio: auto !important;
}

[data-component="modal"].emu-modal.modal--has-video .plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding: var(--emu-common-spacing-none);
  height: 100vh;
  transform: none !important;
}

.cq-Editable-dom--container [data-component="modal"].emu-modal.modal--has-video .plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  height: 900px;
}

[data-component="modal"].emu-modal.modal--has-video .emu-embed, [data-component="modal"].emu-modal.modal--has-video .plyr--video, [data-component="modal"].emu-modal.modal--has-video .plyr__video-wrapper.plyr__video-embed {
  background-color: var(--emu-common-colors-transparent);
}

[data-component="modal"].emu-modal.modal--fullscreen .modal-wrapper {
  padding: var(--emu-common-spacing-none);
}

[data-component="modal"].emu-modal.modal--fullscreen .modal-content {
  border-radius: var(--emu-common-border-radius-none);
  max-width: 100%;
  min-height: 100%;
  padding: 40px 20px 201px;
  top: 0;
}

@media (min-width: 1024px) {
  [data-component="modal"].emu-modal.modal--fullscreen .modal-content {
    padding-bottom: 130px;
    padding-left: 72px;
    padding-right: 72px;
  }
}

[data-component="modal"].emu-modal.modal--fullscreen .close {
  z-index: var(--emu-common-other-z-index-modal);
  position: fixed;
}

[data-component="modal"].emu-modal .modal-wrapper {
  opacity: 0;
  visibility: hidden;
  justify-content: center;
  align-items: safe center;
  padding-left: 20px;
  padding-right: 20px;
  transition: all .4s ease-out;
  display: flex;
  overflow: auto;
}

[data-component="modal"].emu-modal .modal-wrapper.is-open {
  opacity: 1;
  visibility: visible;
  transition: all .4s ease-in;
}

[data-component="modal"].emu-modal .modal-wrapper.is-open .close {
  visibility: visible;
}

[data-component="modal"].emu-modal .modal-wrapper.is-open .modal-content {
  opacity: 1;
  transition: all .4s ease-in;
  top: 0;
}

[data-component="modal"].emu-modal .modal-content {
  margin-top: auto;
  margin-right: var(--emu-common-spacing-none);
  margin-bottom: auto;
  margin-left: var(--emu-common-spacing-none);
  opacity: 0;
  border-radius: 12px;
  width: 100%;
  transition: all .4s ease-out;
  position: relative;
  top: 50px;
}

[data-component="modal"].emu-modal .close {
  width: var(--emu-common-sizing-medium);
  height: var(--emu-common-sizing-medium);
  visibility: hidden;
  opacity: 1;
  background-image: url("resources/images/modal-close.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  top: 4px;
  right: 4px;
}

@media (min-width: 1024px) {
  [data-component="modal"].emu-modal .close {
    top: 14px;
    right: 14px;
  }
}

[data-component="modal"].emu-modal .close span {
  display: none;
}

[data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content {
  top: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
  border-radius: var(--emu-common-spacing-none);
  max-width: var(--emu-semantic-sizing-breakpoints-small);
  margin-top: 14vh;
}

@media (min-width: 768px) {
  [data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content {
    max-width: 622px;
    margin-top: 100px;
  }

  [data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content .mobile-view {
    display: none;
  }
}

[data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content .btx-day-modal__content-container .btx-day-modal__title-container {
  padding-left: var(--emu-component-layers-modal-body-padding-left-narrow);
  padding-right: var(--emu-component-layers-modal-body-padding-right-narrow);
  padding-top: var(--emu-component-layers-modal-body-padding-left-narrow);
  padding-bottom: var(--emu-component-layers-modal-body-padding-right-narrow);
  text-align: center;
}

[data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content .btx-day-modal__content-container .btx-day-modal__title-container .title {
  color: var(--emu-semantic-colors-primary-violet-200);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  padding-top: 5px;
  padding-bottom: 24px;
  line-height: 28px;
}

@media (min-width: 768px) {
  [data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content .btx-day-modal__content-container .btx-day-modal__title-container .title {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

[data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content .btx-day-modal__content-container .btx-day-modal__title-container .sub-title__note {
  color: var(--emu-semantic-colors-primary-violet-200);
  font-weight: var(--emu-common-font-weight-regular);
  padding-top: 25px;
  font-size: 8px;
  line-height: 10px;
}

@media (min-width: 768px) {
  [data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content .btx-day-modal__content-container .btx-day-modal__title-container .sub-title__note {
    padding-top: 50px;
  }
}

[data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content .btx-day-modal__content-container .btx-day-modal__title-container a {
  color: var(--emu-semantic-colors-primary-violet-200);
}

[data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content .btx-day-modal__button {
  border-color: var(--emu-semantic-colors-primary-violet-200);
  border-width: var(--emu-semantic-border-width-medium);
  padding: 5px 16px;
  text-decoration: none;
}

[data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content .btx-day-modal__button > span {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  padding-left: 16px;
  padding-right: 16px;
  line-height: 29px;
}

[data-component="modal"].emu-modal.modal-lo3-btx-day .modal-content .close {
  background-size: 12px;
}

.cq-Editable-dom--container [data-component="modal"] .modal-wrapper, .cq-Editable-dom--container [data-component="modal"] .modal-content {
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: initial !important;
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

.aaaem-tabs {
  position: relative;
}

.aaaem-tabs.has-single-tab > .aaaem-tabs__tablist {
  display: none;
}

.aaaem-tabs__tablist {
  background-color: var(--emu-semantic-colors-primary-purple-500);
  --tab-width: 200px;
  --active-tab-position: 0;
  border-radius: 27px;
  margin-top: 29px;
  position: relative;
}

@media (min-width: 1024px) {
  .aaaem-tabs__tablist {
    margin-top: 34px;
  }
}

.aaaem-tabs__tablist:before {
  content: "";
  border: 3px solid var(--emu-semantic-colors-primary-purple-500);
  top: 0;
  left: calc(var(--active-tab-position) * var(--tab-width));
  width: var(--tab-width);
  background-color: var(--emu-common-colors-white);
  z-index: var(--emu-common-other-z-index-base);
  border-radius: 27px;
  height: 100%;
  transition: all .2s linear;
  position: absolute;
}

.aaaem-tabs__tab {
  z-index: var(--emu-common-other-z-index-layer);
  flex: 1;
  position: relative;
}

.aaaem-tabs__tab a {
  font-size: 18px;
  font-weight: var(--emu-common-font-weight-regular);
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 48px;
  line-height: 24px;
  transition: all .2s linear;
  display: flex;
}

.aaaem-tabs__tab a[aria-selected="true"] {
  transition-delay: .1s;
}

.aaaem-tabs__tabpanels {
  position: relative;
}

.aaaem-tabs__tabpanel {
  transition: opacity .2s ease-in .2s;
}

.aaaem-tabs__tabpanel[hidden] {
  width: 100%;
  transition: opacity .2s ease-out;
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 1024px) {
  .aaaem-tabs__tabpanel[hidden] {
    opacity: 0;
    pointer-events: none;
    display: block;
  }
}

.aaaem-tabs__tabpanel[hidden] .aaaem-tabs__tablist {
  display: none;
}

@media (min-width: 1024px) {
  .aaaem-tabs__tabpanel[hidden] .aaaem-tabs__tablist {
    opacity: 0;
  }
}

.custom-tabs.has-single-tab > .tabs .aaaem-tabs > .aaaem-tabs__tablist, .custom-tabs.has-single-tab > .container > .custom-tabs__select-wrapper {
  display: none;
}

@media (max-width: 1023px) {
  .custom-tabs > .tabs .aaaem-tabs__tablist {
    display: none;
  }
}

@media (min-width: 1024px) {
  .custom-tabs > .container .custom-tabs__select-wrapper {
    display: none;
  }
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

@keyframes gradientDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 0 40%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes transitionFromDark {
  0% {
    background-position: 0 20%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes gradientLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 80% 80%;
  }
}

@keyframes transitionFromLight {
  0% {
    background-position: 80% 80%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveBigHoopsDesktop {
  0%, 100% {
    top: 179%;
    left: 113%;
    transform: translate(-118%, -188%);
  }

  50% {
    top: 145%;
    left: 76%;
    transform: translate(-78%, -153%);
  }
}

@keyframes moveBigHoopsMobile {
  0%, 100% {
    top: 145%;
    left: 51%;
    transform: translate(-51%, -151%);
  }

  50% {
    top: 98%;
    left: 25%;
    transform: translate(-23%, -101%);
  }
}

@keyframes moveSmallHoopsDesktop {
  0%, 100% {
    top: -6%;
    left: 77%;
    transform: translate(-79%, 11%);
  }

  50% {
    top: 9%;
    left: 52%;
    transform: translate(-53%, -4%);
  }

  75% {
    top: 1%;
    left: 71%;
    transform: translate(-73%, 3%);
  }
}

@keyframes moveSmallHoopsMobile {
  0%, 100% {
    top: -16%;
    left: -22%;
    transform: translate(48px, 38px);
  }

  50% {
    top: 10%;
    left: 22%;
    transform: translate(-32px, -10px);
  }
}

[data-component="text"], [data-component="title"] {
  color: var(--emu-common-colors-white);
  font: var(--emu-semantic-typography-narrow-body-regular);
}

[data-component="text"] p, [data-component="text"] li, [data-component="title"] p, [data-component="title"] li {
  margin: var(--emu-common-spacing-none);
}

[data-component="text"] b sup, [data-component="title"] b sup {
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 1024px) {
  [data-component="text"] b sup, [data-component="title"] b sup {
    font-size: 50%;
  }
}

[data-component="text"] sup, [data-component="title"] sup {
  vertical-align: top;
  font-family: inherit;
  font-size: 60%;
  font-weight: 300;
  position: relative;
  top: -2px;
}

[data-component="text"] .emphasis, [data-component="title"] .emphasis {
  display: inline-block;
}

[data-component="text"] h2 sup, [data-component="title"] h2 sup {
  font-weight: var(--emu-common-font-weight-light);
}

.break--mobile br {
  display: block;
}

@media (min-width: 1024px) {
  .break--mobile br {
    display: none;
  }
}

.break--desktop br {
  display: none;
}

@media (min-width: 1024px) {
  .break--desktop br {
    display: block;
  }
}

/*# sourceMappingURL=main.css.map */

html {
  -webkit-text-size-adjust: 100%;
}

// Commenting out, makes fonts less bold
// :root {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-rendering: optimizeLegibility;
// }

html,
body {
  margin: 0 auto;
  padding: 0;
  font: var(--emu-semantic-typography-narrow-body-regular);
  text-align: left;
  scroll-behavior: smooth;

  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }

  .experiencefragment {
    padding: 0;
  }
}

body {
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(
    135deg,
    var(--emu-semantic-colors-primary-violet-200) 0%,
    var(--emu-semantic-colors-primary-violet-200) 17.56%,
    var(--emu-semantic-colors-primary-purple-100) 41.52%,
    var(--emu-semantic-colors-primary-purple-500) 62.36%,
    var(--emu-semantic-colors-primary-purple-500) 74.86%,
    #f1ede9 87.36%,
    #f1ede9 100%
  );
  background-size: 400% 400%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  max-width: var(
    --emu-semantic-sizing-breakpoints-xx-large
  ); //to match figma design
  margin: 0 auto;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

.floating-cta {
  flex-direction: column;
  gap: 20px;
  margin: auto;
  display: flex;
  position: fixed;
  bottom: 132px; //to match figma design
  left: var(--emu-common-spacing-none);
  right: var(--emu-common-spacing-none);
  width: 89%;
  z-index: var(--emu-common-other-z-index-isi);
  transition: all 0.4s;

  @include aem-editor-view {
    position: static;

    > .button {
      margin-bottom: 20px;
      display: flex !important;
      opacity: 1 !important;
      transform: none !important;
    }
  }

  @include mq('large') {
    bottom: 140px; //to match figma design
    flex-direction: row;
    width: 91%;
    gap: 20px; //to match figma design
    justify-content: space-between;
    align-items: center;
    max-width: 1298px; //to match figma design
  }

  @include mq('x-large') {
    bottom: 151px; //to match figma design
  }

  .button {
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 335px;
    align-self: center;

    @include mq('large') {
      flex: 0 0 244px; //to match figma design
      align-self: initial;
      transition: all 0.4s;
    }

    &:first-child {
      width: auto;
      justify-content: flex-start;
      align-self: flex-start;

      @include mq('large') {
        align-self: initial;
      }
    }

    a {
      width: 100%;
    }

    .scroll-button {
      background-color: var(--emu-common-colors-transparent);
      padding: var(--emu-common-spacing-none);
      gap: 5px;
      color: var(--emu-semantic-colors-primary-violet-100);
      align-items: center;
      color: var(--emu-semantic-colors-primary-purple-600);
    }

    .start-journey-cta.aaaem-button__primary-filled {
      padding-left: 19px; //to match figma design
      padding-right: var(--emu-common-spacing-medium);

      @include mq('large') {
        padding-left: 18px; //to match figma design
        padding-right: 18px; //to match figma design
      }

      > svg {
        margin-right: auto;
      }

      > span {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 10px; //to match figma design

        @include mq('large') {
          padding-right: var(--emu-common-spacing-none);
        }

        &::after {
          content: '';
          width: 100%;
          height: 100%;
          background-image: url('../../assets/images/right-arrow.png');
          background-repeat: no-repeat;
          display: flex;
          background-position: center right;
          position: absolute;
          left: var(--emu-common-spacing-none);
          align-items: center;
          justify-content: flex-end;
          z-index: var(--emu-common-other-z-index-layer);

          @include mq('large') {
            background-size: 7px;
            top: 1px;
            margin-left: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }
}

.first-look-intro {
  .content-box {
    padding: var(--emu-common-spacing-none);
    display: block;
    max-width: none;

    &__background {
      @include aem-editor-view {
        display: block !important;
      }
    }
  }

  &__content {
    background-color: #e0e0e6; // used only once
    position: relative;
    padding-top: 14px;
    padding-bottom: 14px;
    transform: translateY(102px);

    @include mq('large') {
      padding-top: 69px;
      padding-bottom: 62px;
      transform: translateY(65px);
    }

    // media query needed to make sure that the content is not overlapping in small tabs
    @media (min-height: 700px) and (min-width: 1024px) {
      transform: translateY(198px);
    }

    &-inner {
      display: flex;
      justify-content: center;

      @include mq('large') {
        justify-content: flex-end;
        margin-right: 72px;
      }
    }

    h3 {
      font-size: 12.734px; // from figma
      line-height: normal;
      letter-spacing: -0.245px; // from figma
      text-align: left;

      @include mq('large') {
        font-size: 26px;
        letter-spacing: -0.5px;
      }

      &:nth-child(3) {
        text-align: right;
      }
    }

    h2 {
      font-size: 48.977px; // from figma
      line-height: normal;
      letter-spacing: -0.49px; // from figma

      @include mq('large') {
        font-size: 80px;
        letter-spacing: -1px;
      }

      @include mq('xx-large') {
        font-size: 100px;
      }
    }
  }

  &__img {
    position: absolute;
    bottom: 0;
    left: 0;

    @media (orientation: portrait) and (min-width: 768px) and (max-width: 1440px) {
      width: 100%;
    }

    @include aem-editor-view {
      position: static;
    }

    img {
      height: 584px; // as per figma
      object-fit: cover;

      @include mq('large') {
        margin-left: 140px;
        max-width: 44vw;
        object-fit: contain;
        object-position: bottom;
      }

      @include mq('x-large') {
        height: calc(100vh - 5px);
        max-width: none;
        max-height: 900px;
        margin-left: 173px;
      }

      @media (orientation: portrait) and (min-width: 768px) and (max-width: 1440px) {
        max-width: none;
        max-height: calc(100vh - 330px);
        height: auto;
        width: 100%;
        object-fit: contain;
        object-position: center;
        margin: var(--emu-common-spacing-none);
      }

      @include aem-editor-view {
        height: auto;
        margin: var(--emu-common-spacing-none);
      }
    }
  }

  .patient-disclaimer {
    p {
      @include mq('large') {
        text-align: right;
      }
    }
  }
}

// default transition states
.first-look-intro {
  &__content {
    h3 {
      transform: translateX(100%);

      &:nth-child(3) {
        transform: translateX(-100%);
      }
    }

    h2 {
      transform: translateY(30px);
    }

    h2,
    h3 {
      transition: all 0.4s ease-in-out 0.4s;
      opacity: 0;

      @include aem-editor-view {
        opacity: 1;
        transform: none;
      }
    }
  }

  &__img {
    transition: all 0.4s linear;
    transform: translate(-40px, 0);
  }
}

// active animation
.lo3-section-container--active {
  .first-look-intro {
    &__content {
      h2,
      h3 {
        transform: translate(0, 0);
        opacity: 1;
      }
    }

    &__img {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}

// prev-active-animation-state
.lo3-section-container--prev-active {
  .first-look-intro {
    &__content {
      transform: translateY(-40px);
      transition: all 0.8s linear;

      h2,
      h3 {
        transform: translate(0, 0);
      }
    }

    &__img {
      transform: translate(0, -40px);
      transition: all 0.6s linear 0.4s;
    }
  }
}

.gallery {
  &__modal {
    &[data-component='modal'] {
      &.modal--fullscreen {
        .modal-wrapper {
          .modal-content {
            padding-top: var(--emu-common-spacing-none);
            padding-left: 9px;
            padding-right: 9px;

            @include mq('large') {
              padding-top: 10px;
            }
          }
        }
      }
    }
  }

  &__inner-container {
    max-width: 857px;
    margin-left: auto;
    margin-right: auto;

    > .container:last-child {
      .gallery__section {
        > .container:last-child {
          .gallery__subsection {
            margin-bottom: 14px;

            @include mq('large') {
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }

  &__section {
    margin-bottom: -9px; // needed to negate the margin added by gallery__subsection, to match the design
    margin-top: 20px;

    @include mq('large') {
      margin-bottom: -5px;
    }

    &-title[data-component='title'] {
      .cmp-title__text {
        color: var(--emu-semantic-colors-primary-purple-600);
        font-size: 40px;
        line-height: normal;
        letter-spacing: -1px;

        @include mq('large') {
          font-size: 48px;
        }
      }
    }
  }

  &__subsection {
    margin-bottom: 29px;

    @include mq('large') {
      margin-bottom: 35px;
    }

    &-pretitle[data-component='title'] {
      .cmp-title__text {
        color: var(--emu-common-colors-black);
        font-size: 14px;
        font-weight: var(--emu-common-font-weight-bold);
        line-height: normal;
        margin-top: 15px;

        @include mq('large') {
          font-size: 16px;
          margin-bottom: 5px;
          margin-top: 20px;
        }
      }
    }

    &-title[data-component='title'] {
      @include mq('large') {
        position: relative;

        &::after {
          content: '';
          width: 100%;
          height: var(--emu-common-border-width-thin);
          background-color: var(--emu-semantic-colors-primary-purple-500);
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          z-index: var(--emu-common-other-z-index-base);
        }
      }

      .cmp-title__text {
        color: var(--emu-semantic-colors-primary-violet-200);
        font-size: 28px;
        line-height: normal;
        letter-spacing: -1px;

        @include mq('large') {
          display: inline-block;
          padding-left: 30px;
          padding-right: 30px;
          position: relative;
          z-index: var(--emu-common-other-z-index-layer);
          background-color: var(--emu-common-colors-white);
        }
      }
    }
  }

  &__treatment {
    &-wrapper { 
      margin-top: 10px;

      @include mq('large') {
        margin-top: 14px;
        margin-bottom: 18px;
      }
    }

    &-img {
      img {
        width: 100%;
      }
    }

    &-title[data-component='title'] {
      .cmp-title__text {
        color: var(--emu-common-colors-black);
        font-size: 16px;
        font-weight: var(--emu-common-font-weight-regular);
        line-height: normal;
        margin-top: 20px;
        margin-bottom: 5px;

        @include mq('large') {
          font-size: 24px;
          margin-top: 35px;
        }
      }
    }

    &-section {
      &-wrapper {
        gap: 9px;
        display: flex;

        @include mq('large') {
          gap: 25px;
        }

        > .container {
          width: 50%;
        }
      }

      &-title[data-component='title'] {
        .cmp-title__text {
          color: var(--emu-common-colors-black);
          font-size: 15px;
          line-height: normal;
          font-weight: var(--emu-common-font-weight-light);
          margin-bottom: var(--emu-common-spacing-xs);

          @include mq('large') {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  &__close-cta {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 375px;

    @include mq('large') {
      width: auto;
    }

    &.aaaem-button {
      padding-top: 12px;
      padding-bottom: 12px;

      @include mq('large') {
        padding-right: 25px;
        padding-left: 22px;
      }
    }

    &-container {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      padding-top: 20px;
      padding-bottom: calc(113px + 20px); // Height of the ISI plus 20px spacing
      background-color: var(--emu-common-colors-white);
      width: 100%;
      z-index: var(--emu-common-other-z-index-layer);

      @include mq('large') {
        text-align: right;
        max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
        padding: var(--emu-common-spacing-none);
        bottom: 150px;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--emu-common-colors-transparent);
      }

      > .button {
        width: 89%;
        margin-left: auto;
        margin-right: auto;
        max-width: 375px;

        @include mq('large') {
          width: 91%;
          max-width: 1298px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

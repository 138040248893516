.start-journey {
  &__planner {
    .content-box {
      padding-top: 63px;
      justify-content: flex-start;

      @include mq('large') {
        padding-top: 132px;
      }

      &__title {
        @include mq('large') {
          margin-bottom: 50px;
        }
      }

      &__text-small {
        text-align: center;
        max-width: 317px;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          max-width: none;
        }

        p {
          letter-spacing: normal;

          @include mq('large') {
            margin-bottom: 18px;
          }
        }
      }

      &__button.cmp-button {
        margin-top: 20px;
        padding-right: 18px;

        svg {
          margin-right: var(--emu-common-spacing-none);
        }

        @include mq('large') {
          margin-top: 22px;
        }
      }
    }

    &-text[data-component='text'] {
      text-align: center;
      margin-bottom: 10px;

      @include mq('large') {
        text-align: left;
        margin-bottom: 14px;
      }

      p {
        @include mq('large') {
          line-height: 32px;
        }
      }

      sup {
        top: -4px;
      }
    }

    &-visit-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 11px;
      margin-bottom: 29px;

      @include mq('large') {
        align-items: flex-start;
      }

      > .container {
        width: 100%;
      }

      .cmp-form-text {
        display: flex;
        flex-direction: column;
        gap: 9px;
      }

      label {
        color: var(--emu-semantic-colors-primary-violet-200);
        display: block;
        text-align: center;

        @include mq('large') {
          font-size: 24px;
          line-height: 32px;
        }
      }

      input {
        width: 104px; // needed as per the design
        height: 24px; // needed as per the design
        padding: var(--emu-common-spacing-none);
        border: none;
        pointer-events: none;
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-semantic-colors-primary-purple-100);
        padding: var(--emu-common-spacing-none);
        font-weight: var(--emu-common-font-weight-regular);
        opacity: 0.5;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        transition: all 0.4s linear;

        @include mq('large') {
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
          font-size: 36px;
          line-height: normal;
          height: auto;
        }

        &,
        &:active,
        &:hover,
        &:focus {
          outline: none;
          box-shadow: none;
        }

        &::placeholder {
          color: var(--emu-semantic-colors-primary-purple-100);
        }

        &.datepicker-input--has-value {
          color: var(--emu-semantic-colors-primary-purple-600);
          background-color: rgba(#9577bf, 0.2);

          @include mq('large') {
            width: 210px; // needed as per the design
            height: auto; // needed as per the design
            text-align: center;
            letter-spacing: -1px;
          }
        }

        &.has-value {
          color: var(--emu-semantic-colors-primary-purple-100);
          opacity: 1;
        }
      }

      &--visit1 {
        gap: var(--emu-common-spacing-none);

        @include mq('large') {
          margin-bottom: 35px;
        }

        &::after {
          display: none;
        }

        input {
          width: 110px;
          opacity: 1;
          border-bottom: var(--emu-common-border-width-medium) solid
            var(--emu-semantic-colors-primary-purple-600);
          pointer-events: initial;

          @include mq('large') {
            width: auto;
            height: auto;
            max-width: 230px;
            text-align: left;
            padding-bottom: var(--emu-common-spacing-xxs);
            border-bottom-color: var(--emu-semantic-colors-primary-purple-100);
          }
        }
      }

      &--visit2 {
        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-large);
        }
        .emu-atc__dropdown-content {
          @media screen and (max-height: 850px) {
            top: -260px;
          }
        }
      }

      &--visit3 {
        @include mq('large') {
          margin-bottom: 29px;
        }
        .emu-atc__dropdown-content {
          @media screen and (max-height: 850px) {
            top: -260px;
          }
        }
      }

      &--with-atc {
        @include mq('large') {
          flex-direction: row;
          align-items: center;
          gap: var(--emu-common-spacing-none);
        }

        > .container {
          flex: 1;
        }

        .cmp-form-text {
          @include mq('large') {
            flex-direction: row;
            gap: 20px;
            align-items: baseline;
          }

          input {
            @include mq('large') {
              letter-spacing: 2px;
            }
          }
        }

        input {
          @include mq('large') {
            width: 250px;
            text-align: left;
          }
        }
      }
    }

    &-modal[data-component='modal'] {
      .modal-wrapper .modal-content {
        @include mq('large') {
          max-width: 777px; // needed as per the design
        }
      }

      .content-box {
        &__modal {
          &-text {
            padding-left: 10px;
            padding-right: 10px;
            margin-top: -22px;

            @include mq('large') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
              margin-top: 10px;
            }
          }

          &-title-wrapper {
            @include mq('large') {
              gap: 24px;
              align-items: flex-start;
              margin-top: var(--emu-common-spacing-none);
            }

            .emu-title__text {
              margin-top: -26px;
            }
          }

          &-small-text {
            p {
              @include mq('large') {
                margin-top: 14px;
              }
            }
          }
        }
      }
    }
  }
}

// animations
.start-journey {
  &__planner {
    .content-box {
      transform: translateY(100px);
      opacity: 0;
      transition: all 0.4s linear;

      &__title,
      &__text,
      &__text-small,
      &__button {
        transform: translateY(0);
        opacity: 1;
        transition: none;
      }
    }
  }
}

.lo3-section-container--active {
  .start-journey__planner {
    .content-box {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.lo3-section-container--prev-active {
  .start-journey__planner {
    .content-box {
      transform: translateY(100px);
      opacity: 0;
    }
  }
}

// Media queries
@media screen and (min-width: 1024px) and (max-height: 750px) {
  .start-journey {
    &__planner {
      .content-box {
        padding-top: 59px;
        width: auto;

        &__title {
          margin-bottom: 21px;
        }

        &__button.cmp-button {
          position: fixed;
          top: 36px;
          right: 71px;
          margin-top: var(--emu-common-spacing-none);
        }
      }

      &-visit-container {
        &--visit1 {
          margin-bottom: 27px;
        }

        &--visit2 {
          margin-bottom: var(--emu-common-spacing-medium);
        }

        &--visit3 {
          margin-bottom: 27px;
        }
      }
    }
  }
}

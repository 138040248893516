.header {
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
  transition: transform 0.5s;
  position: fixed;
  top: var(--emu-common-spacing-none);
  left: var(--emu-common-spacing-none);

  .cmp-container-header {
    width: 100%;
    padding-left: 11px; //to match figma design
    padding-top: 15px; //to match figma design
    padding-right: 20px; //to match figma design
    align-items: center;
    display: flex;
    max-width: var(
      --emu-semantic-sizing-breakpoints-xx-large
    ); //to match figma design
    margin-left: auto;
    margin-right: auto;

    @include mq('large') {
      margin-top: 57px; //to match figma design
      padding-left: 57px; //to match figma design
    }
  }

  &__global-navigation {
    display: none;
  }

  &__logo {
    padding-right: var(--emu-common-spacing-medium);
    width: auto;
    margin-right: var(--emu-common-spacing-small);
    position: relative;
    transition: all 0.4s linear;

    a {
      z-index: var(--emu-common-other-z-index-header);
      position: relative;

      @include mq('large') {
        z-index: var(--emu-common-other-z-index-base);
      }
    }

    @include mq('large') {
      margin-right: 15px; //to match figma design
    }

    &::after {
      content: '';
      display: block;
      border-right: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-violet-100);
      width: 93px; //to match figma design
      height: 37px; //to match figma design
      position: absolute;
      top: 3px; //to match figma design

      @include mq('large') {
        height: 56px; //to match figma design
        top: 5px; //to match figma design
        left: 132px; //to match figma design
        width: 4px; //to match figma design
      }
    }

    img {
      height: 48px; //to match figma design
      @include mq('large') {
        height: 70px; //to match figma design
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;

    > .image {
      img {
        height: 39px; //to match figma design
        transition: all 0.4s linear;

        @include mq('large') {
          height: 59px; //to match figma design
        }
      }
    }

    > .button {
      z-index: var(--emu-common-other-z-index-layer);
      margin-left: auto;
      position: relative;
      width: 24px; //to match figma design
      margin-right: var(--emu-common-spacing-xs);

      @include mq('large') {
        display: none;
      }

      .header__mobile-trigger.aaaem-button {
        background-color: var(--emu-common-colors-transparent);
        padding: var(--emu-semantic-spacing-none);
        border: none;
        outline: none;

        &.js-toggle-on {
          .chevron {
            width: 100%;
            height: 100%;
            background-image: url('../../assets/images/close.png');
            background-color: transparent;
            background-repeat: no-repeat;
            background-size: auto;
            display: block;
            position: absolute;
            top: var(--emu-common-spacing-none);
            left: var(--emu-common-spacing-none);

            svg {
              display: none;
            }
          }
          svg {
            display: none;
          }
        }
      }
    }
  }

  &__sidenav-container {
    display: none;

    @include mq('large') {
      position: fixed;
      left: var(--emu-common-spacing-none);
      top: 50%;
      left: 0;
      display: block;
      opacity: 1;
      padding-bottom: 180px;
      transform: translateX(84px) translateY(-50%);
      pointer-events: initial;
      transition: all 0.4s ease-in-out;
      left: -50px;
    }

    // needed to make sure that content and side nav does not have a lot of space at this resolution
    @include mq('1080px') {
      left: -34px;
    }

    @include mq('xx-large', 'min-height') {
      top: calc(50% - 310px);
    }

    @include mq('x-large') {
      left: var(--emu-common-spacing-none);
    }

    @include mq('xx-large') {
      transform: translateX(calc(50vw - 720px)) translateX(78px)
        translateY(-50%);
    }

    ul {
      padding: var(--emu-common-spacing-none);
      margin: var(--emu-common-spacing-none);
      display: flex;
      flex-direction: column;
      position: relative;

      @include mq('large') {
        max-width: 310px; // to match the design
      }

      li,
      a {
        &::after {
          content: '';
          height: calc(100% - 12px); //match figma design
          width: 4px; //match figma design
          background: rgba(46, 24, 68, 0.5); //match figma design
          display: flex;
          position: absolute;
          top: 12px;
          left: var(--emu-common-spacing-none);
          pointer-events: none;
        }

        &::before {
          content: '';
          width: 12px; //match figma design
          height: 12px; //match figma design
          display: flex;
          border-radius: 100%;
          border: 2.5px solid rgba(46, 24, 68, 0.5); //match figma design
          position: absolute;
          left: -4px; //match figma design
          top: 0;
        }
      }

      li {
        &::after {
          height: var(--emu-common-sizing-none);
          background-color: var(--emu-common-colors-transparent);
          transition: height 0.2s;
        }

        &::before {
          height: var(--emu-common-sizing-none);
          transition: all 0.2s;
          background-color: var(--emu-common-colors-transparent);
          border: none;
          transition-delay: 200ms;
        }

        &.no-transition {
          &::before,
          &::after {
            transition: none;
          }
        }

        &.active {
          a {
            color: var(--emu-semantic-colors-primary-violet-100);
          }

          &::before {
            height: 12px;
            background-color: var(--emu-semantic-colors-primary-violet-100);
            border-color: var(--emu-semantic-colors-primary-violet-100);
          }
        }

        &.prev-active {
          &::after {
            height: calc(100% - 12px);
            background-color: var(--emu-semantic-colors-primary-violet-100);
            opacity: 1;
          }
        }
      }

      li {
        list-style-type: none;
        display: flex;
        position: relative;
        padding-left: 26px;
        align-items: end;
        padding-bottom: 47.5px; // decimal needed to match design

        &:last-child {
          padding-bottom: var(--emu-common-spacing-none);
        }

        a {
          text-decoration: none;
          color: rgba(46, 24, 68, 0.5); //match figma design
          font-size: 14px; //match figma design
          font-weight: var(--emu-common-font-weight-regular);
          line-height: 16px; //match figma design
        }

        &:last-child {
          &::after {
            display: none;
          }

          a {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

header {
  .header {
    &__eyebrow {
      max-width: var(
        --emu-semantic-sizing-breakpoints-xx-large
      ); //to match figma design
      margin: auto;
      right: var(--emu-common-spacing-none);
      display: none;
      width: 100%;
      position: absolute;
      left: var(--emu-common-spacing-none);
      top: var(--emu-common-spacing-none);
      background-color: var(--emu-semantic-colors-primary-violet-100);
      transition: all 0.4s linear;

      @include mq('large') {
        display: block;
      }

      .header__eyebrow-inner {
        background-color: inherit;

        > .aem-Grid {
          display: flex;
          align-items: center;
          gap: 10px; //to match figma design
          justify-content: center;

          @include mq('x-large') {
            gap: 40px; //to match figma design
          }

          > .button {
            display: flex;
            width: auto;

            .header__nav-submenu-button-desktop {
              font-size: 14px; //to match figma design
              font-weight: var(--emu-common-font-weight-regular);
              line-height: normal;
              background-color: transparent;
              border: none;
              padding: var(--emu-common-spacing-none);

              &:hover,
              &:active {
                color: var(--emu-common-colors-white);
              }

              svg {
                fill: var(--emu-common-colors-white);
              }
            }
          }

          > .text {
            width: auto;

            ul {
              list-style: none;
              justify-content: center;
              display: flex;
              gap: 10px; //to match figma design
              margin-top: 9px; //to match figma design
              margin-bottom: 9px; //to match figma design

              @include mq('x-large') {
                gap: 40px; //to match figma design
              }

              li {
                a {
                  color: var(--emu-common-colors-white);
                  font-size: 14px; //to match figma design
                  font-weight: var(--emu-common-font-weight-regular);
                  line-height: normal;
                  text-decoration: none;
                }
              }
            }
          }

          > .container {
            width: auto;
            .header__eyebrow-social {
              gap: var(--emu-common-spacing-small);
              display: flex;
              background: none;
              margin-left: 20px; //to match figma design

              @include mq('x-large') {
                gap: 12px; //to match figma design
                margin-left: 40px; //to match figma design
              }
            }

            img {
              width: 100%;
              height: 12px; //to match figma design
            }
          }
        }
      }
    }
  }
}

.has-mobile-menu-open {
  overflow: hidden;

  @include mq('large') {
    overflow: auto;
  }

  .header__logo a {
    position: static;

    @include mq('large') {
      position: relative;
    }
  }
}

// Media queries
@media screen and (min-width: 1024px) and (max-height: 750px) {
  .header {
    &__sidenav-container {
      padding-bottom: 114px;
    }
  }
}
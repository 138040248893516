@font-face {
  font-family: 'BrownStd';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-Light.woff') format('woff');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-LightItalic.woff2')
    format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-Regular.woff') format('woff');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-Italic.woff') format('woff');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-Bold.woff') format('woff');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-BoldItalic.woff2')
    format('woff2');
}

@font-face {
  font-family: 'BrownStd';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('./assets/fonts/BrownStd/BrownLLWeb-BoldItalic.woff') format('woff');
}

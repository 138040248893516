.about-intro {
  &--screen-1 {
    .content-box {
      &__title,
      &__text,
      &__text-small {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          max-width: none;
        }
      }

      &__text-small {
        max-width: 78%;

        @include mq('large') {
          max-width: none;
        }
      }
    }
  }

  &--screen-2 {
    .content-box__text {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }

  &--screen-3 {
    .content-box {
      @include mq('large') {
        padding-right: var(--emu-common-spacing-none);
      }

      &__title {
        @include mq('large') {
          margin-bottom: 38px;
        }

        .emphasis {
          @include mq('large') {
            display: inline;
          }

          @include mq('x-large') {
            display: inline-block;
          }
        }

        sup {
          letter-spacing: initial;
          font-size: 50%;
          margin-left: 2px;
          font-weight: var(--emu-common-font-weight-light);
        }
      }

      &__text {
        @include mq('large') {
          max-width: 706px;
        }

        &.has-highlighted-text {
          u {
            @include mq('large') {
              display: inline-block;
            }
          }
        }

        &--spl {
          @include mq('large') {
            max-width: 720px;
          }
        }
      }

      &__text-small {
        max-width: 76%;
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          max-width: 100%;
        }
      }
    }
  }
}

// default position before animation
.about-intro {
  &--screen-2 {
    .content-box__text {
      transition-delay: 0ms;

      p {
        transition: all 0.8s ease-in-out;
        transform: translateY(160px);
        opacity: 0;
      }

      @for $i from 1 through 10 {
        p:nth-child(#{$i}) {
          transition-delay: #{$i * 120}ms;
        }
      }
    }
  }
}

// animation when section becomes active
.lo3-section-container--active {
  .about-intro {
    &--screen-2 {
      .content-box__text {
        p {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
}

@import '../../../node_modules/vanillajs-datepicker/sass/datepicker';

.datepicker {
  &-container {
    width: 100%;
    position: relative;
  }

  &-dropdown {
    margin-top: -3px;
    margin-left: auto;
    margin-right: auto;
    width: 370px;
    max-width: 100%;
    padding: var(--emu-common-spacing-none);
    z-index: var(--emu-common-other-z-index-header);
    position: fixed;

    // mq needed to make sure that the calendar dropdown looks good
    @include mq('635px', 'max-width') {
      width: 100%;
      left: 0 !important;
    }

    @include mq('large') {
      margin-top: -8px;
    }

    .datepicker-picker {
      padding-top: 11px;
      padding-left: var(--emu-common-spacing-none);
      padding-bottom: 19px;
      padding-right: var(--emu-common-spacing-none);
      gap: 13px;
      border-radius: var(--emu-common-border-radius-xs);
      background-color: var(--emu-common-colors-white);
      box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.2);
    }
  }

  &-header {
    padding-top: var(--emu-common-spacing-none);
    padding-right: 24px;
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: 24px;

    .datepicker-controls {
      position: relative;
      padding: var(--emu-common-spacing-none);

      .prev-btn,
      .next-btn {
        opacity: 0;
      }

      .button {
        color: var(--emu-common-colors-black);
        font-weight: var(--emu-common-font-weight-regular);
        letter-spacing: -1px;
        padding: var(--emu-common-spacing-none);
        height: auto;
        line-height: 30px;
      }

      &::after,
      &::before {
        content: '';
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url('../../assets/images/arrow-prev.png');
        background-size: 11px;
        background-repeat: no-repeat;
        background-position: left center;
        pointer-events: none;
        height: 100%;
        width: 12px;
      }

      &::after {
        left: auto;
        right: 0;
        background-image: url('../../assets/images/arrow-next.png');
        background-position: right center;
      }
    }
  }

  &-main {
    padding: var(--emu-common-spacing-none);
  }

  &-view {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

    .days {
      .datepicker-cell {
        height: 32px;
        line-height: 32px;
        color: var(--emu-common-colors-black);
        font-weight: var(--emu-common-font-weight-regular);
        letter-spacing: -1px;
        position: relative;
        font-size: 32px;
        font-feature-settings: 'sups' on;

        &::after {
          content: '';
          width: 50px;
          height: 50px;
          background-color: var(--emu-semantic-colors-primary-purple-100);
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: calc(50% - 6px);
          transform: translate(-50%, -50%) scale(0.5);
          opacity: 0;
        }

        &:hover,
        &.focused {
          background-color: var(--emu-common-colors-transparent);
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            &::after {
              opacity: 0.4;
              transform: translate(-50%, -50%) scale(0.75);
              transition: 0.2s linear;
            }
          }
        }

        span {
          position: relative;
          z-index: var(--emu-common-other-z-index-layer);
          cursor: pointer;
        }

        &.disabled span {
          opacity: 0.4;
          cursor: not-allowed;
        }

        &.next {
          opacity: 0.6;
        }

        &.selected {
          background-color: var(--emu-common-colors-transparent);
          color: var(--emu-common-colors-white);

          &::after {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
            transition: 0.2s linear;
          }
        }
      }

      .days-of-week {
        .dow {
          margin-bottom: 11px;
          height: 32px;
          font-size: 19px;
          font-weight: var(--emu-common-font-weight-regular);
          letter-spacing: -1px;
        }
      }
    }
  }

  &-grid {
    row-gap: 18px;
  }

  &-footer {
    display: none;
  }
}

// Media queries
@media screen and (min-width: 1024px) and (max-height: 580px) {
  .datepicker {
    &-dropdown {
      width: 310px;

      .datepicker-picker {
        gap: var(--emu-common-spacing-xs);
        padding-top: var(--emu-common-spacing-small);
        padding-bottom: var(--emu-common-spacing-small);
      }
    }

    &-grid {
      row-gap: var(--emu-common-spacing-xs);
    }

    &-view {
      .days {
        .datepicker-cell {
          font-size: var(--emu-semantic-font-sizes-wide-medium);

          &:after {
            width: 40px;
            height: 40px;
          }
        }

        .days-of-week .dow {
          margin-bottom: var(--emu-common-spacing-small);
          height: 28px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (min-height: 581px) and (max-height: 630px) {
  .datepicker {
    &-dropdown {
      width: 350px;

      .datepicker-picker {
        gap: var(--emu-common-spacing-small);
      }
    }

    &-grid {
      row-gap: 12px;
    }
  }
}

/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-component-cards-card-margin-top-narrow: 0px !default;
$emu-component-cards-card-margin-top-wide: 0px !default;
$emu-component-cards-card-margin-right-narrow: 0px !default;
$emu-component-cards-card-margin-right-wide: 0px !default;
$emu-component-cards-card-margin-bottom-narrow: 0px !default;
$emu-component-cards-card-margin-bottom-wide: 0px !default;
$emu-component-cards-card-margin-left-narrow: 0px !default;
$emu-component-cards-card-margin-left-wide: 0px !default;
$emu-component-cards-card-padding-top-narrow: 16px !default;
$emu-component-cards-card-padding-top-wide: 16px !default;
$emu-component-cards-card-padding-right-narrow: 16px !default;
$emu-component-cards-card-padding-right-wide: 16px !default;
$emu-component-cards-card-padding-bottom-narrow: 16px !default;
$emu-component-cards-card-padding-bottom-wide: 16px !default;
$emu-component-cards-card-padding-left-narrow: 16px !default;
$emu-component-cards-card-padding-left-wide: 16px !default;
$emu-component-cards-card-color-background-dark: #000000 !default;
$emu-component-cards-card-color-background-light: #FFFFFF !default;
$emu-component-cards-card-header-color-background-light: rgba(0,0,0,0) !default;
$emu-component-cards-card-header-color-background-dark: rgba(0,0,0,0) !default;
$emu-component-cards-card-header-button-text-color-light: #FFFFFF !default;
$emu-component-cards-card-header-button-text-color-dark: #000000 !default;
$emu-component-cards-card-header-button-text-color-focus-light: #FFFFFF !default;
$emu-component-cards-card-header-button-text-color-focus-dark: #000000 !default;
$emu-component-cards-card-header-button-text-color-hover-light: #FFFFFF !default;
$emu-component-cards-card-header-button-text-color-hover-dark: #000000 !default;
$emu-component-cards-card-header-button-text-color-active-light: #FFFFFF !default;
$emu-component-cards-card-header-button-text-color-active-dark: #000000 !default;
$emu-component-cards-card-header-button-icon-size-height: 30px !default;
$emu-component-cards-card-header-button-icon-size-width: 30px !default;
$emu-component-cards-card-header-button-icon-spacing: 4px !default;
$emu-component-cards-card-header-button-icon-color-light: #FFFFFF !default;
$emu-component-cards-card-header-button-icon-color-dark: #000000 !default;
$emu-component-cards-card-header-button-icon-color-focus-light: #FFFFFF !default;
$emu-component-cards-card-header-button-icon-color-focus-dark: #000000 !default;
$emu-component-cards-card-header-button-icon-color-hover-light: #FFFFFF !default;
$emu-component-cards-card-header-button-icon-color-hover-dark: #000000 !default;
$emu-component-cards-card-header-button-icon-color-active-light: #FFFFFF !default;
$emu-component-cards-card-header-button-icon-color-active-dark: #000000 !default;
$emu-component-cards-card-header-button-icon-thickness: 2px !default;
$emu-component-cards-card-header-button-color-background-light: #000000 !default;
$emu-component-cards-card-header-button-color-background-dark: #FFFFFF !default;
$emu-component-cards-card-header-button-color-background-focus-light: #000000 !default;
$emu-component-cards-card-header-button-color-background-focus-dark: #FFFFFF !default;
$emu-component-cards-card-header-button-color-background-hover-light: #000000 !default;
$emu-component-cards-card-header-button-color-background-hover-dark: #FFFFFF !default;
$emu-component-cards-card-header-button-color-background-active-light: #000000 !default;
$emu-component-cards-card-header-button-color-background-active-dark: #FFFFFF !default;
$emu-component-cards-card-header-button-color-border-light: #000000 !default;
$emu-component-cards-card-header-button-color-border-dark: #FFFFFF !default;
$emu-component-cards-card-header-button-color-border-focus-light: #000000 !default;
$emu-component-cards-card-header-button-color-border-focus-dark: #FFFFFF !default;
$emu-component-cards-card-header-button-color-border-hover-light: #000000 !default;
$emu-component-cards-card-header-button-color-border-hover-dark: #FFFFFF !default;
$emu-component-cards-card-header-button-color-border-active-light: #000000 !default;
$emu-component-cards-card-header-button-color-border-active-dark: #FFFFFF !default;
$emu-component-cards-card-body-color-background-dark: rgba(0,0,0,0) !default;
$emu-component-cards-card-body-color-background-light: rgba(0,0,0,0) !default;
$emu-component-cards-teaser-padding-top-narrow: 32px !default;
$emu-component-cards-teaser-padding-top-wide: 32px !default;
$emu-component-cards-teaser-padding-right-narrow: 32px !default;
$emu-component-cards-teaser-padding-right-wide: 32px !default;
$emu-component-cards-teaser-padding-bottom-narrow: 32px !default;
$emu-component-cards-teaser-padding-bottom-wide: 32px !default;
$emu-component-cards-teaser-padding-left-narrow: 32px !default;
$emu-component-cards-teaser-padding-left-wide: 32px !default;
$emu-component-cards-teaser-text-color-light: #FFFFFF !default;
$emu-component-cards-teaser-text-color-dark: #000000 !default;
$emu-component-cards-teaser-title-max-width: 66.66667% !default;
$emu-component-cards-teaser-title-margin-top-narrow: 4px !default;
$emu-component-cards-teaser-title-margin-top-wide: 4px !default;
$emu-component-cards-teaser-title-margin-bottom-narrow: 0px !default;
$emu-component-cards-teaser-title-margin-bottom-wide: 0px !default;
$emu-component-cards-teaser-description-margin-top-narrow: 0px !default;
$emu-component-cards-teaser-description-margin-top-wide: 4px !default;
$emu-component-cards-teaser-description-margin-bottom-narrow: 0px !default;
$emu-component-cards-teaser-description-margin-bottom-wide: 8px !default;
$emu-component-lists-accordion-color-background-light: #FFFFFF !default;
$emu-component-lists-accordion-color-background-dark: #000000 !default;
$emu-component-lists-accordion-color-text-light: #000000 !default;
$emu-component-lists-accordion-color-text-dark: #FFFFFF !default;
$emu-component-lists-accordion-border-radius-top-left: 0px !default;
$emu-component-lists-accordion-border-radius-top-right: 0px !default;
$emu-component-lists-accordion-border-radius-bottom-right: 0px !default;
$emu-component-lists-accordion-border-radius-bottom-left: 0px !default;
$emu-component-lists-accordion-margin-top-narrow: 16px !default;
$emu-component-lists-accordion-margin-top-wide: 16px !default;
$emu-component-lists-accordion-margin-right-narrow: 16px !default;
$emu-component-lists-accordion-margin-right-wide: 16px !default;
$emu-component-lists-accordion-margin-bottom-narrow: 0px !default;
$emu-component-lists-accordion-margin-bottom-wide: 0px !default;
$emu-component-lists-accordion-margin-left-narrow: 16px !default;
$emu-component-lists-accordion-margin-left-wide: 16px !default;
$emu-component-lists-accordion-item-color-border-bottom-closed-dark: #FFFFFF !default;
$emu-component-lists-accordion-item-color-border-bottom-closed-light: #000000 !default;
$emu-component-lists-accordion-item-color-border-bottom-open-dark: rgba(0,0,0,0) !default;
$emu-component-lists-accordion-item-color-border-bottom-open-light: rgba(0,0,0,0) !default;
$emu-component-lists-accordion-item-body-color-background-light: rgba(0,0,0,0) !default;
$emu-component-lists-accordion-item-body-color-background-dark: rgba(0,0,0,0) !default;
$emu-component-lists-accordion-item-body-transition: 400ms !default;
$emu-component-lists-accordion-item-body-padding-top-narrow: 8px !default;
$emu-component-lists-accordion-item-body-padding-top-wide: 8px !default;
$emu-component-lists-accordion-item-body-padding-right-narrow: 8px !default;
$emu-component-lists-accordion-item-body-padding-right-wide: 8px !default;
$emu-component-lists-accordion-item-body-padding-bottom-narrow: 8px !default;
$emu-component-lists-accordion-item-body-padding-bottom-wide: 8px !default;
$emu-component-lists-accordion-item-body-padding-left-narrow: 8px !default;
$emu-component-lists-accordion-item-body-padding-left-wide: 8px !default;
$emu-component-lists-accordion-item-header-text-typography-narrow: 300 24px/29px BrownStd !default;
$emu-component-lists-accordion-item-header-text-typography-wide: 300 36px/43px BrownStd !default;
$emu-component-lists-accordion-item-header-padding-top-narrow: 8px !default;
$emu-component-lists-accordion-item-header-padding-top-wide: 8px !default;
$emu-component-lists-accordion-item-header-padding-right-narrow: 8px !default;
$emu-component-lists-accordion-item-header-padding-right-wide: 8px !default;
$emu-component-lists-accordion-item-header-padding-bottom-narrow: 8px !default;
$emu-component-lists-accordion-item-header-padding-bottom-wide: 8px !default;
$emu-component-lists-accordion-item-header-padding-left-narrow: 8px !default;
$emu-component-lists-accordion-item-header-padding-left-wide: 8px !default;
$emu-component-lists-accordion-item-header-icon-spacing: 16px !default;
$emu-component-lists-accordion-item-header-icon-size-height: 12px !default;
$emu-component-lists-accordion-item-header-icon-size-width: 12px !default;
$emu-component-lists-accordion-item-header-icon-thickness: 2px !default;
$emu-component-lists-accordion-item-header-icon-border-radius-top-left: 8px !default;
$emu-component-lists-accordion-item-header-icon-border-radius-top-right: 8px !default;
$emu-component-lists-accordion-item-header-icon-border-radius-bottom-right: 8px !default;
$emu-component-lists-accordion-item-header-icon-border-radius-bottom-left: 8px !default;
$emu-component-lists-accordion-item-header-icon-transform-open: rotate(0) !default;
$emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg) !default;
$emu-component-lists-accordion-item-header-icon-transition: 400ms !default;
$emu-component-lists-accordion-item-header-icon-color-fill-open-light: #000000 !default;
$emu-component-lists-accordion-item-header-icon-color-fill-open-dark: #FFFFFF !default;
$emu-component-lists-accordion-item-header-icon-color-fill-closed-light: #000000 !default;
$emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: #FFFFFF !default;
$emu-component-lists-accordion-item-header-color-background-light: rgba(0,0,0,0) !default;
$emu-component-lists-accordion-item-header-color-background-dark: rgba(0,0,0,0) !default;
$emu-component-lists-accordion-item-border-width-bottom-open: 1px !default;
$emu-component-lists-navigation-banner-color-background-light: #000000 !default;
$emu-component-lists-navigation-banner-color-background-dark: #FFFFFF !default;
$emu-component-lists-navigation-banner-item-color-background-default-light: #000000 !default;
$emu-component-lists-navigation-banner-item-color-background-default-dark: #FFFFFF !default;
$emu-component-lists-navigation-banner-item-color-background-hover-light: #303436 !default;
$emu-component-lists-navigation-banner-item-color-background-hover-dark: #303436 !default;
$emu-component-lists-navigation-banner-item-color-border-default-light: #000000 !default;
$emu-component-lists-navigation-banner-item-color-border-default-dark: #FFFFFF !default;
$emu-component-lists-navigation-banner-item-color-border-hover-light: #303436 !default;
$emu-component-lists-navigation-banner-item-color-border-hover-dark: #303436 !default;
$emu-component-lists-navigation-banner-item-text-color-default-light: #FFFFFF !default;
$emu-component-lists-navigation-banner-item-text-color-default-dark: #000000 !default;
$emu-component-lists-navigation-banner-item-text-color-hover-light: #FFFFFF !default;
$emu-component-lists-navigation-banner-item-text-color-hover-dark: #000000 !default;
$emu-component-lists-navigation-banner-item-border-width: 1px !default;
$emu-component-lists-navigation-banner-padding-top-narrow: 0px !default;
$emu-component-lists-navigation-banner-padding-top-wide: 0px !default;
$emu-component-lists-navigation-banner-padding-right-narrow: 0px !default;
$emu-component-lists-navigation-banner-padding-right-wide: 0px !default;
$emu-component-lists-navigation-banner-padding-bottom-narrow: 0px !default;
$emu-component-lists-navigation-banner-padding-bottom-wide: 0px !default;
$emu-component-lists-navigation-banner-padding-left-narrow: 0px !default;
$emu-component-lists-navigation-banner-padding-left-wide: 0px !default;
$emu-component-lists-navigation-dropdown-color-background-light: #FFFFFF !default;
$emu-component-lists-navigation-dropdown-color-background-dark: #000000 !default;
$emu-component-lists-navigation-dropdown-color-border-light: #000000 !default;
$emu-component-lists-navigation-dropdown-color-border-dark: #FFFFFF !default;
$emu-component-lists-navigation-dropdown-text-color-default-light: #000000 !default;
$emu-component-lists-navigation-dropdown-text-color-default-dark: #FFFFFF !default;
$emu-component-lists-navigation-dropdown-text-color-hover-light: #000000 !default;
$emu-component-lists-navigation-dropdown-text-color-hover-dark: #FFFFFF !default;
$emu-component-lists-navigation-dropdown-border-width: 1px !default;
$emu-component-lists-navigation-dropdown-item-color-background-default-light: #FFFFFF !default;
$emu-component-lists-navigation-dropdown-item-color-background-default-dark: #000000 !default;
$emu-component-lists-navigation-dropdown-item-color-background-hover-light: #F4F5F5 !default;
$emu-component-lists-navigation-dropdown-item-color-background-hover-dark: #F4F5F5 !default;
$emu-component-lists-navigation-dropdown-item-text-color-default-light: #000000 !default;
$emu-component-lists-navigation-dropdown-item-text-color-default-dark: #FFFFFF !default;
$emu-component-lists-navigation-dropdown-item-text-color-hover-light: #000000 !default;
$emu-component-lists-navigation-dropdown-item-text-color-hover-dark: #FFFFFF !default;
$emu-component-lists-navigation-z-index: 500 !default;
$emu-component-lists-navigation-text-padding-top-narrow: 8px !default;
$emu-component-lists-navigation-text-padding-top-wide: 8px !default;
$emu-component-lists-navigation-text-padding-right-narrow: 8px !default;
$emu-component-lists-navigation-text-padding-right-wide: 8px !default;
$emu-component-lists-navigation-text-padding-bottom-narrow: 8px !default;
$emu-component-lists-navigation-text-padding-bottom-wide: 8px !default;
$emu-component-lists-navigation-text-padding-left-narrow: 8px !default;
$emu-component-lists-navigation-text-padding-left-wide: 8px !default;
$emu-component-feeds-instagram-padding-top-narrow: 0px !default;
$emu-component-feeds-instagram-padding-top-wide: 0px !default;
$emu-component-feeds-instagram-padding-right-narrow: 0px !default;
$emu-component-feeds-instagram-padding-right-wide: 0px !default;
$emu-component-feeds-instagram-padding-bottom-narrow: 8px !default;
$emu-component-feeds-instagram-padding-bottom-wide: 8px !default;
$emu-component-feeds-instagram-padding-left-narrow: 0px !default;
$emu-component-feeds-instagram-padding-left-wide: 0px !default;
$emu-component-feeds-instagram-post-gap-narrow: 40px !default;
$emu-component-feeds-instagram-post-gap-wide: 40px !default;
$emu-component-ingredients-icon-color-fill-light: #000000 !default;
$emu-component-ingredients-icon-color-fill-dark: #FFFFFF !default;
$emu-component-ingredients-icon-size-height: 16px !default;
$emu-component-ingredients-icon-size-width: 16px !default;
$emu-component-ingredients-separator-color-background-light: #000000 !default;
$emu-component-ingredients-separator-color-background-dark: #FFFFFF !default;
$emu-component-ingredients-separator-size-height: 1px !default;
$emu-component-ingredients-title-h1-typography-narrow: 700 60px/72px BrownStd !default;
$emu-component-ingredients-title-h1-typography-wide: 700 80px/96px BrownStd !default;
$emu-component-ingredients-title-h2-typography-narrow: 700 30px/36px BrownStd !default;
$emu-component-ingredients-title-h2-typography-wide: 700 54px/62px BrownStd !default;
$emu-component-ingredients-title-h3-typography-narrow: 700 42px/62px BrownStd !default;
$emu-component-ingredients-title-h3-typography-wide: 700 48px/62px BrownStd !default;
$emu-component-ingredients-title-h4-typography-narrow: 300 24px/29px BrownStd !default;
$emu-component-ingredients-title-h4-typography-wide: 300 36px/43px BrownStd !default;
$emu-component-ingredients-title-h5-typography-narrow: 300 24px/29px BrownStd !default;
$emu-component-ingredients-title-h5-typography-wide: 300 30px/36px BrownStd !default;
$emu-component-ingredients-title-color-light: #000000 !default;
$emu-component-ingredients-title-color-dark: #FFFFFF !default;
$emu-component-ingredients-text-text-color-light: #000000 !default;
$emu-component-ingredients-text-text-color-dark: #FFFFFF !default;
$emu-component-ingredients-text-text-typography-narrow: 300 18px/24px BrownStd !default;
$emu-component-ingredients-text-text-typography-wide: 300 24px/36px BrownStd !default;
$emu-component-ingredients-loader-color-fill-light: #000000 !default;
$emu-component-ingredients-loader-color-fill-dark: #FFFFFF !default;
$emu-component-ingredients-image-map-buttons-size-height: 50px !default;
$emu-component-ingredients-image-map-buttons-size-width: 50px !default;
$emu-component-ingredients-image-map-buttons-color-background-light: rgba(255, 255, 255, 0.3) !default;
$emu-component-ingredients-image-map-buttons-color-background-dark: rgba(255, 255, 255, 0.3) !default;
$emu-component-ingredients-image-map-buttons-color-icon-light: rgba(0,0,0,0) !default;
$emu-component-ingredients-image-map-buttons-color-icon-dark: rgba(0,0,0,0) !default;
$emu-component-ingredients-image-map-buttons-border-radius-top-left: 50% !default;
$emu-component-ingredients-image-map-buttons-border-radius-top-right: 50% !default;
$emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50% !default;
$emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50% !default;
$emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 rgb(0 0 0 / 0) !default;
$emu-component-ingredients-google-map-color-background-light: rgba(46, 24, 68, 0.8) !default;
$emu-component-ingredients-google-map-color-background-dark: rgba(46, 24, 68, 0.8) !default;
$emu-component-ingredients-google-map-margin-top-narrow: 8px !default;
$emu-component-ingredients-google-map-margin-top-wide: 8px !default;
$emu-component-ingredients-google-map-margin-right-narrow: 8px !default;
$emu-component-ingredients-google-map-margin-right-wide: 8px !default;
$emu-component-ingredients-google-map-margin-bottom-narrow: 8px !default;
$emu-component-ingredients-google-map-margin-bottom-wide: 8px !default;
$emu-component-ingredients-google-map-margin-left-narrow: 8px !default;
$emu-component-ingredients-google-map-margin-left-wide: 8px !default;
$emu-component-ingredients-select-input-option-text-color-filled-default-light: #2e1844 !default;
$emu-component-ingredients-select-input-option-text-color-filled-default-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-option-text-color-filled-hover-light: #2e1844 !default;
$emu-component-ingredients-select-input-option-text-color-filled-hover-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-option-text-color-filled-active-light: #2e1844 !default;
$emu-component-ingredients-select-input-option-text-color-filled-active-dark: #000000 !default;
$emu-component-ingredients-select-input-option-text-color-filled-focus-light: #2e1844 !default;
$emu-component-ingredients-select-input-option-text-color-filled-focus-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-option-text-color-filled-disabled-light: #2e1844 !default;
$emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: #000000 !default;
$emu-component-ingredients-select-input-option-text-color-filled-error-light: #2e1844 !default;
$emu-component-ingredients-select-input-option-text-color-filled-error-dark: #000000 !default;
$emu-component-ingredients-select-input-option-text-color-outline-default-light: #000000 !default;
$emu-component-ingredients-select-input-option-text-color-outline-default-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-option-text-color-outline-hover-light: #000000 !default;
$emu-component-ingredients-select-input-option-text-color-outline-hover-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-option-text-color-outline-active-light: #FFFFFF !default;
$emu-component-ingredients-select-input-option-text-color-outline-active-dark: #000000 !default;
$emu-component-ingredients-select-input-option-text-color-outline-focus-light: #000000 !default;
$emu-component-ingredients-select-input-option-text-color-outline-focus-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-option-text-color-outline-disabled-light: #000000 !default;
$emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: #000000 !default;
$emu-component-ingredients-select-input-option-text-color-outline-error-light: #000000 !default;
$emu-component-ingredients-select-input-option-text-color-outline-error-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-default-filled-light: #FFFFFF !default;
$emu-component-ingredients-select-input-option-color-background-default-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-default-outline-light: rgba(0,0,0,0) !default;
$emu-component-ingredients-select-input-option-color-background-default-outline-dark: rgba(0,0,0,0) !default;
$emu-component-ingredients-select-input-option-color-background-hover-filled-light: #FFFFFF !default;
$emu-component-ingredients-select-input-option-color-background-hover-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-hover-outline-light: #9577BF !default;
$emu-component-ingredients-select-input-option-color-background-hover-outline-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-active-filled-light: #FFFFFF !default;
$emu-component-ingredients-select-input-option-color-background-active-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-active-outline-light: #9577BF !default;
$emu-component-ingredients-select-input-option-color-background-active-outline-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-focus-filled-light: #FFFFFF !default;
$emu-component-ingredients-select-input-option-color-background-focus-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-focus-outline-light: #FFFFFF !default;
$emu-component-ingredients-select-input-option-color-background-focus-outline-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-disabled-filled-light: #FFFFFF !default;
$emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-disabled-outline-light: #FFFFFF !default;
$emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-error-filled-light: #FFFFFF !default;
$emu-component-ingredients-select-input-option-color-background-error-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-error-outline-light: #000000 !default;
$emu-component-ingredients-select-input-option-color-background-error-outline-dark: #000000 !default;
$emu-component-ingredients-select-input-color-border-default-filled-light: #d9d8e9 !default;
$emu-component-ingredients-select-input-color-border-default-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-color-border-default-outline-light: #000000 !default;
$emu-component-ingredients-select-input-color-border-default-outline-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-color-border-hover-filled-light: #9577BF !default;
$emu-component-ingredients-select-input-color-border-hover-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-color-border-hover-outline-light: #000000 !default;
$emu-component-ingredients-select-input-color-border-hover-outline-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-color-border-active-filled-light: #9577BF !default;
$emu-component-ingredients-select-input-color-border-active-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-color-border-active-outline-light: #000000 !default;
$emu-component-ingredients-select-input-color-border-active-outline-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-color-border-focus-filled-light: #9577BF !default;
$emu-component-ingredients-select-input-color-border-focus-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-color-border-focus-outline-light: #000000 !default;
$emu-component-ingredients-select-input-color-border-focus-outline-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-color-border-disabled-filled-light: #9577BF !default;
$emu-component-ingredients-select-input-color-border-disabled-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-color-border-disabled-outline-light: #000000 !default;
$emu-component-ingredients-select-input-color-border-disabled-outline-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-color-border-error-filled-light: #9577BF !default;
$emu-component-ingredients-select-input-color-border-error-filled-dark: #000000 !default;
$emu-component-ingredients-select-input-color-border-error-outline-light: #000000 !default;
$emu-component-ingredients-select-input-color-border-error-outline-dark: #000000 !default;
$emu-component-ingredients-select-input-border-radius-narrow: 32px !default;
$emu-component-ingredients-select-input-border-radius-wide: 32px !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-default-light: #2e1844 !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-active-light: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: #000000 !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-error-light: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: #000000 !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-default-light: #000000 !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: #000000 !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-active-light: #000000 !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: #000000 !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: #FFFFFF !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: #000000 !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: #000000 !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-error-light: #000000 !default;
$emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: #000000 !default;
$emu-component-ingredients-select-input-padding-narrow: 7px 20px 7px 19px !default;
$emu-component-ingredients-select-input-padding-wide: 11px 22px 11px 25px !default;
$emu-component-ingredients-select-input-gap-narrow: 0px !default;
$emu-component-ingredients-select-input-gap-wide: 0px !default;
$emu-component-ingredients-select-input-border-width-filled-narrow: 2px !default;
$emu-component-ingredients-select-input-border-width-filled-wide: 2px !default;
$emu-component-ingredients-select-input-border-width-outline-narrow: 1px !default;
$emu-component-ingredients-select-input-border-width-outline-wide: 1px !default;
$emu-component-ingredients-select-input-label-margin-narrow: 0px !default;
$emu-component-ingredients-select-input-label-margin-wide: 0px !default;
$emu-component-ingredients-select-input-label-text-color-default-light: #000000 !default;
$emu-component-ingredients-select-input-label-text-color-default-dark: #000000 !default;
$emu-component-ingredients-select-input-label-text-color-hover-light: #000000 !default;
$emu-component-ingredients-select-input-label-text-color-hover-dark: #000000 !default;
$emu-component-ingredients-select-input-label-text-color-disabled-light: #000000 !default;
$emu-component-ingredients-select-input-label-text-color-disabled-dark: #000000 !default;
$emu-component-ingredients-select-input-label-text-color-error-light: #000000 !default;
$emu-component-ingredients-select-input-label-text-color-error-dark: #000000 !default;
$emu-component-ingredients-select-input-field-message-margin-narrow: 0px !default;
$emu-component-ingredients-select-input-field-message-margin-wide: 0px !default;
$emu-component-ingredients-select-input-field-message-text-color-light: #000000 !default;
$emu-component-ingredients-select-input-field-message-text-color-dark: #000000 !default;
$emu-component-ingredients-select-input-error-message-margin-narrow: 0px !default;
$emu-component-ingredients-select-input-error-message-margin-wide: 0px !default;
$emu-component-ingredients-select-input-error-message-text-color-light: #000000 !default;
$emu-component-ingredients-select-input-error-message-text-color-dark: #000000 !default;
$emu-component-actions-button-text-typography-narrow: 400 18px/22px BrownStd !default;
$emu-component-actions-button-text-typography-wide: 400 18px/22px BrownStd !default;
$emu-component-actions-button-text-color-link-light: #FFFFFF !default;
$emu-component-actions-button-text-color-link-dark: #000000 !default;
$emu-component-actions-button-text-color-link-hover-light: #FFFFFF !default;
$emu-component-actions-button-text-color-link-hover-dark: #000000 !default;
$emu-component-actions-button-text-color-primary-filled-light: #000000 !default;
$emu-component-actions-button-text-color-primary-filled-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-primary-filled-focus-light: #000000 !default;
$emu-component-actions-button-text-color-primary-filled-focus-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-primary-filled-hover-light: #FFFFFF !default;
$emu-component-actions-button-text-color-primary-filled-hover-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-primary-filled-active-light: #FFFFFF !default;
$emu-component-actions-button-text-color-primary-filled-active-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-primary-outline-light: #000000 !default;
$emu-component-actions-button-text-color-primary-outline-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-primary-outline-focus-light: #000000 !default;
$emu-component-actions-button-text-color-primary-outline-focus-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-primary-outline-hover-light: #000000 !default;
$emu-component-actions-button-text-color-primary-outline-hover-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-primary-outline-active-light: #000000 !default;
$emu-component-actions-button-text-color-primary-outline-active-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-secondary-filled-light: #2e1844 !default;
$emu-component-actions-button-text-color-secondary-filled-dark: #2e1844 !default;
$emu-component-actions-button-text-color-secondary-filled-focus-light: #2e1844 !default;
$emu-component-actions-button-text-color-secondary-filled-focus-dark: #2e1844 !default;
$emu-component-actions-button-text-color-secondary-filled-hover-light: #2e1844 !default;
$emu-component-actions-button-text-color-secondary-filled-hover-dark: #2e1844 !default;
$emu-component-actions-button-text-color-secondary-filled-active-light: #2e1844 !default;
$emu-component-actions-button-text-color-secondary-filled-active-dark: #2e1844 !default;
$emu-component-actions-button-text-color-secondary-outline-light: #000000 !default;
$emu-component-actions-button-text-color-secondary-outline-dark: #9577BF !default;
$emu-component-actions-button-text-color-secondary-outline-focus-light: #000000 !default;
$emu-component-actions-button-text-color-secondary-outline-focus-dark: #9577BF !default;
$emu-component-actions-button-text-color-secondary-outline-hover-light: #000000 !default;
$emu-component-actions-button-text-color-secondary-outline-hover-dark: #9577BF !default;
$emu-component-actions-button-text-color-secondary-outline-active-light: #000000 !default;
$emu-component-actions-button-text-color-secondary-outline-active-dark: #9577BF !default;
$emu-component-actions-button-padding-top-narrow: 13px !default;
$emu-component-actions-button-padding-top-wide: 13px !default;
$emu-component-actions-button-padding-right-narrow: 32px !default;
$emu-component-actions-button-padding-right-wide: 19px !default;
$emu-component-actions-button-padding-bottom-narrow: 13px !default;
$emu-component-actions-button-padding-bottom-wide: 13px !default;
$emu-component-actions-button-padding-left-narrow: 32px !default;
$emu-component-actions-button-padding-left-wide: 19px !default;
$emu-component-actions-button-border-width-primary-filled: 0px !default;
$emu-component-actions-button-border-width-primary-outline: 1px !default;
$emu-component-actions-button-border-width-secondary-filled: 1px !default;
$emu-component-actions-button-border-width-secondary-outline: 2px !default;
$emu-component-actions-button-outline-width-primary-filled: thin !default;
$emu-component-actions-button-outline-width-primary-outline: 0 !default;
$emu-component-actions-button-outline-width-secondary-filled: 0 !default;
$emu-component-actions-button-outline-width-secondary-outline: 0 !default;
$emu-component-actions-button-border-radius-primary-filled-top-left: 37px !default;
$emu-component-actions-button-border-radius-primary-filled-top-right: 37px !default;
$emu-component-actions-button-border-radius-primary-filled-bottom-right: 37px !default;
$emu-component-actions-button-border-radius-primary-filled-bottom-left: 37px !default;
$emu-component-actions-button-border-radius-primary-outline-top-left: 37px !default;
$emu-component-actions-button-border-radius-primary-outline-top-right: 37px !default;
$emu-component-actions-button-border-radius-primary-outline-bottom-right: 37px !default;
$emu-component-actions-button-border-radius-primary-outline-bottom-left: 37px !default;
$emu-component-actions-button-border-radius-secondary-filled-top-left: 30px !default;
$emu-component-actions-button-border-radius-secondary-filled-top-right: 30px !default;
$emu-component-actions-button-border-radius-secondary-filled-bottom-right: 30px !default;
$emu-component-actions-button-border-radius-secondary-filled-bottom-left: 30px !default;
$emu-component-actions-button-border-radius-secondary-outline-top-left: 30px !default;
$emu-component-actions-button-border-radius-secondary-outline-top-right: 30px !default;
$emu-component-actions-button-border-radius-secondary-outline-bottom-right: 30px !default;
$emu-component-actions-button-border-radius-secondary-outline-bottom-left: 30px !default;
$emu-component-actions-button-color-border-primary-filled-light: #FFFFFF !default;
$emu-component-actions-button-color-border-primary-filled-dark: #000000 !default;
$emu-component-actions-button-color-border-primary-filled-focus-light: #FFFFFF !default;
$emu-component-actions-button-color-border-primary-filled-focus-dark: #000000 !default;
$emu-component-actions-button-color-border-primary-filled-hover-light: #000000 !default;
$emu-component-actions-button-color-border-primary-filled-hover-dark: #FFFFFF !default;
$emu-component-actions-button-color-border-primary-filled-active-light: #000000 !default;
$emu-component-actions-button-color-border-primary-filled-active-dark: #FFFFFF !default;
$emu-component-actions-button-color-border-primary-outline-light: #000000 !default;
$emu-component-actions-button-color-border-primary-outline-dark: #FFFFFF !default;
$emu-component-actions-button-color-border-primary-outline-focus-light: #000000 !default;
$emu-component-actions-button-color-border-primary-outline-focus-dark: #FFFFFF !default;
$emu-component-actions-button-color-border-primary-outline-hover-light: #000000 !default;
$emu-component-actions-button-color-border-primary-outline-hover-dark: #FFFFFF !default;
$emu-component-actions-button-color-border-primary-outline-active-light: #000000 !default;
$emu-component-actions-button-color-border-primary-outline-active-dark: #FFFFFF !default;
$emu-component-actions-button-color-border-secondary-filled-light: #d9d8e9 !default;
$emu-component-actions-button-color-border-secondary-filled-dark: #d9d8e9 !default;
$emu-component-actions-button-color-border-secondary-filled-focus-light: #d9d8e9 !default;
$emu-component-actions-button-color-border-secondary-filled-focus-dark: #d9d8e9 !default;
$emu-component-actions-button-color-border-secondary-filled-hover-light: #d9d8e9 !default;
$emu-component-actions-button-color-border-secondary-filled-hover-dark: #d9d8e9 !default;
$emu-component-actions-button-color-border-secondary-filled-active-light: #d9d8e9 !default;
$emu-component-actions-button-color-border-secondary-filled-active-dark: #d9d8e9 !default;
$emu-component-actions-button-color-border-secondary-outline-light: #000000 !default;
$emu-component-actions-button-color-border-secondary-outline-dark: #9577BF !default;
$emu-component-actions-button-color-border-secondary-outline-focus-light: #000000 !default;
$emu-component-actions-button-color-border-secondary-outline-focus-dark: #9577BF !default;
$emu-component-actions-button-color-border-secondary-outline-hover-light: #FFFFFF !default;
$emu-component-actions-button-color-border-secondary-outline-hover-dark: #9577BF !default;
$emu-component-actions-button-color-border-secondary-outline-active-light: #FFFFFF !default;
$emu-component-actions-button-color-border-secondary-outline-active-dark: #9577BF !default;
$emu-component-actions-button-color-background-primary-filled-light: #FFFFFF !default;
$emu-component-actions-button-color-background-primary-filled-dark: #9577BF !default;
$emu-component-actions-button-color-background-primary-filled-focus-light: #FFFFFF !default;
$emu-component-actions-button-color-background-primary-filled-focus-dark: #9577BF !default;
$emu-component-actions-button-color-background-primary-filled-hover-light: #000000 !default;
$emu-component-actions-button-color-background-primary-filled-hover-dark: #9577BF !default;
$emu-component-actions-button-color-background-primary-filled-active-light: #000000 !default;
$emu-component-actions-button-color-background-primary-filled-active-dark: #9577BF !default;
$emu-component-actions-button-color-background-primary-outline-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-primary-outline-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-primary-outline-focus-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-primary-outline-focus-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-primary-outline-hover-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-primary-outline-hover-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-primary-outline-active-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-primary-outline-active-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-secondary-filled-light: #FFFFFF !default;
$emu-component-actions-button-color-background-secondary-filled-dark: #FFFFFF !default;
$emu-component-actions-button-color-background-secondary-filled-focus-light: #FFFFFF !default;
$emu-component-actions-button-color-background-secondary-filled-focus-dark: #FFFFFF !default;
$emu-component-actions-button-color-background-secondary-filled-hover-light: #FFFFFF !default;
$emu-component-actions-button-color-background-secondary-filled-hover-dark: #FFFFFF !default;
$emu-component-actions-button-color-background-secondary-filled-active-light: #FFFFFF !default;
$emu-component-actions-button-color-background-secondary-filled-active-dark: #FFFFFF !default;
$emu-component-actions-button-color-background-secondary-outline-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-secondary-outline-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-secondary-outline-focus-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-secondary-outline-focus-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-secondary-outline-hover-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-secondary-outline-hover-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-secondary-outline-active-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-secondary-outline-active-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-focus-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-focus-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-hover-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-hover-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-active-light: #B4B8BB !default;
$emu-component-actions-button-color-outline-color-primary-filled-active-dark: #B4B8BB !default;
$emu-component-actions-button-color-outline-color-primary-outline-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-focus-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-focus-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-hover-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-hover-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-active-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-active-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-filled-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-filled-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-filled-focus-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-filled-hover-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-filled-active-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-filled-active-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-outline-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-outline-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-outline-focus-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-outline-hover-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-outline-active-light: #B4B8BB !default;
$emu-component-actions-button-color-outline-color-secondary-outline-active-dark: #B4B8BB !default;
$emu-component-actions-button-group-horizontal-gap: 8px !default;
$emu-component-actions-button-group-horizontal-padding-left: 8px !default;
$emu-component-actions-button-group-horizontal-padding-right: 8px !default;
$emu-component-actions-button-group-horizontal-padding-top: 8px !default;
$emu-component-actions-button-group-horizontal-padding-bottom: 8px !default;
$emu-component-actions-button-group-vertical-gap: 8px !default;
$emu-component-actions-button-group-vertical-padding-left: 8px !default;
$emu-component-actions-button-group-vertical-padding-right: 8px !default;
$emu-component-actions-button-group-vertical-padding-top: 8px !default;
$emu-component-actions-button-group-vertical-padding-bottom: 8px !default;
$emu-component-actions-button-icon-size-height: 12px !default;
$emu-component-actions-button-icon-size-width: 12px !default;
$emu-component-actions-button-icon-spacing: 4px !default;
$emu-component-actions-button-icon-thickness: 2px !default;
$emu-component-actions-button-icon-border-radius-top-left: 8px !default;
$emu-component-actions-button-icon-border-radius-top-right: 8px !default;
$emu-component-actions-button-icon-border-radius-bottom-right: 8px !default;
$emu-component-actions-button-icon-border-radius-bottom-left: 8px !default;
$emu-component-actions-button-icon-transform-open: rotate(0) !default;
$emu-component-actions-button-icon-transform-closed: rotate(180deg) !default;
$emu-component-actions-button-icon-transition: 400ms !default;
$emu-component-actions-button-icon-color-primary-filled-light: #000000 !default;
$emu-component-actions-button-icon-color-primary-filled-dark: #FFFFFF !default;
$emu-component-actions-button-icon-color-primary-filled-focus-light: #000000 !default;
$emu-component-actions-button-icon-color-primary-filled-focus-dark: #FFFFFF !default;
$emu-component-actions-button-icon-color-primary-filled-hover-light: #FFFFFF !default;
$emu-component-actions-button-icon-color-primary-filled-hover-dark: #000000 !default;
$emu-component-actions-button-icon-color-primary-filled-active-light: #FFFFFF !default;
$emu-component-actions-button-icon-color-primary-filled-active-dark: #000000 !default;
$emu-component-actions-button-icon-color-primary-outline-light: #FFFFFF !default;
$emu-component-actions-button-icon-color-primary-outline-dark: #000000 !default;
$emu-component-actions-button-icon-color-primary-outline-focus-light: #FFFFFF !default;
$emu-component-actions-button-icon-color-primary-outline-focus-dark: #000000 !default;
$emu-component-actions-button-icon-color-primary-outline-hover-light: #000000 !default;
$emu-component-actions-button-icon-color-primary-outline-hover-dark: #FFFFFF !default;
$emu-component-actions-button-icon-color-primary-outline-active-light: #000000 !default;
$emu-component-actions-button-icon-color-primary-outline-active-dark: #FFFFFF !default;
$emu-component-actions-button-icon-color-secondary-filled-light: #2e1844 !default;
$emu-component-actions-button-icon-color-secondary-filled-dark: #2e1844 !default;
$emu-component-actions-button-icon-color-secondary-filled-focus-light: #FFFFFF !default;
$emu-component-actions-button-icon-color-secondary-filled-focus-dark: #000000 !default;
$emu-component-actions-button-icon-color-secondary-filled-hover-light: #000000 !default;
$emu-component-actions-button-icon-color-secondary-filled-hover-dark: #FFFFFF !default;
$emu-component-actions-button-icon-color-secondary-filled-active-light: #000000 !default;
$emu-component-actions-button-icon-color-secondary-filled-active-dark: #FFFFFF !default;
$emu-component-actions-button-icon-color-secondary-outline-light: #000000 !default;
$emu-component-actions-button-icon-color-secondary-outline-dark: #FFFFFF !default;
$emu-component-actions-button-icon-color-secondary-outline-hover-light: #000000 !default;
$emu-component-actions-button-icon-color-secondary-outline-hover-dark: #FFFFFF !default;
$emu-component-actions-button-icon-color-secondary-outline-focus-light: #000000 !default;
$emu-component-actions-button-icon-color-secondary-outline-focus-dark: #FFFFFF !default;
$emu-component-actions-button-icon-color-secondary-outline-active-light: #000000 !default;
$emu-component-actions-button-icon-color-secondary-outline-active-dark: #FFFFFF !default;
$emu-component-actions-back-to-top-z-index: 600 !default;
$emu-component-actions-back-to-top-icon-size-height: 32px !default;
$emu-component-actions-back-to-top-icon-size-width: 32px !default;
$emu-component-actions-back-to-top-position-bottom-narrow: 0px !default;
$emu-component-actions-back-to-top-position-bottom-wide: 0px !default;
$emu-component-actions-back-to-top-position-left-narrow: unset !default;
$emu-component-actions-back-to-top-position-left-wide: unset !default;
$emu-component-actions-back-to-top-position-right-narrow: 0px !default;
$emu-component-actions-back-to-top-position-right-wide: 0px !default;
$emu-component-actions-back-to-top-position-top-narrow: unset !default;
$emu-component-actions-back-to-top-position-top-wide: unset !default;
$emu-component-actions-back-to-top-margin-bottom-narrow: 8px !default;
$emu-component-actions-back-to-top-margin-bottom-wide: 8px !default;
$emu-component-actions-back-to-top-margin-left-narrow: 8px !default;
$emu-component-actions-back-to-top-margin-left-wide: 8px !default;
$emu-component-actions-back-to-top-margin-right-narrow: 8px !default;
$emu-component-actions-back-to-top-margin-right-wide: 8px !default;
$emu-component-actions-back-to-top-margin-top-narrow: 8px !default;
$emu-component-actions-back-to-top-margin-top-wide: 8px !default;
$emu-component-containers-scroll-reveal-header-text-typography-narrow: 300 24px/29px BrownStd !default;
$emu-component-containers-scroll-reveal-header-text-typography-wide: 300 36px/43px BrownStd !default;
$emu-component-containers-scroll-reveal-header-text-color-light: #000000 !default;
$emu-component-containers-scroll-reveal-header-text-color-dark: #FFFFFF !default;
$emu-component-containers-scroll-reveal-color-background-light: #FFFFFF !default;
$emu-component-containers-scroll-reveal-color-background-dark: #000000 !default;
$emu-component-containers-scroll-reveal-padding-top-narrow: 8px !default;
$emu-component-containers-scroll-reveal-padding-top-wide: 8px !default;
$emu-component-containers-scroll-reveal-padding-bottom-narrow: 8px !default;
$emu-component-containers-scroll-reveal-padding-bottom-wide: 8px !default;
$emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: #000000 !default;
$emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: #FFFFFF !default;
$emu-component-containers-scroll-reveal-progress-bar-color-bar-light: #B4B8BB !default;
$emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: #B4B8BB !default;
$emu-component-containers-scroll-reveal-static-section-margin-top-narrow: 8px !default;
$emu-component-containers-scroll-reveal-static-section-margin-top-wide: 8px !default;
$emu-component-containers-scroll-reveal-static-section-margin-right-narrow: 8px !default;
$emu-component-containers-scroll-reveal-static-section-margin-right-wide: 32px !default;
$emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: 8px !default;
$emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: 8px !default;
$emu-component-containers-scroll-reveal-static-section-margin-left-narrow: 8px !default;
$emu-component-containers-scroll-reveal-static-section-margin-left-wide: 8px !default;
$emu-component-containers-scroll-reveal-cards-padding-top-narrow: 8px !default;
$emu-component-containers-scroll-reveal-cards-padding-top-wide: 8px !default;
$emu-component-containers-scroll-reveal-cards-padding-right-narrow: 8px !default;
$emu-component-containers-scroll-reveal-cards-padding-right-wide: 8px !default;
$emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: 8px !default;
$emu-component-containers-scroll-reveal-cards-padding-bottom-wide: 8px !default;
$emu-component-containers-scroll-reveal-cards-padding-left-narrow: 8px !default;
$emu-component-containers-scroll-reveal-cards-padding-left-wide: 0px !default;
$emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: 300 24px/29px BrownStd !default;
$emu-component-containers-scroll-reveal-cards-header-text-typography-wide: 300 30px/36px BrownStd !default;
$emu-component-containers-scroll-reveal-cards-header-text-color-light: #000000 !default;
$emu-component-containers-scroll-reveal-cards-header-text-color-dark: #FFFFFF !default;
$emu-component-containers-scroll-reveal-cards-text-typography-narrow: 300 24px/29px BrownStd !default;
$emu-component-containers-scroll-reveal-cards-text-typography-wide: 300 36px/43px BrownStd !default;
$emu-component-containers-scroll-reveal-cards-text-color-light: #000000 !default;
$emu-component-containers-scroll-reveal-cards-text-color-dark: #FFFFFF !default;
$emu-component-containers-comparison-slider-divider-color-light: #000000 !default;
$emu-component-containers-comparison-slider-divider-color-dark: #FFFFFF !default;
$emu-component-containers-comparison-slider-divider-size-width-narrow: 2px !default;
$emu-component-containers-comparison-slider-divider-size-width-wide: 2px !default;
$emu-component-containers-comparison-slider-handle-size-width-narrow: 32px !default;
$emu-component-containers-comparison-slider-handle-size-width-wide: 64px !default;
$emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: 8px !default;
$emu-component-containers-comparison-slider-custom-handle-padding-top-wide: 8px !default;
$emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: 8px !default;
$emu-component-containers-comparison-slider-custom-handle-padding-right-wide: 8px !default;
$emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: 8px !default;
$emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: 8px !default;
$emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: 8px !default;
$emu-component-containers-comparison-slider-custom-handle-padding-left-wide: 8px !default;
$emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: 16px !default;
$emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: 16px !default;
$emu-component-containers-comparison-slider-custom-handle-arrow-color-light: #000000 !default;
$emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: #FFFFFF !default;
$emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: 1px !default;
$emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: 1px !default;
$emu-component-containers-comparison-slider-custom-handle-color-background-light: rgba(0,0,0,0) !default;
$emu-component-containers-comparison-slider-custom-handle-color-background-dark: #000000 !default;
$emu-component-containers-comparison-slider-custom-handle-text-color-light: #000000 !default;
$emu-component-containers-comparison-slider-custom-handle-text-color-dark: #FFFFFF !default;
$emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: 24px !default;
$emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: 30px !default;
$emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: 29px !default;
$emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: 36px !default;
$emu-component-containers-tabs-gap-horizontal-narrow: 0px !default;
$emu-component-containers-tabs-gap-horizontal-wide: 0px !default;
$emu-component-containers-tabs-gap-vertical-narrow: 0px !default;
$emu-component-containers-tabs-gap-vertical-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-top-narrow: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-top-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-right-narrow: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-right-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-bottom-narrow: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-bottom-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-left-narrow: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-left-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: 0px !default;
$emu-component-containers-tabs-navigation-item-gap-horizontal-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-gap-vertical-narrow: 0px !default;
$emu-component-containers-tabs-navigation-item-gap-vertical-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-top-default: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-top-active: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-right-default: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-right-active: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-bottom-default: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-bottom-active: 1px !default;
$emu-component-containers-tabs-navigation-item-border-width-left-default: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-left-active: 0px !default;
$emu-component-containers-tabs-navigation-item-color-border-default-light: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-color-border-default-dark: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-color-border-active-light: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-color-border-active-dark: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-color-background-default-light: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-color-background-default-dark: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-color-background-active-light: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-color-background-active-dark: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-text-color-default-light: #2e1844 !default;
$emu-component-containers-tabs-navigation-item-text-color-default-dark: #2e1844 !default;
$emu-component-containers-tabs-navigation-item-text-color-active-light: #2e1844 !default;
$emu-component-containers-tabs-navigation-item-text-color-active-dark: #2e1844 !default;
$emu-component-containers-tabs-content-padding-top-narrow: 0px !default;
$emu-component-containers-tabs-content-padding-top-wide: 0px !default;
$emu-component-containers-tabs-content-padding-right-narrow: 0px !default;
$emu-component-containers-tabs-content-padding-right-wide: 0px !default;
$emu-component-containers-tabs-content-padding-bottom-narrow: 0px !default;
$emu-component-containers-tabs-content-padding-bottom-wide: 0px !default;
$emu-component-containers-tabs-content-padding-left-narrow: 0px !default;
$emu-component-containers-tabs-content-padding-left-wide: 0px !default;
$emu-component-containers-tabs-content-gap-horizontal-narrow: 0px !default;
$emu-component-containers-tabs-content-gap-horizontal-wide: 0px !default;
$emu-component-containers-tabs-content-gap-vertical-narrow: 0px !default;
$emu-component-containers-tabs-content-gap-vertical-wide: 0px !default;
$emu-component-containers-tabs-content-color-background-light: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-content-color-background-dark: rgba(0,0,0,0) !default;
$emu-component-containers-isi-header-padding-top-narrow: 14px !default;
$emu-component-containers-isi-header-padding-top-wide: 16px !default;
$emu-component-containers-isi-header-padding-right-narrow: 8px !default;
$emu-component-containers-isi-header-padding-right-wide: 16px !default;
$emu-component-containers-isi-header-padding-bottom-narrow: 16px !default;
$emu-component-containers-isi-header-padding-bottom-wide: 10px !default;
$emu-component-containers-isi-header-padding-left-narrow: 24px !default;
$emu-component-containers-isi-header-padding-left-wide: 16px !default;
$emu-component-containers-isi-header-color-background-dark: #000000 !default;
$emu-component-containers-isi-header-color-background-light: rgba(0,0,0,0) !default;
$emu-component-containers-isi-header-color-border-top-dark: #000000 !default;
$emu-component-containers-isi-header-color-border-top-light: #FFFFFF !default;
$emu-component-containers-isi-header-border-width-top: 0px !default;
$emu-component-containers-isi-body-padding-top-narrow: 35px !default;
$emu-component-containers-isi-body-padding-top-wide: 55px !default;
$emu-component-containers-isi-body-padding-right-narrow: 20px !default;
$emu-component-containers-isi-body-padding-right-wide: 71px !default;
$emu-component-containers-isi-body-padding-bottom-narrow: 42px !default;
$emu-component-containers-isi-body-padding-bottom-wide: 55px !default;
$emu-component-containers-isi-body-padding-left-narrow: 20px !default;
$emu-component-containers-isi-body-padding-left-wide: 71px !default;
$emu-component-containers-isi-body-color-background-dark: #000000 !default;
$emu-component-containers-isi-body-color-background-light: #FFFFFF !default;
$emu-component-containers-isi-body-color-border-top-dark: #000000 !default;
$emu-component-containers-isi-body-color-border-top-light: #FFFFFF !default;
$emu-component-containers-isi-body-border-width-top: 1px !default;
$emu-component-containers-isi-open-content-size: 30vh !default;
$emu-component-containers-isi-z-index: 400 !default;
$emu-component-containers-container-max-width: 100% !default;
$emu-component-containers-container-padding-top-narrow: 0px !default;
$emu-component-containers-container-padding-top-wide: 0px !default;
$emu-component-containers-container-padding-right-narrow: 0px !default;
$emu-component-containers-container-padding-right-wide: 0px !default;
$emu-component-containers-container-padding-bottom-narrow: 0px !default;
$emu-component-containers-container-padding-bottom-wide: 0px !default;
$emu-component-containers-container-padding-left-narrow: 0px !default;
$emu-component-containers-container-padding-left-wide: 0px !default;
$emu-component-containers-container-gap-horizontal-narrow: 0px !default;
$emu-component-containers-container-gap-horizontal-wide: 0px !default;
$emu-component-containers-container-gap-vertical-narrow: 0px !default;
$emu-component-containers-container-gap-vertical-wide: 0px !default;
$emu-component-containers-container-color-background-light: rgba(0,0,0,0) !default;
$emu-component-containers-container-color-background-dark: #000000 !default;
$emu-component-containers-carousel-gap-horizontal-narrow: 8px !default;
$emu-component-containers-carousel-gap-horizontal-wide: 8px !default;
$emu-component-containers-carousel-gap-vertical-narrow: 8px !default;
$emu-component-containers-carousel-gap-vertical-wide: 8px !default;
$emu-component-containers-carousel-indicators-margin-top-narrow: 8px !default;
$emu-component-containers-carousel-indicators-margin-top-wide: 8px !default;
$emu-component-containers-carousel-indicators-margin-bottom-narrow: 8px !default;
$emu-component-containers-carousel-indicators-margin-bottom-wide: 8px !default;
$emu-component-containers-carousel-indicators-gap-horizontal-narrow: 8px !default;
$emu-component-containers-carousel-indicators-gap-horizontal-wide: 8px !default;
$emu-component-containers-carousel-indicators-dots-color-background-default-light: #D4D7D8 !default;
$emu-component-containers-carousel-indicators-dots-color-background-default-dark: #D4D7D8 !default;
$emu-component-containers-carousel-indicators-dots-color-background-active-light: #B4B8BB !default;
$emu-component-containers-carousel-indicators-dots-color-background-active-dark: #B4B8BB !default;
$emu-component-containers-carousel-indicators-dots-color-border-default-light: #D4D7D8 !default;
$emu-component-containers-carousel-indicators-dots-color-border-default-dark: #D4D7D8 !default;
$emu-component-containers-carousel-indicators-dots-color-border-active-light: #B4B8BB !default;
$emu-component-containers-carousel-indicators-dots-color-border-active-dark: #B4B8BB !default;
$emu-component-containers-carousel-indicators-dots-border-width-all: 1px !default;
$emu-component-containers-carousel-indicators-dots-size-narrow: 8px !default;
$emu-component-containers-carousel-indicators-dots-size-wide: 8px !default;
$emu-component-containers-carousel-indicators-image-container-size-narrow: 160px !default;
$emu-component-containers-carousel-indicators-image-container-size-wide: 160px !default;
$emu-component-containers-carousel-indicators-image-color-border-default-light: #F4F5F5 !default;
$emu-component-containers-carousel-indicators-image-color-border-default-dark: #F4F5F5 !default;
$emu-component-containers-carousel-indicators-image-color-border-active-light: #D4D7D8 !default;
$emu-component-containers-carousel-indicators-image-color-border-active-dark: #D4D7D8 !default;
$emu-component-containers-carousel-indicators-image-border-width-all: 4px !default;
$emu-component-containers-carousel-indicators-image-size-narrow: 32px !default;
$emu-component-containers-carousel-indicators-image-size-wide: 32px !default;
$emu-component-containers-carousel-indicators-text-color-background-light: #FFFFFF !default;
$emu-component-containers-carousel-indicators-text-color-background-dark: #000000 !default;
$emu-component-containers-carousel-indicators-text-color-text-light: #000000 !default;
$emu-component-containers-carousel-indicators-text-color-text-dark: #FFFFFF !default;
$emu-component-containers-carousel-indicators-text-padding-top-narrow: 8px !default;
$emu-component-containers-carousel-indicators-text-padding-top-wide: 8px !default;
$emu-component-containers-carousel-indicators-text-padding-right-narrow: 0px !default;
$emu-component-containers-carousel-indicators-text-padding-right-wide: 0px !default;
$emu-component-containers-carousel-indicators-text-padding-bottom-narrow: 8px !default;
$emu-component-containers-carousel-indicators-text-padding-bottom-wide: 8px !default;
$emu-component-containers-carousel-indicators-text-padding-left-narrow: 0px !default;
$emu-component-containers-carousel-indicators-text-padding-left-wide: 0px !default;
$emu-component-containers-carousel-indicators-text-font-size-narrow: 24px !default;
$emu-component-containers-carousel-indicators-text-font-size-wide: 30px !default;
$emu-component-containers-carousel-controls-padding-top-narrow: 8px !default;
$emu-component-containers-carousel-controls-padding-top-wide: 8px !default;
$emu-component-containers-carousel-controls-padding-right-narrow: 8px !default;
$emu-component-containers-carousel-controls-padding-right-wide: 8px !default;
$emu-component-containers-carousel-controls-padding-bottom-narrow: 8px !default;
$emu-component-containers-carousel-controls-padding-bottom-wide: 8px !default;
$emu-component-containers-carousel-controls-padding-left-narrow: 8px !default;
$emu-component-containers-carousel-controls-padding-left-wide: 8px !default;
$emu-component-containers-carousel-controls-color-background-light: #FFFFFF !default;
$emu-component-containers-carousel-controls-color-background-dark: #000000 !default;
$emu-component-containers-carousel-controls-color-border-light: #FFFFFF !default;
$emu-component-containers-carousel-controls-color-border-dark: #000000 !default;
$emu-component-containers-carousel-controls-border-width-all: 1px !default;
$emu-component-containers-carousel-controls-z-index: 100 !default;
$emu-component-containers-carousel-controls-icon-color-fill-light: #000000 !default;
$emu-component-containers-carousel-controls-icon-color-fill-dark: #FFFFFF !default;
$emu-component-containers-carousel-controls-icon-size-narrow: 32px !default;
$emu-component-containers-carousel-controls-icon-size-wide: 32px !default;
$emu-component-containers-embed-gap-horizontal-narrow: 0px !default;
$emu-component-containers-embed-gap-horizontal-wide: 0px !default;
$emu-component-containers-embed-gap-vertical-narrow: 0px !default;
$emu-component-containers-embed-gap-vertical-wide: 0px !default;
$emu-component-containers-embed-color-background-light: #FFFFFF !default;
$emu-component-containers-embed-color-background-dark: #000000 !default;
$emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px !default;
$emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px !default;
$emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px !default;
$emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px !default;
$emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400 !default;
$emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600 !default;
$emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7px !default;
$emu-component-containers-embed-player-vimeo-color-background-light: rgba(0, 0, 0, 1) !default;
$emu-component-containers-embed-player-vimeo-color-background-dark: rgba(0, 0, 0, 1) !default;
$emu-component-containers-embed-player-vimeo-outline-color-focus-light: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-outline-color-focus-dark: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px !default;
$emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464 !default;
$emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464 !default;
$emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px !default;
$emu-component-containers-embed-player-vimeo-badge-text-color-light: #ffffff !default;
$emu-component-containers-embed-player-vimeo-badge-text-color-dark: #ffffff !default;
$emu-component-containers-embed-player-vimeo-menu-color-background-light: rgba(255, 255, 255, 0.9) !default;
$emu-component-containers-embed-player-vimeo-menu-color-background-dark: rgba(255, 255, 255, 0.9) !default;
$emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5 !default;
$emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5 !default;
$emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 rgba(0, 0, 0, 0.15) !default;
$emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px !default;
$emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197 !default;
$emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197 !default;
$emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: 13px !default;
$emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464 !default;
$emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464 !default;
$emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px !default;
$emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px !default;
$emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px !default;
$emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px !default;
$emu-component-containers-embed-player-vimeo-controls-global-padding: 7px !default;
$emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px !default;
$emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: rgba(0, 0, 0, 0) !default;
$emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: rgba(0, 0, 0, 0.75) !default;
$emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff !default;
$emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff !default;
$emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff !default;
$emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff !default;
$emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: rgba(0, 0, 0, 0) !default;
$emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: rgba(0, 0, 0, 0.75) !default;
$emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff !default;
$emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff !default;
$emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464 !default;
$emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464 !default;
$emu-component-containers-embed-player-vimeo-tooltip-color-background-light: rgba(255, 255, 255, 0.9) !default;
$emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: rgba(255, 255, 255, 0.9) !default;
$emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464 !default;
$emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464 !default;
$emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 rgba(0, 0, 0, 0.15) !default;
$emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px !default;
$emu-component-containers-embed-player-vimeo-tooltip-padding: 5px !default;
$emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px !default;
$emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: #00b3ff !default;
$emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: rgba(35, 40, 47, 0.6) !default;
$emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: rgba(35, 40, 47, 0.6) !default;
$emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px !default;
$emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px !default;
$emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff !default;
$emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff !default;
$emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 rgba(215, 26, 18, 0.15) !default;
$emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 rgba(215, 26, 18, 0.2) !default;
$emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px !default;
$emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px !default;
$emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: rgba(193, 200, 209, 0.6) !default;
$emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: rgba(193, 200, 209, 0.6) !default;
$emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: rgba(215, 26, 18, 0.1) !default;
$emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: rgba(215, 26, 18, 0.1) !default;
$emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: rgba(255, 255, 255, 0.5) !default;
$emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: rgba(255, 255, 255, 0.5) !default;
$emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-vimeo-captions-text-color-light: #ffffff !default;
$emu-component-containers-embed-player-vimeo-captions-text-color-dark: #ffffff !default;
$emu-component-containers-embed-player-vimeo-captions-color-background-light: rgba(0, 0, 0, 0.8) !default;
$emu-component-containers-embed-player-vimeo-captions-color-background-dark: rgba(0, 0, 0, 0.8) !default;
$emu-component-containers-embed-player-vimeo-time-text-typography-font-size: 13px !default;
$emu-component-containers-embed-player-youtube-global-color-light: #00b3ff !default;
$emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff !default;
$emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px !default;
$emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px !default;
$emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px !default;
$emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px !default;
$emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400 !default;
$emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600 !default;
$emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7px !default;
$emu-component-containers-embed-player-youtube-color-background-light: rgba(0, 0, 0, 1) !default;
$emu-component-containers-embed-player-youtube-color-background-dark: rgba(0, 0, 0, 1) !default;
$emu-component-containers-embed-player-youtube-outline-color-focus-light: #00b3ff !default;
$emu-component-containers-embed-player-youtube-outline-color-focus-dark: #00b3ff !default;
$emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px !default;
$emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464 !default;
$emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464 !default;
$emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px !default;
$emu-component-containers-embed-player-youtube-badge-text-color-light: #ffffff !default;
$emu-component-containers-embed-player-youtube-badge-text-color-dark: #ffffff !default;
$emu-component-containers-embed-player-youtube-menu-color-background-light: rgba(255, 255, 255, 0.9) !default;
$emu-component-containers-embed-player-youtube-menu-color-background-dark: rgba(255, 255, 255, 0.9) !default;
$emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5 !default;
$emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5 !default;
$emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 rgba(0, 0, 0, 0.15) !default;
$emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px !default;
$emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197 !default;
$emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197 !default;
$emu-component-containers-embed-player-youtube-menu-text-typography-font-size: 13px !default;
$emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464 !default;
$emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464 !default;
$emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px !default;
$emu-component-containers-embed-player-youtube-menu-arrow-size: 6px !default;
$emu-component-containers-embed-player-youtube-controls-global-spacing: 10px !default;
$emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px !default;
$emu-component-containers-embed-player-youtube-controls-global-padding: 7px !default;
$emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: #00b3ff !default;
$emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: #00b3ff !default;
$emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px !default;
$emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: rgba(0, 0, 0, 0) !default;
$emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: rgba(0, 0, 0, 0.75) !default;
$emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: #00b3ff !default;
$emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: #00b3ff !default;
$emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff !default;
$emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff !default;
$emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff !default;
$emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff !default;
$emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: rgba(0, 0, 0, 0) !default;
$emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: rgba(0, 0, 0, 0.75) !default;
$emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: #00b3ff !default;
$emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: #00b3ff !default;
$emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff !default;
$emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff !default;
$emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464 !default;
$emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464 !default;
$emu-component-containers-embed-player-youtube-tooltip-color-background-light: rgba(255, 255, 255, 0.9) !default;
$emu-component-containers-embed-player-youtube-tooltip-color-background-dark: rgba(255, 255, 255, 0.9) !default;
$emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464 !default;
$emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464 !default;
$emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 rgba(0, 0, 0, 0.15) !default;
$emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px !default;
$emu-component-containers-embed-player-youtube-tooltip-padding: 5px !default;
$emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px !default;
$emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: #00b3ff !default;
$emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: #00b3ff !default;
$emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: rgba(35, 40, 47, 0.6) !default;
$emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: rgba(35, 40, 47, 0.6) !default;
$emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px !default;
$emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px !default;
$emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff !default;
$emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff !default;
$emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 rgba(215, 26, 18, 0.15) !default;
$emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 rgba(215, 26, 18, 0.2) !default;
$emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px !default;
$emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px !default;
$emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: rgba(193, 200, 209, 0.6) !default;
$emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: rgba(193, 200, 209, 0.6) !default;
$emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: rgba(215, 26, 18, 0.1) !default;
$emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: rgba(215, 26, 18, 0.1) !default;
$emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: rgba(255, 255, 255, 0.5) !default;
$emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: rgba(255, 255, 255, 0.5) !default;
$emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: rgba(255, 255, 255, 0.25) !default;
$emu-component-containers-embed-player-youtube-captions-text-color-light: #ffffff !default;
$emu-component-containers-embed-player-youtube-captions-text-color-dark: #ffffff !default;
$emu-component-containers-embed-player-youtube-captions-color-background-light: rgba(0, 0, 0, 0.8) !default;
$emu-component-containers-embed-player-youtube-captions-color-background-dark: rgba(0, 0, 0, 0.8) !default;
$emu-component-containers-embed-player-youtube-time-text-typography-font-size: 13px !default;
$emu-component-containers-gallery-padding-top-narrow: 8px !default;
$emu-component-containers-gallery-padding-top-wide: 8px !default;
$emu-component-containers-gallery-padding-right-narrow: 8px !default;
$emu-component-containers-gallery-padding-right-wide: 8px !default;
$emu-component-containers-gallery-padding-bottom-narrow: 8px !default;
$emu-component-containers-gallery-padding-bottom-wide: 8px !default;
$emu-component-containers-gallery-padding-left-narrow: 8px !default;
$emu-component-containers-gallery-padding-left-wide: 8px !default;
$emu-component-containers-gallery-margin-top-narrow: 8px !default;
$emu-component-containers-gallery-margin-top-wide: 8px !default;
$emu-component-containers-gallery-margin-right-narrow: 8px !default;
$emu-component-containers-gallery-margin-right-wide: 8px !default;
$emu-component-containers-gallery-margin-bottom-narrow: 8px !default;
$emu-component-containers-gallery-margin-bottom-wide: 8px !default;
$emu-component-containers-gallery-margin-left-narrow: 8px !default;
$emu-component-containers-gallery-margin-left-wide: 8px !default;
$emu-component-containers-gallery-border-width: 1px !default;
$emu-component-containers-gallery-color-background-light: #000000 !default;
$emu-component-containers-gallery-color-background-dark: #FFFFFF !default;
$emu-component-containers-gallery-color-border-light: #000000 !default;
$emu-component-containers-gallery-color-border-dark: #FFFFFF !default;
$emu-component-containers-gallery-separator-content-padding-top-narrow: 8px !default;
$emu-component-containers-gallery-separator-content-padding-top-wide: 8px !default;
$emu-component-containers-gallery-separator-content-padding-right-narrow: 0px !default;
$emu-component-containers-gallery-separator-content-padding-right-wide: 0px !default;
$emu-component-containers-gallery-separator-content-padding-bottom-narrow: 8px !default;
$emu-component-containers-gallery-separator-content-padding-bottom-wide: 8px !default;
$emu-component-containers-gallery-separator-content-padding-left-narrow: 0px !default;
$emu-component-containers-gallery-separator-content-padding-left-wide: 0px !default;
$emu-component-containers-gallery-item-sizing-width-narrow: 100% !default;
$emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px) !default;
$emu-component-containers-gallery-item-padding-top-narrow: 0px !default;
$emu-component-containers-gallery-item-padding-top-wide: 0px !default;
$emu-component-containers-gallery-item-padding-right-narrow: 0px !default;
$emu-component-containers-gallery-item-padding-right-wide: 0px !default;
$emu-component-containers-gallery-item-padding-bottom-narrow: 0px !default;
$emu-component-containers-gallery-item-padding-bottom-wide: 0px !default;
$emu-component-containers-gallery-item-padding-left-narrow: 0px !default;
$emu-component-containers-gallery-item-padding-left-wide: 0px !default;
$emu-component-containers-gallery-item-margin-top-narrow: 0px !default;
$emu-component-containers-gallery-item-margin-top-wide: 0px !default;
$emu-component-containers-gallery-item-margin-right-narrow: 0px !default;
$emu-component-containers-gallery-item-margin-right-wide: 0px !default;
$emu-component-containers-gallery-item-margin-bottom-narrow: 0px !default;
$emu-component-containers-gallery-item-margin-bottom-wide: 0px !default;
$emu-component-containers-gallery-item-margin-left-narrow: 0px !default;
$emu-component-containers-gallery-item-margin-left-wide: 0px !default;
$emu-component-containers-gallery-item-border-width: 1px !default;
$emu-component-containers-gallery-item-text-color-light: #000000 !default;
$emu-component-containers-gallery-item-text-color-dark: #FFFFFF !default;
$emu-component-containers-gallery-item-color-background-light: #000000 !default;
$emu-component-containers-gallery-item-color-background-dark: #FFFFFF !default;
$emu-component-containers-gallery-item-color-border-light: #000000 !default;
$emu-component-containers-gallery-item-color-border-dark: #FFFFFF !default;
$emu-component-containers-gallery-item-active-text-color-light: #000000 !default;
$emu-component-containers-gallery-item-active-text-color-dark: #FFFFFF !default;
$emu-component-containers-gallery-item-active-color-background-light: #000000 !default;
$emu-component-containers-gallery-item-active-color-background-dark: #FFFFFF !default;
$emu-component-containers-gallery-item-active-color-border-light: #000000 !default;
$emu-component-containers-gallery-item-active-color-border-dark: #FFFFFF !default;
$emu-component-containers-gallery-item-hover-text-color-light: #000000 !default;
$emu-component-containers-gallery-item-hover-text-color-dark: #FFFFFF !default;
$emu-component-containers-gallery-item-hover-color-background-light: #000000 !default;
$emu-component-containers-gallery-item-hover-color-background-dark: #FFFFFF !default;
$emu-component-containers-gallery-item-hover-color-border-light: #000000 !default;
$emu-component-containers-gallery-item-hover-color-border-dark: #FFFFFF !default;
$emu-component-containers-gallery-stage-container-padding-top-narrow: 0px !default;
$emu-component-containers-gallery-stage-container-padding-top-wide: 0px !default;
$emu-component-containers-gallery-stage-container-padding-right-narrow: 0px !default;
$emu-component-containers-gallery-stage-container-padding-right-wide: 0px !default;
$emu-component-containers-gallery-stage-container-padding-bottom-narrow: 0px !default;
$emu-component-containers-gallery-stage-container-padding-bottom-wide: 0px !default;
$emu-component-containers-gallery-stage-container-padding-left-narrow: 0px !default;
$emu-component-containers-gallery-stage-container-padding-left-wide: 0px !default;
$emu-component-containers-gallery-stage-container-margin-top-narrow: 0px !default;
$emu-component-containers-gallery-stage-container-margin-top-wide: 0px !default;
$emu-component-containers-gallery-stage-container-margin-right-narrow: 0px !default;
$emu-component-containers-gallery-stage-container-margin-right-wide: 0px !default;
$emu-component-containers-gallery-stage-container-margin-bottom-narrow: 0px !default;
$emu-component-containers-gallery-stage-container-margin-bottom-wide: 0px !default;
$emu-component-containers-gallery-stage-container-margin-left-narrow: 0px !default;
$emu-component-containers-gallery-stage-container-margin-left-wide: 0px !default;
$emu-component-containers-gallery-preview-container-padding-top-narrow: 0px !default;
$emu-component-containers-gallery-preview-container-padding-top-wide: 0px !default;
$emu-component-containers-gallery-preview-container-padding-right-narrow: 0px !default;
$emu-component-containers-gallery-preview-container-padding-right-wide: 0px !default;
$emu-component-containers-gallery-preview-container-padding-bottom-narrow: 0px !default;
$emu-component-containers-gallery-preview-container-padding-bottom-wide: 0px !default;
$emu-component-containers-gallery-preview-container-padding-left-narrow: 0px !default;
$emu-component-containers-gallery-preview-container-padding-left-wide: 0px !default;
$emu-component-containers-gallery-preview-container-margin-top-narrow: 0px !default;
$emu-component-containers-gallery-preview-container-margin-top-wide: 0px !default;
$emu-component-containers-gallery-preview-container-margin-right-narrow: 0px !default;
$emu-component-containers-gallery-preview-container-margin-right-wide: 0px !default;
$emu-component-containers-gallery-preview-container-margin-bottom-narrow: 0px !default;
$emu-component-containers-gallery-preview-container-margin-bottom-wide: 0px !default;
$emu-component-containers-gallery-preview-container-margin-left-narrow: 0px !default;
$emu-component-containers-gallery-preview-container-margin-left-wide: 0px !default;
$emu-component-containers-gallery-preview-container-gap-narrow: 8px !default;
$emu-component-containers-gallery-preview-container-gap-wide: 8px !default;
$emu-component-layers-modal-z-index: 600 !default;
$emu-component-layers-modal-scrim-color-background: rgba(46, 24, 68, 0.8) !default;
$emu-component-layers-modal-body-sizing-width: 100% !default;
$emu-component-layers-modal-body-sizing-max-width: 636px !default;
$emu-component-layers-modal-body-padding-top-narrow: 32px !default;
$emu-component-layers-modal-body-padding-top-wide: 46px !default;
$emu-component-layers-modal-body-padding-right-narrow: 20px !default;
$emu-component-layers-modal-body-padding-right-wide: 48px !default;
$emu-component-layers-modal-body-padding-bottom-narrow: 32px !default;
$emu-component-layers-modal-body-padding-bottom-wide: 52px !default;
$emu-component-layers-modal-body-padding-left-narrow: 20px !default;
$emu-component-layers-modal-body-padding-left-wide: 56px !default;
$emu-component-layers-modal-body-color-background-light: #000000 !default;
$emu-component-layers-modal-body-color-background-dark: #FFFFFF !default;
$emu-component-layers-modal-body-color-border-light: #000000 !default;
$emu-component-layers-modal-body-color-border-dark: #FFFFFF !default;
$emu-component-layers-modal-body-border-width-all: 1px !default;
$emu-component-layers-modal-actions-close-padding-top: 0px !default;
$emu-component-layers-modal-actions-close-padding-right: 0px !default;
$emu-component-layers-modal-actions-close-padding-bottom: 0px !default;
$emu-component-layers-modal-actions-close-padding-left: 0px !default;
$emu-component-layers-modal-actions-close-text-typography: 700 30px/36px BrownStd !default;
$emu-component-layers-modal-actions-close-text-color-light: #000000 !default;
$emu-component-layers-modal-actions-close-text-color-dark: #FFFFFF !default;
$emu-component-layers-modal-actions-prompt-button-group-gap-narrow: 8px !default;
$emu-component-layers-modal-actions-prompt-button-group-gap-wide: 8px !default;
$emu-component-layers-modal-actions-prompt-button-group-margin-top: 8px !default;
$emu-component-layers-cookie-consent-banner-z-index: 700 !default;
$emu-component-layers-cookie-consent-banner-max-width: 100%px !default;
$emu-component-layers-cookie-consent-banner-text-typography-narrow: 400 12px/17px BrownStd !default;
$emu-component-layers-cookie-consent-banner-text-typography-wide: 400 12px/17px BrownStd !default;
$emu-component-layers-cookie-consent-banner-text-color-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-text-color-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-color-background-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-color-background-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.2) !default;
$emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px !default;
$emu-component-layers-cookie-consent-banner-padding-top-wide: 24px !default;
$emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px !default;
$emu-component-layers-cookie-consent-banner-padding-right-wide: 32px !default;
$emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px !default;
$emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px !default;
$emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px !default;
$emu-component-layers-cookie-consent-banner-padding-left-wide: 32px !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: 400 12px/17px BrownStd !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: 400 12px/17px BrownStd !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px !default;
$emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px !default;
$emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px !default;
$emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-light: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: #B4B8BB !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: #B4B8BB !default;

$component: (
  'component': (
    'cards': (
      'card': (
        'margin': (
          'top': (
            'narrow': $emu-component-cards-card-margin-top-narrow,
            'wide': $emu-component-cards-card-margin-top-wide
          ),
          'right': (
            'narrow': $emu-component-cards-card-margin-right-narrow,
            'wide': $emu-component-cards-card-margin-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-cards-card-margin-bottom-narrow,
            'wide': $emu-component-cards-card-margin-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-cards-card-margin-left-narrow,
            'wide': $emu-component-cards-card-margin-left-wide
          )
        ),
        'padding': (
          'top': (
            'narrow': $emu-component-cards-card-padding-top-narrow,
            'wide': $emu-component-cards-card-padding-top-wide
          ),
          'right': (
            'narrow': $emu-component-cards-card-padding-right-narrow,
            'wide': $emu-component-cards-card-padding-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-cards-card-padding-bottom-narrow,
            'wide': $emu-component-cards-card-padding-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-cards-card-padding-left-narrow,
            'wide': $emu-component-cards-card-padding-left-wide
          )
        ),
        'color': (
          'background': (
            'dark': $emu-component-cards-card-color-background-dark,
            'light': $emu-component-cards-card-color-background-light
          )
        ),
        'header': (
          'color': (
            'background': (
              'light': $emu-component-cards-card-header-color-background-light,
              'dark': $emu-component-cards-card-header-color-background-dark
            )
          ),
          'button': (
            'text': (
              'color': (
                'light': $emu-component-cards-card-header-button-text-color-light,
                'dark': $emu-component-cards-card-header-button-text-color-dark,
                'focus': (
                  'light': $emu-component-cards-card-header-button-text-color-focus-light,
                  'dark': $emu-component-cards-card-header-button-text-color-focus-dark
                ),
                'hover': (
                  'light': $emu-component-cards-card-header-button-text-color-hover-light,
                  'dark': $emu-component-cards-card-header-button-text-color-hover-dark
                ),
                'active': (
                  'light': $emu-component-cards-card-header-button-text-color-active-light,
                  'dark': $emu-component-cards-card-header-button-text-color-active-dark
                )
              )
            ),
            'icon': (
              'size': (
                'height': $emu-component-cards-card-header-button-icon-size-height,
                'width': $emu-component-cards-card-header-button-icon-size-width
              ),
              'spacing': $emu-component-cards-card-header-button-icon-spacing,
              'color': (
                'light': $emu-component-cards-card-header-button-icon-color-light,
                'dark': $emu-component-cards-card-header-button-icon-color-dark,
                'focus': (
                  'light': $emu-component-cards-card-header-button-icon-color-focus-light,
                  'dark': $emu-component-cards-card-header-button-icon-color-focus-dark
                ),
                'hover': (
                  'light': $emu-component-cards-card-header-button-icon-color-hover-light,
                  'dark': $emu-component-cards-card-header-button-icon-color-hover-dark
                ),
                'active': (
                  'light': $emu-component-cards-card-header-button-icon-color-active-light,
                  'dark': $emu-component-cards-card-header-button-icon-color-active-dark
                )
              ),
              'thickness': $emu-component-cards-card-header-button-icon-thickness
            ),
            'color': (
              'background': (
                'light': $emu-component-cards-card-header-button-color-background-light,
                'dark': $emu-component-cards-card-header-button-color-background-dark,
                'focus': (
                  'light': $emu-component-cards-card-header-button-color-background-focus-light,
                  'dark': $emu-component-cards-card-header-button-color-background-focus-dark
                ),
                'hover': (
                  'light': $emu-component-cards-card-header-button-color-background-hover-light,
                  'dark': $emu-component-cards-card-header-button-color-background-hover-dark
                ),
                'active': (
                  'light': $emu-component-cards-card-header-button-color-background-active-light,
                  'dark': $emu-component-cards-card-header-button-color-background-active-dark
                )
              ),
              'border': (
                'light': $emu-component-cards-card-header-button-color-border-light,
                'dark': $emu-component-cards-card-header-button-color-border-dark,
                'focus': (
                  'light': $emu-component-cards-card-header-button-color-border-focus-light,
                  'dark': $emu-component-cards-card-header-button-color-border-focus-dark
                ),
                'hover': (
                  'light': $emu-component-cards-card-header-button-color-border-hover-light,
                  'dark': $emu-component-cards-card-header-button-color-border-hover-dark
                ),
                'active': (
                  'light': $emu-component-cards-card-header-button-color-border-active-light,
                  'dark': $emu-component-cards-card-header-button-color-border-active-dark
                )
              )
            )
          )
        ),
        'body': (
          'color': (
            'background': (
              'dark': $emu-component-cards-card-body-color-background-dark,
              'light': $emu-component-cards-card-body-color-background-light
            )
          )
        )
      ),
      'teaser': (
        'padding': (
          'top': (
            'narrow': $emu-component-cards-teaser-padding-top-narrow,
            'wide': $emu-component-cards-teaser-padding-top-wide
          ),
          'right': (
            'narrow': $emu-component-cards-teaser-padding-right-narrow,
            'wide': $emu-component-cards-teaser-padding-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-cards-teaser-padding-bottom-narrow,
            'wide': $emu-component-cards-teaser-padding-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-cards-teaser-padding-left-narrow,
            'wide': $emu-component-cards-teaser-padding-left-wide
          )
        ),
        'text': (
          'color': (
            'light': $emu-component-cards-teaser-text-color-light,
            'dark': $emu-component-cards-teaser-text-color-dark
          )
        ),
        'title': (
          'max-width': $emu-component-cards-teaser-title-max-width,
          'margin': (
            'top': (
              'narrow': $emu-component-cards-teaser-title-margin-top-narrow,
              'wide': $emu-component-cards-teaser-title-margin-top-wide
            ),
            'bottom': (
              'narrow': $emu-component-cards-teaser-title-margin-bottom-narrow,
              'wide': $emu-component-cards-teaser-title-margin-bottom-wide
            )
          )
        ),
        'description': (
          'margin': (
            'top': (
              'narrow': $emu-component-cards-teaser-description-margin-top-narrow,
              'wide': $emu-component-cards-teaser-description-margin-top-wide
            ),
            'bottom': (
              'narrow': $emu-component-cards-teaser-description-margin-bottom-narrow,
              'wide': $emu-component-cards-teaser-description-margin-bottom-wide
            )
          )
        )
      )
    ),
    'lists': (
      'accordion': (
        'color': (
          'background': (
            'light': $emu-component-lists-accordion-color-background-light,
            'dark': $emu-component-lists-accordion-color-background-dark
          ),
          'text': (
            'light': $emu-component-lists-accordion-color-text-light,
            'dark': $emu-component-lists-accordion-color-text-dark
          )
        ),
        'borderRadius': (
          'topLeft': $emu-component-lists-accordion-border-radius-top-left,
          'topRight': $emu-component-lists-accordion-border-radius-top-right,
          'bottomRight': $emu-component-lists-accordion-border-radius-bottom-right,
          'bottomLeft': $emu-component-lists-accordion-border-radius-bottom-left
        ),
        'margin': (
          'top': (
            'narrow': $emu-component-lists-accordion-margin-top-narrow,
            'wide': $emu-component-lists-accordion-margin-top-wide
          ),
          'right': (
            'narrow': $emu-component-lists-accordion-margin-right-narrow,
            'wide': $emu-component-lists-accordion-margin-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-lists-accordion-margin-bottom-narrow,
            'wide': $emu-component-lists-accordion-margin-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-lists-accordion-margin-left-narrow,
            'wide': $emu-component-lists-accordion-margin-left-wide
          )
        ),
        'item': (
          'color': (
            'borderBottom': (
              'closed': (
                'dark': $emu-component-lists-accordion-item-color-border-bottom-closed-dark,
                'light': $emu-component-lists-accordion-item-color-border-bottom-closed-light
              ),
              'open': (
                'dark': $emu-component-lists-accordion-item-color-border-bottom-open-dark,
                'light': $emu-component-lists-accordion-item-color-border-bottom-open-light
              )
            )
          ),
          'body': (
            'color': (
              'background': (
                'light': $emu-component-lists-accordion-item-body-color-background-light,
                'dark': $emu-component-lists-accordion-item-body-color-background-dark
              )
            ),
            'transition': $emu-component-lists-accordion-item-body-transition,
            'padding': (
              'top': (
                'narrow': $emu-component-lists-accordion-item-body-padding-top-narrow,
                'wide': $emu-component-lists-accordion-item-body-padding-top-wide
              ),
              'right': (
                'narrow': $emu-component-lists-accordion-item-body-padding-right-narrow,
                'wide': $emu-component-lists-accordion-item-body-padding-right-wide
              ),
              'bottom': (
                'narrow': $emu-component-lists-accordion-item-body-padding-bottom-narrow,
                'wide': $emu-component-lists-accordion-item-body-padding-bottom-wide
              ),
              'left': (
                'narrow': $emu-component-lists-accordion-item-body-padding-left-narrow,
                'wide': $emu-component-lists-accordion-item-body-padding-left-wide
              )
            )
          ),
          'header': (
            'text': (
              'typography': (
                'narrow': $emu-component-lists-accordion-item-header-text-typography-narrow,
                'wide': $emu-component-lists-accordion-item-header-text-typography-wide
              )
            ),
            'padding': (
              'top': (
                'narrow': $emu-component-lists-accordion-item-header-padding-top-narrow,
                'wide': $emu-component-lists-accordion-item-header-padding-top-wide
              ),
              'right': (
                'narrow': $emu-component-lists-accordion-item-header-padding-right-narrow,
                'wide': $emu-component-lists-accordion-item-header-padding-right-wide
              ),
              'bottom': (
                'narrow': $emu-component-lists-accordion-item-header-padding-bottom-narrow,
                'wide': $emu-component-lists-accordion-item-header-padding-bottom-wide
              ),
              'left': (
                'narrow': $emu-component-lists-accordion-item-header-padding-left-narrow,
                'wide': $emu-component-lists-accordion-item-header-padding-left-wide
              )
            ),
            'icon': (
              'spacing': $emu-component-lists-accordion-item-header-icon-spacing,
              'size': (
                'height': $emu-component-lists-accordion-item-header-icon-size-height,
                'width': $emu-component-lists-accordion-item-header-icon-size-width
              ),
              'thickness': $emu-component-lists-accordion-item-header-icon-thickness,
              'borderRadius': (
                'topLeft': $emu-component-lists-accordion-item-header-icon-border-radius-top-left,
                'topRight': $emu-component-lists-accordion-item-header-icon-border-radius-top-right,
                'bottomRight': $emu-component-lists-accordion-item-header-icon-border-radius-bottom-right,
                'bottomLeft': $emu-component-lists-accordion-item-header-icon-border-radius-bottom-left
              ),
              'transform': (
                'open': $emu-component-lists-accordion-item-header-icon-transform-open,
                'closed': $emu-component-lists-accordion-item-header-icon-transform-closed
              ),
              'transition': $emu-component-lists-accordion-item-header-icon-transition,
              'color': (
                'fill': (
                  'open': (
                    'light': $emu-component-lists-accordion-item-header-icon-color-fill-open-light,
                    'dark': $emu-component-lists-accordion-item-header-icon-color-fill-open-dark
                  ),
                  'closed': (
                    'light': $emu-component-lists-accordion-item-header-icon-color-fill-closed-light,
                    'dark': $emu-component-lists-accordion-item-header-icon-color-fill-closed-dark
                  )
                )
              )
            ),
            'color': (
              'background': (
                'light': $emu-component-lists-accordion-item-header-color-background-light,
                'dark': $emu-component-lists-accordion-item-header-color-background-dark
              )
            )
          ),
          'borderWidth': (
            'bottom': (
              'open': $emu-component-lists-accordion-item-border-width-bottom-open
            )
          )
        )
      ),
      'navigation': (
        'banner': (
          'color': (
            'background': (
              'light': $emu-component-lists-navigation-banner-color-background-light,
              'dark': $emu-component-lists-navigation-banner-color-background-dark
            )
          ),
          'item': (
            'color': (
              'background': (
                'default': (
                  'light': $emu-component-lists-navigation-banner-item-color-background-default-light,
                  'dark': $emu-component-lists-navigation-banner-item-color-background-default-dark
                ),
                'hover': (
                  'light': $emu-component-lists-navigation-banner-item-color-background-hover-light,
                  'dark': $emu-component-lists-navigation-banner-item-color-background-hover-dark
                )
              ),
              'border': (
                'default': (
                  'light': $emu-component-lists-navigation-banner-item-color-border-default-light,
                  'dark': $emu-component-lists-navigation-banner-item-color-border-default-dark
                ),
                'hover': (
                  'light': $emu-component-lists-navigation-banner-item-color-border-hover-light,
                  'dark': $emu-component-lists-navigation-banner-item-color-border-hover-dark
                )
              )
            ),
            'text': (
              'color': (
                'default': (
                  'light': $emu-component-lists-navigation-banner-item-text-color-default-light,
                  'dark': $emu-component-lists-navigation-banner-item-text-color-default-dark
                ),
                'hover': (
                  'light': $emu-component-lists-navigation-banner-item-text-color-hover-light,
                  'dark': $emu-component-lists-navigation-banner-item-text-color-hover-dark
                )
              )
            ),
            'borderWidth': $emu-component-lists-navigation-banner-item-border-width
          ),
          'padding': (
            'top': (
              'narrow': $emu-component-lists-navigation-banner-padding-top-narrow,
              'wide': $emu-component-lists-navigation-banner-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-lists-navigation-banner-padding-right-narrow,
              'wide': $emu-component-lists-navigation-banner-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-lists-navigation-banner-padding-bottom-narrow,
              'wide': $emu-component-lists-navigation-banner-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-lists-navigation-banner-padding-left-narrow,
              'wide': $emu-component-lists-navigation-banner-padding-left-wide
            )
          )
        ),
        'dropdown': (
          'color': (
            'background': (
              'light': $emu-component-lists-navigation-dropdown-color-background-light,
              'dark': $emu-component-lists-navigation-dropdown-color-background-dark
            ),
            'border': (
              'light': $emu-component-lists-navigation-dropdown-color-border-light,
              'dark': $emu-component-lists-navigation-dropdown-color-border-dark
            )
          ),
          'text': (
            'color': (
              'default': (
                'light': $emu-component-lists-navigation-dropdown-text-color-default-light,
                'dark': $emu-component-lists-navigation-dropdown-text-color-default-dark
              ),
              'hover': (
                'light': $emu-component-lists-navigation-dropdown-text-color-hover-light,
                'dark': $emu-component-lists-navigation-dropdown-text-color-hover-dark
              )
            )
          ),
          'borderWidth': $emu-component-lists-navigation-dropdown-border-width,
          'item': (
            'color': (
              'background': (
                'default': (
                  'light': $emu-component-lists-navigation-dropdown-item-color-background-default-light,
                  'dark': $emu-component-lists-navigation-dropdown-item-color-background-default-dark
                ),
                'hover': (
                  'light': $emu-component-lists-navigation-dropdown-item-color-background-hover-light,
                  'dark': $emu-component-lists-navigation-dropdown-item-color-background-hover-dark
                )
              )
            ),
            'text': (
              'color': (
                'default': (
                  'light': $emu-component-lists-navigation-dropdown-item-text-color-default-light,
                  'dark': $emu-component-lists-navigation-dropdown-item-text-color-default-dark
                ),
                'hover': (
                  'light': $emu-component-lists-navigation-dropdown-item-text-color-hover-light,
                  'dark': $emu-component-lists-navigation-dropdown-item-text-color-hover-dark
                )
              )
            )
          )
        ),
        'zIndex': $emu-component-lists-navigation-z-index,
        'text': (
          'padding': (
            'top': (
              'narrow': $emu-component-lists-navigation-text-padding-top-narrow,
              'wide': $emu-component-lists-navigation-text-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-lists-navigation-text-padding-right-narrow,
              'wide': $emu-component-lists-navigation-text-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-lists-navigation-text-padding-bottom-narrow,
              'wide': $emu-component-lists-navigation-text-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-lists-navigation-text-padding-left-narrow,
              'wide': $emu-component-lists-navigation-text-padding-left-wide
            )
          )
        )
      )
    ),
    'feeds': (
      'instagram': (
        'padding': (
          'top': (
            'narrow': $emu-component-feeds-instagram-padding-top-narrow,
            'wide': $emu-component-feeds-instagram-padding-top-wide
          ),
          'right': (
            'narrow': $emu-component-feeds-instagram-padding-right-narrow,
            'wide': $emu-component-feeds-instagram-padding-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-feeds-instagram-padding-bottom-narrow,
            'wide': $emu-component-feeds-instagram-padding-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-feeds-instagram-padding-left-narrow,
            'wide': $emu-component-feeds-instagram-padding-left-wide
          )
        ),
        'post': (
          'gap': (
            'narrow': $emu-component-feeds-instagram-post-gap-narrow,
            'wide': $emu-component-feeds-instagram-post-gap-wide
          )
        )
      )
    ),
    'ingredients': (
      'icon': (
        'color': (
          'fill': (
            'light': $emu-component-ingredients-icon-color-fill-light,
            'dark': $emu-component-ingredients-icon-color-fill-dark
          )
        ),
        'size': (
          'height': $emu-component-ingredients-icon-size-height,
          'width': $emu-component-ingredients-icon-size-width
        )
      ),
      'separator': (
        'color': (
          'background': (
            'light': $emu-component-ingredients-separator-color-background-light,
            'dark': $emu-component-ingredients-separator-color-background-dark
          )
        ),
        'size': (
          'height': $emu-component-ingredients-separator-size-height
        )
      ),
      'title': (
        'h1': (
          'typography': (
            'narrow': $emu-component-ingredients-title-h1-typography-narrow,
            'wide': $emu-component-ingredients-title-h1-typography-wide
          )
        ),
        'h2': (
          'typography': (
            'narrow': $emu-component-ingredients-title-h2-typography-narrow,
            'wide': $emu-component-ingredients-title-h2-typography-wide
          )
        ),
        'h3': (
          'typography': (
            'narrow': $emu-component-ingredients-title-h3-typography-narrow,
            'wide': $emu-component-ingredients-title-h3-typography-wide
          )
        ),
        'h4': (
          'typography': (
            'narrow': $emu-component-ingredients-title-h4-typography-narrow,
            'wide': $emu-component-ingredients-title-h4-typography-wide
          )
        ),
        'h5': (
          'typography': (
            'narrow': $emu-component-ingredients-title-h5-typography-narrow,
            'wide': $emu-component-ingredients-title-h5-typography-wide
          )
        ),
        'color': (
          'light': $emu-component-ingredients-title-color-light,
          'dark': $emu-component-ingredients-title-color-dark
        )
      ),
      'text': (
        'text': (
          'color': (
            'light': $emu-component-ingredients-text-text-color-light,
            'dark': $emu-component-ingredients-text-text-color-dark
          ),
          'typography': (
            'narrow': $emu-component-ingredients-text-text-typography-narrow,
            'wide': $emu-component-ingredients-text-text-typography-wide
          )
        )
      ),
      'loader': (
        'color': (
          'fill': (
            'light': $emu-component-ingredients-loader-color-fill-light,
            'dark': $emu-component-ingredients-loader-color-fill-dark
          )
        )
      ),
      'imageMap': (
        'buttons': (
          'size': (
            'height': $emu-component-ingredients-image-map-buttons-size-height,
            'width': $emu-component-ingredients-image-map-buttons-size-width
          ),
          'color': (
            'background': (
              'light': $emu-component-ingredients-image-map-buttons-color-background-light,
              'dark': $emu-component-ingredients-image-map-buttons-color-background-dark
            ),
            'icon': (
              'light': $emu-component-ingredients-image-map-buttons-color-icon-light,
              'dark': $emu-component-ingredients-image-map-buttons-color-icon-dark
            )
          ),
          'borderRadius': (
            'topLeft': $emu-component-ingredients-image-map-buttons-border-radius-top-left,
            'topRight': $emu-component-ingredients-image-map-buttons-border-radius-top-right,
            'bottomRight': $emu-component-ingredients-image-map-buttons-border-radius-bottom-right,
            'bottomLeft': $emu-component-ingredients-image-map-buttons-border-radius-bottom-left
          ),
          'shadow': $emu-component-ingredients-image-map-buttons-shadow
        )
      ),
      'googleMap': (
        'color': (
          'background': (
            'light': $emu-component-ingredients-google-map-color-background-light,
            'dark': $emu-component-ingredients-google-map-color-background-dark
          )
        ),
        'margin': (
          'top': (
            'narrow': $emu-component-ingredients-google-map-margin-top-narrow,
            'wide': $emu-component-ingredients-google-map-margin-top-wide
          ),
          'right': (
            'narrow': $emu-component-ingredients-google-map-margin-right-narrow,
            'wide': $emu-component-ingredients-google-map-margin-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-ingredients-google-map-margin-bottom-narrow,
            'wide': $emu-component-ingredients-google-map-margin-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-ingredients-google-map-margin-left-narrow,
            'wide': $emu-component-ingredients-google-map-margin-left-wide
          )
        )
      ),
      'selectInput': (
        'option': (
          'text': (
            'color': (
              'filled': (
                'default': (
                  'light': $emu-component-ingredients-select-input-option-text-color-filled-default-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-filled-default-dark
                ),
                'hover': (
                  'light': $emu-component-ingredients-select-input-option-text-color-filled-hover-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-ingredients-select-input-option-text-color-filled-active-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-filled-active-dark
                ),
                'focus': (
                  'light': $emu-component-ingredients-select-input-option-text-color-filled-focus-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-filled-focus-dark
                ),
                'disabled': (
                  'light': $emu-component-ingredients-select-input-option-text-color-filled-disabled-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-filled-disabled-dark
                ),
                'error': (
                  'light': $emu-component-ingredients-select-input-option-text-color-filled-error-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-filled-error-dark
                )
              ),
              'outline': (
                'default': (
                  'light': $emu-component-ingredients-select-input-option-text-color-outline-default-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-outline-default-dark
                ),
                'hover': (
                  'light': $emu-component-ingredients-select-input-option-text-color-outline-hover-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-ingredients-select-input-option-text-color-outline-active-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-outline-active-dark
                ),
                'focus': (
                  'light': $emu-component-ingredients-select-input-option-text-color-outline-focus-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-outline-focus-dark
                ),
                'disabled': (
                  'light': $emu-component-ingredients-select-input-option-text-color-outline-disabled-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-outline-disabled-dark
                ),
                'error': (
                  'light': $emu-component-ingredients-select-input-option-text-color-outline-error-light,
                  'dark': $emu-component-ingredients-select-input-option-text-color-outline-error-dark
                )
              )
            )
          ),
          'color': (
            'background': (
              'default': (
                'filled': (
                  'light': $emu-component-ingredients-select-input-option-color-background-default-filled-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-default-filled-dark
                ),
                'outline': (
                  'light': $emu-component-ingredients-select-input-option-color-background-default-outline-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-default-outline-dark
                )
              ),
              'hover': (
                'filled': (
                  'light': $emu-component-ingredients-select-input-option-color-background-hover-filled-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-hover-filled-dark
                ),
                'outline': (
                  'light': $emu-component-ingredients-select-input-option-color-background-hover-outline-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-hover-outline-dark
                )
              ),
              'active': (
                'filled': (
                  'light': $emu-component-ingredients-select-input-option-color-background-active-filled-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-active-filled-dark
                ),
                'outline': (
                  'light': $emu-component-ingredients-select-input-option-color-background-active-outline-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-active-outline-dark
                )
              ),
              'focus': (
                'filled': (
                  'light': $emu-component-ingredients-select-input-option-color-background-focus-filled-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-focus-filled-dark
                ),
                'outline': (
                  'light': $emu-component-ingredients-select-input-option-color-background-focus-outline-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-focus-outline-dark
                )
              ),
              'disabled': (
                'filled': (
                  'light': $emu-component-ingredients-select-input-option-color-background-disabled-filled-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-disabled-filled-dark
                ),
                'outline': (
                  'light': $emu-component-ingredients-select-input-option-color-background-disabled-outline-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-disabled-outline-dark
                )
              ),
              'error': (
                'filled': (
                  'light': $emu-component-ingredients-select-input-option-color-background-error-filled-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-error-filled-dark
                ),
                'outline': (
                  'light': $emu-component-ingredients-select-input-option-color-background-error-outline-light,
                  'dark': $emu-component-ingredients-select-input-option-color-background-error-outline-dark
                )
              )
            )
          )
        ),
        'color': (
          'border': (
            'default': (
              'filled': (
                'light': $emu-component-ingredients-select-input-color-border-default-filled-light,
                'dark': $emu-component-ingredients-select-input-color-border-default-filled-dark
              ),
              'outline': (
                'light': $emu-component-ingredients-select-input-color-border-default-outline-light,
                'dark': $emu-component-ingredients-select-input-color-border-default-outline-dark
              )
            ),
            'hover': (
              'filled': (
                'light': $emu-component-ingredients-select-input-color-border-hover-filled-light,
                'dark': $emu-component-ingredients-select-input-color-border-hover-filled-dark
              ),
              'outline': (
                'light': $emu-component-ingredients-select-input-color-border-hover-outline-light,
                'dark': $emu-component-ingredients-select-input-color-border-hover-outline-dark
              )
            ),
            'active': (
              'filled': (
                'light': $emu-component-ingredients-select-input-color-border-active-filled-light,
                'dark': $emu-component-ingredients-select-input-color-border-active-filled-dark
              ),
              'outline': (
                'light': $emu-component-ingredients-select-input-color-border-active-outline-light,
                'dark': $emu-component-ingredients-select-input-color-border-active-outline-dark
              )
            ),
            'focus': (
              'filled': (
                'light': $emu-component-ingredients-select-input-color-border-focus-filled-light,
                'dark': $emu-component-ingredients-select-input-color-border-focus-filled-dark
              ),
              'outline': (
                'light': $emu-component-ingredients-select-input-color-border-focus-outline-light,
                'dark': $emu-component-ingredients-select-input-color-border-focus-outline-dark
              )
            ),
            'disabled': (
              'filled': (
                'light': $emu-component-ingredients-select-input-color-border-disabled-filled-light,
                'dark': $emu-component-ingredients-select-input-color-border-disabled-filled-dark
              ),
              'outline': (
                'light': $emu-component-ingredients-select-input-color-border-disabled-outline-light,
                'dark': $emu-component-ingredients-select-input-color-border-disabled-outline-dark
              )
            ),
            'error': (
              'filled': (
                'light': $emu-component-ingredients-select-input-color-border-error-filled-light,
                'dark': $emu-component-ingredients-select-input-color-border-error-filled-dark
              ),
              'outline': (
                'light': $emu-component-ingredients-select-input-color-border-error-outline-light,
                'dark': $emu-component-ingredients-select-input-color-border-error-outline-dark
              )
            )
          )
        ),
        'borderRadius': (
          'narrow': $emu-component-ingredients-select-input-border-radius-narrow,
          'wide': $emu-component-ingredients-select-input-border-radius-wide
        ),
        'icon': (
          'color': (
            'fill': (
              'filled': (
                'default': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-filled-default-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-filled-default-dark
                ),
                'hover': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-filled-hover-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-filled-active-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-filled-active-dark
                ),
                'focus': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-filled-focus-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark
                ),
                'disabled': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark
                ),
                'error': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-filled-error-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-filled-error-dark
                )
              ),
              'outline': (
                'default': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-outline-default-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-outline-default-dark
                ),
                'hover': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-outline-hover-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-outline-active-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-outline-active-dark
                ),
                'focus': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-outline-focus-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark
                ),
                'disabled': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark
                ),
                'error': (
                  'light': $emu-component-ingredients-select-input-icon-color-fill-outline-error-light,
                  'dark': $emu-component-ingredients-select-input-icon-color-fill-outline-error-dark
                )
              )
            )
          )
        ),
        'padding': (
          'narrow': $emu-component-ingredients-select-input-padding-narrow,
          'wide': $emu-component-ingredients-select-input-padding-wide
        ),
        'gap': (
          'narrow': $emu-component-ingredients-select-input-gap-narrow,
          'wide': $emu-component-ingredients-select-input-gap-wide
        ),
        'borderWidth': (
          'filled': (
            'narrow': $emu-component-ingredients-select-input-border-width-filled-narrow,
            'wide': $emu-component-ingredients-select-input-border-width-filled-wide
          ),
          'outline': (
            'narrow': $emu-component-ingredients-select-input-border-width-outline-narrow,
            'wide': $emu-component-ingredients-select-input-border-width-outline-wide
          )
        ),
        'label': (
          'margin': (
            'narrow': $emu-component-ingredients-select-input-label-margin-narrow,
            'wide': $emu-component-ingredients-select-input-label-margin-wide
          ),
          'text': (
            'color': (
              'default': (
                'light': $emu-component-ingredients-select-input-label-text-color-default-light,
                'dark': $emu-component-ingredients-select-input-label-text-color-default-dark
              ),
              'hover': (
                'light': $emu-component-ingredients-select-input-label-text-color-hover-light,
                'dark': $emu-component-ingredients-select-input-label-text-color-hover-dark
              ),
              'disabled': (
                'light': $emu-component-ingredients-select-input-label-text-color-disabled-light,
                'dark': $emu-component-ingredients-select-input-label-text-color-disabled-dark
              ),
              'error': (
                'light': $emu-component-ingredients-select-input-label-text-color-error-light,
                'dark': $emu-component-ingredients-select-input-label-text-color-error-dark
              )
            )
          )
        ),
        'fieldMessage': (
          'margin': (
            'narrow': $emu-component-ingredients-select-input-field-message-margin-narrow,
            'wide': $emu-component-ingredients-select-input-field-message-margin-wide
          ),
          'text': (
            'color': (
              'light': $emu-component-ingredients-select-input-field-message-text-color-light,
              'dark': $emu-component-ingredients-select-input-field-message-text-color-dark
            )
          )
        ),
        'errorMessage': (
          'margin': (
            'narrow': $emu-component-ingredients-select-input-error-message-margin-narrow,
            'wide': $emu-component-ingredients-select-input-error-message-margin-wide
          ),
          'text': (
            'color': (
              'light': $emu-component-ingredients-select-input-error-message-text-color-light,
              'dark': $emu-component-ingredients-select-input-error-message-text-color-dark
            )
          )
        )
      )
    ),
    'actions': (
      'button': (
        'text': (
          'typography': (
            'narrow': $emu-component-actions-button-text-typography-narrow,
            'wide': $emu-component-actions-button-text-typography-wide
          ),
          'color': (
            'link': (
              'light': $emu-component-actions-button-text-color-link-light,
              'dark': $emu-component-actions-button-text-color-link-dark,
              'hover': (
                'light': $emu-component-actions-button-text-color-link-hover-light,
                'dark': $emu-component-actions-button-text-color-link-hover-dark
              )
            ),
            'primary': (
              'filled': (
                'light': $emu-component-actions-button-text-color-primary-filled-light,
                'dark': $emu-component-actions-button-text-color-primary-filled-dark,
                'focus': (
                  'light': $emu-component-actions-button-text-color-primary-filled-focus-light,
                  'dark': $emu-component-actions-button-text-color-primary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-text-color-primary-filled-hover-light,
                  'dark': $emu-component-actions-button-text-color-primary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-text-color-primary-filled-active-light,
                  'dark': $emu-component-actions-button-text-color-primary-filled-active-dark
                )
              ),
              'outline': (
                'light': $emu-component-actions-button-text-color-primary-outline-light,
                'dark': $emu-component-actions-button-text-color-primary-outline-dark,
                'focus': (
                  'light': $emu-component-actions-button-text-color-primary-outline-focus-light,
                  'dark': $emu-component-actions-button-text-color-primary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-text-color-primary-outline-hover-light,
                  'dark': $emu-component-actions-button-text-color-primary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-text-color-primary-outline-active-light,
                  'dark': $emu-component-actions-button-text-color-primary-outline-active-dark
                )
              )
            ),
            'secondary': (
              'filled': (
                'light': $emu-component-actions-button-text-color-secondary-filled-light,
                'dark': $emu-component-actions-button-text-color-secondary-filled-dark,
                'focus': (
                  'light': $emu-component-actions-button-text-color-secondary-filled-focus-light,
                  'dark': $emu-component-actions-button-text-color-secondary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-text-color-secondary-filled-hover-light,
                  'dark': $emu-component-actions-button-text-color-secondary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-text-color-secondary-filled-active-light,
                  'dark': $emu-component-actions-button-text-color-secondary-filled-active-dark
                )
              ),
              'outline': (
                'light': $emu-component-actions-button-text-color-secondary-outline-light,
                'dark': $emu-component-actions-button-text-color-secondary-outline-dark,
                'focus': (
                  'light': $emu-component-actions-button-text-color-secondary-outline-focus-light,
                  'dark': $emu-component-actions-button-text-color-secondary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-text-color-secondary-outline-hover-light,
                  'dark': $emu-component-actions-button-text-color-secondary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-text-color-secondary-outline-active-light,
                  'dark': $emu-component-actions-button-text-color-secondary-outline-active-dark
                )
              )
            )
          )
        ),
        'padding': (
          'top': (
            'narrow': $emu-component-actions-button-padding-top-narrow,
            'wide': $emu-component-actions-button-padding-top-wide
          ),
          'right': (
            'narrow': $emu-component-actions-button-padding-right-narrow,
            'wide': $emu-component-actions-button-padding-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-actions-button-padding-bottom-narrow,
            'wide': $emu-component-actions-button-padding-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-actions-button-padding-left-narrow,
            'wide': $emu-component-actions-button-padding-left-wide
          )
        ),
        'borderWidth': (
          'primary': (
            'filled': $emu-component-actions-button-border-width-primary-filled,
            'outline': $emu-component-actions-button-border-width-primary-outline
          ),
          'secondary': (
            'filled': $emu-component-actions-button-border-width-secondary-filled,
            'outline': $emu-component-actions-button-border-width-secondary-outline
          )
        ),
        'outlineWidth': (
          'primary': (
            'filled': $emu-component-actions-button-outline-width-primary-filled,
            'outline': $emu-component-actions-button-outline-width-primary-outline
          ),
          'secondary': (
            'filled': $emu-component-actions-button-outline-width-secondary-filled,
            'outline': $emu-component-actions-button-outline-width-secondary-outline
          )
        ),
        'borderRadius': (
          'primary': (
            'filled': (
              'topLeft': $emu-component-actions-button-border-radius-primary-filled-top-left,
              'topRight': $emu-component-actions-button-border-radius-primary-filled-top-right,
              'bottomRight': $emu-component-actions-button-border-radius-primary-filled-bottom-right,
              'bottomLeft': $emu-component-actions-button-border-radius-primary-filled-bottom-left
            ),
            'outline': (
              'topLeft': $emu-component-actions-button-border-radius-primary-outline-top-left,
              'topRight': $emu-component-actions-button-border-radius-primary-outline-top-right,
              'bottomRight': $emu-component-actions-button-border-radius-primary-outline-bottom-right,
              'bottomLeft': $emu-component-actions-button-border-radius-primary-outline-bottom-left
            )
          ),
          'secondary': (
            'filled': (
              'topLeft': $emu-component-actions-button-border-radius-secondary-filled-top-left,
              'topRight': $emu-component-actions-button-border-radius-secondary-filled-top-right,
              'bottomRight': $emu-component-actions-button-border-radius-secondary-filled-bottom-right,
              'bottomLeft': $emu-component-actions-button-border-radius-secondary-filled-bottom-left
            ),
            'outline': (
              'topLeft': $emu-component-actions-button-border-radius-secondary-outline-top-left,
              'topRight': $emu-component-actions-button-border-radius-secondary-outline-top-right,
              'bottomRight': $emu-component-actions-button-border-radius-secondary-outline-bottom-right,
              'bottomLeft': $emu-component-actions-button-border-radius-secondary-outline-bottom-left
            )
          )
        ),
        'color': (
          'border': (
            'primary': (
              'filled': (
                'light': $emu-component-actions-button-color-border-primary-filled-light,
                'dark': $emu-component-actions-button-color-border-primary-filled-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-border-primary-filled-focus-light,
                  'dark': $emu-component-actions-button-color-border-primary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-border-primary-filled-hover-light,
                  'dark': $emu-component-actions-button-color-border-primary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-border-primary-filled-active-light,
                  'dark': $emu-component-actions-button-color-border-primary-filled-active-dark
                )
              ),
              'outline': (
                'light': $emu-component-actions-button-color-border-primary-outline-light,
                'dark': $emu-component-actions-button-color-border-primary-outline-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-border-primary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-border-primary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-border-primary-outline-hover-light,
                  'dark': $emu-component-actions-button-color-border-primary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-border-primary-outline-active-light,
                  'dark': $emu-component-actions-button-color-border-primary-outline-active-dark
                )
              )
            ),
            'secondary': (
              'filled': (
                'light': $emu-component-actions-button-color-border-secondary-filled-light,
                'dark': $emu-component-actions-button-color-border-secondary-filled-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-border-secondary-filled-focus-light,
                  'dark': $emu-component-actions-button-color-border-secondary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-border-secondary-filled-hover-light,
                  'dark': $emu-component-actions-button-color-border-secondary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-border-secondary-filled-active-light,
                  'dark': $emu-component-actions-button-color-border-secondary-filled-active-dark
                )
              ),
              'outline': (
                'light': $emu-component-actions-button-color-border-secondary-outline-light,
                'dark': $emu-component-actions-button-color-border-secondary-outline-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-border-secondary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-border-secondary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-border-secondary-outline-hover-light,
                  'dark': $emu-component-actions-button-color-border-secondary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-border-secondary-outline-active-light,
                  'dark': $emu-component-actions-button-color-border-secondary-outline-active-dark
                )
              )
            )
          ),
          'background': (
            'primary': (
              'filled': (
                'light': $emu-component-actions-button-color-background-primary-filled-light,
                'dark': $emu-component-actions-button-color-background-primary-filled-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-background-primary-filled-focus-light,
                  'dark': $emu-component-actions-button-color-background-primary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-background-primary-filled-hover-light,
                  'dark': $emu-component-actions-button-color-background-primary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-background-primary-filled-active-light,
                  'dark': $emu-component-actions-button-color-background-primary-filled-active-dark
                )
              ),
              'outline': (
                'light': $emu-component-actions-button-color-background-primary-outline-light,
                'dark': $emu-component-actions-button-color-background-primary-outline-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-background-primary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-background-primary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-background-primary-outline-hover-light,
                  'dark': $emu-component-actions-button-color-background-primary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-background-primary-outline-active-light,
                  'dark': $emu-component-actions-button-color-background-primary-outline-active-dark
                )
              )
            ),
            'secondary': (
              'filled': (
                'light': $emu-component-actions-button-color-background-secondary-filled-light,
                'dark': $emu-component-actions-button-color-background-secondary-filled-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-background-secondary-filled-focus-light,
                  'dark': $emu-component-actions-button-color-background-secondary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-background-secondary-filled-hover-light,
                  'dark': $emu-component-actions-button-color-background-secondary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-background-secondary-filled-active-light,
                  'dark': $emu-component-actions-button-color-background-secondary-filled-active-dark
                )
              ),
              'outline': (
                'light': $emu-component-actions-button-color-background-secondary-outline-light,
                'dark': $emu-component-actions-button-color-background-secondary-outline-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-background-secondary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-background-secondary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-background-secondary-outline-hover-light,
                  'dark': $emu-component-actions-button-color-background-secondary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-background-secondary-outline-active-light,
                  'dark': $emu-component-actions-button-color-background-secondary-outline-active-dark
                )
              )
            )
          ),
          'outlineColor': (
            'primary': (
              'filled': (
                'light': $emu-component-actions-button-color-outline-color-primary-filled-light,
                'dark': $emu-component-actions-button-color-outline-color-primary-filled-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-outline-color-primary-filled-focus-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-outline-color-primary-filled-hover-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-outline-color-primary-filled-active-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-filled-active-dark
                )
              ),
              'outline': (
                'light': $emu-component-actions-button-color-outline-color-primary-outline-light,
                'dark': $emu-component-actions-button-color-outline-color-primary-outline-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-outline-color-primary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-outline-color-primary-outline-hover-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-outline-color-primary-outline-active-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-outline-active-dark
                )
              )
            ),
            'secondary': (
              'filled': (
                'light': $emu-component-actions-button-color-outline-color-secondary-filled-light,
                'dark': $emu-component-actions-button-color-outline-color-secondary-filled-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-outline-color-secondary-filled-focus-light,
                  'dark': $emu-component-actions-button-color-outline-color-secondary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-outline-color-secondary-filled-hover-light,
                  'dark': $emu-component-actions-button-color-outline-color-secondary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-outline-color-secondary-filled-active-light,
                  'dark': $emu-component-actions-button-color-outline-color-secondary-filled-active-dark
                )
              ),
              'outline': (
                'light': $emu-component-actions-button-color-outline-color-secondary-outline-light,
                'dark': $emu-component-actions-button-color-outline-color-secondary-outline-dark,
                'focus': (
                  'light': $emu-component-actions-button-color-outline-color-secondary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-outline-color-secondary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-outline-color-secondary-outline-hover-light,
                  'dark': $emu-component-actions-button-color-outline-color-secondary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-outline-color-secondary-outline-active-light,
                  'dark': $emu-component-actions-button-color-outline-color-secondary-outline-active-dark
                )
              )
            )
          )
        ),
        'group': (
          'horizontal': (
            'gap': $emu-component-actions-button-group-horizontal-gap,
            'padding': (
              'left': $emu-component-actions-button-group-horizontal-padding-left,
              'right': $emu-component-actions-button-group-horizontal-padding-right,
              'top': $emu-component-actions-button-group-horizontal-padding-top,
              'bottom': $emu-component-actions-button-group-horizontal-padding-bottom
            )
          ),
          'vertical': (
            'gap': $emu-component-actions-button-group-vertical-gap,
            'padding': (
              'left': $emu-component-actions-button-group-vertical-padding-left,
              'right': $emu-component-actions-button-group-vertical-padding-right,
              'top': $emu-component-actions-button-group-vertical-padding-top,
              'bottom': $emu-component-actions-button-group-vertical-padding-bottom
            )
          )
        ),
        'icon': (
          'size': (
            'height': $emu-component-actions-button-icon-size-height,
            'width': $emu-component-actions-button-icon-size-width
          ),
          'spacing': $emu-component-actions-button-icon-spacing,
          'thickness': $emu-component-actions-button-icon-thickness,
          'borderRadius': (
            'topLeft': $emu-component-actions-button-icon-border-radius-top-left,
            'topRight': $emu-component-actions-button-icon-border-radius-top-right,
            'bottomRight': $emu-component-actions-button-icon-border-radius-bottom-right,
            'bottomLeft': $emu-component-actions-button-icon-border-radius-bottom-left
          ),
          'transform': (
            'open': $emu-component-actions-button-icon-transform-open,
            'closed': $emu-component-actions-button-icon-transform-closed
          ),
          'transition': $emu-component-actions-button-icon-transition,
          'color': (
            'primary': (
              'filled': (
                'light': $emu-component-actions-button-icon-color-primary-filled-light,
                'dark': $emu-component-actions-button-icon-color-primary-filled-dark,
                'focus': (
                  'light': $emu-component-actions-button-icon-color-primary-filled-focus-light,
                  'dark': $emu-component-actions-button-icon-color-primary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-icon-color-primary-filled-hover-light,
                  'dark': $emu-component-actions-button-icon-color-primary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-icon-color-primary-filled-active-light,
                  'dark': $emu-component-actions-button-icon-color-primary-filled-active-dark
                )
              ),
              'outline': (
                'light': $emu-component-actions-button-icon-color-primary-outline-light,
                'dark': $emu-component-actions-button-icon-color-primary-outline-dark,
                'focus': (
                  'light': $emu-component-actions-button-icon-color-primary-outline-focus-light,
                  'dark': $emu-component-actions-button-icon-color-primary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-icon-color-primary-outline-hover-light,
                  'dark': $emu-component-actions-button-icon-color-primary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-icon-color-primary-outline-active-light,
                  'dark': $emu-component-actions-button-icon-color-primary-outline-active-dark
                )
              )
            ),
            'secondary': (
              'filled': (
                'light': $emu-component-actions-button-icon-color-secondary-filled-light,
                'dark': $emu-component-actions-button-icon-color-secondary-filled-dark,
                'focus': (
                  'light': $emu-component-actions-button-icon-color-secondary-filled-focus-light,
                  'dark': $emu-component-actions-button-icon-color-secondary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-icon-color-secondary-filled-hover-light,
                  'dark': $emu-component-actions-button-icon-color-secondary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-icon-color-secondary-filled-active-light,
                  'dark': $emu-component-actions-button-icon-color-secondary-filled-active-dark
                )
              ),
              'outline': (
                'light': $emu-component-actions-button-icon-color-secondary-outline-light,
                'dark': $emu-component-actions-button-icon-color-secondary-outline-dark,
                'hover': (
                  'light': $emu-component-actions-button-icon-color-secondary-outline-hover-light,
                  'dark': $emu-component-actions-button-icon-color-secondary-outline-hover-dark
                ),
                'focus': (
                  'light': $emu-component-actions-button-icon-color-secondary-outline-focus-light,
                  'dark': $emu-component-actions-button-icon-color-secondary-outline-focus-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-icon-color-secondary-outline-active-light,
                  'dark': $emu-component-actions-button-icon-color-secondary-outline-active-dark
                )
              )
            )
          )
        )
      ),
      'backToTop': (
        'zIndex': $emu-component-actions-back-to-top-z-index,
        'icon': (
          'size': (
            'height': $emu-component-actions-back-to-top-icon-size-height,
            'width': $emu-component-actions-back-to-top-icon-size-width
          )
        ),
        'position': (
          'bottom': (
            'narrow': $emu-component-actions-back-to-top-position-bottom-narrow,
            'wide': $emu-component-actions-back-to-top-position-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-actions-back-to-top-position-left-narrow,
            'wide': $emu-component-actions-back-to-top-position-left-wide
          ),
          'right': (
            'narrow': $emu-component-actions-back-to-top-position-right-narrow,
            'wide': $emu-component-actions-back-to-top-position-right-wide
          ),
          'top': (
            'narrow': $emu-component-actions-back-to-top-position-top-narrow,
            'wide': $emu-component-actions-back-to-top-position-top-wide
          )
        ),
        'margin': (
          'bottom': (
            'narrow': $emu-component-actions-back-to-top-margin-bottom-narrow,
            'wide': $emu-component-actions-back-to-top-margin-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-actions-back-to-top-margin-left-narrow,
            'wide': $emu-component-actions-back-to-top-margin-left-wide
          ),
          'right': (
            'narrow': $emu-component-actions-back-to-top-margin-right-narrow,
            'wide': $emu-component-actions-back-to-top-margin-right-wide
          ),
          'top': (
            'narrow': $emu-component-actions-back-to-top-margin-top-narrow,
            'wide': $emu-component-actions-back-to-top-margin-top-wide
          )
        )
      )
    ),
    'containers': (
      'scrollReveal': (
        'header': (
          'text': (
            'typography': (
              'narrow': $emu-component-containers-scroll-reveal-header-text-typography-narrow,
              'wide': $emu-component-containers-scroll-reveal-header-text-typography-wide
            ),
            'color': (
              'light': $emu-component-containers-scroll-reveal-header-text-color-light,
              'dark': $emu-component-containers-scroll-reveal-header-text-color-dark
            )
          )
        ),
        'color': (
          'background': (
            'light': $emu-component-containers-scroll-reveal-color-background-light,
            'dark': $emu-component-containers-scroll-reveal-color-background-dark
          )
        ),
        'padding': (
          'top': (
            'narrow': $emu-component-containers-scroll-reveal-padding-top-narrow,
            'wide': $emu-component-containers-scroll-reveal-padding-top-wide
          ),
          'bottom': (
            'narrow': $emu-component-containers-scroll-reveal-padding-bottom-narrow,
            'wide': $emu-component-containers-scroll-reveal-padding-bottom-wide
          )
        ),
        'progressBar': (
          'color': (
            'progressIndicator': (
              'light': $emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light,
              'dark': $emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark
            ),
            'bar': (
              'light': $emu-component-containers-scroll-reveal-progress-bar-color-bar-light,
              'dark': $emu-component-containers-scroll-reveal-progress-bar-color-bar-dark
            )
          )
        ),
        'staticSection': (
          'margin': (
            'top': (
              'narrow': $emu-component-containers-scroll-reveal-static-section-margin-top-narrow,
              'wide': $emu-component-containers-scroll-reveal-static-section-margin-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-scroll-reveal-static-section-margin-right-narrow,
              'wide': $emu-component-containers-scroll-reveal-static-section-margin-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow,
              'wide': $emu-component-containers-scroll-reveal-static-section-margin-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-scroll-reveal-static-section-margin-left-narrow,
              'wide': $emu-component-containers-scroll-reveal-static-section-margin-left-wide
            )
          )
        ),
        'cards': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-scroll-reveal-cards-padding-top-narrow,
              'wide': $emu-component-containers-scroll-reveal-cards-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-scroll-reveal-cards-padding-right-narrow,
              'wide': $emu-component-containers-scroll-reveal-cards-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-scroll-reveal-cards-padding-bottom-narrow,
              'wide': $emu-component-containers-scroll-reveal-cards-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-scroll-reveal-cards-padding-left-narrow,
              'wide': $emu-component-containers-scroll-reveal-cards-padding-left-wide
            )
          ),
          'header': (
            'text': (
              'typography': (
                'narrow': $emu-component-containers-scroll-reveal-cards-header-text-typography-narrow,
                'wide': $emu-component-containers-scroll-reveal-cards-header-text-typography-wide
              ),
              'color': (
                'light': $emu-component-containers-scroll-reveal-cards-header-text-color-light,
                'dark': $emu-component-containers-scroll-reveal-cards-header-text-color-dark
              )
            )
          ),
          'text': (
            'typography': (
              'narrow': $emu-component-containers-scroll-reveal-cards-text-typography-narrow,
              'wide': $emu-component-containers-scroll-reveal-cards-text-typography-wide
            ),
            'color': (
              'light': $emu-component-containers-scroll-reveal-cards-text-color-light,
              'dark': $emu-component-containers-scroll-reveal-cards-text-color-dark
            )
          )
        )
      ),
      'comparisonSlider': (
        'divider': (
          'color': (
            'light': $emu-component-containers-comparison-slider-divider-color-light,
            'dark': $emu-component-containers-comparison-slider-divider-color-dark
          ),
          'size': (
            'width': (
              'narrow': $emu-component-containers-comparison-slider-divider-size-width-narrow,
              'wide': $emu-component-containers-comparison-slider-divider-size-width-wide
            )
          )
        ),
        'handle': (
          'size': (
            'width': (
              'narrow': $emu-component-containers-comparison-slider-handle-size-width-narrow,
              'wide': $emu-component-containers-comparison-slider-handle-size-width-wide
            )
          )
        ),
        'customHandle': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-comparison-slider-custom-handle-padding-top-narrow,
              'wide': $emu-component-containers-comparison-slider-custom-handle-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-comparison-slider-custom-handle-padding-right-narrow,
              'wide': $emu-component-containers-comparison-slider-custom-handle-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow,
              'wide': $emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-comparison-slider-custom-handle-padding-left-narrow,
              'wide': $emu-component-containers-comparison-slider-custom-handle-padding-left-wide
            )
          ),
          'arrow': (
            'size': (
              'width': (
                'narrow': $emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow,
                'wide': $emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide
              )
            ),
            'color': (
              'light': $emu-component-containers-comparison-slider-custom-handle-arrow-color-light,
              'dark': $emu-component-containers-comparison-slider-custom-handle-arrow-color-dark
            ),
            'thickness': (
              'narrow': $emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow,
              'wide': $emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide
            )
          ),
          'color': (
            'background': (
              'light': $emu-component-containers-comparison-slider-custom-handle-color-background-light,
              'dark': $emu-component-containers-comparison-slider-custom-handle-color-background-dark
            )
          ),
          'text': (
            'color': (
              'light': $emu-component-containers-comparison-slider-custom-handle-text-color-light,
              'dark': $emu-component-containers-comparison-slider-custom-handle-text-color-dark
            ),
            'fontSize': (
              'narrow': $emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow,
              'wide': $emu-component-containers-comparison-slider-custom-handle-text-font-size-wide
            ),
            'lineHeight': (
              'narrow': $emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow,
              'wide': $emu-component-containers-comparison-slider-custom-handle-text-line-height-wide
            )
          )
        )
      ),
      'tabs': (
        'gap': (
          'horizontal': (
            'narrow': $emu-component-containers-tabs-gap-horizontal-narrow,
            'wide': $emu-component-containers-tabs-gap-horizontal-wide
          ),
          'vertical': (
            'narrow': $emu-component-containers-tabs-gap-vertical-narrow,
            'wide': $emu-component-containers-tabs-gap-vertical-wide
          )
        ),
        'navigation': (
          'item': (
            'padding': (
              'top': (
                'narrow': $emu-component-containers-tabs-navigation-item-padding-top-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-padding-top-wide
              ),
              'right': (
                'narrow': $emu-component-containers-tabs-navigation-item-padding-right-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-padding-right-wide
              ),
              'bottom': (
                'narrow': $emu-component-containers-tabs-navigation-item-padding-bottom-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-padding-bottom-wide
              ),
              'left': (
                'narrow': $emu-component-containers-tabs-navigation-item-padding-left-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-padding-left-wide
              )
            ),
            'gap': (
              'horizontal': (
                'narrow': $emu-component-containers-tabs-navigation-item-gap-horizontal-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-gap-horizontal-wide
              ),
              'vertical': (
                'narrow': $emu-component-containers-tabs-navigation-item-gap-vertical-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-gap-vertical-wide
              )
            ),
            'borderWidth': (
              'top': (
                'default': $emu-component-containers-tabs-navigation-item-border-width-top-default,
                'active': $emu-component-containers-tabs-navigation-item-border-width-top-active
              ),
              'right': (
                'default': $emu-component-containers-tabs-navigation-item-border-width-right-default,
                'active': $emu-component-containers-tabs-navigation-item-border-width-right-active
              ),
              'bottom': (
                'default': $emu-component-containers-tabs-navigation-item-border-width-bottom-default,
                'active': $emu-component-containers-tabs-navigation-item-border-width-bottom-active
              ),
              'left': (
                'default': $emu-component-containers-tabs-navigation-item-border-width-left-default,
                'active': $emu-component-containers-tabs-navigation-item-border-width-left-active
              )
            ),
            'color': (
              'border': (
                'default': (
                  'light': $emu-component-containers-tabs-navigation-item-color-border-default-light,
                  'dark': $emu-component-containers-tabs-navigation-item-color-border-default-dark
                ),
                'active': (
                  'light': $emu-component-containers-tabs-navigation-item-color-border-active-light,
                  'dark': $emu-component-containers-tabs-navigation-item-color-border-active-dark
                )
              ),
              'background': (
                'default': (
                  'light': $emu-component-containers-tabs-navigation-item-color-background-default-light,
                  'dark': $emu-component-containers-tabs-navigation-item-color-background-default-dark
                ),
                'active': (
                  'light': $emu-component-containers-tabs-navigation-item-color-background-active-light,
                  'dark': $emu-component-containers-tabs-navigation-item-color-background-active-dark
                )
              )
            ),
            'text': (
              'color': (
                'default': (
                  'light': $emu-component-containers-tabs-navigation-item-text-color-default-light,
                  'dark': $emu-component-containers-tabs-navigation-item-text-color-default-dark
                ),
                'active': (
                  'light': $emu-component-containers-tabs-navigation-item-text-color-active-light,
                  'dark': $emu-component-containers-tabs-navigation-item-text-color-active-dark
                )
              )
            )
          )
        ),
        'content': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-tabs-content-padding-top-narrow,
              'wide': $emu-component-containers-tabs-content-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-tabs-content-padding-right-narrow,
              'wide': $emu-component-containers-tabs-content-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-tabs-content-padding-bottom-narrow,
              'wide': $emu-component-containers-tabs-content-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-tabs-content-padding-left-narrow,
              'wide': $emu-component-containers-tabs-content-padding-left-wide
            )
          ),
          'gap': (
            'horizontal': (
              'narrow': $emu-component-containers-tabs-content-gap-horizontal-narrow,
              'wide': $emu-component-containers-tabs-content-gap-horizontal-wide
            ),
            'vertical': (
              'narrow': $emu-component-containers-tabs-content-gap-vertical-narrow,
              'wide': $emu-component-containers-tabs-content-gap-vertical-wide
            )
          ),
          'color': (
            'background': (
              'light': $emu-component-containers-tabs-content-color-background-light,
              'dark': $emu-component-containers-tabs-content-color-background-dark
            )
          )
        )
      ),
      'isi': (
        'header': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-isi-header-padding-top-narrow,
              'wide': $emu-component-containers-isi-header-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-isi-header-padding-right-narrow,
              'wide': $emu-component-containers-isi-header-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-isi-header-padding-bottom-narrow,
              'wide': $emu-component-containers-isi-header-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-isi-header-padding-left-narrow,
              'wide': $emu-component-containers-isi-header-padding-left-wide
            )
          ),
          'color': (
            'background': (
              'dark': $emu-component-containers-isi-header-color-background-dark,
              'light': $emu-component-containers-isi-header-color-background-light
            ),
            'borderTop': (
              'dark': $emu-component-containers-isi-header-color-border-top-dark,
              'light': $emu-component-containers-isi-header-color-border-top-light
            )
          ),
          'borderWidth': (
            'top': $emu-component-containers-isi-header-border-width-top
          )
        ),
        'body': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-isi-body-padding-top-narrow,
              'wide': $emu-component-containers-isi-body-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-isi-body-padding-right-narrow,
              'wide': $emu-component-containers-isi-body-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-isi-body-padding-bottom-narrow,
              'wide': $emu-component-containers-isi-body-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-isi-body-padding-left-narrow,
              'wide': $emu-component-containers-isi-body-padding-left-wide
            )
          ),
          'color': (
            'background': (
              'dark': $emu-component-containers-isi-body-color-background-dark,
              'light': $emu-component-containers-isi-body-color-background-light
            ),
            'borderTop': (
              'dark': $emu-component-containers-isi-body-color-border-top-dark,
              'light': $emu-component-containers-isi-body-color-border-top-light
            )
          ),
          'borderWidth': (
            'top': $emu-component-containers-isi-body-border-width-top
          )
        ),
        'open': (
          'content': (
            'size': $emu-component-containers-isi-open-content-size
          )
        ),
        'zIndex': $emu-component-containers-isi-z-index
      ),
      'container': (
        'maxWidth': $emu-component-containers-container-max-width,
        'padding': (
          'top': (
            'narrow': $emu-component-containers-container-padding-top-narrow,
            'wide': $emu-component-containers-container-padding-top-wide
          ),
          'right': (
            'narrow': $emu-component-containers-container-padding-right-narrow,
            'wide': $emu-component-containers-container-padding-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-containers-container-padding-bottom-narrow,
            'wide': $emu-component-containers-container-padding-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-containers-container-padding-left-narrow,
            'wide': $emu-component-containers-container-padding-left-wide
          )
        ),
        'gap': (
          'horizontal': (
            'narrow': $emu-component-containers-container-gap-horizontal-narrow,
            'wide': $emu-component-containers-container-gap-horizontal-wide
          ),
          'vertical': (
            'narrow': $emu-component-containers-container-gap-vertical-narrow,
            'wide': $emu-component-containers-container-gap-vertical-wide
          )
        ),
        'color': (
          'background': (
            'light': $emu-component-containers-container-color-background-light,
            'dark': $emu-component-containers-container-color-background-dark
          )
        )
      ),
      'carousel': (
        'gap': (
          'horizontal': (
            'narrow': $emu-component-containers-carousel-gap-horizontal-narrow,
            'wide': $emu-component-containers-carousel-gap-horizontal-wide
          ),
          'vertical': (
            'narrow': $emu-component-containers-carousel-gap-vertical-narrow,
            'wide': $emu-component-containers-carousel-gap-vertical-wide
          )
        ),
        'indicators': (
          'margin': (
            'top': (
              'narrow': $emu-component-containers-carousel-indicators-margin-top-narrow,
              'wide': $emu-component-containers-carousel-indicators-margin-top-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-carousel-indicators-margin-bottom-narrow,
              'wide': $emu-component-containers-carousel-indicators-margin-bottom-wide
            )
          ),
          'gap': (
            'horizontal': (
              'narrow': $emu-component-containers-carousel-indicators-gap-horizontal-narrow,
              'wide': $emu-component-containers-carousel-indicators-gap-horizontal-wide
            )
          ),
          'dots': (
            'color': (
              'background': (
                'default': (
                  'light': $emu-component-containers-carousel-indicators-dots-color-background-default-light,
                  'dark': $emu-component-containers-carousel-indicators-dots-color-background-default-dark
                ),
                'active': (
                  'light': $emu-component-containers-carousel-indicators-dots-color-background-active-light,
                  'dark': $emu-component-containers-carousel-indicators-dots-color-background-active-dark
                )
              ),
              'border': (
                'default': (
                  'light': $emu-component-containers-carousel-indicators-dots-color-border-default-light,
                  'dark': $emu-component-containers-carousel-indicators-dots-color-border-default-dark
                ),
                'active': (
                  'light': $emu-component-containers-carousel-indicators-dots-color-border-active-light,
                  'dark': $emu-component-containers-carousel-indicators-dots-color-border-active-dark
                )
              )
            ),
            'borderWidth': (
              'all': $emu-component-containers-carousel-indicators-dots-border-width-all
            ),
            'size': (
              'narrow': $emu-component-containers-carousel-indicators-dots-size-narrow,
              'wide': $emu-component-containers-carousel-indicators-dots-size-wide
            )
          ),
          'image': (
            'container': (
              'size': (
                'narrow': $emu-component-containers-carousel-indicators-image-container-size-narrow,
                'wide': $emu-component-containers-carousel-indicators-image-container-size-wide
              )
            ),
            'color': (
              'border': (
                'default': (
                  'light': $emu-component-containers-carousel-indicators-image-color-border-default-light,
                  'dark': $emu-component-containers-carousel-indicators-image-color-border-default-dark
                ),
                'active': (
                  'light': $emu-component-containers-carousel-indicators-image-color-border-active-light,
                  'dark': $emu-component-containers-carousel-indicators-image-color-border-active-dark
                )
              )
            ),
            'borderWidth': (
              'all': $emu-component-containers-carousel-indicators-image-border-width-all
            ),
            'size': (
              'narrow': $emu-component-containers-carousel-indicators-image-size-narrow,
              'wide': $emu-component-containers-carousel-indicators-image-size-wide
            )
          ),
          'text': (
            'color': (
              'background': (
                'light': $emu-component-containers-carousel-indicators-text-color-background-light,
                'dark': $emu-component-containers-carousel-indicators-text-color-background-dark
              ),
              'text': (
                'light': $emu-component-containers-carousel-indicators-text-color-text-light,
                'dark': $emu-component-containers-carousel-indicators-text-color-text-dark
              )
            ),
            'padding': (
              'top': (
                'narrow': $emu-component-containers-carousel-indicators-text-padding-top-narrow,
                'wide': $emu-component-containers-carousel-indicators-text-padding-top-wide
              ),
              'right': (
                'narrow': $emu-component-containers-carousel-indicators-text-padding-right-narrow,
                'wide': $emu-component-containers-carousel-indicators-text-padding-right-wide
              ),
              'bottom': (
                'narrow': $emu-component-containers-carousel-indicators-text-padding-bottom-narrow,
                'wide': $emu-component-containers-carousel-indicators-text-padding-bottom-wide
              ),
              'left': (
                'narrow': $emu-component-containers-carousel-indicators-text-padding-left-narrow,
                'wide': $emu-component-containers-carousel-indicators-text-padding-left-wide
              )
            ),
            'fontSize': (
              'narrow': $emu-component-containers-carousel-indicators-text-font-size-narrow,
              'wide': $emu-component-containers-carousel-indicators-text-font-size-wide
            )
          )
        ),
        'controls': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-carousel-controls-padding-top-narrow,
              'wide': $emu-component-containers-carousel-controls-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-carousel-controls-padding-right-narrow,
              'wide': $emu-component-containers-carousel-controls-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-carousel-controls-padding-bottom-narrow,
              'wide': $emu-component-containers-carousel-controls-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-carousel-controls-padding-left-narrow,
              'wide': $emu-component-containers-carousel-controls-padding-left-wide
            )
          ),
          'color': (
            'background': (
              'light': $emu-component-containers-carousel-controls-color-background-light,
              'dark': $emu-component-containers-carousel-controls-color-background-dark
            ),
            'border': (
              'light': $emu-component-containers-carousel-controls-color-border-light,
              'dark': $emu-component-containers-carousel-controls-color-border-dark
            )
          ),
          'borderWidth': (
            'all': $emu-component-containers-carousel-controls-border-width-all
          ),
          'zIndex': $emu-component-containers-carousel-controls-z-index,
          'icon': (
            'color': (
              'fill': (
                'light': $emu-component-containers-carousel-controls-icon-color-fill-light,
                'dark': $emu-component-containers-carousel-controls-icon-color-fill-dark
              )
            ),
            'size': (
              'narrow': $emu-component-containers-carousel-controls-icon-size-narrow,
              'wide': $emu-component-containers-carousel-controls-icon-size-wide
            )
          )
        )
      ),
      'embed': (
        'gap': (
          'horizontal': (
            'narrow': $emu-component-containers-embed-gap-horizontal-narrow,
            'wide': $emu-component-containers-embed-gap-horizontal-wide
          ),
          'vertical': (
            'narrow': $emu-component-containers-embed-gap-vertical-narrow,
            'wide': $emu-component-containers-embed-gap-vertical-wide
          )
        ),
        'color': (
          'background': (
            'light': $emu-component-containers-embed-color-background-light,
            'dark': $emu-component-containers-embed-color-background-dark
          )
        ),
        'playerVimeo': (
          'global': (
            'color': (
              'light': $emu-component-containers-embed-player-vimeo-global-color-light,
              'dark': $emu-component-containers-embed-player-vimeo-global-color-dark
            ),
            'text': (
              'typography': (
                'fontSize': (
                  'base': $emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base,
                  'small': $emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small,
                  'large': $emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large,
                  'xlarge': $emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge
                ),
                'fontWeight': (
                  'regular': $emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular,
                  'bold': $emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold
                ),
                'lineHeight': $emu-component-containers-embed-player-vimeo-global-text-typography-line-height
              )
            )
          ),
          'color': (
            'background': (
              'light': $emu-component-containers-embed-player-vimeo-color-background-light,
              'dark': $emu-component-containers-embed-player-vimeo-color-background-dark
            )
          ),
          'outlineColor': (
            'focus': (
              'light': $emu-component-containers-embed-player-vimeo-outline-color-focus-light,
              'dark': $emu-component-containers-embed-player-vimeo-outline-color-focus-dark
            )
          ),
          'badge': (
            'borderRadius': (
              'all': $emu-component-containers-embed-player-vimeo-badge-border-radius-all
            ),
            'color': (
              'background': (
                'light': $emu-component-containers-embed-player-vimeo-badge-color-background-light,
                'dark': $emu-component-containers-embed-player-vimeo-badge-color-background-dark
              )
            ),
            'text': (
              'typography': (
                'fontSize': $emu-component-containers-embed-player-vimeo-badge-text-typography-font-size
              ),
              'color': (
                'light': $emu-component-containers-embed-player-vimeo-badge-text-color-light,
                'dark': $emu-component-containers-embed-player-vimeo-badge-text-color-dark
              )
            )
          ),
          'menu': (
            'color': (
              'background': (
                'light': $emu-component-containers-embed-player-vimeo-menu-color-background-light,
                'dark': $emu-component-containers-embed-player-vimeo-menu-color-background-dark
              ),
              'border': (
                'light': $emu-component-containers-embed-player-vimeo-menu-color-border-light,
                'dark': $emu-component-containers-embed-player-vimeo-menu-color-border-dark
              )
            ),
            'shadow': $emu-component-containers-embed-player-vimeo-menu-shadow,
            'itemArrow': (
              'size': $emu-component-containers-embed-player-vimeo-menu-item-arrow-size,
              'color': (
                'light': $emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light,
                'dark': $emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark
              )
            ),
            'text': (
              'typography': (
                'fontSize': $emu-component-containers-embed-player-vimeo-menu-text-typography-font-size
              ),
              'color': (
                'light': $emu-component-containers-embed-player-vimeo-menu-text-color-light,
                'dark': $emu-component-containers-embed-player-vimeo-menu-text-color-dark
              )
            ),
            'borderRadius': (
              'all': $emu-component-containers-embed-player-vimeo-menu-border-radius-all
            ),
            'arrowSize': $emu-component-containers-embed-player-vimeo-menu-arrow-size
          ),
          'controls': (
            'global': (
              'spacing': $emu-component-containers-embed-player-vimeo-controls-global-spacing,
              'icon': (
                'sizing': $emu-component-containers-embed-player-vimeo-controls-global-icon-sizing
              ),
              'padding': $emu-component-containers-embed-player-vimeo-controls-global-padding,
              'toggle': (
                'color': (
                  'background': (
                    'light': $emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light,
                    'dark': $emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark
                  )
                )
              ),
              'borderRadius': (
                'all': $emu-component-containers-embed-player-vimeo-controls-global-border-radius-all
              )
            ),
            'video': (
              'color': (
                'backgroundGradient': (
                  'start': $emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start,
                  'end': $emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end
                ),
                'background': (
                  'hover': (
                    'light': $emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light,
                    'dark': $emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark
                  )
                )
              ),
              'control': (
                'icon': (
                  'fill': (
                    'hover': (
                      'light': $emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light,
                      'dark': $emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark
                    ),
                    'light': $emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light,
                    'dark': $emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark
                  )
                )
              )
            ),
            'audio': (
              'color': (
                'backgroundGradient': (
                  'start': $emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start,
                  'end': $emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end
                ),
                'background': (
                  'hover': (
                    'light': $emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light,
                    'dark': $emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark
                  )
                )
              ),
              'control': (
                'icon': (
                  'fill': (
                    'hover': (
                      'light': $emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light,
                      'dark': $emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark
                    ),
                    'light': $emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light,
                    'dark': $emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark
                  )
                )
              )
            )
          ),
          'tooltip': (
            'color': (
              'background': (
                'light': $emu-component-containers-embed-player-vimeo-tooltip-color-background-light,
                'dark': $emu-component-containers-embed-player-vimeo-tooltip-color-background-dark
              )
            ),
            'text': (
              'color': (
                'light': $emu-component-containers-embed-player-vimeo-tooltip-text-color-light,
                'dark': $emu-component-containers-embed-player-vimeo-tooltip-text-color-dark
              )
            ),
            'shadow': $emu-component-containers-embed-player-vimeo-tooltip-shadow,
            'borderRadius': (
              'all': $emu-component-containers-embed-player-vimeo-tooltip-border-radius-all
            ),
            'padding': $emu-component-containers-embed-player-vimeo-tooltip-padding,
            'arrowSize': $emu-component-containers-embed-player-vimeo-tooltip-arrow-size
          ),
          'scrubber': (
            'color': (
              'background': (
                'active': (
                  'light': $emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light,
                  'dark': $emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark
                ),
                'loading': (
                  'light': $emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light,
                  'dark': $emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark
                )
              )
            ),
            'sizing': (
              'height': $emu-component-containers-embed-player-vimeo-scrubber-sizing-height
            ),
            'thumb': (
              'sizing': (
                'height': $emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height
              ),
              'color': (
                'background': (
                  'light': $emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light,
                  'dark': $emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark
                )
              ),
              'shadow': (
                'one': $emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one,
                'two': $emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two,
                'width': (
                  'active': $emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active
                )
              )
            ),
            'loading': (
              'size': $emu-component-containers-embed-player-vimeo-scrubber-loading-size
            ),
            'audio': (
              'progress': (
                'color': (
                  'background': (
                    'buffered': (
                      'light': $emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light,
                      'dark': $emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark
                    )
                  )
                )
              ),
              'thumb': (
                'shadow': (
                  'active': (
                    'light': $emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light,
                    'dark': $emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark
                  )
                )
              ),
              'track': (
                'color': (
                  'background': (
                    'light': $emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light,
                    'dark': $emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark
                  )
                )
              )
            ),
            'video': (
              'progress': (
                'color': (
                  'background': (
                    'buffered': (
                      'light': $emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light,
                      'dark': $emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark
                    )
                  )
                )
              ),
              'thumb': (
                'shadow': (
                  'active': (
                    'light': $emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light,
                    'dark': $emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark
                  )
                )
              ),
              'track': (
                'color': (
                  'background': (
                    'light': $emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light,
                    'dark': $emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark
                  )
                )
              )
            )
          ),
          'captions': (
            'text': (
              'color': (
                'light': $emu-component-containers-embed-player-vimeo-captions-text-color-light,
                'dark': $emu-component-containers-embed-player-vimeo-captions-text-color-dark
              )
            ),
            'color': (
              'background': (
                'light': $emu-component-containers-embed-player-vimeo-captions-color-background-light,
                'dark': $emu-component-containers-embed-player-vimeo-captions-color-background-dark
              )
            )
          ),
          'time': (
            'text': (
              'typography': (
                'fontSize': $emu-component-containers-embed-player-vimeo-time-text-typography-font-size
              )
            )
          )
        ),
        'playerYoutube': (
          'global': (
            'color': (
              'light': $emu-component-containers-embed-player-youtube-global-color-light,
              'dark': $emu-component-containers-embed-player-youtube-global-color-dark
            ),
            'text': (
              'typography': (
                'fontSize': (
                  'base': $emu-component-containers-embed-player-youtube-global-text-typography-font-size-base,
                  'small': $emu-component-containers-embed-player-youtube-global-text-typography-font-size-small,
                  'large': $emu-component-containers-embed-player-youtube-global-text-typography-font-size-large,
                  'xlarge': $emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge
                ),
                'fontWeight': (
                  'regular': $emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular,
                  'bold': $emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold
                ),
                'lineHeight': $emu-component-containers-embed-player-youtube-global-text-typography-line-height
              )
            )
          ),
          'color': (
            'background': (
              'light': $emu-component-containers-embed-player-youtube-color-background-light,
              'dark': $emu-component-containers-embed-player-youtube-color-background-dark
            )
          ),
          'outlineColor': (
            'focus': (
              'light': $emu-component-containers-embed-player-youtube-outline-color-focus-light,
              'dark': $emu-component-containers-embed-player-youtube-outline-color-focus-dark
            )
          ),
          'badge': (
            'borderRadius': (
              'all': $emu-component-containers-embed-player-youtube-badge-border-radius-all
            ),
            'color': (
              'background': (
                'light': $emu-component-containers-embed-player-youtube-badge-color-background-light,
                'dark': $emu-component-containers-embed-player-youtube-badge-color-background-dark
              )
            ),
            'text': (
              'typography': (
                'fontSize': $emu-component-containers-embed-player-youtube-badge-text-typography-font-size
              ),
              'color': (
                'light': $emu-component-containers-embed-player-youtube-badge-text-color-light,
                'dark': $emu-component-containers-embed-player-youtube-badge-text-color-dark
              )
            )
          ),
          'menu': (
            'color': (
              'background': (
                'light': $emu-component-containers-embed-player-youtube-menu-color-background-light,
                'dark': $emu-component-containers-embed-player-youtube-menu-color-background-dark
              ),
              'border': (
                'light': $emu-component-containers-embed-player-youtube-menu-color-border-light,
                'dark': $emu-component-containers-embed-player-youtube-menu-color-border-dark
              )
            ),
            'shadow': $emu-component-containers-embed-player-youtube-menu-shadow,
            'itemArrow': (
              'size': $emu-component-containers-embed-player-youtube-menu-item-arrow-size,
              'color': (
                'light': $emu-component-containers-embed-player-youtube-menu-item-arrow-color-light,
                'dark': $emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark
              )
            ),
            'text': (
              'typography': (
                'fontSize': $emu-component-containers-embed-player-youtube-menu-text-typography-font-size
              ),
              'color': (
                'light': $emu-component-containers-embed-player-youtube-menu-text-color-light,
                'dark': $emu-component-containers-embed-player-youtube-menu-text-color-dark
              )
            ),
            'borderRadius': (
              'all': $emu-component-containers-embed-player-youtube-menu-border-radius-all
            ),
            'arrowSize': $emu-component-containers-embed-player-youtube-menu-arrow-size
          ),
          'controls': (
            'global': (
              'spacing': $emu-component-containers-embed-player-youtube-controls-global-spacing,
              'icon': (
                'sizing': $emu-component-containers-embed-player-youtube-controls-global-icon-sizing
              ),
              'padding': $emu-component-containers-embed-player-youtube-controls-global-padding,
              'toggle': (
                'color': (
                  'background': (
                    'light': $emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light,
                    'dark': $emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark
                  )
                )
              ),
              'borderRadius': (
                'all': $emu-component-containers-embed-player-youtube-controls-global-border-radius-all
              )
            ),
            'video': (
              'color': (
                'backgroundGradient': (
                  'start': $emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start,
                  'end': $emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end
                ),
                'background': (
                  'hover': (
                    'light': $emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light,
                    'dark': $emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark
                  )
                )
              ),
              'control': (
                'icon': (
                  'fill': (
                    'hover': (
                      'light': $emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light,
                      'dark': $emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark
                    ),
                    'light': $emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light,
                    'dark': $emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark
                  )
                )
              )
            ),
            'audio': (
              'color': (
                'backgroundGradient': (
                  'start': $emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start,
                  'end': $emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end
                ),
                'background': (
                  'hover': (
                    'light': $emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light,
                    'dark': $emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark
                  )
                )
              ),
              'control': (
                'icon': (
                  'fill': (
                    'hover': (
                      'light': $emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light,
                      'dark': $emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark
                    ),
                    'light': $emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light,
                    'dark': $emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark
                  )
                )
              )
            )
          ),
          'tooltip': (
            'color': (
              'background': (
                'light': $emu-component-containers-embed-player-youtube-tooltip-color-background-light,
                'dark': $emu-component-containers-embed-player-youtube-tooltip-color-background-dark
              )
            ),
            'text': (
              'color': (
                'light': $emu-component-containers-embed-player-youtube-tooltip-text-color-light,
                'dark': $emu-component-containers-embed-player-youtube-tooltip-text-color-dark
              )
            ),
            'shadow': $emu-component-containers-embed-player-youtube-tooltip-shadow,
            'borderRadius': (
              'all': $emu-component-containers-embed-player-youtube-tooltip-border-radius-all
            ),
            'padding': $emu-component-containers-embed-player-youtube-tooltip-padding,
            'arrowSize': $emu-component-containers-embed-player-youtube-tooltip-arrow-size
          ),
          'scrubber': (
            'color': (
              'background': (
                'active': (
                  'light': $emu-component-containers-embed-player-youtube-scrubber-color-background-active-light,
                  'dark': $emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark
                ),
                'loading': (
                  'light': $emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light,
                  'dark': $emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark
                )
              )
            ),
            'sizing': (
              'height': $emu-component-containers-embed-player-youtube-scrubber-sizing-height
            ),
            'thumb': (
              'sizing': (
                'height': $emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height
              ),
              'color': (
                'background': (
                  'light': $emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light,
                  'dark': $emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark
                )
              ),
              'shadow': (
                'one': $emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one,
                'two': $emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two,
                'width': (
                  'active': $emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active
                )
              )
            ),
            'loading': (
              'size': $emu-component-containers-embed-player-youtube-scrubber-loading-size
            ),
            'audio': (
              'progress': (
                'color': (
                  'background': (
                    'buffered': (
                      'light': $emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light,
                      'dark': $emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark
                    )
                  )
                )
              ),
              'thumb': (
                'shadow': (
                  'active': (
                    'light': $emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light,
                    'dark': $emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark
                  )
                )
              ),
              'track': (
                'color': (
                  'background': (
                    'light': $emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light,
                    'dark': $emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark
                  )
                )
              )
            ),
            'video': (
              'progress': (
                'color': (
                  'background': (
                    'buffered': (
                      'light': $emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light,
                      'dark': $emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark
                    )
                  )
                )
              ),
              'thumb': (
                'shadow': (
                  'active': (
                    'light': $emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light,
                    'dark': $emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark
                  )
                )
              ),
              'track': (
                'color': (
                  'background': (
                    'light': $emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light,
                    'dark': $emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark
                  )
                )
              )
            )
          ),
          'captions': (
            'text': (
              'color': (
                'light': $emu-component-containers-embed-player-youtube-captions-text-color-light,
                'dark': $emu-component-containers-embed-player-youtube-captions-text-color-dark
              )
            ),
            'color': (
              'background': (
                'light': $emu-component-containers-embed-player-youtube-captions-color-background-light,
                'dark': $emu-component-containers-embed-player-youtube-captions-color-background-dark
              )
            )
          ),
          'time': (
            'text': (
              'typography': (
                'fontSize': $emu-component-containers-embed-player-youtube-time-text-typography-font-size
              )
            )
          )
        )
      ),
      'gallery': (
        'padding': (
          'top': (
            'narrow': $emu-component-containers-gallery-padding-top-narrow,
            'wide': $emu-component-containers-gallery-padding-top-wide
          ),
          'right': (
            'narrow': $emu-component-containers-gallery-padding-right-narrow,
            'wide': $emu-component-containers-gallery-padding-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-containers-gallery-padding-bottom-narrow,
            'wide': $emu-component-containers-gallery-padding-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-containers-gallery-padding-left-narrow,
            'wide': $emu-component-containers-gallery-padding-left-wide
          )
        ),
        'margin': (
          'top': (
            'narrow': $emu-component-containers-gallery-margin-top-narrow,
            'wide': $emu-component-containers-gallery-margin-top-wide
          ),
          'right': (
            'narrow': $emu-component-containers-gallery-margin-right-narrow,
            'wide': $emu-component-containers-gallery-margin-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-containers-gallery-margin-bottom-narrow,
            'wide': $emu-component-containers-gallery-margin-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-containers-gallery-margin-left-narrow,
            'wide': $emu-component-containers-gallery-margin-left-wide
          )
        ),
        'borderWidth': $emu-component-containers-gallery-border-width,
        'color': (
          'background': (
            'light': $emu-component-containers-gallery-color-background-light,
            'dark': $emu-component-containers-gallery-color-background-dark
          ),
          'border': (
            'light': $emu-component-containers-gallery-color-border-light,
            'dark': $emu-component-containers-gallery-color-border-dark
          )
        ),
        'separatorContent': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-gallery-separator-content-padding-top-narrow,
              'wide': $emu-component-containers-gallery-separator-content-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-gallery-separator-content-padding-right-narrow,
              'wide': $emu-component-containers-gallery-separator-content-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-gallery-separator-content-padding-bottom-narrow,
              'wide': $emu-component-containers-gallery-separator-content-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-gallery-separator-content-padding-left-narrow,
              'wide': $emu-component-containers-gallery-separator-content-padding-left-wide
            )
          )
        ),
        'item': (
          'sizing': (
            'width': (
              'narrow': $emu-component-containers-gallery-item-sizing-width-narrow,
              'wide': $emu-component-containers-gallery-item-sizing-width-wide
            )
          ),
          'padding': (
            'top': (
              'narrow': $emu-component-containers-gallery-item-padding-top-narrow,
              'wide': $emu-component-containers-gallery-item-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-gallery-item-padding-right-narrow,
              'wide': $emu-component-containers-gallery-item-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-gallery-item-padding-bottom-narrow,
              'wide': $emu-component-containers-gallery-item-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-gallery-item-padding-left-narrow,
              'wide': $emu-component-containers-gallery-item-padding-left-wide
            )
          ),
          'margin': (
            'top': (
              'narrow': $emu-component-containers-gallery-item-margin-top-narrow,
              'wide': $emu-component-containers-gallery-item-margin-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-gallery-item-margin-right-narrow,
              'wide': $emu-component-containers-gallery-item-margin-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-gallery-item-margin-bottom-narrow,
              'wide': $emu-component-containers-gallery-item-margin-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-gallery-item-margin-left-narrow,
              'wide': $emu-component-containers-gallery-item-margin-left-wide
            )
          ),
          'borderWidth': $emu-component-containers-gallery-item-border-width,
          'text': (
            'color': (
              'light': $emu-component-containers-gallery-item-text-color-light,
              'dark': $emu-component-containers-gallery-item-text-color-dark
            )
          ),
          'color': (
            'background': (
              'light': $emu-component-containers-gallery-item-color-background-light,
              'dark': $emu-component-containers-gallery-item-color-background-dark
            ),
            'border': (
              'light': $emu-component-containers-gallery-item-color-border-light,
              'dark': $emu-component-containers-gallery-item-color-border-dark
            )
          ),
          'active': (
            'text': (
              'color': (
                'light': $emu-component-containers-gallery-item-active-text-color-light,
                'dark': $emu-component-containers-gallery-item-active-text-color-dark
              )
            ),
            'color': (
              'background': (
                'light': $emu-component-containers-gallery-item-active-color-background-light,
                'dark': $emu-component-containers-gallery-item-active-color-background-dark
              ),
              'border': (
                'light': $emu-component-containers-gallery-item-active-color-border-light,
                'dark': $emu-component-containers-gallery-item-active-color-border-dark
              )
            )
          ),
          'hover': (
            'text': (
              'color': (
                'light': $emu-component-containers-gallery-item-hover-text-color-light,
                'dark': $emu-component-containers-gallery-item-hover-text-color-dark
              )
            ),
            'color': (
              'background': (
                'light': $emu-component-containers-gallery-item-hover-color-background-light,
                'dark': $emu-component-containers-gallery-item-hover-color-background-dark
              ),
              'border': (
                'light': $emu-component-containers-gallery-item-hover-color-border-light,
                'dark': $emu-component-containers-gallery-item-hover-color-border-dark
              )
            )
          )
        ),
        'stageContainer': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-gallery-stage-container-padding-top-narrow,
              'wide': $emu-component-containers-gallery-stage-container-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-gallery-stage-container-padding-right-narrow,
              'wide': $emu-component-containers-gallery-stage-container-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-gallery-stage-container-padding-bottom-narrow,
              'wide': $emu-component-containers-gallery-stage-container-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-gallery-stage-container-padding-left-narrow,
              'wide': $emu-component-containers-gallery-stage-container-padding-left-wide
            )
          ),
          'margin': (
            'top': (
              'narrow': $emu-component-containers-gallery-stage-container-margin-top-narrow,
              'wide': $emu-component-containers-gallery-stage-container-margin-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-gallery-stage-container-margin-right-narrow,
              'wide': $emu-component-containers-gallery-stage-container-margin-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-gallery-stage-container-margin-bottom-narrow,
              'wide': $emu-component-containers-gallery-stage-container-margin-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-gallery-stage-container-margin-left-narrow,
              'wide': $emu-component-containers-gallery-stage-container-margin-left-wide
            )
          )
        ),
        'previewContainer': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-gallery-preview-container-padding-top-narrow,
              'wide': $emu-component-containers-gallery-preview-container-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-gallery-preview-container-padding-right-narrow,
              'wide': $emu-component-containers-gallery-preview-container-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-gallery-preview-container-padding-bottom-narrow,
              'wide': $emu-component-containers-gallery-preview-container-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-gallery-preview-container-padding-left-narrow,
              'wide': $emu-component-containers-gallery-preview-container-padding-left-wide
            )
          ),
          'margin': (
            'top': (
              'narrow': $emu-component-containers-gallery-preview-container-margin-top-narrow,
              'wide': $emu-component-containers-gallery-preview-container-margin-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-gallery-preview-container-margin-right-narrow,
              'wide': $emu-component-containers-gallery-preview-container-margin-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-gallery-preview-container-margin-bottom-narrow,
              'wide': $emu-component-containers-gallery-preview-container-margin-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-gallery-preview-container-margin-left-narrow,
              'wide': $emu-component-containers-gallery-preview-container-margin-left-wide
            )
          ),
          'gap': (
            'narrow': $emu-component-containers-gallery-preview-container-gap-narrow,
            'wide': $emu-component-containers-gallery-preview-container-gap-wide
          )
        )
      )
    ),
    'layers': (
      'modal': (
        'zIndex': $emu-component-layers-modal-z-index,
        'scrim': (
          'color': (
            'background': $emu-component-layers-modal-scrim-color-background
          )
        ),
        'body': (
          'sizing': (
            'width': $emu-component-layers-modal-body-sizing-width,
            'maxWidth': $emu-component-layers-modal-body-sizing-max-width
          ),
          'padding': (
            'top': (
              'narrow': $emu-component-layers-modal-body-padding-top-narrow,
              'wide': $emu-component-layers-modal-body-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-layers-modal-body-padding-right-narrow,
              'wide': $emu-component-layers-modal-body-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-layers-modal-body-padding-bottom-narrow,
              'wide': $emu-component-layers-modal-body-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-layers-modal-body-padding-left-narrow,
              'wide': $emu-component-layers-modal-body-padding-left-wide
            )
          ),
          'color': (
            'background': (
              'light': $emu-component-layers-modal-body-color-background-light,
              'dark': $emu-component-layers-modal-body-color-background-dark
            ),
            'border': (
              'light': $emu-component-layers-modal-body-color-border-light,
              'dark': $emu-component-layers-modal-body-color-border-dark
            )
          ),
          'borderWidth': (
            'all': $emu-component-layers-modal-body-border-width-all
          )
        ),
        'actions': (
          'close': (
            'padding': (
              'top': $emu-component-layers-modal-actions-close-padding-top,
              'right': $emu-component-layers-modal-actions-close-padding-right,
              'bottom': $emu-component-layers-modal-actions-close-padding-bottom,
              'left': $emu-component-layers-modal-actions-close-padding-left
            ),
            'text': (
              'typography': $emu-component-layers-modal-actions-close-text-typography,
              'color': (
                'light': $emu-component-layers-modal-actions-close-text-color-light,
                'dark': $emu-component-layers-modal-actions-close-text-color-dark
              )
            )
          ),
          'prompt': (
            'buttonGroup': (
              'gap': (
                'narrow': $emu-component-layers-modal-actions-prompt-button-group-gap-narrow,
                'wide': $emu-component-layers-modal-actions-prompt-button-group-gap-wide
              ),
              'margin': (
                'top': $emu-component-layers-modal-actions-prompt-button-group-margin-top
              )
            )
          )
        )
      ),
      'cookieConsentBanner': (
        'zIndex': $emu-component-layers-cookie-consent-banner-z-index,
        'maxWidth': $emu-component-layers-cookie-consent-banner-max-width,
        'text': (
          'typography': (
            'narrow': $emu-component-layers-cookie-consent-banner-text-typography-narrow,
            'wide': $emu-component-layers-cookie-consent-banner-text-typography-wide
          ),
          'color': (
            'light': $emu-component-layers-cookie-consent-banner-text-color-light,
            'dark': $emu-component-layers-cookie-consent-banner-text-color-dark
          )
        ),
        'color': (
          'background': (
            'light': $emu-component-layers-cookie-consent-banner-color-background-light,
            'dark': $emu-component-layers-cookie-consent-banner-color-background-dark
          )
        ),
        'shadow': $emu-component-layers-cookie-consent-banner-shadow,
        'padding': (
          'top': (
            'narrow': $emu-component-layers-cookie-consent-banner-padding-top-narrow,
            'wide': $emu-component-layers-cookie-consent-banner-padding-top-wide
          ),
          'right': (
            'narrow': $emu-component-layers-cookie-consent-banner-padding-right-narrow,
            'wide': $emu-component-layers-cookie-consent-banner-padding-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-layers-cookie-consent-banner-padding-bottom-narrow,
            'wide': $emu-component-layers-cookie-consent-banner-padding-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-layers-cookie-consent-banner-padding-left-narrow,
            'wide': $emu-component-layers-cookie-consent-banner-padding-left-wide
          )
        ),
        'settingsButton': (
          'text': (
            'typography': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide
            ),
            'color': (
              'light': $emu-component-layers-cookie-consent-banner-settings-button-text-color-light,
              'dark': $emu-component-layers-cookie-consent-banner-settings-button-text-color-dark,
              'focus': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark
              ),
              'hover': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark
              ),
              'active': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark
              )
            )
          ),
          'padding': (
            'top': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide
            )
          ),
          'margin': (
            'top': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide
            ),
            'right': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide
            )
          ),
          'borderWidth': $emu-component-layers-cookie-consent-banner-settings-button-border-width,
          'outlineWidth': $emu-component-layers-cookie-consent-banner-settings-button-outline-width,
          'borderRadius': (
            'topLeft': $emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left,
            'topRight': $emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right,
            'bottomRight': $emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right,
            'bottomLeft': $emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left
          ),
          'color': (
            'border': (
              'light': $emu-component-layers-cookie-consent-banner-settings-button-color-border-light,
              'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-border-dark,
              'focus': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark
              ),
              'hover': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark
              ),
              'active': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark
              )
            ),
            'background': (
              'light': $emu-component-layers-cookie-consent-banner-settings-button-color-background-light,
              'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-background-dark,
              'focus': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark
              ),
              'hover': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark
              ),
              'active': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark
              )
            ),
            'outlineColor': (
              'light': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light,
              'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark,
              'focus': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark
              ),
              'hover': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark
              ),
              'active': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark
              )
            )
          )
        )
      )
    )
  )
);

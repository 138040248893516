.container-isi {
  .aaaem-isi-container {
    bottom: var(--emu-common-spacing-none); //to match figma design
    max-height: 113px; //to match figma design
    transition: all 0.3s ease-in;

    @include mq('large') {
      max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
      left: var(--emu-common-spacing-none);
      right: var(--emu-common-spacing-none);
      margin: auto;
    }

    &__isi-content {
      max-width: 100%;
      margin: auto;
      position: relative;
      z-index: var(--emu-common-other-z-index-isi);

      @include mq('large') {
        z-index: var(--emu-common-other-z-index-header);
      }

      @include mq('medium') {
        max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
        > div:first-child {
          padding-bottom: 58px; //to match figma design
        }
      }

      .aaaem-text {
        p {
          font-weight: var(--emu-semantic-font-weight-light);
          color: var(--emu-common-colors-black);
          word-break: break-word;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: 32px; //to match figma design
          }

          sup {
            vertical-align: top;
            top: -3px;

            @include mq('large') {
              top: -4px;
            }
          }

          a {
            color: var(--emu-common-colors-black);

            &[href^='tel:'] {
              text-decoration: none;
            }
          }
        }

        ul {
          padding-inline-start: 28px; //to match figma design
          margin-top: var(--emu-common-spacing-xxs);
          margin-bottom: var(--emu-common-spacing-none);
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
          @include mq('large') {
            padding-inline-start: 37px; //to match figma design
          }
          li,
          p,
          b {
            color: var(--emu-common-colors-black);
            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-narrow-large);
              line-height: 32px; //to match figma design
            }
          }
        }
      }
    }

    .aaaem-isi-banner {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 89%;
      margin-top: var(--emu-common-spacing-none);
      margin-right: auto;
      margin-bottom: var(--emu-common-spacing-none);
      margin-left: auto;
      border-top-left-radius: var(--emu-common-border-radius-small);
      border-top-right-radius: var(--emu-common-border-radius-small);
      background-color: var(--emu-semantic-colors-primary-violet-100);
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05); //to match figma design

      @include mq('large') {
        max-width: 1298px; // to match figma design
        width: 91%;
        padding-left: 30px; //to match figma design
        padding-right: 30px; //to match figma design
      }

      .aaaem-button {
        padding: var(--emu-common-spacing-none) var(--emu-common-spacing-none)
          10px; //to match figma design
        pointer-events: none;
        visibility: hidden;
        opacity: 0;

        .aaaem-button__icon {
          &::before,
          &::after {
            display: none;
          }
        }
      }

      .aaaem-isi-container__banner-content {
        margin-top: -10px;
        max-width: 300px; //to match figma design

        // mq needed to make it look good
        @include mq('425px') {
          max-width: 100%;
        }

        p,
        ul {
          width: 100%;
          color: var(--emu-component-ingredients-title-color-dark);
          margin: var(--emu-common-spacing-none);
          font-size: 16px; //to match figma design
          line-height: normal;
          font-weight: var(--emu-common-font-weight-regular);

          sup {
            top: 1px;
            vertical-align: top;
            font-size: 60%;
            position: relative;
          }
        }

        ul {
          padding-top: var(--emu-common-spacing-xxs);
          padding-inline-start: 20px; //to match figma design

          @include mq('large') {
            padding-inline-start: 30px; //to match figma design
          }

          li {
            font-size: inherit;
            line-height: 16px; //to match figma design
          }
        }
      }
    }

    &__button-wrapper {
      align-self: end;
    }
  }
}

// plus icon of the ISI. The one that shows Out out the box from ISI cannot be used because of the customized scroll functionality of LO3
.isi-dummy-clicker {
  &__container {
    position: fixed;
    bottom: 93px;
    left: 0;
    width: 100%;
    opacity: 1;
    pointer-events: initial;
    transition: all 0.1s linear 0.2s;

    > .button {
      width: 89%;
      margin-left: auto;
      margin-right: auto;
      padding-left: var(--emu-common-spacing-small);
      padding-right: var(--emu-common-spacing-small);
      display: flex;
      justify-content: flex-end;

      @include mq('large') {
        padding-left: 30px;
        padding-right: 30px;
        width: 91%;
        max-width: 1298px;
      }

      .isi-dummy-clicker__cta.emu-button-v2.emu-button {
        padding: var(--emu-common-spacing-none);
        width: auto;
        height: auto;
        transform: translateY(6px);

        &,
        &:active,
        &:hover {
          background-color: var(--emu-common-colors-transparent);
          outline: var(--emu-common-outline-width-unset);
          border: var(--emu-common-border-width-none);
        }

        @include mq('medium') {
          transform: translateY(7px) translateX(-8px);
        }

        @include mq('large') {
          transform: translateY(16px) translateX(12px);
        }

        &::after {
          content: '+';
          color: var(--emu-component-ingredients-title-color-dark);
          font-size: 36px;
          font-weight: var(--emu-common-font-weight-light);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
          }
        }
      }
    }
  }
}

.js-content-shown .isi-dummy-clicker__container {
  opacity: 0;
  pointer-events: none;
  transition: none;
}

[data-component='container-isi-v2'].js-content-shown .isi-container {
  transition: all 0.3s ease-out;
  opacity: 0;
  pointer-events: none;
}

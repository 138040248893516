.real-stories {
  &--screen-0 {
    .content-box {
      &__title {
        sup {
          margin-left: 2px; //to match figma design
          font-size: 50%;
          letter-spacing: -1px;
          top: -5px; //to match figma design

          @include mq('large') {
            top: -9px; //to match figma design
          }
        }
      }
    }
  }

  &--screen-1 {
    [data-component='text'].patient-disclaimer {
      p {
        @include mq('large') {
          text-align: left;
        }
      }

      &--second-screen {
        display: none;

        @include mq('large') {
          display: inline-block;
          background-color: #2e184480; // intentionally added to give a opacity if 0.5 to the color
          top: 42px;
          right: 0;
          bottom: auto;
          left: auto;
          padding-top: 6px;
          padding-bottom: 7px;
          padding-left: 18px;
          padding-right: 24px;
          width: auto;
          min-width: 296px;

          p {
            width: 100%;
          }
        }
      }

      &--first-screen {
        @include mq('large') {
          display: none;
        }
      }
    }

    .content-box {
      opacity: 0;
    }

    &.content-visible {
      .content-box {
        opacity: 1;

        .patient-disclaimer--first-screen {
          display: none;

          @include mq('large') {
            display: block;
          }
        }
      }

      .real-stories__bg-img {
        opacity: 0;
        pointer-events: none;
        mix-blend-mode: revert;

        &--desktop {
          opacity: 1;
        }

        &--initial {
          transform: translateX(100%);
          opacity: 0;
          transition: right 0.4s linear, transform 0.8s linear 0.4s,
            opacity 0.4s linear 0.4s;
        }

        &--initial,
        &--final {
          @include mq('large') {
            right: -60vw;
          }

          @include mq('1140px') {
            right: -54.5vw;
          }

          @include mq('xx-large') {
            right: -800px;
          }
        }

        &--flowers {
          @include mq('large') {
            right: -20vw;
          }

          @include mq('1140px') {
            right: -17vw;
          }

          @include mq('xx-large') {
            right: -260px;
          }
        }
      }
    }

    .content-box {
      position: static;

      @include mq('large') {
        width: 590px;
      }

      &__inner {
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);

        .lo3-animated-subsection {
          height: auto !important;
        }
      }

      &__button {
        margin-top: 26px;
        padding-left: 70px;
        padding-right: 70px;
        max-width: 100%;

        @include mq('large') {
          padding-left: 72px;
          padding-right: 72px;
        }
      }

      &__text-small {
        margin-top: 33px;

        @include mq('large') {
          margin-top: 45px;
          max-width: 420px;
        }

        @include mq('x-large') {
          max-width: 481px;
        }

        p {
          letter-spacing: normal;
          text-indent: -8px;
          text-align: left;

          .emphasis {
            text-indent: initial;
            sup {
              top: 1px;
            }
          }
        }
      }
    }

    .real-stories {
      &__bg-img {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 187px; // 187 is the height of the ISI and buttons
        align-items: center;
        transition: right 0.4s linear, opacity 0.4s linear;
        transform: translateX(0);
        mix-blend-mode: color;

        @include mq('large') {
          padding-bottom: 113px;
        }

        &--mobile {
          display: flex;
          @include mq('large') {
            display: none;
          }
        }

        &--desktop {
          display: none;
          @include mq('large') {
            display: flex;
          }
        }

        img {
          width: auto;
          height: auto;
          margin: auto;

          @include mq('large') {
            width: 100%;
          }
        }
      }
    }
  }
}

.real-stories__bg-img {
  @include aem-editor-view {
    display: block !important;
    position: static !important;
  }
}

.real-stories--screen-1 .content-box {
  @include aem-editor-view {
    opacity: 1 !important;
  }
}

#real-stories {
  .lo3-section {
    overflow: hidden;
  }
}

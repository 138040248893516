.emu-atc {
  @include mq('large') {
    text-align: left;
  }

  .button .emu-button.emu-button-v2 {
    background-color: var(--emu-semantic-colors-primary-purple-100);
    text-align: center;
    border: var(--emu-common-border-width-thin) solid
      var(--emu-semantic-colors-primary-purple-100);
    border-radius: var(--emu-common-border-radius-large); // to match figma
    color: var(--emu-common-colors-grey-100);
    font: var(--emu-component-actions-button-text-typography-wide);
    justify-content: center;
    max-width: 350px; // to match figma
    padding: 9.5px var(--emu-common-spacing-large); // to match figma
    position: relative;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    width: 244px; // to match figma

    > svg {
      margin-right: auto;
      margin-left: -10px; // to match figma
    }
    > span {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 10px; //to match figma design

      @include mq('large') {
        padding-right: var(--emu-common-spacing-none);
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/right-arrow.png');
        background-repeat: no-repeat;
        display: flex;
        background-position: center right;
        position: absolute;
        left: var(--emu-common-spacing-medium);
        align-items: center;
        justify-content: flex-end;
        z-index: var(--emu-common-other-z-index-layer);

        @include mq('large') {
          left: var(--emu-common-spacing-none);
          margin-left: 18px; // to match figma
          background-size: 7px; // to match figma
        }
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-color: var(--emu-semantic-colors-primary-purple-100);
      box-shadow: none;
      color: var(--emu-common-colors-grey-100);
      border: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-purple-100);
    }
  }

  &__dropdown-content {
    position: absolute;
    left: calc(50% - 82.5px);
    top: var(--emu-common-spacing-xl);
    width: 165px;
    border-radius: var(--emu-common-border-radius-small);
    background-color: #2e2e2e;
    color: #dedede;
    box-shadow: rgba(0 0 0 / 60%) 0 4px 20px -2px,
      rgba(0 0 0 / 40%) 0 2px 4px -1px;
    z-index: var(--emu-common-other-z-index-cookie-banner);
    overflow: hidden;

    li:nth-child(1) img,
    li:nth-child(2) img,
    li:nth-child(4) img {
      filter: invert(100%);
    }

    a {
      color: var(--emu-common-colors-grey-100);
      font: var(--emu-component-actions-button-text-typography-wide);
      padding: 14px 22px; //to match figma
      display: flex;

      &:hover {
        background-color: #373737;
        color: #f1f1f1;
      }

      img {
        width: 18px; //to match figma
        height: 18px; //to match figma
      }

      span {
        margin-left: 13px; //to match figma
      }
    }
  }
}
.emu-atc.disabled {
  .button .emu-button.emu-button-v2 {
    transition: all 0.4s linear;
    opacity: 0.5 !important; // needed to override the inline styles added by the JavaScript
    pointer-events: none;
  }

  .emu-atc__main--dropdown ul {
    display: none !important;
  }
}

div.emu-form-dropdown[data-component='dropdown-menu'][data-emu-type='dropdown'] {
  .emu-dropdown-menu {
    &__button {
      border-style: solid;
      font-weight: var(--emu-common-font-weight-regular);
      line-height: normal;
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
      width: 100% !important; // needed to override the styles added via JavaScript
      display: flex;
      max-width: 375px;
      margin-left: auto;
      margin-right: auto;
      z-index: var(--emu-common-other-z-index-isi);

      @include mq('large') {
        max-width: 100%;
      }

      svg {
        width: 12px;
        height: 10px;
        transition: all 0.2s linear;
      }

      span {
        @include mq('large') {
          flex: 1;
          text-align: center;
        }
      }
    }

    &__group {
      box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.1);
      border-radius: var(--emu-common-border-radius-small);
      background-color: var(--emu-semantic-colors-primary-purple-500);
      z-index: var(--emu-common-other-z-index-overlay);
      height: auto;
      pointer-events: none;
      transition: all 0.2s linear;
      transform: translateY(-40px);
      width: 100% !important; // needed to override the styles added via JavaScript
      max-width: 375px;

      @include mq('large') {
        max-width: 379px;
      }
    }

    &__item {
      font-weight: var(--emu-common-font-weight-regular);
      color: var(--emu-semantic-colors-primary-purple-600);
      background-color: var(--emu-common-colors-transparent);

      @include mq('large') {
        text-align: center;
      }

      &[aria-selected='true'],
      &:hover {
        background-color: var(--emu-semantic-colors-primary-purple-100);
        color: var(--emu-common-colors-white);
      }
    }
  }

  // when dropdown is open
  &:not(.js-hide-dropdown) {
    .emu-dropdown-menu {
      &__button {
        border-radius: var(
          --emu-component-ingredients-select-input-border-radius-narrow
        );
        background-color: var(--emu-semantic-colors-primary-purple-100);
        border-color: var(--emu-semantic-colors-primary-purple-100);
        color: var(--emu-common-colors-white);

        svg path {
          fill: var(--emu-common-colors-white);
        }
      }

      &__group {
        pointer-events: initial;
        opacity: 1;
        transform: translateY(10px);
      }
    }
  }
}

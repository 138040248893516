.first-look-description {
  .content-box {
    @include mq('x-large') {
      width: 812px; // as per figma
    }

    &__title {
      max-width: 70%;
      margin-left: auto;
      margin-right: auto;

      @include mq('large') {
        max-width: none;
      }
    }

    &__inner {
      @include mq('xx-large') {
        padding-top: 100px; // to align with the side nav, as per design
      }
    }
  }

  &--screen-1 {
    .content-box {
      &__text {
        @include mq('large') {
          text-indent: -4px;
        }
      }
    }
  }
}

.real-results {
  &--screen-1 {
    .tablet-mobile-view {
      @include aem-editor-view {
        display: block !important;
      }
    }
  }

  &--screen-2 {
    .content-box {
      @include mq('x-large') {
        width: 810px;
      }

      &__title {
        .emphasis {
          sup {
            font-size: 95%;
            top: 0;
          }
        }
      }

      &__text-small {
        max-width: 785px;

        p,
        li {
          color: var(--emu-semantic-colors-secondary-grey-500);
          text-indent: -8px;
          letter-spacing: normal;
          text-align: left;
        }

        .emphasis {
          text-indent: initial;
          sup {
            top: 1px;
          }
        }
      }
    }
  }

  // testimonial carousel
  &--screen-3 {
    .real-results-modal-trigger {
      display: none;
    }

    .content-box {
      width: auto;

      @include mq('large') {
        margin-right: 4.5%;
        padding-right: var(--emu-common-spacing-none);
      }
    }

    .aaaem-carousel {
      transform: translateY(60px);
      opacity: 0;
      margin: var(--emu-common-spacing-none);

      .tns-outer {
        &:before,
        &::after {
          content: '';
          display: block;
          background-image: linear-gradient(90deg, #160c1f99, 75%, #fff0 100%);
          width: var(--emu-common-sizing-none); //for gradient
          height: 240px; //for gradient,Image height
          position: absolute;
          z-index: var(--emu-common-other-z-index-layer);
          opacity: 0;
          pointer-events: none;
          transition: all 0.4s linear;
          left: 0;
          top: 0;

          @include mq('large') {
            // height: calc(100% - 4px); //for gradient,Image height
            height: 100%;
          }
        }

        &:after {
          left: auto;
          right: 0;
          background-image: linear-gradient(270deg, #160c1f99, 75%, #fff0 100%);

          @include mq('large') {
            background-image: linear-gradient(
              270deg,
              #160c1f99,
              75%,
              #fff0 100%
            );
          }
        }

        &.prevActive:before,
        &.nextActive:after {
          opacity: 1;
          width: 29px;

          @include mq('large') {
            width: 100px;
          }
        }

        .tns-item {
          @include mq('large') {
            max-width: calc(45vh - 20px);
          }
        }
      }

      &__content {
        margin-bottom: var(--emu-common-spacing-xs);
      }

      &__item {
        &-container {
          .testimonial-slide {
            position: relative;
            cursor: pointer;

            &:before {
              content: '';
              height: 40%;
              width: 100%;
              background: linear-gradient(0deg, #2e184466 0%, #2e184400 100%);
              position: absolute;
              left: var(--emu-common-spacing-none);
              right: var(--emu-common-spacing-none);
              bottom: var(--emu-common-spacing-none);
              z-index: var(--emu-common-other-z-index-layer);
              border-radius: 10px;
            }

            &:after {
              content: '';
              position: absolute;
              left: 18px;
              bottom: 20px;
              z-index: var(--emu-common-other-z-index-layer);
              background-image: url('./assets/images/hand-icon.png');
              background-repeat: no-repeat;
              background-size: contain;
              width: var(--emu-common-sizing-small);
              height: 21px;

              @include mq('large') {
                width: 28px;
                height: var(--emu-common-sizing-medium);
                left: 30px;
                bottom: 30px;
              }
            }

            &__img {
              img {
                position: relative;
                z-index: var(--emu-common-other-z-index-base);
                border-radius: 10px;
                object-fit: cover;
                width: 216px; //to match figma design
                height: 240px; //to match figma design

                @include mq('large') {
                  width: 360px;
                  height: auto;
                }
              }
            }

            &__text {
              position: absolute;
              bottom: 5px;
              right: 10px;
              z-index: var(--emu-common-other-z-index-layer);
              width: max-content;

              @include mq('large') {
                bottom: var(--emu-common-spacing-small);
                right: 18px;
              }

              p {
                margin: var(--emu-common-spacing-none);
                text-align: right;
                font-weight: var(--emu-common-font-weight-regular);
                font-size: var(--emu-common-font-sizes-wide-medium);
              }
            }
          }
        }
      }

      &__actions {
        position: absolute;
        bottom: -60px;
        right: 0;

        @include mq('large') {
          bottom: -84px;
        }
      }

      &__action {
        position: relative;
        width: 36px;
        height: 36px;
        background-repeat: no-repeat;
        background-color: var(--emu-common-colors-white);
        border-radius: 100%;
        background-position: center;
        background-size: 21px 18px;
        border: none;

        @include mq('large') {
          background-size: auto;
          width: 48px;
          height: 48px;
        }

        &:disabled {
          opacity: 0.5;
        }

        svg {
          display: none;
        }

        &-prev {
          margin-right: var(--emu-common-spacing-medium);
          background-image: url('./assets/images/arrow-prev.png');

          @include mq('large') {
            margin-right: var(--emu-common-spacing-large);
          }
        }

        &-next {
          background-image: url('./assets/images/arrow-next.png');
        }
      }
    }

    .patient-disclaimer {
      p {
        @include mq('large') {
          text-align: left;
        }
      }
    }
  }

  &__modal {
    // when there is a video that is made fullscreen, hide the other items to make sure that the video appears fullscreen
    &:has(.plyr--fullscreen-fallback) {
      .modal-wrapper {
        z-index: 700;
      }

      .custom-tabs__select-wrapper,
      .aaaem-tabs__tablist {
        visibility: hidden;
      }

      .modal-content {
        > .container {
          z-index: 700;
          position: relative;
        }
      }

      .plyr--fullscreen-fallback {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .embed .aaaem-embed__oembed {
      padding-bottom: 56.25%;
    }

    .emu-pf {
      overflow: initial;
    }

    .modal-content {
      top: 0;
      .close {
        background-color: var(--emu-common-colors-white);
        border-radius: 50%;
      }
    }

    &[data-component='modal'].emu-modal {
      .modal-wrapper,
      .modal-content {
        // for mobile versions disabling the animations
        @include mq('large', 'max-width') {
          opacity: 1;
          display: none;
        }
      }

      .modal-wrapper.is-open {
        @include mq('large', 'max-width') {
          display: block;
        }

        .modal-content {
          display: block;
        }
      }
    }
  }
}

// testimonial carousel animations
.real-results--screen-3 {
  .aaaem-carousel {
    opacity: 0;
    transition-property: all;
    transition-timing-function: ease-in;
    transition-duration: 0.4s;
  }
}

.lo3-section-container--active {
  .real-results--screen-3 .aaaem-carousel {
    transform: translateY(0);
    opacity: 1;
  }
}

.lo3-section-container--prev-active {
  .real-results--screen-3 .aaaem-carousel {
    transform: translateY(-100px);
    opacity: 0;
  }
}

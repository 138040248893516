#home-banner,
#home-banner-inner {
  .patient-disclaimer {
    p {
      text-align: right;

      @include mq('large') {
        text-align: left;
      }
    }
  }

  .home-banner__video-container {
    &--mobile {
      iframe {
        width: 100%;
        transform: translateY(-60%) translateX(-50%);
      }
    }
  }
}

// author mode styles
.home-banner__video-container {
  @include aem-editor-view {
    display: block !important;
  }
}

#error {
  .header__sidenav-container {
    display: none;
  }

  .content-box {
    max-width: var(--emu-component-containers-container-max-width);
    margin: var(--emu-common-spacing-none);
    width: 100%;
  }

  .header .cmp-container-header {
    background-color: var(--emu-common-colors-white);
    padding-bottom: var(--emu-common-spacing-medium);
    @include mq('large') {
      margin-top: 42px;
    }
  }

  .error-container {
    .aem-Grid {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__title {
      .emu-title__text {
        font-size: var(--emu-semantic-font-sizes-wide-xxxl);
        line-height: var(--emu-semantic-line-heights-wide-xxxl);
        margin-bottom: var(--emu-common-spacing-large);
        margin-top: var(--emu-common-spacing-large);

        @include mq('large') {
          font-size: 160px;
          line-height: 192px;
        }
      }
    }

    &__text {
      p {
        margin-top: var(--emu-common-spacing-medium);
        margin-bottom: var(--emu-common-spacing-medium);
      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;

      .cmp-button {
        width: auto;
        padding-left: var(--emu-common-spacing-large);
        padding-right: var(--emu-common-spacing-large);
        margin-top: var(--emu-common-spacing-medium);
      }
    }

    &__footer-text {
      margin-top: var(--emu-common-spacing-medium);
      margin-bottom: var(--emu-common-spacing-medium);

      a {
        color: var(--emu-semantic-colors-primary-purple-100);
        font-weight: var(--emu-common-font-weight-regular);
      }
    }
  }
}
